import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

export const EditorX = ({ editorState, onEditorStateChange, placeholder }) => {
  const editorRef = useRef(null);

  return (
    <>
      <JoditEditor
        ref={editorRef}
        config={{ placeholder: '' }}
        value={editorState}
        onBlur={(content) => onEditorStateChange(content)}
      />
    </>
  );
};
