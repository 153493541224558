import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import {del, get, post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {page_limit} from "../../config";
import pageColumns from "../../page.columns";
import {useHistory} from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import {showError} from "../../helpers/util.helper";
import toastr from "toastr";
import {TableLoader} from "../../components/Loader";
import SettingsImagePreview from "../Settings/util/image-preview";
import Moment from "react-moment";

const provinces = [{
    key: 'ab',
    label: 'Alberta'
}, {
    key: 'on',
    label: 'Ontario'
}, {
    key: 'bc',
    label: 'British Columbia'
}, {
    key: 'mb',
    label: 'Manitoba'
}, {
    key: 'nb',
    label: 'New Brunswick'
}, {
    key: 'nl',
    label: 'Newfoundland and Labrador'
}, {
    key: 'ns',
    label: 'Nova Scotia'
}, {
    key: 'on',
    label: 'Ontario'
}, {
    key: 'qc',
    label: 'Quebec'
}, {
    key: 'sk',
    label: 'Saskatchewan'
},];

const PreConstruction = ({match}) => {
    let history = useHistory();
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({});

    const [filterFields, setFilterFields] = useState([]);
    const [deletingPreConstructionId, setDeletingPreConstructionId] = useState(null);
    useEffect(() => {
        getPreConstruction({limit, offset});

    }, []);

    const actions = [
        {
            name: "Edit",
            icon: "fas fa-pencil-alt",
            fn: editPreConstruction
        },
        {
            name: "Share",
            icon: "fas fa-eye",
            fn: viewProperty
        },
        {
            name: "showOnWebsite",
            title: '',
            type: 'toggle',
            fn: togglePreConstruction
        }];
    const limit = page_limit;
    const offset = 0;
    const [pagesLoading, setPageLoading] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState({
        columns: pageColumns.preConstruction,
        rows: []
    });

    function viewProperty(property) {
        window.open(property.link, '_blank');
    }

    function pageChange(e) {
        setCurrentPage(e);
        getPreConstruction({limit, offset: (limit * (e - 1))});

    }

    useEffect(() => {
        setFilterFields(filterFields.map(fields => {
            fields.value = filters[fields.name];
            return fields;
        }));
        pageChange(1);
    }, [filters]);

    const getPreConstruction = async ({limit, offset}) => {
        setPageLoading(1);
        const preConstructionData = await get(url.PRE_CONSTRUCTION, {
            params: {
                ...filters, limit, offset
            }
        });
        setPageLoading(0);
        preConstructionData.data = setPreConstructionData(preConstructionData.data);
        let _d = {...data, rows: preConstructionData.data};
        setData(_d);
        setTotalPages(preConstructionData.total_count);
        if (filterFields.length) {
            filterFields.map(fields => {
                fields.value = filters[fields.name];
                return fields;
            })
        } else {
            setFilterFields([{
                type: "text",
                name: 'search',
                placeholder: ''
            }, {
                type: "select",
                name: "province",
                label: 'Province',
                options: provinces.map(d => ({value: d.key, label: d.label}))
            }]);
        }
    };

    function editPreConstruction(preConstruction) {
        history.push("/editPreConstruction/" + preConstruction._id);
    }

    function handleFile(e, key) {
        const reader = new FileReader();
        reader.onload = function (e) {
            setData({...data, [key]: e.target.result});
        };
        reader.readAsDataURL(e.target.files[0]);
    }


    async function togglePreConstruction({_id}, status) {
        await post(url.PRE_CONSTRUCTION_TOGGLE, {id: _id});
        await getPreConstruction({limit, offset: (limit * (currentPage - 1))});
    }

    function addPreConstruction() {
        history.push("/addProject");
    }

    async function deletePreConstruction() {
        const response = await del(url.PRE_CONSTRUCTION + "/" + deletingPreConstructionId);
        if (!response.api_status) {
            let _d = data.rows.filter(d => d.preConstruction_id !== deletingPreConstructionId);
            setData({...data, rows: _d});
            setDeletingPreConstructionId(null);
            setDeleteModal(false);
            toastr.success("PreConstruction deleted successfully.", "Success");
        } else {
            showError(response);
        }
    }

    function openDeleteModal({preConstruction_id}) {
        setDeletingPreConstructionId(preConstruction_id);
        setDeleteModal(true);
    }

    function setPreConstructionData(preConstructions) {
        if (preConstructions && preConstructions.length) {
            return preConstructions.map(preConstruction => {
                if (preConstruction.image) {
                    preConstruction.image =
                        <SettingsImagePreview image={ preConstruction.image} width="100px" height="100px"/>;
                }
                if (preConstruction.lastUpdated) {
                    preConstruction.lastUpdated = <Moment format="MMM DD, YYYY">{preConstruction.lastUpdated}</Moment>
                }
                if (preConstruction.link) {
                    // preConstruction.link = <a href={preConstruction.link} target="_blank">View</a>
                }

                return preConstruction;
            });
        }
        return [];
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Project List"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {
                                        pagesLoading ?
                                            <TableLoader/>
                                            :
                                            <AETable columns={data.columns}
                                                     rows={data.rows}
                                                     itemsPerPage={limit}
                                                     loading={pagesLoading}
                                                     currentPage={currentPage}
                                                     actions={actions}
                                                     totalCount={totalPages}
                                                     filters={filterFields}
                                                     onFilter={setFilters}
                                                     pageFn={pageChange}/>
                                    }

                                </CardBody>
                                <DeleteModal
                                    onCloseClick={() => {
                                        setDeletingPreConstructionId(null);
                                        setDeleteModal(false);
                                    }}
                                    onDeleteClick={deletePreConstruction}
                                    show={deleteModal}>
                                </DeleteModal>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PreConstruction;
