import React from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

const Profile = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="VirtualTours" breadcrumbItem="Profile"/>
        </div>
        <Card>
          <CardBody>

            <AvForm>
              <Row>
                <Col md={6} sm={12}>
                  <AvField type="text" name="name" placeholder="Full Name"/>
                </Col>
                <Col md={6} sm={12}>
                  <AvField type="text" name="email" placeholder="Email" readonly/>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Profile;
