import { Card, CardBody, Col, Row } from "reactstrap"
import React from "react"
import PropTypes from "prop-types"

const WebsiteUsers = (props) => {
  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardBody>
            <h4 className="card-title">Website Users</h4>
            <p className="card-title-desc">Website users</p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
WebsiteUsers.propTypes = {
  data: PropTypes.object,
  handleValidSubmit: PropTypes.func
}
export default WebsiteUsers
