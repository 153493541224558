import { Button, Card, CardBody, Input, Label } from "reactstrap"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
import React, { useEffect, useRef, useState } from "react"
import { showError } from "../../helpers/util.helper"
import VisitorToolsEditModal from "./visitor-tools-edit.modal"

const visitors_config = [
  {
    title: "Calculators",
    name: "calculators",
    checked: true,
    order: 1,
    child: [
      {
        title: "Mortgage Calculator",
        name: "mortgage_calculator",
        checked: true,
        path: "/mortgage_calculator",
      },
      {
        title: "CMHC Insurance Calculator",
        name: "cmhc_insurance_calculator",
        checked: true,
        path: "/cmhc-insurance-calculator",
      },
      {
        title: "Land Transfer Calculator",
        name: "land_transfer_calculator",
        checked: true,
        path: "/land-transfer-tax-calculator",
      },
      {
        title: "Current Mortgage Rates",
        name: "current_mortgage_rates",
        checked: true,
        path: "/current-mortgage-rates",
      },
      {
        title: "Mortgage Payment Calculator ",
        name: "mortgage_payment_calculator",
        checked: true,
        path: "/mortgage-payments-calculator",
      },
      {
        title: "Affordability Calculator",
        name: "affordability_calculator",
        checked: true,
        path: "/affordability-calculator",
      },
    ],
  },
  {
    title: "Useful Links",
    name: "useful_links",
    checked: true,
    order: 2,
    child: [
      {
        title: "Bank of Canada",
        name: "bank_of_canada",
        checked: true,
        newTab: true,
        path: "https://www.bankofcanada.ca/",
      },
      {
        title: "Government of Canada",
        name: "government_of_canada",
        checked: true,
        newTab: true,
        path: "https://www.canada.ca/en.html",
      },
      {
        title: "City of Toronto",
        name: "government_of_toronto",
        newTab: true,
        path: "https://www.toronto.ca/",
      },
      {
        title: "City of Peel",
        name: "government_of_peel",
        newTab: true,
        path: "https://www.peelregion.ca/",
      },
    ],
  },
  {
    title: "Seller's Guide",
    name: "seller_guide",
    checked: true,
    order: 4,
    child: [
      {
        title: "Seller's Guide",
        name: "seller_guide_check",
        checked: true,
        path: "/seller-guide",
      },
      {
        title: "Home Evaluation",
        name: "home_evaluation",
        checked: true,
        path: "/landing/home-evaluation",
      },
      {
        title: "Recently Sold Alerts",
        name: "recently_sold",
        checked: true,
        path: "/landing/neighbourhood-watch",
      },
    ],
  },
  {
    title: "Buyer's Guide",
    name: "buyer_guide",
    checked: false,
    order: 3,
    child: [
      {
        title: "Buying Tips",
        name: "buying_tips",
        checked: true,
        path: "/buying-tips",
      },
      {
        title: "Buyer's Guide",
        name: "buyer_guide_check",
        checked: true,
        path: "/buyers-guide",
      },
      {
        title: "GST/HST Rebate",
        name: "gst_rebate",
        checked: true,
        newTab: true,
        path: "https://www.canada.ca/en/revenue-agency/services/forms-publications/publications/rc4028/gst-hst-new-housing-rebate.html",
      },
    ],
  },
]

const VisitorTools = ({ savedConfig, save, className }) => {
  const toolsRef = useRef()
  const [visitorConfig, setVisitorConfig] = useState({})
  const [vtModalData, setVtModalData] = useState({
    data: {},
    show: false,
    onClose: data => {
      if (data) {
        console.log(data, "data to save")
        setVtModalData(vt => {
          setVisitorConfig(config => {
            config[vt.row.name].child = config[vt.row.name].child
              .map(d => {
                if (d && d.title === vt.data.title) {
                  d.title = data.title
                  d.path = data.path
                  d.newTab = data.newTab
                }
                return d
              })
              .filter(d => d)
            return JSON.parse(JSON.stringify(config))
          })
          return { ...vt, show: false, data: {} }
        })
      } else {
        setVtModalData(vt => ({ ...vt, show: false, data: {} }))
      }
    },
  })
  toolsRef.current = visitorConfig
  useEffect(function () {
    let defaultConfig = {}
    visitors_config
      .sort((a, b) => a.order - b.order)
      .forEach(con => {
        defaultConfig[con.name] = con
      })
    setVisitorConfig(defaultConfig)
  }, [])
  const [form, setForm] = useState()
  useEffect(function () {
    if (savedConfig) {
      let config = {}
      JSON.parse(savedConfig)
        .sort((a, b) => a.order - b.order)
        .forEach(con => {
          config[con.name] = con
        })
      setVisitorConfig(c => {
        return JSON.parse(JSON.stringify(config))
      })
    }
  }, [])

  useEffect(function () {
    $(function () {
      function setSubMenuSort() {
        $(".visitor-menu-sort").sortable({
          stop: (e, ui) => {
            let vc = JSON.parse(
              JSON.stringify(
                Object.values(toolsRef.current).sort(
                  (a, b) => a.order - b.order
                )
              )
            )
            let config = $.map(
              $(".visitor-menu").find(".list-item"),
              function (el) {
                return el.id.split("-")[1]
              }
            ).map((name, i) => {
              let item = vc.find(r => r.name === name)
              $.map(
                $("." + name + "-checkbox").find(".list-item"),
                function (el, i) {
                  item.child.find(d => d.name === el.id).order = i
                  console.log(el.id, i)
                }
              )
              item.child = item.child.sort((a, b) => a.order - b.order)
              return item
            })
            let setConfig = {}
            config.forEach(con => {
              setConfig[con.name] = con
            })

            setTimeout(function () {
              setVisitorConfig({})
              setVisitorConfig(setConfig)
              setSubMenuSort()
            }, 100)
          },
        })
      }

      setSubMenuSort()
      let items = $(".visitor-menu").sortable({
        handle: ".handle",
        stop: (e, ui) => {
          let vc = JSON.parse(
            JSON.stringify(
              Object.values(toolsRef.current).sort((a, b) => a.order - b.order)
            )
          )
          let config = $.map(
            $(".visitor-menu").find(".list-item"),
            function (el) {
              return el.id.split("-")[1]
            }
          ).map((name, i) => {
            let item = vc.find(r => r.name === name)
            item.order = i
            return item
          })
          let setConfig = {}
          config.forEach(con => {
            setConfig[con.name] = con
          })
          setTimeout(function () {
            setVisitorConfig({})
            setVisitorConfig(setConfig)
          }, 0)
        },
      })
    })
  }, [])

  function saveData(values) {
    for (let name in values) {
      if (values.hasOwnProperty(name) && visitorConfig[name]) {
        visitorConfig[name].title = values[name].title
        visitorConfig[name].child = visitorConfig[name].child.map(child => {
          child.checked = values[child.name]
          return child
        })
      }
    }
    save({ visitors_tools: Object.values(visitorConfig) })
  }

  function getList() {
    return Object.values(visitorConfig).sort((a, b) => +a.order - +b.order)
  }

  function addSubMenu({ row }) {
    let title = $("#" + row.name + "-new-title").val()
    let link = $("#" + row.name + "-new-link").val()
    let newTab = $("#" + row.name + "-new-tab").val()
    if (visitorConfig[row.name].child.find(d => d.title === title)) {
      showError({ message: "Same link name exists." })
      return
    }
    if (!(title && title.trim())) {
      showError({ message: "Please enter a valid title." })
      return
    }
    if (!(link && link.trim())) {
      showError({ message: "Please enter a valid link." })
      return
    }
    let name = title.replaceAll(" ", "_")
    setVisitorConfig(config => {
      config[row.name].child.push({
        title,
        name,
        newTab,
        path: link,
        checked: true,
        custom: true,
      })
      row.add = false
      setVisitorConfig(JSON.parse(JSON.stringify(config)))
    })
  }

  useEffect(
    function () {
      console.log("config changed", visitorConfig)
    },
    [visitorConfig]
  )

  return (
    <>
      <Card className={className} ref={toolsRef}>
        <CardBody>
          <h4 className="card-title mb-1">Visitor's Tools</h4>
          <AvForm
            onValidSubmit={(e, values) => saveData(values)}
            ref={c => setForm(c)}
          >
            <div className="row mt-2 visitor-menu">
              {getList().map((row, key) => (
                <div
                  className="list-item col-12 border py-3 mt-2"
                  key={key}
                  id={"row-" + row.name}
                >
                  <div className="row">
                    <div className="col-10">
                      <AvInput
                        name={row.name + ".title"}
                        // required={visitorConfig[row.name].checked}
                        label={"Column " + key}
                        placeholder={row.title}
                        value={row.title}
                      />
                    </div>
                    <div className="col-2 handle d-flex align-items-center">
                      <div
                        className="d-flex align-items-center gap-2 "
                        style={{ flex: "1" }}
                      >
                        <div
                          style={{ flex: "1 1 100%", cursor: "move" }}
                          className="fa fa-align-justify"
                        />
                        <AvGroup check style={{ marginTop: "-35px" }}>
                          <Label check>
                            <AvInput
                              type="checkbox"
                              onChange={e =>
                                setVisitorConfig(c => {
                                  let config = JSON.parse(JSON.stringify(c))
                                  config[row.name].checked = e.target.checked
                                  return config
                                })
                              }
                              checked={row.checked}
                              className="px-2"
                              defaultChecked={row.checked}
                              name={row.name + "_checkbox"}
                            />
                          </Label>
                        </AvGroup>
                      </div>
                    </div>
                  </div>
                  <AvGroup
                    check
                    className={
                      "mt-2 d-flex flex-column visitor-menu-sort " +
                      row.name +
                      "-checkbox"
                    }
                  >
                    {row.child &&
                      row.child.map((check, key2) => (
                        <div className="d-flex justify-content-between">
                          <Label
                            check
                            key={key2}
                            className={"list-item"}
                            id={check.name}
                          >
                            <AvInput
                              type="checkbox"
                              name={check.name}
                              onChange={e => {
                                setVisitorConfig(c => {
                                  let config = JSON.parse(JSON.stringify(c))
                                  config[row.name].child.find(
                                    k => k.name === check.name
                                  ).checked = e.target.checked
                                  return config
                                })
                              }}
                              defaultChecked={check.checked}
                              checked={check.checked}
                            />{" "}
                            {check.title} <br />
                            <i
                              style={{
                                color: "#888888",
                                fontSize: "0.9em",
                              }}
                            >
                              Path: {check.path}
                            </i>
                          </Label>

                          <div>
                            <i
                              className="bx bx-pencil text-primary font-weight-bold"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setVtModalData(vt => ({
                                  ...vt,
                                  show: true,
                                  row,
                                  data: check,
                                }))
                              }}
                            />
                            {check.custom && (
                              <i
                                className="bx bx-x text-danger font-weight-bold"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setVisitorConfig(config => {
                                    config[row.name].child = config[
                                      row.name
                                    ].child.filter(d => d.name !== check.name)
                                    return JSON.parse(JSON.stringify(config))
                                  })
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                  </AvGroup>
                  {!row.add && (
                    <div className={"d-flex "}>
                      <span
                        className="btn btn-sm mt-2 btn-secondary"
                        onClick={() =>
                          setVisitorConfig(config => {
                            config[row.name].add = !config[row.name].add
                            return JSON.parse(JSON.stringify(config))
                          })
                        }
                      >
                        Add
                      </span>
                    </div>
                  )}
                  {row.add && (
                    <>
                      <div
                        className="d-flex flex-sm-row flex-column
                                            align-items-sm-center align-items-start
                                            "
                        style={{ gap: "15px" }}
                      >
                        <div className="d-flex flex-column">
                          <div className="d-flex" style={{ gap: "15px" }}>
                            <Input
                              type="text"
                              placeholder="Home"
                              className="mt-2"
                              id={row.name + "-new-title"}
                            />
                            <Input
                              type="text"
                              placeholder="http://example.com"
                              id={row.name + "-new-link"}
                              className="mt-2"
                            />
                            <br />
                          </div>
                          <div
                            className="d-flex mt-3 align-items-center"
                            style={{ gap: "15px" }}
                          >
                            <input type="checkbox" id={row.name + "-new-tab"} />
                            <label
                              htmlFor={row.newTab + "-new-tab"}
                              className="m-0"
                            >
                              Open in new tab
                            </label>
                          </div>
                        </div>

                        <div className="d-flex" style={{ gap: "15px" }}>
                          <button
                            type="button"
                            className="btn btn-sm mt-2 btn-primary"
                            onClick={() => addSubMenu({ row })}
                          >
                            Add
                          </button>
                          <div
                            className="btn btn-sm mt-2 btn-secondary"
                            onClick={() =>
                              setVisitorConfig(config => {
                                config[row.name].add = !config[row.name].add
                                return JSON.parse(JSON.stringify(config))
                              })
                            }
                          >
                            {row.add ? "X" : "Add"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="mt-3 text-center">
              <Button color="primary" className="py-2" type="submit">
                Save
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
      {vtModalData.show && <VisitorToolsEditModal {...vtModalData} />}
    </>
  )
}
export default VisitorTools
