import PropTypes from "prop-types"
import React from "react"

import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap"
// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"
// availity-reactstrap-validation
import {AvField, AvForm} from "availity-reactstrap-validation"
//Social Media Imports
// import TwitterLogin from "react-twitter-auth"
// actions
import {apiError, loginUser, socialLogin} from "store/actions"
// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/agentedge.png"
//Import config
import {facebook, google} from "../../config"

const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  };

  const signIn = (res, type) => {
    const {socialLogin} = props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, props.history, type)
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  };

  return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"/>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Agent Edge.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid"/>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                          />
                        </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                      >
                        {props.error && typeof props.error === "object" &&
                        <Alert color="danger">{props.error.message}</Alert>}

                        <div className="form-group">
                          <AvField
                              name="email"
                              label="Email"
                              value=""
                              className="form-control"
                              placeholder="Enter email"
                              type="text"
                              required
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                              name="password"
                              label="Password"
                              value=""
                              type="password"
                              required
                              placeholder="Enter Password"
                          />
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customControlInline"
                          />
                          <label
                              className="custom-control-label"
                              htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3">
                          <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit">
                            Log In
                          </button>
                        </div>


                        <div className="mt-4 text-center ">
                          <a href={process.env.REACT_APP_AE_URL + "/auth/forgotPassword"} target={"__blank"}
                             className="text-muted">
                            <i className="mdi mdi-lock mr-1"/>
                            Forgot your password?
                          </a>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center d-none">
                  <p>
                    Don&#39;t have an account ?{" "}
                    <Link
                        to="register"
                        className="font-weight-medium text-primary"
                    >
                      {" "}
                      Signup now{" "}
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} Agent Edge{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
};

const mapStateToProps = state => {
  const {error} = state.Login;
  return {error}
};

export default withRouter(
    connect(mapStateToProps, {loginUser, apiError, socialLogin})(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
};
