import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"
//Include Both Helper File with needed methods
import { postSocialLogin } from "../../../helpers/fakebackend_helper"
import {
  accessTokenLogin,
  loginUserApi,
  resetLogin,
  websiteNotifications,
} from "../../../helpers/main-backend.helper"
import notificationStore from "../../../notification.service"
import { userAccessTokenLogin } from "../../user/actions"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(loginUserApi, {
      user_name: user.user_name,
      email: user.email,
      password: user.password,
    })
    if (response && !response.api_status) {
      localStorage.removeItem("website")
      localStorage.removeItem("website_id")
      localStorage.removeItem("adminMenu")
      localStorage.setItem("user", JSON.stringify(response))
      localStorage.setItem(
        "access_token",
        JSON.stringify(response.access_token)
      )
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(response.refresh_token)
      )
      localStorage.setItem("website_id", response.website_id)
      yield call(resetLogin, response.access_token)
      const userData = yield call(accessTokenLogin)
      const notificationData = yield call(websiteNotifications)
      notificationStore.clearData()
      if (notificationData && !notificationData.api_status) {
        notificationStore.addNotification(notificationData.data)
      }
      yield put(loginSuccess(userData))
      yield call(userAccessTokenLogin)
      history.push("/dashboard")
      window.location.reload()
    } else {
      console.log("apiError", response)
      yield put(apiError(response))
    }
  } catch (error) {
    console.log("error", error)
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    history.push("/login")
    localStorage.removeItem("user")
    localStorage.removeItem("website")
    localStorage.removeItem("websites")
    localStorage.removeItem("adminMenu")
    localStorage.removeItem("access_token")
    localStorage.removeItem("website_id")
    window.location.reload()
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const response = yield call(postSocialLogin, data)
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
