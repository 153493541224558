import {Modal} from "reactstrap"
import React from "react"


export const PlanEndModal = ({onClose, closePlan, message}) => {

    return (
        <Modal
            isOpen={true}
            toggle={onClose}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
                <button
                    type="button"
                    onClick={onClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <p>{message}</p>
            </div>
            <div className="modal-footer">
                <button
                    onClick={closePlan}
                    className="btn btn-danger waves-effect waves-light"
                >
                    Execute
                </button>
            </div>
        </Modal>
    )
}
