import {page_limit} from "../../config";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import pageColumns from "../../page.columns";
import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card, CardBody, Col, Row} from "reactstrap";
import {TableLoader} from "../../components/Loader";
import AETable from "../../components/Common/Table";
import NoDataAvailable from "../../components/NoDataAvailable";
import SettingsImagePreview from "../Settings/util/image-preview";
import {showSuccess} from "../../helpers/util.helper";

const VirtualTour = (props) => {
  let limit = page_limit;
  let offset = 0;
  const history = useHistory();
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({
    columns: pageColumns.virtualTours,
    rows: []
  });
  useEffect(() => {
    getPageData({ limit, offset });
  }, []);
  const getPageData = async ({ limit, offset }) => {
    setPageLoading(1);
    const virtualTourData = await get(url.VIRTUAL_TOUR, {
      params: {
        limit, offset
      }
    });
    setPageLoading(0);
    if (virtualTourData && virtualTourData.data) {
      if (virtualTourData.data && virtualTourData.data.length) {
        virtualTourData.data.forEach(tour => {
          tour.photo = <SettingsImagePreview image={tour.image_url} width="200px" height="200px"/>;
        });
      }
      let _d = { ...data, rows: virtualTourData.data };

      setData(_d);
      setTotalPages(virtualTourData.total_count);
    }

  };

  function pageChange(e) {
    setCurrentPage(e);
    getPageData({ limit, offset: (limit * (e - 1)) });
  }

  const actions = [
    {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editVirtualTour
    }, {
      name: "Copy Link",
      icon: "fas fa-clipboard",
      fn: copyLink
    },];

  function copyLink(tour) {
    let website = JSON.parse(localStorage.getItem("website"));
    if (website && website.base_url) {
      let url = website.base_url + '/virtual-tour/' + encodeURIComponent(tour.address.replaceAll(' ', '-').replaceAll(',', '-')) + '?id=' + tour.vt_id;
      if (!url.startsWith("http")) {
        url = "https://" + url;
      }
      navigator.clipboard.writeText(url).then(function () {
        showSuccess({ message: 'Saved in clipboard.' });
      }, function (err) {
        // console.error('Async: Could not copy text: ', err);
      });
    }
  }

  function editVirtualTour(virtualTour) {
    history.push("/editVirtualTour/" + virtualTour.vt_id);
  }

  function addVirtualTour() {
    history.push("/addVirtualTour");
  }

  function gotoWebsite(website) {
    props.toggleAdminMenu(false);
    localStorage.setItem("website_id", website.website_id);
    localStorage.setItem("website", JSON.stringify(website));
    history.push("/settings");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="VirtualTours" breadcrumbItem="VirtualTours List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                      data.rows.length
                        ? <AETable columns={data.columns}
                                   rows={data.rows}
                                   itemsPerPage={limit}
                                   loading={pagesLoading}
                                   currentPage={currentPage}
                                   actions={actions}
                                   addLink={addVirtualTour}
                                   totalCount={totalPages}
                                   pageFn={pageChange}/>
                        :
                        <NoDataAvailable module="virtual_tour"/>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  );
};

export default VirtualTour;
