import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import LeadEmailDetails from "./Emails/lead-email-details";
import { setTasksPriority } from "../../helpers/util.helper";

const LeadUpcomingTask = ({ task }) => {
  let [taskDescription, setTaskDescription] = useState("");
  let [taskModal, setTaskModal] = useState({ show: false });
  useEffect(function() {
    setTaskDescription(task?.task_description);
  }, [task]);

  function setPreviewData(task) {
    return {
      title: task.task_title,
      description: task.task_description,
      time: task.task_due_date
    };
  }

  return (<>
    <Card>
      <CardBody>

        <h6 className="text-muted mb-3 ">Upcoming Task</h6>
        {
          task ? <>
            <h5>
              <Link to="blog-details" className="text-dark">{task.task_title}</Link>
            </h5>
            <p className="text-muted">
              <Moment format={"MMM, DD, YYYY hh:mm A"}>{task.task_due_date}</Moment>
            </p>
            <p className="text-muted">
              {setTasksPriority(task.task_priority)}
            </p>

            <p className="text-truncate">{taskDescription}</p>

            <div>
              <a onClick={() => setTaskModal({
                open: true,
                email: setPreviewData(task)
              })} className="text-primary">
                Read more <i className="mdi mdi-arrow-right"/>
              </a>
            </div>
          </>
            : <h5 className="mt-2">No task scheduled.</h5>
        }
      </CardBody>
    </Card>
    {taskModal.open && <LeadEmailDetails
      openModal={taskModal.open}
      closeModal={() => {
        setTaskModal({ open: false });
      }
      }
      email={taskModal.email}
    />}
  </>);
};

export default LeadUpcomingTask;
