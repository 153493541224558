function getAddButton({ link, title }) {
  return {
    link,
    title: "Add " + title
  };
}

export const buttons = {
  testimonials: {
    message: "No Testimonial added",
    buttons: [getAddButton({ link: "/addTestimonial", title: "Testimonial" })]
  },
  blogs: {
    message: "No Blog added",
    buttons: [getAddButton({ link: "/addBlog", title: "Blog" })]
  },
  page: {
    message: "No Page found.",
    buttons: [getAddButton({ link: "/addPage", title: "Page" })]
  },
  services: {
    message: "No Services added.",
    buttons: [getAddButton({ link: "/addService", title: "Service" })]
  },
  teams: {
    message: "No team members added.",
    buttons: [getAddButton({ link: "/addTeam", title: "Team" })]
  },
  portfolio: {
    message: "No projects added.",
    buttons: [getAddButton({ link: "/addProject", title: "Project" })]
  },
  virtual_tour: {
    message: 'No virtual tour added.',
    buttons: [getAddButton({ link: '/addVirtualTour', title: 'Virtual Tour' })]
  },
  leads: {
    message: 'No lead data found.',
    buttons: [getAddButton({ link: '/addLead', title: 'Lead' })]
  },
  components: {
    message: 'No components found.',
    buttons: [getAddButton({ link: '/addComponents', title: 'Component' })]
  },
  photos: {
    message: 'No photos found.',
    buttons: [getAddButton({ link: '/addPhoto', title: 'Photo' })]
  }
};
