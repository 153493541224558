import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, Col, UncontrolledTooltip } from "reactstrap";
import '../util/Team.css'
const MemberCard = props => {
  const { user } = props;

  return (
    <React.Fragment>
      <Col xl="3" sm="6" className="pointer ui-state-default" id={user.tm_id}>
        <Card className="text-center">
          <CardBody>
            {!user.member_photo ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className="avatar-title rounded-circle"
                >
                  {user.member_name.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-sm"
                  src={user.member_photo_original}
                  alt=""
                />
              </div>
            )}

            <h5 className="font-size-15">
              {user.member_name}
            </h5>
            <p className="text-muted">{user.member_designation}</p>
            <p className="text-muted "><small>{user.member_description}</small></p>

            <div>
              {/*{user.skills.map((skill, key) => (
                <Link
                  to="#"
                  className="badge badge-primary font-size-11 m-1"
                  key={"_skill_" + key}
                >
                  {skill.name}
                </Link>
              ))}*/}
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <Link to={"/editTeam/" + user.tm_id} id={"profile" + user.tm_id}>
                  <i className="bx bx-edit-alt"/>
                  <UncontrolledTooltip
                    placement="top"
                    target={"profile" + user.tm_id}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </div>

              <div className="flex-fill" onClick={() => {
                props.deleteMember(user);
              }} id={"delete" + user.tm_id}>
                <i className="bx bx-trash-alt"/>
                <UncontrolledTooltip
                  placement="top"
                  target={"delete" + user.tm_id}
                >
                  Delete
                </UncontrolledTooltip>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  );
};

MemberCard.propTypes = {
  user: PropTypes.object,
  deleteMember: PropTypes.func
};

export default MemberCard;
