import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Select from "react-select";
import {get, post, put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import {useHistory, useParams} from "react-router-dom";
import {showError} from "../../helpers/util.helper";
import * as classnames from "classnames";
import SettingsImagePreview from "../Settings/util/image-preview";
import PageBackgroundModal from "./builder-modals/page-background.modal";
import {connect} from "react-redux";
import {toggleAdminMenu} from "../../store/layout/actions";
import Moment from "react-moment";
import BuilderModal from "./builder.modal";
import ContentLoader from "react-content-loader";
import {TableLoader} from "../../components/Loader";
import FormLoader from "../../components/Loader/FormLoader";
import './webpages.style.scss';

let pageTypeList$ = [
  {label: "Full Width", value: "FULL_PAGE"},
  {label: "Left Sidebar", value: "LEFT_SIDEBAR"},
  {label: "Right Sidebar", value: "RIGHT_SIDEBAR"},
  // {
  //   label: "Landing Page: Dream Home",
  //   value: "DREAM_HOME",
  //   type: "landing",
  //   landing_page_heading: "Looking for your dream home?",
  //   landing_page_description: "Finding A Dream Home Was Never So Easy!"
  // },
  // {
  //   label: "Landing Page: Home Evaluation", value: "HOME_EVALUATION", type: "landing",
  //   landing_page_heading: "Want to know what your home worth?",
  //   landing_page_description: "It may help you are thinking of selling you house."
  // },
  // {
  //   label: "Landing Page: Listing Alerts", value: "LISTING_ALERT", type: "landing",
  //   landing_page_heading: "Get listings directly in your email box.",
  //   landing_page_description: "We will send you your queried updated listings everyday."
  // },
  // {
  //   label: "Landing Page: Neighbourhood Watch", value: "NEIGHBOURHOOD_WATCH", type: "landing",
  //   landing_page_heading: "Neighborhood Watch",
  //   landing_page_description: "Get all updates of nearby properties directly in your email box."
  // }

];

const agentInfoSidesList = [
  { label: "Right", value: "RIGHT" },
  { label: "Left", value: "LEFT" }
];

const AddPage = (props) => {
  const history = useHistory();
  let { page_id } = useParams();
  const [activeTab, setactiveTab] = useState("1");
  const [showAgentInfo, setShowAgentInfo] = useState(false);
  const [backgroundModal, setBackgroundModal] = useState(false);
  const [pageTypeList, setPageTypeList] = useState(pageTypeList$);
  const [showBuilderModal, setShowBuilderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const [data, setData] = useState({page_html: " "});
  const [seo, setSeo] = useState({});
  const [pageType, setPageTypeData] = useState({page_html: " "});
  const [agentInfoSide, setAgentInfoSide] = useState("right");
  const [pageHistory, setPageHistory] = useState([]);
  useEffect(async function () {
    if (page_id) {
      setLoading(true);
      await getWebPage();
      setLoading(false);
    }
  }, []);
  useEffect(function () {
    console.log(data, "loaded");
  }, [data]);
  const childRef = useRef();

  function updatePageSlug(e) {
    let value = e.target.value;
    value = value.replaceAll("/", "");
    let v = value.trim() ? encodeURIComponent(String(value.trim()).split(" ").join("-")) : "";
    let _d = { ...data, page_slug: v };
    setData(_d);
  }


  function setPageKey({ value, key }) {
    let _d = { ...data, [key]: value };
    setData(_d);
  }


  function addKey(arr, key) {
    let addedKeys = [];
    function updateKey() {
      key = key + 1;
      if (addedKeys.includes(key)) {
        return updateKey();
      }
      addedKeys.push(key);
      return key;
    }
    function addKeyData(arr, key) {
      for (let a of arr) {
        a.key = updateKey();
        if (a.children && a.children.length) {
          addKeyData(a.children, key);
        }
      }
    }
    addKeyData(arr, key);
  }

  async function getWebPage() {
    const response = await get(url.PAGE_ADD + "/" + page_id);
    if (!response.api_status) {
      if (response.page_type) {
        let _pageType = pageTypeList.find(d => d.value === response.page_type);
        if (_pageType.type === "landing") {
          setPageTypeList(pageTypeList.filter(d => d.type === "landing"));
        } else {
          setPageTypeList(pageTypeList.filter(d => d.type !== "landing"));
        }
        setPageTypeData(_pageType);
        console.log(pageTypeList, "is page type list");
      }
      if (response.page_history) {
        setPageHistory(response.page_history);
      }
      if (response.page_slug) {
        response.page_slug = response.page_slug.substring(1);
      }
      if (response.config) {
        setSeo(response.config);
      }
      setAgentInfoSide(response.agent_info_side);
      setShowAgentInfo(response.show_agent_info);
      // let linear = resetComponentData({config: JSON.parse(response.page_config)});
      // setPageConfig({...linear});
      setData(response);


    } else {
      showError(response);
    }
  }

/*  function resetComponentData({config, linear = {}}) {
    if (config && config.children.length) {
      config.children.forEach(rec => {
        if (rec.config && rec.config.key) {
          linear[rec.config?.key] = rec.config;
        }
        if (rec.children) {
          return resetComponentData({config: rec, linear})
        }
      })
    }
    return linear;
  }*/

  async function savePageBuilderData({page_config, page_html}) {
    addKey(page_config.children, 1);
    const response = await put(url.WEB_PAGE_LIST + "/" + page_id, {
      page_config, page_html
    });
    if (!response.api_status) {
      setData(response);
    } else {
      showError(response);
    }
  }

  async function saveSeoSettings() {
    const $inputs = $("#config-form :input");
    const values = {};
    $inputs.each(function() {
      values[this.name] = $(this).val();
    });
    let d = { ...seo, ...values };
    let response = await post(url.UPDATE_SEO + "/" + page_id, d);
    if (!response.api_status) {
      toastr.success("Page SEO settings updated successfully.", "Success");
      // history.push("/pages")
    } else {
      showError(response);
    }
  }

  async function saveWebPage({ page_html, page_config }) {
    const $inputs = $("#page-form :input");
    const values = {};
    $inputs.each(function() {
      values[this.name] = $(this).val();
    });
    console.log(data, values);
    let d = { ...data, ...values };
    if (!page_id) {
      d = {
        ...d,
        page_html: `<div className="container-area layout-area container dropzone" data-col="layout"></div>`
      };
    } else {
      d = { ...d, page_html, page_config };
    }
    d.page_slug = "/" + d.page_slug;
    let response;
    if (page_id) {
      if (data.landing_page_background && data.landing_page_background.startsWith("data")) {
        const { image_path } = await saveFile({ file: data.landing_page_background });
        d.landing_page_background = image_path;
      } else {
        d.landing_page_background = data.landing_page_background;
      }
      d.show_agent_info = showAgentInfo;
      if (d && d.page_config && d.page_config.children) {
        // addKey(d.page_config.children, 1);
      }
      response = await put(url.WEB_PAGE_LIST + "/" + page_id, d);
    } else {
      // d.landing_page_heading = pageTypeList.find(page => page.value === d.page_type).landing_page_heading;
      // d.landing_page_description = pageTypeList.find(page => page.value === d.page_type).landing_page_description;
      response = await post(url.PAGE_ADD, d);
    }
    if (!response.api_status) {
      if (page_id) {
        toastr.success("Website updated successfully.", "Success");
        history.push("/pages");
      } else {
        toastr.success("Website created successfully.", "Success");
        history.push("/pages/" + response.page_id);
      }

    } else {
      showError(response);
    }
  }

  async function saveFile({ file }) {
    const response = await post(url.SAVE_IMAGE, {
      image: file
    });
    if (!response.api_status) {
      return response;
    } else {
      showError(response);
    }
    return {};
  }

  function back() {
    history.push("/pages");
  }

  function handleFile(e, key) {
    const reader = new FileReader();
    reader.onload = function (e) {
      setData({...data, [key]: e.target.result});
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  function removeFile(key) {
    setData({...data, [key]: ""});
  }

  const pagePreview = () => {
    return childRef.current.pagePreview();
  };
  const onPreviewPage = async ({config, html}) => {
    const response = await post(url.PAGE_ADD + "/preview/" + page_id, {config, html});
    if (response && response.id) {
      window.open(props.user.website.base_url + '/preview-page?page_id=' + response.id, '_blank').focus();
    }
  };
  const previewHistory = (page) => {
    window.open('https://' + props.user.website.subdomain + '.agentedge.ca/preview-page?page_id=' + page.pp_id, '_blank').focus();
  };
  const rollbackHistory = async (page) => {
    await put(url.WEB_PAGE_LIST + "/" + page_id, {page_config: page.preview_config, page_html: page.preview_html});
    toastr.success("Website updated successfully.", "Success");
    history.push("/pages");
  };
  const saveConfig = async (config) => {
    if (config) {
      await put(url.WEB_PAGE_LIST + "/" + page_id, {page_config: config});
      getWebPage();
      toastr.success("Website updated successfully.", "Success");
    }
    setShowBuilderModal(false)
  };

  const savePreview = async (config) => {
    const response = await post(url.PAGE_ADD + "/preview/" + page_id, {config});
    if (response && response.id) {
      window.open('https://' + props.user.website.subdomain + '.agentedge.ca/preview-page?page_id=' + response.id, '_blank').focus();
    }
  };

  return (
      <React.Fragment>
        <div className="page-content webpage-form">
          {
            !page_id && <div className="container-fluid">
              <Breadcrumbs title={page_id ? "Update" : "Add"}
                           breadcrumbItem={page_id ? "Page Update" : "Page Add"}/>
            </div>
          }

          {
            !loading ?
                <Card>
                  <CardBody>


                    <Nav tabs>
                      <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              active: activeTab === "1"
                            })}
                            onClick={() => {
                              toggle("1");
                            }}
                        >
                          Page Content
                        </NavLink>
                      </NavItem>
                      {
                        page_id &&
                        <>
                          <NavItem>
                            <NavLink
                                style={{cursor: "pointer"}}
                                className={classnames({
                                  active: activeTab === "3"
                                })}
                                onClick={() => {
                                  toggle("3");
                                }}
                            >
                              SEO Settings
                            </NavLink>
                          </NavItem>
                          {
                            pageHistory.length > 0 &&
                            <NavItem>
                              <NavLink
                                  style={{cursor: "pointer"}}
                                  className={classnames({
                                    active: activeTab === "4"
                                  })}
                                  onClick={() => {
                                    toggle("4");
                                  }}
                              >History</NavLink>
                            </NavItem>
                          }
                        </>
                      }
                    </Nav>
                    <TabContent className="mt-4" activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <AvForm className="needs-validation" modal={data} id="page-form">
                              <Row>
                                <Col xl="12">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <h4 className="card-title">Page info</h4>
                                      <p className="card-title-desc">Add information regarding your new web page</p>
                                    </div>
                                    {
                                      page_id && !pageType.type && <div>
                                        <Button color="primary" size={"sm"} type="submit"
                                                onClick={() => setShowBuilderModal(true)}>
                                          <span className="bx bx-edit"/> Page Builder
                                        </Button>
                                      </div>
                                    }
                                  </div>
                                  <Row>
                                    <Col md="6">
                                      <FormGroup>
                                        <Label htmlFor="validationCustom01">Page name</Label>
                                        <AvField
                                            name="page_name"
                                            value={data.page_name}
                                            placeholder="Lorum Ipsum.."
                                            type="text"
                                            className="form-control"
                                            id="validationCustom03"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup>
                                        <Label htmlFor="validationCustom01">Page Type</Label>
                                        <Select
                                            value={pageTypeList.filter(option => option.value === data.page_type)}
                                            onChange={(e) => {
                                              setPageKey({value: e.value, key: "page_type"});
                                            }}
                                            options={pageTypeList}
                                            classNamePrefix="select2-selection"
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col md="6">
                                      <FormGroup>
                                        <Label htmlFor="validationCustom01">Page slug</Label>
                                        <div className="d-flex align-items-center" >
                                          <p >{props.user.website?.base_url}/</p>
                                          <AvField
                                              name="page_slug"
                                              value={data.page_slug}
                                              onChange={(e) => {
                                                updatePageSlug(e);
                                              }}
                                              placeholder=""
                                              type="text"
                                              className="form-control flex-1"
                                              style={{
                                                borderTop: 0,
                                                borderLeft: 0,
                                                borderRight: 0,
                                                borderRadius: 0,
                                                paddingLeft:0,
                                                flex:1
                                              }}
                                              id="validationCustom03"
                                          />
                                        </div>
                                        <span className="badgecount">
                                    </span>
                                      </FormGroup>
                                    </Col>

                                    <Col md="6">
                                      <FormGroup>
                                        <Label htmlFor="validationCustom01">Page Info</Label>
                                        <AvField
                                            name="page_info"
                                            value={data.page_info}
                                            placeholder="Lorum Ipsum.."
                                            type="textarea"
                                            className="form-control"
                                            id="validationCustom03"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  {
                                    pageType.type === "landing" &&
                                    <>
                                      <p className="pt-4 font-weight-bold">Landing Page Settings</p>
                                      <Row>
                                        <Col md="6">
                                          <FormGroup>
                                            <Label htmlFor="validationCustom01">Heading</Label>
                                            <AvField
                                                name="landing_page_heading"
                                                value={data.landing_page_heading}
                                                placeholder="Lorum Ipsum.."
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="6">
                                          <FormGroup>
                                            <Label htmlFor="validationCustom01">Underline</Label>
                                            <AvField
                                                name="landing_page_description"
                                                value={data.landing_page_description}
                                                placeholder="Lorum Ipsum.."
                                                type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="12" className="my-4">
                                          <p className="font-weight-bold">Agent Information</p>
                                          <div className="form-check mb-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="show_agent_info"
                                                id="show_agent_info"
                                                onChange={(e) => {
                                                  setShowAgentInfo(e.target.checked);
                                                }}
                                                checked={showAgentInfo}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="show_agent_info"
                                            >
                                              Show Agent Info
                                            </label>
                                          </div>
                                          {
                                            showAgentInfo ?
                                                <Row>
                                                  <Col md="6">
                                                    <FormGroup>
                                                      <Label htmlFor="validationCustom01">Agent Info on side</Label>
                                                      <Select
                                                          value={agentInfoSidesList.filter(option => option.value === data.agent_info_side)}
                                                          onChange={(e) => {
                                                            setPageKey({value: e.value, key: "agent_info_side"});
                                                          }}
                                                          options={agentInfoSidesList}/>
                                                    </FormGroup>

                                                  </Col>
                                                </Row> : ""
                                          }

                                        </Col>
                                        <Col md="6">
                                          <Row>
                                            <Col md="12">
                                              <FormGroup>
                                                <Label htmlFor="home-page-banner">Page Background</Label>
                                                <AvField
                                                    name="landing_page_background"
                                                    type="file"
                                                    className="form-control"
                                                    id="home-page-banner"
                                                    onChange={event =>
                                                        handleFile(event, "landing_page_background")
                                                    }
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="12" className="mb-2">
                                              <span className="font-weight-bold">OR</span>
                                            </Col>
                                            <Col md="12">
                                              <button className="btn btn-primary" onClick={() => {
                                                setBackgroundModal(true);
                                              }}>Choose Background
                                              </button>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md="6">
                                          {
                                            data.landing_page_background &&
                                            <>
                                        <span className="fas fa-minus-circle float-right"
                                              onClick={() => {
                                                removeFile("landing_page_background");
                                              }}
                                              style={{color: "red", cursor: "pointer"}}/>
                                              <SettingsImagePreview
                                                  height="500px"
                                                  width="100%"
                                                  image={data.landing_page_background}/>
                                            </>
                                          }
                                        </Col>

                                      </Row>
                                    </>
                                  }
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12 text-center">
                                  <Button color="secondary" className="py-2 mr-2" type="button" onClick={back}>
                                    Back
                                  </Button>
                                  <Button color="primary" className="py-2" type="submit" onClick={() => {
                                    if (page_id && childRef.current && typeof childRef.current.saveLayout !== "undefined") {
                                      return childRef.current.saveLayout();
                                    }
                                    saveWebPage({});

                                  }}>
                                    {
                                      data.page_id ?
                                          "Update"
                                          : "Save"
                                    }
                                  </Button>
                                </Col>
                              </Row>
                            </AvForm>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                              <AvForm className="needs-validation" modal={seo} id="config-form">
                                <Row>
                                  <Col xl="12">

                                    <h4 className="card-title">Page SEO settings</h4>
                                    <p className="card-title-desc">You can update meta information which is available
                                      for search engine boats.</p>
                                    <Row>
                                      <Col md="12">
                                        <FormGroup>
                                          <Label htmlFor="validationCustom01">Title</Label>
                                          <AvField
                                              name="title"
                                              value={seo.title}
                                              placeholder="Lorum Ipsum.."
                                              type="text"
                                              className="form-control"
                                              id="validationCustom03"
                                          />
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <Label htmlFor="validationCustom01">Meta Description</Label>
                                      <AvField
                                          name="meta_description"
                                          value={seo.meta_description}
                                          placeholder="Lorum Ipsum.."
                                          type="textarea"
                                          className="form-control"
                                          id="validationCustom03"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <Label htmlFor="validationCustom01">Meta Keywords</Label>
                                      <AvField
                                          name="meta_keywords"
                                          value={seo.meta_keywords}
                                          placeholder="Lorum Ipsum.."
                                          type="textarea"
                                          className="form-control"
                                          id="validationCustom03"
                                      />
                                    </FormGroup>
                                  </Col>
                                    </Row>

                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="12 text-center">

                                    <Button color="secondary" className="py-2 mr-2" type="submit" onClick={back}>
                                      Back
                                    </Button>
                                    <Button color="primary" className="py-2" type="submit" onClick={() => {
                                      saveSeoSettings();
                                    }}>
                                      {
                                        data.page_id ?
                                            "Update"
                                            : "Save"
                                      }
                                    </Button>

                                  </Col>
                                </Row>
                              </AvForm>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <CardText>

                              <div className="list-group list-group-flush">
                                {
                                  pageHistory.map((note, key) => <div className="list-group-item px-0" key={key}>
                                    <div className="d-flex align-items-center" style={{gap: '15px'}}>
                              <span>
                                <b>Version <span>{(pageHistory.length) - key}</span></b> <br/>
                                <Moment withTitle titleFormat="" format="LLL">{note.created}</Moment>
                              </span>
                                      <span className="btn btn-outline-secondary btn-sm"
                                            onClick={() => previewHistory(note)}>Preview</span>
                                      <span className="btn btn-outline-primary btn-sm"
                                            onClick={() => rollbackHistory(note)}>Rollback</span>
                                    </div>
                                  </div>)
                                }
                              </div>


                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                :
                <FormLoader />

          }

        {
          backgroundModal ?
            <PageBackgroundModal openModal={backgroundModal} onClosePageBackgroundModal={(config) => {
              setBackgroundModal(false);
              console.log(config);

              if (config && config.background) {
                setData({...data, landing_page_background: config.background});
              }
            }}/>
              : ""
        }
        </div>
        {
          showBuilderModal && <BuilderModal saveConfig={saveConfig} saveImage={saveFile}
                                            savePreview={savePreview}
                                            config={data.page_config}/>
        }
      </React.Fragment>
  );
};

const mapStatetoProps = state => {
  const {
    user
  } = state.user;
  return {user}
};

export default connect(mapStatetoProps, {
  toggleAdminMenu
})(AddPage)
