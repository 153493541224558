import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Label} from "reactstrap";
import {get, put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {showError, showWarning} from "../../helpers/util.helper";
import MenuModal from "./add-menu-item.modal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import MenuSettings from "../Settings/components/menu.settings";
import VisitorTools from "./visitor_tools";
import './menu.scss'
import {AvForm, AvInput} from "availity-reactstrap-validation";
import {connect} from "react-redux";

let mainMenu = [
  {
    id: "1",
    page_id: "1",
    title: "Home",
    children: [{
      id: "2",
      link: "https://hutbros.com",
      title: "About"
    }]
  },
  { id: "3", link: "http://about.com", title: "Contact" }
];

const style = {
  menuType: {
    "marginLeft": "5px",
    "fontSize": "9px",
    "color": "grey",
    "fontWeight": "600",
    "fontStyle": "italic"
  },
  listGroupItem: {
    minWidth: "380px"
  },
  addMenu: {
    position: "absolute",
    top: "10px",
    right: "10px"
  }
};
let startId = 10;
const Menu = (props) => {
  const [menuList, _setMyState] = useState([]);
  const [menuItemsDragged, setMenuItemsDragged] = useState([]);
  const [showVisitorsTools, setShowVisitorsTools] = useState(false);
  const menuListRef = React.useRef(menuList);

  const setMenuList = data => {
    menuListRef.current = data;
    _setMyState(data);
  };
  const [menuType, setMenuType] = useState("custom");
  const [list, setList] = useState();
  const [menuGroup, setMenuGroup] = useState(null);
  const [websiteConfig, setWebsiteConfig] = useState();
  const [editItem, setEditItem] = useState({});
  const [pages, setPages] = useState([]);
  const [selectedPageId, setPageId] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [visitorsTools, setVisitorsTools] = useState();
  const [disableMenu, setDisableMenu] = useState(false);
  useEffect(function () {
    getPages();
    setTimeout(function () {
      var oldContainer;
      let group = $("#menuitems").sortable({
        group: 'serialization',
        onDrop: function ($item, container, _super) {
          let data = group.sortable("serialize").get();
          let jsonString = JSON.stringify(data, null, ' ');
          $('#serialize_output').text(jsonString);
          setMenuItemsDragged(data[0]);
          if (getLevel(data[0]).level >= 2) {
            setDisableMenu(true);
            showWarning({message: 'Only 2 level nested menu will work. Below 2 level menu items will be discarded.'})
          } else {
            setDisableMenu(false);
          }
          _super($item, container);
        },
      });
      setMenuGroup(group);
    }, 1000)
  }, []);

  async function getPages() {
    const response = await get(url.WEB_PAGE_LIST);
    if (!response.api_status) {
      if (response.data && response.data.length) {
        response.data = [...response.default_nav, ...response.data];
        setPages(response.data.map(key => {
          return {
            label: <span>{key.page_name}
              {key.type === 'default' && <span className="ml-2 badge badge-sm badge-success">Default</span>}
            </span>,
            name: key.page_name,
            value: key.page_id,
            type: key.type
          };
        }));
        setVisitorsTools(response.visitors_tools || false);
        if (response.menu && response.menu.length) {
          if (response.menu[0].showVisitorsTools) {
            setShowVisitorsTools(response.menu[0].showVisitorsTools);
          }
          let nested = (setMenuNestedItems(response.menu));
          if (response.web_config) {
            setWebsiteConfig(response.web_config);
          }
          setMenuList(nested);
        }
      }
    } else {
      showError(response);
    }
  }

  function setMenuNestedItems(mn) {
    let menu = mn.filter(m => m.placement === "HEADER").sort((i, j) => i.parent_mn_id - j.parent_mn_id)
      .map(item => {
        item.title = item.nav_name;
        item.link = item.external_url;
        item.id = item.mn_id;
        return item;
      });
    const output = menu.reduce((acc, place) => {
      const plc = { ...place, children: [] };
      if (place.parent_mn_id) {
        if (acc[place.parent_mn_id]) {
          acc[place.parent_mn_id].children.push(plc);
        }
      }
      acc[place.mn_id] = plc;

      return acc;
    }, []);
    const menuItems = output.filter(d => !d.parent_mn_id).map(d => {
      if (!d.children.length) {
        d.children = [];
      }
      return d;
    });
    return menuItems.map(r => {
      if (r.children && r.children.length) {
        r.children = r.children.sort((i, j) => {
          return i.list_order - j.list_order;
        });
      }
      return r;
    }).sort((i, j) => {
      return +i.list_order - +j.list_order;
    });
  }

  function prepareNewMenu() {
    return <ul className="menu ui-sortable" id="menuitems">

      {setListData({items: menuListRef.current, level: 1})}
    </ul>;
  }

  function setListData({items, level}) {
    return items.map((item, key) => <li data-id={item.id} key={"menu-" + key}>
      <div
          className="menu-item-bar bg-white d-flex justify-content-between align-items-center">
        <span>{item.title}</span>
        <div className="d-flex" style={{gap: "5px"}}>
          <div className="d-inline-block btn btn-sm btn-primary edit-menu" data-all={JSON.stringify(item)}
               onClick={() => editMenu(item)}>
            <span className="fas fa-edit"/>
          </div>
          <span className="d-inline-block btn btn-sm btn-danger edit-menu ml-2" data-all={JSON.stringify(item)}
                onClick={() => deleteMenu(item.id)}><span className="fas fa-trash"/></span>
        </div>

      </div>
      <div className="collapse" id={"collapse" + key}>
        <div className="input-box">
        </div>
      </div>
      <ul>
        {
          item.children && item.children.length ? setListData({items: item.children, level: ++level}) : <></>
        }
      </ul>
    </li>)
  }



  function editMenu(item) {
    if (item.page_id) {
      setPageId(item.page_id);
      setMenuType("page");
    } else if (item.link && item.link.startsWith("http")) {
      setMenuType("external_link");
    } else {
      setMenuType("custom");
    }
    setEditModal(true);
    setEditItem(item);
  }



  function deleteMenu(id) {
    setMenuList(menuListRef.current.filter(it => {
      if (it.children) {
        it.children = it.children.filter(i => +i.id !== +id);
      }
      return +it.id !== +id;
    }));
    setList(prepareNewMenu());
  }


  function addUpdateMenuItem(values) {
    let list = menuList;
    if (editItem && editItem.id) {
      list.forEach(item => {
        if (item.children && item.children.length) {
          item.children.forEach((child) => {
            if (child.id === editItem.id) {
              child.title = values.title;
              child.link = values.link;
              if (values.selectedPageId) {
                child.page_id = values.selectedPageId;
              }
            }
          });
        }
        if (item.id === editItem.id) {
          item.title = values.title;
          item.link = values.link;
          if (values.selectedPageId) {
            item.page_id = values.selectedPageId;
          }
        }
      });
    } else {
      if (values.selectedPageId) {
        list.push({
          title: values.title,
          page_id: values.selectedPageId,
          id: ++startId
        });
      } else {
        list.push({
          title: values.title,
          link: values.link,
          id: ++startId
        });
      }
    }
    setMenuList(list);
    resetMenuData();
    setList(prepareNewMenu());
  }

  const crushObj = (arr) => arr.reduce((acc, item) => {
    if (item.children && item.children.length) {
      acc = [...acc, ...crushObj(item.children)]
    }
    delete acc.children;
    acc = [...acc, item];
    return acc;
  }, []);

  const getLevel = (arr, meta = {level: 0}) => arr.reduce((acc, item) => {
    console.log(meta, arr)
    if (item.children && item.children[0].length) {
      ++meta.level;
      getLevel(item.children[0], meta);
    }
    return meta;
  }, []);

  async function saveMenu() {
    let output = [];

    let menuListFlat = crushObj(menuList);
    console.log('sds', menuGroup.sortable("serialize").get()[0], menuListFlat)
    menuGroup.sortable("serialize").get()[0].forEach(item => {
      let nav = menuListFlat.find((j) => j.id === item.id);
      console.log(nav, item);
      if (nav) {
        let obj = {child: [], nav_name: nav.title, external_url: nav.link, page_id: nav.page_id};
        if (item.children && item.children.length && item.children[0].length) {
          item.children[0].forEach(c => {
            let nav = menuListFlat.find((j) => j.id === c.id);
            if (nav) {
              obj.child.push({
                nav_name: nav.title, external_url: nav.link, page_id: nav.page_id
              });
            }
          });
        }
        output.push(obj);
      }
    });

    const response = await put(url.UPDATE_MENU, {menu: output, showVisitorsTools});
    if (!response.api_status) {
      toastr.success('Menu updated successfully.');
    } else {
      toastr.success('Something went wrong.');
    }
  }

  function resetMenuData() {
    setPageId(null);
    setEditItem({ title: "", link: "" });
    setMenuType("custom");
  }

  useEffect(function () {
    setList(prepareNewMenu());
  }, [menuList]);

  async function saveWebsiteData(websiteData) {
    const response = await put(url.WEBSITE + "/" + localStorage.getItem("website_id"), websiteData);
    if (!response.api_status) {
      toastr.success("Menu updated successfully.", "Success");
    } else {
      showError(response);
    }
  }

  function handleValidSubmit(event, values) {
    values = JSON.parse(JSON.stringify(values));
    saveWebsiteData(values);
  }
  return (
    <React.Fragment>
      <div className="page-content menu-builder">
        <div className="container-fluid">
          <div className="row">
            <Col md="5">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-1">Main Menu</h4>
                  <p className="small ">Drag to arrange.</p>
                  <div>
                    <button className="btn" style={style.addMenu} onClick={() => {
                      resetMenuData();
                      setEditModal(true);
                    }}>
                      <span className="fas fa-plus"/>
                    </button>
                  </div>
                  <div className="list-group" id="list-group">
                    {list}
                  </div>

                  {
                    props.user && props.user.website
                    && +props.user.website.industry_id === 1 &&
                    <div className="menu-checks" style={{padding: 'inherit'}}>
                      <AvForm>
                        <Label check className="d-flex ">
                          <AvInput type="checkbox" name="show_visitors_tools"
                                   onChange={(e) => setShowVisitorsTools(e.target.checked)}
                                   checked={showVisitorsTools}
                                   className="p-0"
                                   defaultChecked={showVisitorsTools}
                          />
                          <span style={{paddingTop: '2px'}}>Show Visitors Tools</span>
                        </Label>
                      </AvForm>
                    </div>
                  }
                  {
                    disableMenu &&  <p className="text-danger my-0 py-0"> <small>Only 2 level nesting is allowed</small></p>
                  }
                  <div className="mt-3 text-center">
                    <Button color="primary" className="py-2" type="submit"
                            disabled={disableMenu}
                            onClick={() => {
                      saveMenu();
                    }}>
                      Save
                    </Button>
                  </div>
                </CardBody>
              </Card>
             {/* {
                websiteConfig &&
                <MenuSettings data={{website_config: websiteConfig}} handleSubmit={handleValidSubmit}/>
              }*/}
            </Col>
            <Col md="5">
              {typeof visitorsTools !== 'undefined' && showVisitorsTools &&
              <VisitorTools className={'visitors-tools'} save={saveWebsiteData} savedConfig={visitorsTools}/>
              }
            </Col>
          </div>
        </div>
      </div>
      {
        editModal ?
            <MenuModal
                onCloseClick={(config) => {
                  if (config && config.title) {
                    addUpdateMenuItem(config);
                  }
                  setEditModal(!editModal);
                }}
                menuType={menuType}
                page_id={selectedPageId}
                pages={pages}
                type={menuType}
                editItem={editItem}
                show={editModal}>
            </MenuModal>
            : ""
      }
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user && state.user.user
  };
};

export default connect(mapStateToProps, null)(Menu);
