import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import {post, put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import {showError} from "../../helpers/util.helper";
import {useHistory} from "react-router-dom";

const UploadThemeImage = ({match}) => {
    const history = useHistory();
    const [data, setData] = useState({});

    async function handleValidSubmit(e, values) {
        if (!data.theme_image) {
            return showError({message: 'Please add image.'});
        }
        let component = {
            ...data,
            ...values,
        };
        let response;
        response = await post(url.THEME_IMAGES, component);
        toastr.success("Image added successfully.", "Success");
        if (!response.api_status) {
            history.push('/theme-images')
        } else {
            showError(response);
        }
    }


    function handleFile(e, key) {
        console.log(key, 'triggered')
        const reader = new FileReader();
        reader.onload = function (e) {
            setData({...data, [key]: e.target.result});
        };
        reader.readAsDataURL(e.target.files[0]);
    }


    return (
        <React.Fragment>
            <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>

                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs breadcrumbItem={"Add Image"}/>
                    </div>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <h4 className="card-title">Image Info</h4>
                                            <p className="card-title-desc">Add information regarding image.</p>
                                        </Col>
                                        <Col md="6" sm={12}>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom04">Image Title</Label>
                                                        <AvField
                                                            name="image_title"
                                                            value={data.image_title}
                                                            type="text"
                                                            required={true}
                                                            className="form-control"
                                                            id="validationCustom04"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12" className="mt-3">
                                                    <FormGroup>
                                                        <Label htmlFor="image">Image</Label>
                                                        <input
                                                            name="post"
                                                            type="file"
                                                            className="form-control"
                                                            id="image"
                                                            onChange={event =>
                                                                handleFile(event, "theme_image")
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    {
                                                        <img src={data.theme_image}
                                                             alt="" className="img img-thumbnail"/>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12 text-center">
                            <Card>
                                <CardBody>
                                    <Button color="secondary" className="py-2 mr-2" type="button"
                                            onClick={() => history.push("/theme-images")}>
                                        Back
                                    </Button>
                                    <button type={"submit"} id="draftBtn" className="d-none"/>

                                    <Button color="primary" className="py-2" type="submit">
                                        {
                                            data.post_id ?
                                                "Update"
                                                : "Save"
                                        }
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </AvForm>

        </React.Fragment>
    );
};

export default UploadThemeImage;
