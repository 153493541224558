import React, {useEffect, useState} from "react"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap"
import {map} from "lodash"
import {getWebsites} from "../../../store/websites/actions"
import {connect} from "react-redux"
import {WebsiteSelectModal} from "./WebsiteSelect.modal";

const WebsiteDropdown = (props) => {
  const [website, setWebsite] = useState({});
  const [websites, setWebsites] = useState([]);
  const [menu, setMenu] = useState(false);
  const [selectModal, setSelectModal] = useState(false);

  useEffect(() => {
    let localWebsites = localStorage.getItem("websites");
    // if (!localWebsites) {
    if (props.websites?.length === 0) {
      props.getWebsites({limit: 1000, offset: 0})
    }
    // } else {
    //   setWebsite(JSON.parse(localWebsites));
    //   checkWebsiteSaved();
    // }
  }, []);
  useEffect(function () {
    if (props.websites && props.websites.length) {
      localStorage.setItem("websites", JSON.stringify(props.websites));
      setWebsites(props.websites.map((website) => {
        website.website_url = getWebsiteUrl(website);
        return website;
      }));
      checkWebsiteSaved(props.websites)
    }
  }, [props.websites]);

  // async function getWebsites() {
  //   const websites = await get(url.WEBSITES, { params: { limit: 1000 } })
  //   if (websites && websites.data && websites.data.length) {
  //     localStorage.setItem("websites", JSON.stringify(websites))
  //     setWebsites(websites.data)
  //     checkWebsiteSaved(websites.data)
  //   }
  // }

  function checkWebsiteSaved(websites) {
    let localWebsite = localStorage.getItem("website");
    if (localWebsite) {
      try {
        let v = JSON.parse(localWebsite);
        setWebsite(v)
      } catch (e) {
        setWebsite({})
      }
    } else {
      setWebsite(websites[0]);
      setWebsiteData(websites[0])
      // window.location.reload()
    }
  }

  function setWebsiteData(website) {
    localStorage.setItem("website", JSON.stringify(website));
    localStorage.setItem("website_id", website.website_id);
  }

  function getWebsiteUrl(website) {
    return website.base_url?.replace(/http\:\/\/|https\:\/\//g, "").replace('www.', '')
  }

  const toggle = () => {
    setMenu(!menu)
  };

  return (
      <>
        <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
          <DropdownToggle className="btn header-item waves-effect" tag="button" onClick={() => setSelectModal(true)}>
            <div className="" id={"good"}>
              <span className="bx bx-xs bx-globe align-middle"/>{" "}
              <span className="align-bottom">{getWebsiteUrl(website)}</span>
            </div>
          </DropdownToggle>
        </Dropdown>
        {
          selectModal && <WebsiteSelectModal websites={websites}
                                             selected={website.website_id}
                                             onClose={() => {setSelectModal(false)}}  onSelect={(key) => {
            setSelectModal(false);
            setWebsiteData(key);
            setWebsite(key);
            window.location = '/';
          }}/>
        }
      </>
  )
};
const mapStateToProps = state => {
  return state.websites;
};
export default connect(
    mapStateToProps,
    {getWebsites}
)(WebsiteDropdown)
