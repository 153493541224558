import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
//Import images
//i18n
import { withTranslation } from "react-i18next";
import notificationStore from "../../../notification.service";
import NotificationTile from "../../NotificationTile";
import { readAllNotifications } from "../../../helpers/main-backend.helper";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState(notificationStore.initialState);
  useEffect(function() {
    notificationStore.subscribe(setNotifications);
  }, []);

  useEffect(function() {
    if (menu) {
      notificationStore.clearNewNotificationCount();
      // readAllNotifications()
    }
  }, [menu]);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada"/>
          {notifications.newDataCount > 0 &&
          <span className="badge badge-danger badge-pill">{notifications.newDataCount}</span>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to="/notifications" className="small" onClick={() => setMenu(false)}>
                  {" "}
                  View All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {
              notifications.data.map((notification, key) => (
                <NotificationTile key={key} notification={notification} closeModal={() => setMenu(false)}/>
              ))
            }
          </SimpleBar>
          <div className="p-2 border-top">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications" onClick={() => setMenu(false)}
            >
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};
