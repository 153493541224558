import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
// Crypto Redux States
import { GET_WEBSITE_DETAIL, GET_WEBSITES } from "./actionTypes"
import { getWebsitesFail, getWebsitesSuccess, websitesLoading } from "./actions"
import { websiteList } from "../../helpers/main-backend.helper"

//Include Both Helper File with needed methods

function* fetchWebsites({ limit, offset }) {
  try {
    const response = yield call(websiteList, { limit, offset })
    yield put(getWebsitesSuccess(response))
  } catch (error) {
    yield put(getWebsitesFail(error))
  }
}

function* fetchWebsiteDetail({ invoiceId }) {
  try {
    const response = yield call(getWebsiteDetail, invoiceId)
    yield put(getWebsiteDE(response))
  } catch (error) {
    yield put(getWebsiteDetailFail(error))
  }
}

function* websitesSaga() {
  yield takeLatest(GET_WEBSITES, fetchWebsites)
  yield takeLatest(GET_WEBSITE_DETAIL, fetchWebsiteDetail)
}

export default websitesSaga
