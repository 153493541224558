import {
  GET_WEBPAGE_DETAIL_FAIL,
  GET_WEBPAGE_DETAIL_SUCCESS,
  GET_WEBPAGES_FAIL,
  GET_WEBPAGES_LOADING,
  GET_WEBPAGES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  pagesLoading: false,
  pages: [],
  limit: 2,
  offset: 0,
  totalPages: 0,
  pageDetail: {},
  error: {}
}

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WEBPAGES_LOADING:
      return {
        ...state,
        pages: [],
        pagesLoading: true
      }
    case GET_WEBPAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload.data,
        totalPages: action.payload.total_count,
        pagesLoading: false
      }

    case GET_WEBPAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        pagesLoading: false
      }

    case GET_WEBPAGE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
        pagesLoading: false
      }

    case GET_WEBPAGE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        pagesLoading: false
      }

    default:
      return state
  }
}

export default Invoices
