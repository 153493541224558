import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import {get, post, put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import {showError} from "../../helpers/util.helper";
import {useHistory} from "react-router-dom";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button, Card, CardBody, Col, Form, FormGroup, Label, Row} from "reactstrap";
import Dropzone from "react-dropzone";
import {Link} from "react-router-dom";
import {useJsApiLoader} from "@react-google-maps/api";

const librariesToLoad = ['places'];
const AddVirtualTour = function (props) {
  const [data, setData] = useState({});
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [savedPhotos, setSavedPhotos] = useState([]);
  const [mapSet, setMapSet] = useState(false);

  const history = useHistory();
  const { match: { params } } = props;


  function handleSubmit(event, values) {
    let d = { ...data, ...values };
    addVirtualTour(d);
  }

  useEffect(async () => {
    if (params.vt_id) {
      await getVirtualTour();
    }
  }, []);

  async function getVirtualTour() {
    const response = await get(url.VIRTUAL_TOUR + "/" + params.vt_id);
    if (!response.api_status) {
      setData(response);
      if (response.photos && response.photos.length) {
        setSavedPhotos(response.photos.map(d => d.image_url));
      }
    } else {
      showError(response);
    }
  }


  async function addVirtualTour(data) {
    let response;
    data = { ...data, ...latLng };
    if (data.vt_id) {
      data.photos = savedPhotos;
      response = await put(url.VIRTUAL_TOUR + "/" + data.vt_id, data);
    } else {
      response = await post(url.VIRTUAL_TOUR, data);
    }
    if (!response.api_status) {
      toastr.success("Virtual Tour Created. We are uploading Images it may take some time. Please wait....", "Success");
      for (let file of selectedFiles) {
        console.log(file);
        await post(url.VIRTUAL_TOUR_UPLOAD, { image: file.base64, vt_id: response.vt_id });
      }
      toastr.success("All Images uploaded successfully.", "Success");
      history.push('/virtual-tour');
    } else {
      showError(response);
    }
  }


  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    files.forEach((file, i) => {
      const reader = new FileReader();
      reader.onload = () => {
        // Do whatever you want with the file contents
        file.base64 = reader.result;
        if (i === files.length - 1) {
          files = [...selectedFiles, ...files];
          setselectedFiles(files);
        }
      };
      reader.readAsDataURL(file);
    });

  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function deleteImage(i) {
    setselectedFiles(selectedFiles.filter((d, index) => index !== i));
  }

  function deleteSavedImage(i) {
    setSavedPhotos(savedPhotos.filter((d, index) => index !== i));
  }

  function initMap() {
    if (!mapSet) {
      setMapSet(true);
      const input = document.getElementById("address");
      const options = {
        componentRestrictions: { country: "ca" },
        fields: ["formatted_address", "geometry", "name"],
        // origin: map.getCenter(),
        strictBounds: false,
        types: ["establishment"],
      };

      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.setFields(['address_component', 'geometry']);
      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      // autocomplete.bindTo("bounds", map);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const fields = autocomplete.getFields();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        setLatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
      });
    }
    return true;
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: librariesToLoad,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY
  });
  useEffect(() => {
    if (isLoaded && !mapSet) {
      initMap();
    }
  }, [isLoaded]);


  return (
    <>
      {
        isLoaded && <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Add VirtualTour" breadcrumbItem="VirtualTour Add"/>
            <AvForm className="needs-validation" onValidSubmit={handleSubmit} modal={data}>
              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      {/*<h4 className="card-title">Virtual Tour Info</h4>*/}
                      {/*<p className="card-title-desc">Please add following information to add a virtual tour.</p>*/}
                      <h5 className="py-1 font-weight-bold">Location </h5>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="address">Address</Label>
                            <AvField
                              name="address"
                              value={data.address}
                              onKeyPress={(e) => {
                                setData({ ...data, address: e.target.value });
                                e.key === 'Enter' && e.preventDefault();
                              }}
                              placeholder="#112 Bakers street"
                              type="search"
                              autocomplete="off"
                              className="form-control"
                              id="address"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="city">City</Label>
                            <AvField
                              name="city"
                              value={data.city}
                              placeholder="Brampton"
                              type="text"
                              className="form-control"
                              id="city"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">State</Label>
                            <AvField
                              name="state"
                              value={data.state}
                              placeholder="Ontario"
                              type="text"
                              className="form-control"
                              id="state"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="zip">Postal Code</Label>
                            <AvField
                              name="postal_code"
                              onEnter={(e) => {
                                console.log('enter');
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              value={data.postal_code}
                              placeholder="A12345"
                              type="text"
                              className="form-control"
                              id="zip"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <h5 className="py-1 font-weight-bold">Property Info</h5>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="bed">Bedroom(s)</Label>
                            <AvField
                              name="bed"
                              value={data.bed}
                              placeholder="1"
                              type="number"
                              className="form-control"
                              id="bed"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="bath">Bathroom(s)</Label>
                            <AvField
                              name="bath"
                              value={data.bath}
                              placeholder="1"
                              type="number"
                              className="form-control"
                              id="bath"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="area">Area(sqft)</Label>
                            <AvField
                              name="area"
                              value={data.area}
                              placeholder="1250"
                              type="number"
                              className="form-control"
                              id="bed"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="price">Price</Label>
                            <AvField
                              name="price"
                              value={data.price}
                              placeholder="120000"
                              type="number"
                              className="form-control"
                              id="price"
                            />
                          </FormGroup>
                        </Col>

                      </Row>
                      <Row>
                        <Col md="12">
                          <Form>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload"/>
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div className="dropzone-previews mt-3" id="file-previews">
                              {savedPhotos.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f}
                                            src={f}
                                          />
                                        </Col>
                                        <Col>
                                          <p className="mb-0">
                                            <strong>Saved</strong>
                                          </p>
                                          <a onClick={() => {
                                            deleteSavedImage(i);
                                          }} className="text-danger font-weight-bold">Delete</a>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                          <a onClick={() => {
                                            deleteImage(i);
                                          }} className="text-danger font-weight-bold">Delete</a>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                            </div>
                          </Form>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12 text-center">
                          <Button color="secondary" className="py-2 mr-2" type="button" onClick={() => {
                            history.push('/virtual-tour');
                          }}>
                            Back
                          </Button>
                          <Button color="primary" className="py-2" type="submit">
                            {
                              data.vt_id ?
                                "Update"
                                : "Save"
                            }
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>

          </div>
        </div>
      }
    </>
  );
};

export default AddVirtualTour;
