import { Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import LeadCallButtons from "./lead-call-buttons";

const LeadCallLogs = ({ closeModal, saveCallLog, lead }) => {
  return <>
    <Row className="mt-2">
      <Col>
        <AvForm onValidSubmit={(e, values) => saveCallLog(values)}>
          <AvField name="note" type="textarea" label="Note" rows="5" required/>
          <AvField type="select" name="call_type" label="Call Type" required>
            <option value=""/>
            <option value="inbound">Inbound</option>
            <option value="outbound">Outbound</option>
          </AvField>
          <AvField type="select" name="call_response" label="Call response" required>
            <option value=""/>
            <option value="connected">Connected</option>
            <option value="not home">Not Home</option>
            <option value="bad">Bad</option>
          </AvField>
          <LeadCallButtons lead={lead} closeModal={closeModal}/>
        </AvForm>
      </Col>
    </Row>
  </>;
};
export default LeadCallLogs;
