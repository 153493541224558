import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useEffect, useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {get, post, put} from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import toastr from "toastr";
import {showError} from "../../../helpers/util.helper";
import {useHistory} from "react-router-dom";
import Select from "react-select";

const addService = ({ match }) => {

  const [data, setData] = useState({});
  const [pages, setPages] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]);
  const [isPageChecked, setPageChecked] = useState(false);
  const [convertedContent, setConvertedContent] = useState("");
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );
  let history = useHistory();
  useEffect(async function () {
    const { pages } = await getPages();
    if (match && match.params && match.params.service_id) {
      await getService({ service_id: match.params.service_id, pages });
    }
  }, []);

  async function handleValidSubmit(e, values) {
    onEditorStateChange();
    let service = {
      ...data,
      ...values,
      service_description: convertedContent || data.service_description
    };
    if (selectedPage && selectedPage.value) {
      service.page_id = selectedPage.value;
    } else {
      service.page_id = null  ;
    }
    let response;
    if (service.service_id) {
      response = await put(url.SERVICES + "/" + data.service_id, service);
    } else {
      response = await post(url.SERVICES, service);
    }
    if (!response.api_status) {
      toastr.success("Service added successfully.", "Success");
      back();
    } else {
      showError(response);
    }
  }

  function back() {
    history.push("/services");

  }

  function onEditorStateChange(e) {
    if (e) {
      setEditorState(e);
    }
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const value = draftToHtml(
      rawContentState
    );
    setConvertedContent(value);
  };

  function handleFile(e, key) {
    const reader = new FileReader();
    reader.onload = function (e) {
      setData({ ...data, [key]: e.target.result });
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  async function getService({ service_id, pages }) {
    const response = await get(url.SERVICES + "/" + service_id);
    if (!response.api_status) {
      const blocksFromHtml = htmlToDraft(response.service_description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const state = EditorState.createWithContent(contentState);
      setEditorState(state);
      preparePages({ pages: pages, data: response });
      setData(response);
    } else {
      showError(response);
    }
  }

  async function getPages() {
    const response = await get(url.PAGE_ADD);
    if (!response.api_status) {
      await preparePages({ pages: response.data, data });
      return { pages: response.data };
    } else {
      showError(response);
    }
    return {};
  }

  function preparePages({ pages, data }) {
    let list = pages;
    const optionGroup = [
      {
        label: "Pages",
        options: []
      }
    ];
    console.log(list, 'is list');
    if (!list) {
      return;
    }
    list.forEach(category => {
      optionGroup[0].options.push({
        value: category.page_id,
        label: category.page_name
      });
    });
    if (data && data.page_id) {
      setSelectedPage(optionGroup[0].options.filter(category => data.page_id === category.value));
      setPageChecked(true);
    } else {
      if (list && list.length) {
        setSelectedPage(optionGroup[0].options[0]);
      }
    }
    setPages(list);
    setPageOptions(optionGroup);
  }

  return (
    <React.Fragment>
      <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>

        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Add Service" breadcrumbItem="Service Add"/>
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Service info</h4>
                  <p className="card-title-desc">Add information regarding your new service post</p>
                  <Row>
                    <Col md="8">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Service title</Label>
                          <AvField
                            name="service_title"
                            value={data.service_title}
                            placeholder="Lorum Ipsum.."
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Service Basic Info</Label>
                          <AvField
                            name="service_info"
                            value={data.service_info}
                            placeholder="Lorum Ipsum.."
                            type="textarea"
                            className="form-control"
                            id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <Label htmlFor="validationCustom01">Service Details</Label>
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                        />
                      </Col>
                    </Col>
                    <Col md="4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Service Photo</Label>
                            <AvField
                              name="post"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={event =>
                                handleFile(event, "servicePhoto")
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          {
                            <img src={data.servicePhoto || data.service_photo}
                                 alt="" className="img img-thumbnail"/>
                          }
                        </Col>
                        <Col md="12" className="mt-4">
                          <FormGroup className="d-flex align-items-center justify-content-between">
                            <Label htmlFor="validationCustom01">External Page</Label>
                            <div
                              className="custom-control custom-switch custom-switch-md d-inline-block"
                              dir="ltr"
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="externalPage"
                                checked={isPageChecked}
                                onChange={() => {
                                  setPageChecked(!isPageChecked);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="externalPage"
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        {
                          isPageChecked &&
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="validationCustom01">Page</Label>
                              <Select
                                value={selectedPage}
                                isMulti={false}
                                onChange={(values) => {
                                  setSelectedPage(values);
                                }}
                                options={pageOptions}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                        }
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12 text-center">
              <Card>
                <CardBody>
                  <Button color="secondary" className="py-2 mr-2" type="button" onClick={back}>
                    Back
                  </Button>
                  <Button color="primary" className="py-2" type="submit">
                    {
                      data.service_id ?
                        "Update"
                        : "Save"
                    }
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </AvForm>
    </React.Fragment>
  );
};

export default addService;
