import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import React, { useState } from "react";
import PropType from "prop-types";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { isEmail, showError } from "../../../helpers/util.helper";

const LeadEmailModal = (props) => {
  const [convertedContent, setConvertedContent] = useState("");
  const [title, setTitle] = useState();
  const [cc, setCc] = useState("");
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );

  function closeModal() {
    if (!cc || (cc && isEmail(cc))) {
      onEditorStateChange();
      props.sendEmail({
        message: convertedContent,
        title: title,
        to: props.email,
        cc
      });
    } else {
      showError({message: 'Invalid email address in CC.'});
    }
  }

  function onEditorStateChange(e) {
    if (e) {
      setEditorState(e);
    }
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const value = draftToHtml(
      rawContentState
    );
    setConvertedContent(value);
  };

  return <>
    <Modal
      isOpen={props.openModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        props.closeModal();

      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            props.closeModal();

          }}
        >
          New Message
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <Input type="email" className="form-control" placeholder="To"
                     value={props.email} readOnly/>
            </FormGroup>
            <FormGroup>
              <Input type="email" className="form-control" name='cc' placeholder="CC"
                     onChange={(e) => setCc(e.target.value)}/>
            </FormGroup>

            <FormGroup>
              <Input
                type="text"
                className="form-control"
                placeholder="Subject"
                onChange={(event) => setTitle(event.target.value)}
              />
            </FormGroup>
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              props.closeModal();
            }}
          >
            Close
          </Button>
          <Button type="button" color="primary" onClick={closeModal}>
            Send <i className="fab fa-telegram-plane ml-1"/>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  </>;
};
LeadEmailModal.propTypes = {
  sendEmail: PropType.func,
  closeModal: PropType.func,
  openModal: PropType.bool,
  lead: PropType.object
};
export default LeadEmailModal;
