import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import { del, get } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { page_limit } from "../../config";
import pageColumns from "../../page.columns";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import { showError } from "../../helpers/util.helper";
import toastr from "toastr";
import NoDataAvailable from "../../components/NoDataAvailable";
import { TableLoader } from "../../components/Loader";

const Blog = ({ match }) => {
  let history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingBlogId, setDeletingBlogId] = useState(null);
  useEffect(() => {
    getBlog({ limit, offset });
  }, []);

  const actions = [
    {
      name: "View",
      icon: "fas fa-eye",
      fn: viewBlog
    }, {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editBlog
    }, {
      name: "Delete",
      icon: "fas fa-trash",
      color: "danger",
      fn: openDeleteModal
    }];
  const limit = page_limit;
  const offset = 0;
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState({
    columns: pageColumns.blog,
    rows: []
  });

  function pageChange(e) {
    getBlog({ limit, offset: (limit * (e - 1)) });
  }

  const getBlog = async ({ limit, offset }) => {
    setPageLoading(1);
    const blogData = await get(url.BLOG_LIST, {
      params: {
        limit, offset
      }
    });
    setPageLoading(0);

    if(blogData.api_status) {
      return toastr.error('Something went wrong.');
    }
    blogData.data.forEach(blog => {
      if (blog.isDraft) {
        blog.post_status = <span className="badge badge-danger">Draft</span>
      } else {
        blog.post_status = <span className="badge badge-success">Published</span>
      }
    });
    let _d = { ...data, rows: blogData.data };
    setData(_d);
    setTotalPages(blogData.total_count);
  };

  function viewBlog({ post_id, post_title }) {
    try {
      let website = JSON.parse(localStorage.getItem("website"));
      if (website && website.base_url) {
        window.open(website.base_url + "/blog/" + post_title + "?post_id=" + post_id);
      }
    } catch (e) {
      showError({ message: "Something went wrong." });
    }
  }

  function editBlog({ post_id }) {
    history.push("/editBlog/" + post_id);
  }

  function addBlog() {
    history.push("/addBlog");
  }

  async function deleteBlog() {
    const response = await del(url.BLOG_LIST + "/" + deletingBlogId);
    if (!response.api_status) {
      let _d = data.rows.filter(d => d.post_id !== deletingBlogId);
      setData({ ...data, rows: _d });
      setDeletingBlogId(null);
      setDeleteModal(false);
      toastr.success("Blog deleted successfully.", "Success");
    } else {
      showError(response);
    }
  }

  function openDeleteModal({ post_id }) {
    setDeletingBlogId(post_id);
    setDeleteModal(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Blog List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                      data.rows.length
                        ? <AETable columns={data.columns}
                                   rows={data.rows}
                                   itemsPerPage={limit}
                                   loading={pagesLoading}
                                   addLink={addBlog}
                                   actions={actions}
                                   totalCount={totalPages}
                                   pageFn={pageChange}/>
                        :
                        <NoDataAvailable module="blogs"></NoDataAvailable>
                  }

                </CardBody>
                <DeleteModal
                  onCloseClick={() => {
                    setDeletingBlogId(null);
                    setDeleteModal(false);
                  }}
                  onDeleteClick={deleteBlog}
                  show={deleteModal}>
                </DeleteModal>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Blog;
