import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React, { useState } from "react";
import LeadCallLogs from "./lead-call-logs";
import * as classnames from "classnames";
import LeadScheduleCall from "./lead-schedule-call";
import { addCallLogs } from "../../leads.service";

const LeadCalls = ({ show, closeModal, lead, wu_id, getLogs, getTasks }) => {
  const [activeTab, setActiveTab] = useState("1");

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  async function saveCallLog(values) {
    await addCallLogs({ data: values, wu_id });
    getLogs();
    closeModal();
  }

  async function saveCallSchedule(values) {
    await addCallLogs({ data: values, wu_id });
    getLogs();
    getTasks();
    closeModal();

  }

  return <>
    <Modal isOpen={show}
           role="dialog"
           autoFocus={true}
           centered={true}
           className="exampleModal"
           tabIndex="-1"
           toggle={() => {
             closeModal();
           }}>
      <ModalHeader>
        Call Logs

      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1"
              })}
              onClick={() => {

                toggleTab("1");
              }}
            >Log Call
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2"
              })}
              onClick={() => {

                toggleTab("2");
              }}
            >Schedule Call
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <LeadCallLogs closeModal={closeModal} saveCallLog={saveCallLog} lead={lead} wu_id={wu_id}/>
          </TabPane>
          <TabPane tabId="2">
            <LeadScheduleCall closeModal={closeModal} saveCallSchedule={saveCallSchedule} lead={lead} wu_id={wu_id}/>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  </>;
};
export default LeadCalls;
