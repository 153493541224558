import React, {useEffect, useState} from "react"
import {Carousel, CarouselControl, CarouselItem} from "reactstrap"
// Carousel images

const ImageSlider = ({images = [], setActiveImage}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    useEffect(function () {
        setActiveImage(images[activeIndex]);
    }, [activeIndex]);

    function onExiting() {
        setAnimating(true)
    }

    function onExited() {
        setAnimating(false)
    }

    function next() {
        if (animating) return;
        const nextIndex =
            activeIndex === images.length - 1
                ? 0
                : activeIndex + 1;
        setActiveIndex(nextIndex)
    }

    function previous() {
        if (animating) return;
        const nextIndex =
            activeIndex === 0
                ? images.length - 1
                : activeIndex - 1;
        setActiveIndex(nextIndex)
    }

    function goToIndex(newIndex) {
        if (animating) return;
        setActiveIndex(newIndex)
    }

    const slides = images.map((item, key) => {
        return (
            <CarouselItem
                key={key}
                onExiting={onExiting}
                onExited={onExited}
                className={'feed-image img'}
            >
                <img
                    src={item.url}
                    className="d-block img-fluid"
                    alt={item.altText}
                />
            </CarouselItem>
        )
    });

    return (
        <React.Fragment>
            <div style={{background: 'rgba(0,0,0,0.3)'}}>
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    interval={null}

                >
                    {slides}
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                    />
                </Carousel>
            </div>
        </React.Fragment>
    )
};

export default ImageSlider
