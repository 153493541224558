import propertyImage from "../../assets/images/ae/components-new/properties-slider.png";
import blogImage from "../../assets/images/ae/components-new/blog.png";
import isotop from "../../assets/images/ae/components-new/property-in-area.png";
import testimonial from "../../assets/images/ae/components-new/testimonial.png";
import services from "../../assets/images/ae/components-new/our-services-list.png";
import servicesGallery from "../../assets/images/ae/components-new/our-services.png";
import stepperX from "../../assets/images/ae/components-new/stepper-x.png";
import stepperY from "../../assets/images/ae/components-new/stepper-y.png";
import team from "../../assets/images/ae/components-new/team.png";
import team2 from "../../assets/images/ae/components-new/team-2.png";
import team3 from "../../assets/images/ae/components-new/team-3.png";
import team4 from "../../assets/images/ae/components-new/team-4.png";
import contactUsBar from "../../assets/images/ae/components-new/contact-us.png";
import infographic from "../../assets/images/ae/components-new/infographic.png";
import infographicGrid from "../../assets/images/ae/components-new/infographic-grid.png";
import accordion from "../../assets/images/ae/components-new/Collapsable.png";
import faq from "../../assets/images/ae/components-new/faq.png";
import parallax from "../../assets/images/ae/components-new/parralax.png";
import propertySearchBar from "../../assets/images/ae/components-new/property-search.png";
import mortgageCalculatorComplete from "../../assets/images/ae/components/mortgage-calculator-complete.png";
import mortgageCalculator from "../../assets/images/ae/components/mortgage-calculator.png";
import galleryImage from "../../assets/images/ae/components-new/gallery.png";
import CTA from "../../assets/images/ae/components-new/cta.png";
import paragraph from "../../assets/images/ae/components-new/html.png";
import React from "react";
import moment from 'moment-timezone';
import HTMLSettings from "./page-component-settings/html.settings";
import GallerySettings from "./page-component-settings/gallary.settings";
import AccordionSettings from "./page-component-settings/accordion.settings";
import InfographicSettings from "./page-component-settings/info-graphic.settings";
import ParallaxSettings from "./page-component-settings/parallax.settigs";
import PropertySettings from "./page-component-settings/properties.settings";
import PropertyIsotopSettings from "./page-component-settings/properties_isotop.settings";
import TeamSettings from "./page-component-settings/team.settings";
import TestimonialSettings from "./page-component-settings/testimonial.settings";
import StepperSettings from "./page-component-settings/stepper.settings";
import FaqSettings from "./page-component-settings/faq.settings";
import InfoGraphicsGridSettings from "./page-component-settings/infographic-grid.settings";
import rentupComponents from './rentup.components';

const getInfoHtml = function ({key, title}) {
    return <div className="list-group-item " draggable="true" data-component={key}>
        <div className="justify-content-between d-flex">
            <span>{title}</span>
            <span className="btn btn-primary">Drag</span></div>
    </div>;
};
const getComponentUrl = (path) => {
    return process.env.REACT_APP_PUBLIC_URL + '/img/components/' + path + '.png';
}
let allComponents = {
    agency: {
        components: [
            {
                key: 'paragraph',
                textual: true,
                ui: false,
                title: 'Text Content',
                image: paragraph,
                content: ``,
                Settings: HTMLSettings,
                config: {paragraph: ''}
            },
            {
                key: 'gallery',
                ui: true,
                title: 'Gallery',
                image: galleryImage,
                Settings: GallerySettings,
                config: {rows: []}
            },
            {key: 'property_search_bar', ui: true, title: 'Properties Search Form', image: propertySearchBar},
            {
                key: 'propertyList',
                ui: true,
                title: 'Latest Properties',
                image: propertyImage,
                Settings: PropertySettings,
                config: {}
            },
            {
                key: 'properties_isotop',
                ui: true,
                title: 'Properties by Cities',
                image: isotop,
                Settings: PropertyIsotopSettings,
                config: {}
            },
            {
                key: 'team', ui: true,
                title: 'Our Team',
                image: [
                    {key: 'team', image: team},
                    {key: 'team-2', image: team2},
                    {key: 'team-3', image: team3},
                    {key: 'team-4', image: team4},
                ],
                Settings: TeamSettings, config: {}
            },
            {key: 'landing_cta', ui: true, title: 'Call to Actions', image: CTA},
            {
                key: 'mortgage_calculator',
                ui: true,
                title: 'Mortgage Calculator(Complete)',
                image: mortgageCalculatorComplete
            },
            {
                key: 'mortgage_calculator_simple',
                ui: true,
                title: 'Mortgage Calculator(Simple)',
                image: mortgageCalculator
            },
            {key: 'latest_blog', ui: true, title: 'Recent Blog Posts', image: blogImage},

            {
                key: 'testimonial',
                ui: true,
                title: 'Testimonial',
                image: testimonial,
                Settings: TestimonialSettings,
                config: {}
            },
            // {key: 'portfolio', ui: true, title: 'Products list', image: portfolio},
            {
                key: 'services', ui: true, title: 'Services Gallery', image: [
                    {key: 'services', image: servicesGallery},
                    {key: 'services_list', image: services},
                ]
            },
            {
                ui: true,
                title: 'Timeline',
                image: [{key: 'stepper_x', image: stepperX}, {key: 'stepper_y', image: stepperY}],
                Settings: StepperSettings,
                config: {rows: []}
            },
            {
                key: 'infographic_grid', ui: true, title: 'Image/Content List', image: infographicGrid,
                Settings: InfoGraphicsGridSettings,
                config: {rows: []}
            },
            {
                key: 'infographic',
                ui: true,
                title: 'Image/Content Block',
                image: infographic,
                Settings: InfographicSettings,
                config: {}
            },
            {key: 'contact_us_bar', ui: true, title: 'Contact Us Form', image: contactUsBar},
            {
                key: 'accordion',
                ui: true,
                title: 'Collapsible',
                image: accordion,
                Settings: AccordionSettings,
                config: {rows: [], accordion_title: ''}
            },
            {
                key: 'faq',
                ui: true,
                title: 'FAQ\'s',
                image: faq,
                Settings: FaqSettings,
                config: {rows: [], accordion_title: 'FAQ\'s'}
            },
            {
                key: 'parallax',
                ui: true,
                title: 'Parallax Block',
                image: parallax,
                Settings: ParallaxSettings,
                config: {}
            },
        ]
    },
    rentup: {
        components: rentupComponents

    }
}
if (localStorage.getItem('website')) {
    let industry_id = JSON.parse(localStorage.getItem('website')).industry_id;
    let theme_id = JSON.parse(localStorage.getItem('website')).theme_id;
    for (let theme in allComponents) {
        for (let component in allComponents[theme]) {
            if (allComponents[theme].hasOwnProperty(component)) {
                allComponents[theme][component].forEach(c => {
                    c.html = getInfoHtml({key: c.key, title: c.title});
                    c.getDesign = () => getDesign(c);
                    if (!Array.isArray(c.image)) {
                        c.images = [{key: c.key, image: c.image}];
                    } else {
                        c.images = c.image;
                    }
                });
                allComponents[theme][component] = allComponents[theme][component]
                    .filter(component => {
                        if (!component.industries) {
                            return true;
                        } else {
                            return component.industries.includes(+industry_id);
                        }
                    })
                    .filter(component => {
                        if (!component.theme) {
                            return true;
                        } else {
                            return component.theme.includes(theme_id);
                        }
                    });
            }
        }
    }

}

function getDesign(element) {
    let el;
    const generatedId = 'Asd-' + moment().unix();
    if (element.layout) {
        el = `<div class="row" data-col="${element.key}">`;
        Array(element.columns).fill('x').map(d => {
            el += `<div data-col="col-${12 / element.columns}" class="col-${12 / element.columns} container-area dropzone column" style="min-height: 50px"></div>`
        });
        el += `</div>`;
    } else if (element.textual) {
        el = `<div class="row ui-state-default" data-col=${element.key}>
            <div class="paragraph-content">${element.content}</div>
        </div>`;
    } else {
        el = `<div class="row ui-state-default" data-col=${element.key}>
            <div class="col-12">
                <h3 class="font-size-16 text-muted">
                    <a data-toggle="collapse" href="#${generatedId}" role="button" aria-expanded="false" aria-controls="${generatedId}">
                        ${element.title}    
                    </a>
                </h3>
            </div>
            <div class="col-12">
                <div class="card p-2 card-image collapse" id="${generatedId}">
                    <img src="${element.image}"/>
                </div>
            </div>
        </div>`
    }
    return el
}

export default allComponents;
