import {Card, CardBody, Col, Row} from "reactstrap";
import Moment from "react-moment";
import {formatMoney, showSuccess} from "../../../helpers/util.helper";
import React from "react";

const LeadPropertySearch = ({ properties, lead, setModal }) => {
  let base_url = "";
  try {
    let website = localStorage.getItem("website");
    base_url = JSON.parse(website).base_url;
  } catch (e) {
    base_url = "";
  }

  function getLink(property) {
    return base_url ? base_url + "/property/" + property.type + "/" + property.mls + "/" + property.Address : null;
  }

  function copyLink(property) {
    let link = getLink(property);
    navigator.clipboard.writeText(link).then(function () {
      showSuccess({ message: "Link copied." });
    }, function (err) {
      // console.error('Async: Could not copy text: ', err);
    });
  }

  return (<>
    <Card>
      <CardBody>
        <Row>
          {
            properties.map((property, key) => (
              <Col xl="4" sm="6" key={"_col_" + key}>
                <Card>
                  <CardBody>
                    <div className="product-img position-relative">
                      {property.last_visited ? (
                        <div className="product-ribbon font-size-11">
                              <span className="avatar-title p-2  bg-primary">
                                <Moment  withTitle titleFormat="LLL" fromNow >{property.last_visited}</Moment>
                              </span>
                        </div>
                      ) : null}
                      {property.source &&
                        <div className="product-ribbon font-size-11" style={{ top: 0, left: 0, right: "auto" }}>
                                <span className="avatar-title p-2  bg-secondary">
                                      Source {property.source}
                                </span>
                        </div>
                      }

                      <div className="product-ribbon font-size-11" style={{ bottom: 0, top: "auto" }}>
                              <span className="avatar-title p-2  bg-success">
                                {property.total_visits} &nbsp;<span className="fa fa-arrow-up"/>
                              </span>
                      </div>
                      <img
                        src={"https://cdn.agentedge.ca/" + property.mls + "/0.jpg"}
                        onError={(event) => event.target.src = "https://via.placeholder.com/300/eaeaea/FFFFFF/?text=No%20Image"}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                    <div className="mt-4 text-center">
                      <h5 className="mb-3 ">
                        <a
                          href={getLink(property)}
                          target="_blank"
                          className="text-dark"
                        >
                          {property.Address}, {property.Municipality}
                        </a>
                      </h5>
                      <h5 className="my-0">
                        <b>{formatMoney(property.ListPrice)}</b>
                      </h5>
                      <p className="p-0 mt-2 text-center font-size-13">
                        <a className="text-dark" href={getLink(property)}>MLS {property.mls} </a>
                        | {property.Bedrooms} bed | {property.Washrooms} bath
                      </p>
                      <div>
                        <button className="btn btn-outline-light" title="Send Email"
                                onClick={() => setModal({ showModal: true, email: true })}>
                          <span className="fa fa-envelope" />
                        </button>
                        <a href={"tel:" + lead.phone} className="btn btn-outline-light ml-2" title="Call Now">
                          <span className="fa fa-phone-alt" />
                        </a>

                        <button className="btn btn-outline-light ml-2"
                                title="Link copy"
                                onClick={() => copyLink(property)}>
                          <span className="fa fa-clipboard" />
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          }
          {
            properties.length === 0 && <Col><p className="text-center mt-2">No data available.</p></Col>
          }
        </Row>

      </CardBody>
    </Card>
  </>);
};

export default LeadPropertySearch;
