import {Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation"
import React from "react"
import PropTypes from "prop-types"
import FormWrapper from "../util/form-wrapper"
import InputMask from "react-input-mask";

const companySettings = (props) => {
    return (
        <Card>
            <CardBody>
                <h4 className="card-title">Brokerage Details</h4>
                <p className="card-title-desc">Basic information about your company.</p>
                <FormWrapper data={props.data} handleSubmit={props.handleSubmit} fields={
                    <Row>
                        {
                            props.webSettings.company_name&& <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="brokerage-name">Brokerage Name</Label>
                                    <AvField
                                        name="website_config.company_name"
                                        value={props.data.website_config.company_name}
                                        placeholder="ABC brokerage"
                                        type="text"
                                        className="form-control"
                                        id="brokerage-name"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.company_email && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="primary-email">Primary Email</Label>
                                    <AvField
                                        name="website_config.company_email"
                                        value={props.data.website_config.company_email}
                                        placeholder="abc@example.com"
                                        type="email"
                                        className="form-control"
                                        id="primary-email"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.company_contact && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="company-contact">Contact</Label>
                                    <AvField
                                        name="website_config.company_contact"
                                        value={props.data.website_config.company_contact}
                                        placeholder="(999) 999-9999"
                                        type="tel"
                                        mask="(999) 999-9999"
                                        tag={[Input, InputMask]}
                                        className="form-control"
                                        id="company-contact"

                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.company_fax && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="company-fax">Fax</Label>
                                    <AvField
                                        name="website_config.company_fax"
                                        value={props.data.website_config.company_fax}
                                        placeholder="(999) 999-9999"
                                        type="tel"
                                        mask="(999) 999-9999"
                                        tag={[Input, InputMask]}
                                        className="form-control"
                                        id="company-fax"

                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.company_address && <Col md="12">
                                <FormGroup>
                                    <Label htmlFor="company-address">Address Line 1</Label>
                                    <AvField
                                        name="website_config.company_address"
                                        value={props.data.website_config.company_address}
                                        placeholder="Lorum ipsum..."
                                        type="text"
                                        className="form-control"
                                        id="company-address"
                                    />
                                </FormGroup>
                            </Col>
                        }
                         {
                            props.webSettings.company_address && <Col md="12">
                                <FormGroup>
                                    <Label htmlFor="company-address-2">Address Line 2</Label>
                                    <AvField
                                        name="website_config.company_address_2"
                                        value={props.data.website_config.company_address_2}
                                        placeholder="Lorum ipsum..."
                                        type="text"
                                        className="form-control"
                                        id="company-address-2"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.company_logo && <>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="company-logo">Company Logo</Label>
                                        <AvField
                                            name="company_logo"
                                            placeholder="Lorum ipsum..."
                                            type="file"
                                            className="form-control"
                                            id="company-logo"
                                            onChange={event =>
                                                props.handleFile(event, "company_logo")
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    {
                                        <img src={props.data.website_config.company_logo} alt=""
                                             className="img img-thumbnail"/>
                                    }
                                </Col></>
                        }
                    </Row>
                }/>
            </CardBody>
        </Card>
    )
}

companySettings.propTypes = {
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    handleFile: PropTypes.func
}

export default companySettings
