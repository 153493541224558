import {Modal} from "reactstrap";
import React, {useState} from "react";

export const WebsiteSelectModal = ({websites, onSelect, onClose, selected}) => {
    const [filter, setFilter] = useState('');
    return <Modal
        isOpen={true}
        toggle={() => {
            onClose();
        }}
    >
        <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">Websites</h5>
            <button
                type="button"
                onClick={() => {
                    onClose();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div className="modal-body">
            <div className="row">
                <div className="col-12">
                    <input type="text" className="form-control" placeholder="Search.."
                           autoFocus
                           onChange={e => setFilter(e.target.value)}/>
                </div>
                <div className=" col-12 mt-3">
                    <div className="list-group">
                        {
                            Array.isArray(websites) && websites.length > 0 ? websites
                                    .filter(website => {
                                        if (!filter.trim()) {
                                            return website.website_url.trim();
                                        }
                                        const regex = new RegExp(filter);
                                        return website.website_url.trim() && website.website_url.match(regex);
                                    })
                                    .sort((a,b) => {
                                        return a.website_url.localeCompare(b.website_url)
                                    })
                                    .map((website, key) => <>
                                        <div
                                            style={{fontWeight: (selected === website.website_id ? 'bold' : '')}}
                                            className={"list-group-item pointer list-group-item-action "}
                                            onClick={() => onSelect(website)}
                                            key={key}>{website.website_url}</div>
                                    </>)
                                : 'No websites data available'
                        }

                    </div>
                </div>
            </div>
        </div>
    </Modal>
};
