import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap"
import {AvField} from "availity-reactstrap-validation"
import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import FormWrapper from "../util/form-wrapper"
import SettingsImagePreview from "../util/image-preview"
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import HeaderSelectPhotos from "./page-header.settings";
import ThemeImagesModal from "./theme-images.modal";
import EditCodeModal from "../../WebPages/edit-code.modal";

const homepageBannerSettings = props => {
    const [websiteColor, setWebsiteColor] = useState("#000000");
    const [headerModal, setHeaderModal] = useState({open: false});
    const [themeImagesModal, setThemeImagesModal] = useState({open: false});
    const [convertedContent, setConvertedContent] = useState("");
    const [codeModal, setCodeModal] = useState({show: false, code: ''});
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );
    useState(function () {
        setHeaderModal({
            selectedHeader: props.data.website_config.header_type,
            photo_path: props.data.website_config.header_photo_path,
            video_header: props.data.website_config.video_header
        });
        setEditorData(props.data.website_config.banner_title, setEditorState);
    });
    useState(function () {
        setConvertedContent(props.data.website_config.banner_title);
    }, []);

    function onEditorStateChange(e) {
        if (e) {
            setEditorState(e);
        }
        convertContentToHTML(editorState, setConvertedContent);
    }

    const convertContentToHTML = (data, fn) => {
        const rawContentState = convertToRaw(data.getCurrentContent());
        const value = draftToHtml(
            rawContentState
        );
        fn(value);
    };

    function updateHeaderHTML({code}) {
        setCodeModal({show: false, code});
    }

    function setEditorData(data, fn) {
        const blocksFromHtml = htmlToDraft(data || '');
        const {contentBlocks, entityMap} = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const state = EditorState.createWithContent(contentState);
        fn(state);
    }

    const [sideBarEffects, setSideBarEffects] = useState(
        props.data.website_config.homepage_banner_full
    );

    const [enableVideoSource, setEnableVideoSource] = useState(false);
    const onDrag = c1 => {
        setWebsiteColor(c1)
    };

    function homePageBannerSubmit(event, values) {
        if (!values.website_config) {
            values.website_config = {}
        }
        values.website_config = {...props.data.website_config, ...values.website_config};
        values.website_config.primary_color = websiteColor;
        values.website_config.homepage_banner_full = sideBarEffects;
        if (!enableVideoSource) {
            values.homepage_banner_video = ""
        }
        values.website_config.banner_title = convertedContent;
        values.website_config.header_type = headerModal.selectedHeader;
        values.website_config.header_html = codeModal.code;
        values.website_config.header_photo_path = headerModal.photo_path;

        props.handleSubmit(event, values)
    }

    useEffect(function () {
        if (
            props.data &&
            props.data.website_config &&
            props.data.website_config.primary_color
        ) {
            setWebsiteColor(props.data.website_config.primary_color);
            if (
                props.data.headers.find(
                    d => d.key === props.data.website_config.header_type
                )?.video
            ) {
                setEnableVideoSource(true)
            }
        }
        return () => {
            setWebsiteColor("#000000")
        }
    }, []);

    function openHeaderModal() {
        setHeaderModal({...headerModal, open: true,});
    }

    function closeHeaderModal({...args} = {}) {
        setHeaderModal({
            ...headerModal,
            open: false, ...args,
            selectedHeader: args?.header_id,
            header_html: args?.header_html,
            video_header: args?.video
        });
        if (args) {
            setCodeModal({...codeModal, code: args?.header_html})
        }
    }


    useEffect(
        function () {
            console.log("data updated", props.data)
        },
        [props.data]
    );

    return (
        <Card>
            <CardBody>
                <h4 className="card-title">Homepage Banner</h4>
                <p className="card-title-desc">Set Homepage banner data.</p>
                <FormWrapper
                    fields={
                        <Row>
                            {props.webSettings.header_type && (
                                <Col md={12}>
                                    <Row>
                                        <Col md="6">
                                            {
                                                !headerModal.photo_path &&
                                                <FormGroup>

                                                    <Button onClick={openHeaderModal} className="mb-3">Update
                                                        Header</Button>
                                                </FormGroup>
                                            }

                                            {
                                                headerModal.photo_path && <>
                                                    <label>Header Type</label>
                                                    <SettingsImagePreview image={headerModal.photo_path}
                                                    />
                                                    <div className="d-flex" style={{gap: '15px'}}>
                                                        <button  onClick={openHeaderModal}
                                                                className="mb-3 btn btn-primary btn-sm" type="button">Change Header</button>
                                                        <button onClick={() => {
                                                            setCodeModal({
                                                                show: true,
                                                                code: codeModal.code || props.data.website_config.header_html
                                                            })
                                                        }}  className="mb-3 btn btn-primary btn-sm" type="button">Edit HTML</button>
                                                    </div>

                                                </>
                                            }
                                        </Col>
                                        {
                                            props.webSettings.banner_floating_image && <>
                                                {
                                                    !props.data.website_config.banner_floating_image ?
                                                        <Col md="6">
                                                            <FormGroup className="d-none">
                                                                <AvField
                                                                    name="banner_floating_image"
                                                                    type="file"
                                                                    className="form-control "
                                                                    id="banner_floating_image"
                                                                    onChange={event =>
                                                                        props.handleFile(event, "banner_floating_image")
                                                                    }
                                                                />
                                                            </FormGroup>
                                                            <Button onClick={() => $('#banner_floating_image').click()}
                                                                    className="mb-3" color="primary">Upload Agent Image</Button>
                                                        </Col> : <Col md="6">
                                                            <label>Agent Image</label>
                                                            <SettingsImagePreview
                                                                image={props.data.website_config.banner_floating_image}
                                                                removeImage={() => props.handleFile(null, "banner_floating_image")}/>
                                                        </Col>}


                                            </>
                                        }
                                    </Row>
                                </Col>

                            )}


                            {props.webSettings.banner_title && (
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="banner-text">Banner Text</Label>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            editorState={editorState}
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                    </FormGroup>
                                </Col>
                            )}


                            {/* {props.webSettings.banner_size && (
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="banner-text">Banner Height</Label>
                          <AvField
                              name="website_config.banner_size"
                              value={props.data.website_config.banner_size}
                              type="select"
                              className="form-control"
                              id="banner-type"
                          >
                            <option value=""/>
                            <option value="100vh">Full</option>
                            <option value="90vh">90%</option>
                            <option value="85vh">85%</option>
                            <option value="80vh">80%</option>
                            <option value="75vh">75%</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                  )}*/}
                            {enableVideoSource && (
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="button_title">
                                            Homepage Banner Video(Youtube)
                                        </Label>
                                        <AvField
                                            name="website_config.home_banner_video"
                                            value={props.data.website_config.home_banner_video}
                                            placeholder="https://www.youtube.com/watch?v=y9j-BL5ocW8"
                                            type="text"
                                            className="form-control"
                                            id="home_banner_video"
                                        />
                                    </FormGroup>
                                </Col>
                            )}
                            {props.webSettings.homepage_button_title && (
                                <Col md={12} className="d-none">
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="button_type">Button Type</Label>
                                                <AvField
                                                    name="website_config.homepage_button_type"
                                                    value={props.data.website_config.homepage_button_type}
                                                    type="select"
                                                    className="form-control"
                                                    id="button_type"
                                                >
                                                    <option value="url">URL</option>
                                                    <option value="tel">Phone</option>
                                                    <option value="email">Email</option>
                                                </AvField>

                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="homepage_button_title">Button Title</Label>
                                                <AvField
                                                    name="website_config.homepage_button_title"
                                                    value={props.data.website_config.homepage_button_title}
                                                    placeholder="https://example.com"
                                                    type="text"
                                                    className="form-control"
                                                    id="homepage_button_title"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label htmlFor="button_link">Button Link</Label>
                                                <AvField
                                                    name="website_config.homepage_button_link"
                                                    value={props.data.website_config.homepage_button_link}
                                                    placeholder="https://example.com"
                                                    type="text"
                                                    className="form-control"
                                                    id="button_link"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            {
                                !enableVideoSource && <>

                                    {props.webSettings.home_banner_image && (
                                        <>
                                            <Col md="6">
                                                <FormGroup>
                                                    <Label htmlFor="home-page-banner">
                                                        Homepage Full Banner
                                                    </Label>
                                                    <AvField
                                                        name="home_banner_image"
                                                        placeholder="Lorum ipsum..."
                                                        type="file"
                                                        className="form-control d-none"
                                                        id="home-page-banner"
                                                        onChange={event =>
                                                            props.handleFile(event, "home_banner_image")
                                                        }
                                                    />
                                                    <div className="d-flex align-items-center" style={{gap: '15px'}}>
                                                        <Label className="btn btn-primary btn-sm"
                                                               htmlFor="home-page-banner">Upload</Label>
                                                        <span>Or</span>
                                                        <Label onClick={() => setThemeImagesModal({
                                                            open: true,
                                                            key: 'home_banner_image'
                                                        })} className="btn btn-primary btn-sm">Select From
                                                            Gallery</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                {props.data.website_config.home_banner_image && (
                                                    <>

                                                        <Label>
                                                            Preview
                                                        </Label>
                                                        <div
                                                            className="fas fa-minus-circle float-right"
                                                            onClick={() => {
                                                                props.removeFile("home_banner_image")
                                                            }}
                                                            style={{color: "red", cursor: "pointer"}}
                                                        />
                                                        <SettingsImagePreview
                                                            height="500px"
                                                            width="100%"
                                                            image={props.data.website_config.home_banner_image}
                                                        />
                                                    </>
                                                )}
                                            </Col>
                                        </>
                                    )}

                                    {props.webSettings.home_banner_image_tab && (
                                        <>
                                            <Col md="6">
                                                <FormGroup>
                                                    <Label htmlFor="home-page-tab">
                                                        Homepage Banner(Tablet)
                                                    </Label>
                                                    <AvField
                                                        name="home_banner_image_tab"
                                                        placeholder="Lorum ipsum..."
                                                        type="file"
                                                        className="form-control d-none"
                                                        id="home-page-tab"
                                                        onChange={event =>
                                                            props.handleFile(event, "home_banner_image_tab")
                                                        }
                                                    />
                                                    <div className="d-flex align-items-center" style={{gap: '15px'}}>
                                                        <Label className="btn btn-primary btn-sm"
                                                               htmlFor="home-page-tab">Upload</Label>
                                                        <span>Or</span>
                                                        <Label onClick={() => setThemeImagesModal({
                                                            open: true,
                                                            key: 'home_banner_image_tab'
                                                        })} className="btn btn-primary btn-sm">Select From
                                                            Gallery</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                {props.data.website_config.home_banner_image_tab && (
                                                    <>
                                                        <Label>Preview</Label>
                                                        <div
                                                            className="fas fa-minus-circle float-right"
                                                            onClick={() => {
                                                                props.removeFile("home_banner_image_tab")
                                                            }}
                                                            style={{color: "red", cursor: "pointer"}}
                                                        />
                                                        <SettingsImagePreview
                                                            height="500px"
                                                            width="100%"
                                                            image={
                                                                props.data.website_config.home_banner_image_tab
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Col>
                                        </>
                                    )}

                                    {props.webSettings.home_banner_image_mobile && (
                                        <>
                                            <Col md="6">

                                                <FormGroup>
                                                    <Label htmlFor="home-page-mob">
                                                        Homepage Banner(Mobile)
                                                    </Label>
                                                    <AvField
                                                        name="home_banner_image_mobile"
                                                        placeholder="Lorum ipsum..."
                                                        type="file"
                                                        className="form-control d-none"
                                                        id="home-page-mob"
                                                        onChange={event =>
                                                            props.handleFile(event, "home_banner_image_mobile")
                                                        }
                                                    />
                                                    <div className="d-flex align-items-center" style={{gap: '15px'}}>
                                                        <Label className="btn btn-primary btn-sm"
                                                               htmlFor="home-page-mob">Upload</Label>
                                                        <span>Or</span>
                                                        <Label onClick={() => setThemeImagesModal({
                                                            open: true,
                                                            key: 'home_banner_image_mobile'
                                                        })} className="btn btn-primary btn-sm">Select From
                                                            Gallery</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                {props.data.website_config.home_banner_image_mobile && (
                                                    <>
                                                        <Label>Preview</Label>

                                                        <div
                                                            className="fas fa-minus-circle float-right"
                                                            onClick={() => {
                                                                props.removeFile("home_banner_image_mobile")
                                                            }}
                                                            style={{color: "red", cursor: "pointer"}}
                                                        />
                                                        <SettingsImagePreview
                                                            height="500px"
                                                            width="100%"
                                                            image={
                                                                props.data.website_config.home_banner_image_mobile
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Col>
                                        </>
                                    )}
                                </>
                            }
                        </Row>
                    }
                    handleSubmit={homePageBannerSubmit}
                    data={props.data}
                />
            </CardBody>
            {
                headerModal.open && <HeaderSelectPhotos closeModal={closeHeaderModal} {...headerModal}/>
            }
            {
                themeImagesModal.open && <ThemeImagesModal closeModal={image => {
                    image && image.image_path && props.updateConfig({
                        ...props.data.website_config,
                        [themeImagesModal.key]: image.image_path
                    });
                    setThemeImagesModal({open: false});
                }}/>
            }
            {
                codeModal.show &&
                <EditCodeModal onCloseEditCodeModal={updateHeaderHTML}
                               code={codeModal.code}/>
            }
        </Card>

    )
};

homepageBannerSettings.propTypes = {
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    handleFile: PropTypes.func,
    removeFile: PropTypes.func,
};

export default homepageBannerSettings
