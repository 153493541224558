import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row } from "reactstrap";
import { readAllNotifications, websiteNotifications } from "../../helpers/main-backend.helper";
import * as moment from "moment-timezone";
import NotificationTile from "../../components/NotificationTile";
import { page_limit } from "../../config";

const Notifications = () => {

  const [notifications, setNotifications] = useState([]);
  const [pagesLoading, setPageLoading] = useState(0);
  const [moreRecords, setMoreRecords] = useState(false);
  const limit = page_limit;
  const offset = 0;
 

  useEffect(async function() {
    await getNotifications({ limit, offset });
    readAllNotifications()
  }, []);

  async function getNotifications({ limit, offset }) {
    setPageLoading(1);
    const notificationsData = await websiteNotifications({
      params: {
        limit, offset
      }
    });
    setPageLoading(0);
    if (!notificationsData.api_status) {
      console.log(notificationsData);
      let n = [...notifications, ...notificationsData.data];
      setNotifications(n);
      setMoreRecords(notificationsData.total_count > n.length);
    } else {
      setMoreRecords(false);
    }
  }

  function loadMore() {
    getNotifications({ limit, offset: notifications.length });
  }

  function todayNotifications() {
    return notifications.filter(d => moment(d.created).startOf("day").isSameOrAfter(moment().startOf("day")));
  }

  function oldNotifications() {
    return notifications.filter(d => moment(d.created).startOf("day").isBefore(moment().startOf("day")));
  }

  return <>
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Users" breadcrumbItem="Notifications"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {todayNotifications().length > 0 &&
                  <p className="mb-3 font-size-16 font-weight-bold text-muted">Today</p>}
                  {
                    todayNotifications().map((notification, key) => (
                      <NotificationTile notification={notification} key={key}/>
                    ))
                  }
                  {oldNotifications().length > 0 &&
                  <p className="mb-3 font-size-16 font-weight-bold text-muted mt-2">Older</p>}
                  {
                    oldNotifications().map((notification, key) => (
                      <NotificationTile notification={notification} key={key}/>
                    ))
                  }
                  {
                    moreRecords && <div className="text-center mt-4">
                      <button className="btn btn-primary btn-sm text-center" onClick={loadMore}>Load More</button>
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>

  </>;
};

export default Notifications;
