import toastr from "toastr";
import React from "react";


export const showError = function (response) {
  if (response.message) {
    toastr.error(response.message, "Error");
  } else {
    toastr.error("Something went wrong.", "Error");
  }
  return { error: true };
};

export const showWarning = function (response) {
  if (response.message) {
    toastr.warning(response.message, "Error");
  } else {
    toastr.warning("Something went wrong.", "Error");
  }
  return { warning: true };
};


export const showSuccess = function (response) {
  if (response.message) {
    toastr.success(response.message, "Success");
  } else {
    toastr.success("Successful", "Success");
  }
  return { error: false };
};

export const formatPhone = function (number) {
  let prefix  = '';
  if (number && number.startsWith("+")) {
    prefix = number.substr(0, 3);
    number = number.substr(4);
  }
  let cleaned = ("" + number).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return prefix + number;
};
export const formatMoney = function(number, color = false) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "CAD",
    currencyDisplay: "narrowSymbol",
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return color ? <span style={{ color: "#1A652A" }}>{formatter.format(number)}</span> : formatter.format(number);
};
export const isEmail = (email) => {
  let regex = new RegExp(`^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$`);
  return regex.test(email);
};
export const setTasksPriority = (priority) => {
  switch (priority) {
    case "COMPLETED":
      return <span className="badge badge-pill font-size-11 badge-soft-success"> Completed</span>;
    case "NORMAL":
      return <span className="badge badge-pill font-size-11 badge-soft-secondary"> Normal</span>;
    case "IMPORTANT":
      return <span className="badge badge-pill font-size-11 badge-soft-warning"> Important</span>;
    case "HIGH_PRIORITY":
      return <span className="badge badge-pill font-size-11 badge-soft-danger"> High Priority</span>;
    default:
      return;
  }
};
export const capitalize = (stringVal) => stringVal && stringVal.split(" ").filter(d=> d).map(d => d.replace(/^./, d[0].toUpperCase())).join(" ");

export const maxLength = (str = "", len) => {
  if (typeof str !== "string") {
    return "";
  }
  str = str.trim();

  if (str.length <= len) {
    return str;
  } else {
    return str.substring(0, len) + "...";
  }
};

export const unMaskNumber = (str = '') => {
  let reg = new RegExp(/\-|\)|\(|\s/g);
  return str.replace(reg, "");
};

export const ComponentsType = [
  {
    key: 'CALL_TO_ACTION', name: 'CALL TO ACTION',
  }, {
    key: 'ACCORDION', name: 'ACCORDION',
  }, {
    key: 'FAQ', name: 'FAQ',
  }, {
    key: 'BLOG', name: 'BLOG',
  }, {
    key: 'GALLERY', name: 'GALLERY',
  }, {
    key: 'HTML', name: 'HTML',
  }, {
    key: 'INFO_GRAPHIC_BLOCK', name: 'INFO GRAPHIC BLOCK',
  }, {
    key: 'PARALLAX', name: 'PARALLAX',
  },{
    key: 'PAGE_HEADER', name: 'PAGE HEADER',
  }, {
    key: 'PROPERTIES_LIST', name: 'PROPERTIES LIST',
  }, {
    key: 'PROPERTIES_AREA', name: 'PROPERTIES AREA',
  }, {
    key: 'TEAM', name: 'TEAM',
  }, {
    key: 'TESTIMONIAL', name: 'TESTIMONIAL',
  }, {
    key: 'Contact_US_BAR', name: 'Contact Us',
  }, {
    key: 'STEPPER', name: 'STEPPER',
  }, {
    key: 'ABOUT_US', name: 'ABOUT AGENT',
  }, {
    key: 'HTML_RAW', name: 'HTML',
  }, {
    key: 'BUYER_SELLER_GUIDE', name: 'BUYER/SELLER GUIDE',
  }, {
    key: 'AWARDS', name: 'AWARDS/ACHIEVEMENTS',
  }, {
    key: 'SEARCH', name: 'SEARCH',
  }, {
    key: 'MAP', name: 'MAP',
  },{
    key: 'WEBSITE_HEADER', name: 'WEBSITE HEADER',
  }, {
    key: 'WEBSITE_FOOTER', name: 'WEBSITE FOOTER',
  },{
    key: 'PRE_CONSTRUCTIONS', name: 'PRE CONSTRUCTIONS',
  },
];


export function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}