import React, {forwardRef, useEffect, useState} from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, Row, Spinner} from "reactstrap";
import PropTypes from 'prop-types';
import * as moment from 'moment-timezone';
import useScroll from "../../helpers/useScroll";
import {connect} from "react-redux";
import {setUiElements} from './page.service';
import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import EditCodeModal from "./edit-code.modal";
import {showError} from "../../helpers/util.helper";

const BuilderModal = forwardRef((props, ref) => {
    const [executeScroll, elRef] = useScroll();
    const [saving, setSaving] = useState(false);
    const [isMouseOnComponents, setIsMouseOnComponents] = useState(false);
    const [showComponentOptions, setShowComponentOptions] = useState(0);
    const [designElements, setDesignElements] = useState([]);
    const [activeSettings, setActiveSettings] = useState(null);
    let [allComponents, setAllComponents] = useState([]);
    const [codeModal, setCodeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(async () => {
        setLoading(true);
        let components = await getComponents();
        setLoading(false);
        if (!(components && components.length)) {
            showError({message: 'Something went wrong. Please contact administrator.'});
            props.saveConfig(false);
            return;
        }
        components = setUiElements(components);
        if (props.config?.length) {
            let config = typeof props.config === 'string' ? JSON.parse(props.config) : props.config;
            if (Array.isArray(config)) {
                setDesignElements(config.map(el => {
                    let component = components[el.component_type]?.components.find(d => d.tc_id === el.tc_id);
                    return {...component, ...el, component_content: el.content}
                }));
            }

        }
        setAllComponents(components);
    }, []);

    async function getComponents() {
        let result = await get(url.WEBSITE_COMPONENT_LIST);
        if (result && Array.isArray(result)) {
            return result;
        }
        return [];
    }

    const mouseOverComponent = (key) => {
        setShowComponentOptions(key)
    };
    useEffect(function () {
        setShowComponentOptions(0);
    }, [isMouseOnComponents]);

    const addDesign = (element) => {
        setDesignElements(elements => {
            let el = {...element, id: moment().format('x')};
            setActiveSettings(el);
            return [...elements, el];
        });
        let d = $('.design');
        let scr = d[0].scrollHeight;
        console.log(d, 'is d')
        d.animate({scrollTop: scr}, 1000);
    };

    const setSettings = (el) => {
        setActiveSettings(null);
        setTimeout(function () {
            setActiveSettings(el);
        }, 0)
    };

    const closeConfig = () => {
        setActiveSettings(null);
    };

    const updateConfig = async (config) => {
        setSaving(true);
        setActiveSettings(settings => ({...settings, config}));
        if (config.rows && config.rows.length) {
            for (let row of config.rows) {
                if (row.image && row.image.startsWith('data:')) {
                    const {image_path} = await props.saveImage({file: row.image});
                    row.image = image_path;
                }
            }
        }
        if (config.bgImage && !config.bgImage.startsWith('http')) {
            const {image_path} = await props.saveImage({file: config.bgImage});
            config.bgImage = image_path;
        }
        if (config.image && !config.image.startsWith('http')) {
            const {image_path} = await props.saveImage({file: config.image});
            config.image = image_path;
        }
        setDesignElements((elements) => {
            elements.forEach(element => {
                if (element.id === activeSettings.id) {
                    element.config = config;
                }
            });
            return elements;
        });

        setActiveSettings(function () {
            setTimeout(function () {
                setActiveSettings(setting => {
                    if (setting === -1) {
                        return null;
                    } else {
                        return setting;
                    }
                });
            }, 1000);
            return -1;
        });
        setSaving(false);

    };
    const updateCode = ({code}) => {
        setDesignElements((elements) => {
            elements.forEach(element => {
                if (element.id === codeModal.id) {
                    element.component_content = code;
                    element.code_modified = true;
                }
            });
            return elements;
        });
        setCodeModal(false);
    };
    const saveElements = () => {
        let toSave = designElements.map(el => ({
            key: el.component_key,
            id: el.id,
            tc_id: el.tc_id,
            component_type: el.component_type,
            theme_id: el.theme_id,
            content: el.component_content,
            code_modified: el.code_modified,
            image: el.component_image,
            config: el.config
        }));
        // localStorage.setItem('elements', JSON.stringify(toSave))
        props.saveConfig(toSave)
    };
    const preview = () => {
        let toSave = designElements.map(el => ({
            key: el.key,
            id: el.id,
            config: el.config
        }));
        // localStorage.setItem('elements', JSON.stringify(toSave))
        props.savePreview(toSave)
    };
    const close = () => {
        props.saveConfig()
    };

    const enableSettings = () => {
        return activeSettings && designElements.length > 0 && activeSettings.Settings;
    };

    return (
        <>
            <Modal
                isOpen={true}
                size="xl"
                modalClassName=" modal-fullscreen-xl"
            >

                <ModalBody className="builder">
                    {
                        loading ?
                            <div className="loader">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <>
                                <Row style={{minHeight: '80vh'}}>
                                    <Col md={2} className="pl-0 components-container position-relative"
                                         onMouseOver={() => setIsMouseOnComponents(true)}
                                         onMouseLeave={() => {
                                             setIsMouseOnComponents(false);
                                         }}>
                                        <div className="d-flex flex-nowrap bg-white">
                                            <ul className="list-group components"
                                                style={{width: '100%'}}>
                                                {
                                                    Object.values(allComponents)
                                                        .sort((a, b) => (a.title || '').localeCompare((b.title || '')))
                                                        .map(component => (<>
                                                            <li className={"list-group-item " + (component.key === showComponentOptions && 'active font-weight-bold')}
                                                                onMouseOver={() => mouseOverComponent(component.key)}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    mouseOverComponent(component.key)
                                                                }}
                                                                data-item={component.key}>{component.title}
                                                            </li>
                                                        </>))
                                                }
                                            </ul>
                                        </div>
                                        <div className="sidebar bg-white "
                                             style={{width: showComponentOptions !== 0 ? '340px' : '0'}}>
                                            {
                                                showComponentOptions !== 0 && Object.values(allComponents).find(component => component.key === showComponentOptions)
                                                &&
                                                Object.values(allComponents).find(component => component.key === showComponentOptions).components.map(component => (<>

                                                    <ul className="list-group">
                                                        {
                                                            component.images.map(d => (<>
                                                                <li className="list-group-item mb-2 component-image"
                                                                    onClick={() => addDesign({
                                                                        ...component,
                                                                        key: d.key,
                                                                        image: d.image
                                                                    })}>
                                                                    <img
                                                                        src={d.image}
                                                                        alt=""
                                                                        className="img-responsive img-thumbnail"/>

                                                                </li>
                                                            </>))
                                                        }
                                                    </ul>

                                                </>))
                                            }
                                        </div>
                                        <span
                                            className={"footer-disabled " + (enableSettings() && 'enabled')}/>

                                    </Col>
                                    <Col md={7} className="design-container">
                                        <div className=" position-relative design " ref={elRef}>
                                            {
                                                designElements.map((element, index) => <>
                                                    <div
                                                        className={"design-element " + (activeSettings && activeSettings.id === element.id && 'active')}
                                                        onClick={() => setSettings(element)}>
                                                        <div className="actions">
                                                            {
                                                                index !== 0 &&
                                                                <span className="fa fa-arrow-up text-primary"
                                                                      data-bg="blue"
                                                                      onClick={(event) => {
                                                                          setDesignElements(el => {
                                                                              el = JSON.parse(JSON.stringify(el));
                                                                              let tmp = el[index - 1];
                                                                              el[index - 1] = el[index];
                                                                              el[index] = tmp;
                                                                              return el;
                                                                          });
                                                                          event.stopPropagation();
                                                                      }
                                                                      }/>
                                                            }
                                                            {
                                                                designElements.length - 1 !== index &&
                                                                <span className="fa fa-arrow-down text-primary "
                                                                      onClick={(event) => {
                                                                          setDesignElements(el => {
                                                                              el = JSON.parse(JSON.stringify(el));
                                                                              let tmp = el[index + 1];
                                                                              el[index + 1] = el[index];
                                                                              el[index] = tmp;
                                                                              return el;
                                                                          });
                                                                          event.stopPropagation();
                                                                      }
                                                                      }/>
                                                            }
                                                            <span className="fa fa-trash text-danger "
                                                                  onClick={(event) => {
                                                                      event.stopPropagation();
                                                                      setDesignElements(el => {
                                                                          return el.filter((n, i) => i !== index)
                                                                      })
                                                                  }}/>
                                                            <span className="fa fa-code text-secondary "
                                                                  onClick={(event) => {
                                                                      setCodeModal(element);
                                                                      event.stopPropagation();
                                                                  }}/>

                                                        </div>
                                                        <img src={element.image}
                                                             className="img-thumbnail" alt=""/>

                                                    </div>
                                                </>)
                                            }
                                        </div>
                                    </Col>
                                    {

                                        <Col md={3} className={'settings'}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h6 className="text-muted">Settings </h6>
                                                    {
                                                        activeSettings === -1 ?
                                                            'Settings updated successfully.'
                                                            :
                                                            enableSettings() ?
                                                                <activeSettings.Settings config={{
                                                                    component_css: activeSettings.component_css,
                                                                    ...activeSettings.config,
                                                                    id: activeSettings.component_type + '-' + activeSettings.id,
                                                                }}
                                                                                         code={activeSettings.component_content}
                                                                                         close={closeConfig}
                                                                                         updateConfig={updateConfig}/>
                                                                : 'No configuration required.'
                                                    }

                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                {
                                    codeModal &&
                                    <EditCodeModal onCloseEditCodeModal={updateCode}
                                                   codeUpdated={codeModal.code_modified}
                                                   code={codeModal.component_content}/>
                                }
                            </>
                    }
                </ModalBody>
                <ModalFooter className="position-relative">
                    {
                        saving ? <Spinner/> : <>

                            <Button type={"button"} size="small" onClick={close}>Close</Button>
                            {
                                !loading &&
                                <>
                                    <Button type={"button"} size="small" color="info" onClick={preview}>Preview</Button>
                                    <Button type={"button"} size="small" color="primary"
                                            onClick={saveElements}>Save</Button>
                                </>
                            }
                            <span className={"footer-disabled " + (enableSettings() && 'enabled')}/>
                        </>
                    }
                </ModalFooter>


            </Modal>

        </>
    );
});

BuilderModal.propTypes = {
    page_html: PropTypes.string,
    onSavePage: PropTypes.func,
    onGetHtml: PropTypes.func,
    saveImage: PropTypes.func
};

const mapStatetoProps = state => {

    const {user} = state.user;
    return {user};
};
export default connect(mapStatetoProps, null)(BuilderModal);
