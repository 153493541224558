import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card, CardBody, Col, Row} from "reactstrap";
import {get, post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import PlatformConnectionTile from "./platform-connection-tile";
import {signInUser} from "../../Gmb.connect";
import {showError, showSuccess} from "../../helpers/util.helper";
import FacebookConnectAccountModal from "../Facebook/SuccessModal/FacebookConnectAccount.modal";
import {BulletList} from "react-content-loader";
import {GMBLocationModal} from "../Testimonials/gmb-location.modal";

const Integrations = (props) => {
    const [loading, setLoading] = useState(false);
    let initialState = {
        facebook: {
            connected: false,
            connect: connectFacebook,
            disconnect: disconnectFacebook,
            posts: '/facebook-posts'
        },
        gmb: {connected: false, connect: gmbLogin, disconnect: disconnectGmb}
    };
    const [gmbModal, setGMBModal] = useState({showModal: false, location: []});

    const [connectedPlatforms, setConnectedPlatforms] = useState(initialState);
    const [authResponse, setAuthResponse] = useState();
    const [successModal, setSuccessModal] = useState({showModal: false});
    useEffect(async function () {
        setLoading(true);
        await getConnectedPlatforms();
        setLoading(false);
    }, []);

    function connectFacebook() {
        let isPermissionDenied = false;
        FB.login((response) => {
            if (response.authResponse) {
                setAuthResponse(response.authResponse);
                FB.api('/me/permissions', async (data) => {
                    setLoading(true);
                    if (data['data'] && data['data'].length) {
                        data['data'].map(permission => {
                            if (permission.status === 'declined') {
                                isPermissionDenied = true;
                            }
                        });
                        if (isPermissionDenied) {
                            FB.api('me/permissions', 'delete');
                            showError({message: 'Please provide all required permissions'});
                            setLoading(false);
                        } else {
                            FB.api('/me/accounts?fields=name', (pages) => {
                                FB.api('/me/adaccounts?fields=name', (accounts) => {
                                    if (accounts && accounts.data) {
                                        openSuccessModal({pages: pages.data, ad_accounts: accounts.data});
                                    } else {
                                        showError({message: 'We are unable to find ad accounts with this account.'});
                                    }
                                    setLoading(false);
                                });
                            });
                        }
                    } else {
                        setLoading(false);
                        showError({message: 'Something went wrong. Please try again later.'})
                    }
                });
            } else {
                showError({message: 'Something went wrong. Please try again later.'})
            }
        }, {scope: 'pages_show_list, leads_retrieval, pages_manage_ads, ads_management,pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_posts '});
    }

    function openSuccessModal({pages, ad_accounts}) {
        if (typeof pages !== 'undefined') {
            if (!(pages && pages.length)) {
                showError({message: 'No pages associated. Please create a page to continue'});
            } else if (!(ad_accounts && ad_accounts.length)) {
                showError({message: 'No associated ad account  found with this account.'});
            } else {
                setSuccessModal({showModal: true, ...{pages, ad_accounts}});
            }

        } else {
            showError({message: 'Something went wrong.'});
        }
    }

    async function disconnectFacebook() {
        const response = await get(url.FACEBOOK_DISCONNECT);
        if (!response.api_status) {
            showSuccess({message: 'Facebook disconnected successfully.'})
        } else {
            showSuccess(response)
        }
        await getConnectedPlatforms();
    }

    async function connectGmb() {
        const response = await signInUser();
        getConnectedPlatforms();
    }

    async function disconnectGmb() {
        const response = await post(url.GMB_DISCONNECT);
        if (!response.api_status) {
            showSuccess({message: 'Google my business disconnected successfully.'})
        } else {
            showError(response)
        }
        await getConnectedPlatforms();
    }

    async function getConnectedPlatforms() {
        const response = await get(url.CONNECTED_PLATFORMS);
        setConnectedPlatforms(initialState);
        if (response && !response.api_status) {
            let connections = {}
            response.forEach(platform => {
                if (platform.platform_id === "2") {
                    connections = {
                        ...connections,
                        facebook: {...connectedPlatforms.facebook, connected: true, ...platform}
                    };
                } else if (platform.platform_id === "3") {

                    connections = {...connections, gmb: {...connectedPlatforms.gmb, connected: true, ...platform}};
                }
            });
            setConnectedPlatforms({
                ...connectedPlatforms,
                ...connections
            })
        }
    }


    async function gmbLogin() {
        const locations = await signInUser();
        if (locations && locations.length) {
            setGMBModal({...gmbModal, showModal: true, locations})
        }
    };

    async function saveLocation({location_id}) {
        let location_name = gmbModal.locations.find(location => location.location_id === location_id).title;
        setGMBModal({...gmbModal, showModal: false, locations: []});
        const response = await post(url.GMB_UPDATE_LOCATION, {location_id, location_name});
        if (!response.api_status) {
            getConnectedPlatforms();
            return showSuccess({message: 'Google my business account connected successfully.'});
        }
        return showError({message: 'Please select page to continue.'});
    }


    async function associatedCredentials(config) {
        setSuccessModal({showModal: false});
        setLoading(true);
        if (config.page_id) {
            const response = await post(url.FACEBOOK_FEATURE_CONNECT, {
                fb_access_token: authResponse.accessToken,
                page_id: config.page_id,
                fb_user_id: authResponse.userID,
                fb_ad_account_id: config.ad_account_id
            });
            if (!response.api_status) {
                showSuccess({message: 'Account connected successfully.'});
                getConnectedPlatforms();
            } else {
                showError({message: 'Something went wrong.'});
            }
        } else {
            showError({message: 'Please select page and ad account to continue.'});
        }
        setLoading(false);
    }

    return <>
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Platforms" breadcrumbItem="Social Platforms"/>
                </div>
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={12} md={6}>
                                {loading ?
                                    <BulletList/>
                                    :
                                    <ul className="list-group">
                                        {
                                            Object.keys(connectedPlatforms).map((platform, key) => (
                                                <li className="list-group-item" key={key}>
                                                    <PlatformConnectionTile account={connectedPlatforms[platform]}
                                                                            connect={connectedPlatforms[platform].connect}
                                                                            posts={connectedPlatforms[platform].posts}
                                                                            disconnect={connectedPlatforms[platform].disconnect}
                                                                            platform={platform}/>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                }

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            {
                successModal.showModal === true ?
                    <FacebookConnectAccountModal
                        pages={successModal.pages}
                        ad_accounts={successModal.ad_accounts}
                        openModal={successModal.showModal} onCloseFacebookModal={associatedCredentials}/>
                    : ""
            }
            {
                gmbModal.showModal &&
                <GMBLocationModal {...gmbModal}
                                  onSave={saveLocation}
                                  onClose={() => setGMBModal({...gmbModal, showModal: false, locations: []})}/>
            }
        </React.Fragment>
    </>
};
export default Integrations;
