import "./post-share.css"
import * as url from "../../helpers/url_helper"
import { get } from "../../helpers/api_helper"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Row } from "reactstrap"
import Moment from "react-moment"
import ContentLoader from "react-content-loader"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const FacebookPosts = props => {
  const [posts, setPosts] = useState([])
  const [pagination, setPagination] = useState({ next: false, previous: false })
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState([])
  useEffect(async function () {
    getPosts()
  }, [])

  async function getPosts({ type = "" } = {}) {
    setLoading(true)
    let pageUrl = url.FACEBOOK_POSTS
    if (type === "next") {
      pageUrl += "?next=" + pagination.next
    } else if (type === "previous") {
      pageUrl += "?previous=" + pagination.previous
    }
    const posts = await get(pageUrl)
    setLoading(false)
    if (!posts.api_status) {
      setPosts(posts.posts)
      setPage(posts.page)
      setPagination({
        next: posts.pagination.next,
        previous: posts.pagination.previous,
      })
    }
  }

  function setPostLightBox(post, status) {
    post.lighbox = status
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Platforms" breadcrumbItem="Facebook Posts" />
        </div>
        <Card>
          <CardBody>
            <Row>
              <Col sm={12} md={6}>
                {loading
                  ? Array(10)
                      .fill("x")
                      .map((a, key) => <ContentLoader />)
                  : posts.map((post, key) => (
                      <>
                        {post && (
                          <div key={key}>
                            <div>
                              <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom">
                                <div className="d-flex flex-row align-items-center feed-text px-2">
                                  <img
                                    className="rounded-circle"
                                    src={
                                      page.picture &&
                                      page.picture.data &&
                                      page.picture.data.url
                                    }
                                    width="45"
                                  />
                                  <div className="d-flex flex-column flex-wrap ml-2">
                                    <span className="font-weight-bold">
                                      {page.name}
                                    </span>
                                    <span className="text-black-50 time">
                                      <Moment fromNow>
                                        {post.created_time}
                                      </Moment>
                                    </span>
                                  </div>
                                </div>
                                <div className="feed-icon px-2">
                                  <i className="fa fa-ellipsis-v text-black-50" />
                                </div>
                              </div>
                            </div>
                            {post.full_picture && (
                              <div className="feed-image p-2 tpx-3">
                                {post.lightbox ? (
                                  <Lightbox
                                    mainSrc={post.full_picture}
                                    enableZoom={false}
                                    imageCaption={post.message}
                                    onCloseRequest={() =>
                                      setPosts(posts => {
                                        return posts.map(p => {
                                          if (p.id === post.id) {
                                            p.lightbox = false
                                          }
                                          return p
                                        })
                                      })
                                    }
                                  />
                                ) : null}

                                <img
                                  className="img-fluid img-responsive"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setPosts([
                                      ...posts.map(p => {
                                        if (p.id === post.id) {
                                          p.lightbox = true
                                        }
                                        return p
                                      }),
                                    ])
                                  }}
                                  src={post.full_picture}
                                />
                              </div>
                            )}
                            <div className="p-2 px-3">
                              <span style={{ whiteSpace: "pre-line" }}>
                                {post.message}
                              </span>
                            </div>
                            <div className="d-flex justify-content socials p-2 py-3">
                              '
                              <span>
                                {" "}
                                <i className="fa fa-thumbs-up mr-1" />{" "}
                                {post.likes_count}
                              </span>
                              <span className="ml-2">
                                <i className="fa fa-comments mr-1" />{" "}
                                {post.comments_count}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                <Row>
                  <Col md={12}>
                    {pagination.previous && (
                      <span
                        className="btn-link btn"
                        onClick={() => getPosts({ type: "previous" })}
                      >
                        Previous
                      </span>
                    )}
                    {pagination.next && (
                      <span
                        className="btn-link btn float-right"
                        onClick={() => getPosts({ type: "next" })}
                      >
                        Next
                      </span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default FacebookPosts
