import axios from 'axios';
import * as moment from 'moment-timezone';

const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

const setAuthorizationHeader = (token) => {
  if (token) {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token.replace(/"/g, '')}`;
  } else {
    delete axiosApi.defaults.headers.common['Authorization'];
  }
};

axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newAccessToken = await refreshToken();
      originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

      return axiosApi(originalRequest);
    }

    if (error.response && error.response.status === 403) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refreshToken');
      window.location = window.location.origin + '/login';
    }
    return Promise.reject(error);
  },
);

const refreshToken = async () => {
  try {
    //
    const refresh_token = localStorage.getItem('refreshToken');

    if (!refresh_token) {
      throw new Error('Refresh token not found.');
    }

    const response = await post('/api/auth/refreshToken', {
      refresh_token: refresh_token.replace(/"/g, ''),
    });

    localStorage.setItem('access_token', response.access_token);
    localStorage.setItem('refreshToken', response.refresh_token);

    return response.access_token;
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};

const executeRequest = async (method, url, data = {}, config = {}) => {
  setAuthorizationHeader(localStorage.getItem('access_token'));
  axiosApi.defaults.headers.common['x-website-id'] = localStorage.getItem('website_id');
  axiosApi.defaults.headers.common['x-timezone'] = moment().format('Z');

  try {
    const response = await axiosApi.request({
      method,
      url,
      data,
      ...config,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function get(url, config = {}) {
  return await executeRequest('get', url, null, config);
}

export async function post(url, data, config = {}) {
  return await executeRequest('post', url, data, config);
}

export async function put(url, data, config = {}) {
  return await executeRequest('put', url, data, config);
}

export async function del(url, config = {}) {
  return await executeRequest('delete', url, null, config);
}

export function resetHeaders(token) {
  setAuthorizationHeader(token);
}
