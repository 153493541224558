import FacebookMarketingLayout from "./FacebookMarketingLayout";
import React, {useEffect, useState} from "react";
import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {showError} from "../../helpers/util.helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card, CardBody, Col, Row} from "reactstrap";
import {TableLoader} from "../../components/Loader";
import AETable from "../../components/Common/Table";
import NoDataAvailable from "../../components/NoDataAvailable";
import pageColumns from "../../page.columns";
import {page_limit} from "../../config";
import {Link} from "react-router-dom";
import FacebookLeadDetailsModal from "./SuccessModal/FacebookLeadDetailsModal";
import NoDataAvailableImage from "../../components/Common/NoDataAvailableImage"
const FacebookLeadsList = ({ match }) => {
  const [forms, setForms] = useState({
    columns: pageColumns.lead,
    rows: [],
    page: 1
  });
  const [totalPages, setTotalPages] = useState(0);
  const [leadModal, setLeadModal] = useState({ showModal: false });

  const limit = page_limit;
  const offset = 0;
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(async function () {
    if (match.params.form_id) {
      await getLeads();

    }

  }, []);
  const actions = [
    {
      name: "View",
      icon: "fas fa-eye",
      fn: viewLeads
    }];

  async function getLeads() {
    setPageLoading(true);
    const response = await get(url.LEADS + '?form_id=' + match.params.form_id);
    setPageLoading(false);
    if (!response.api_status ) {
      if(response && response.leads && response.leads.length) {
        setTotalPages(response.leads.length);
        setForms({ ...forms, rows: response.leads });
      }
    } else {
      showError(response);
    }
  }

  function viewLeads(e) {
    setLeadModal({ showModal: true, leadData: e.field_data });
  }

  function pageChange(e) {
    setForms({ ...forms, page: e });
  }

  function getList() {
    return forms.rows.slice((limit * (forms.page - 1)));
  }

  return (
    <>
      <FacebookMarketingLayout>
        <Breadcrumbs title="Lead Forms"
                     breadcrumbItem={<Link to="/fb-marketing/forms"><i className="fa fa-arrow-left"></i> Back</Link>}/>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {
                  pageLoading ?
                    <TableLoader/>
                    :
                    forms.rows.length
                      ? <AETable columns={forms.columns}
                                 rows={getList()}
                                 itemsPerPage={limit}
                                 loading={pageLoading}
                                 actions={actions}
                                 totalCount={totalPages}
                                 pageFn={pageChange}/>
                      :
                      <NoDataAvailableImage title="No Leads data available."/>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        {
          leadModal.showModal === true ?
            <FacebookLeadDetailsModal
              lead={leadModal.leadData}
              openModal={leadModal.showModal} onCloseFacebookModal={() => {
              setLeadModal({ showModal: false });
            }
            }/>
            : ""
        }
      </FacebookMarketingLayout>
    </>
  );
};

export default FacebookLeadsList;
