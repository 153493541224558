import {Button, CardBody, Col, FormGroup, Label, Modal, ModalBody, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import Select from "react-select"
import React, {useState} from "react"
import PropTypes from "prop-types"

const MenuModal = ({show, onCloseClick, editItem, pages, page_id, type}) => {
  const [title, setTitle] = useState(editItem.title);
  const [menuForm, setMenuForm] = useState(null);
  const [menuType, setMenuType] = useState(type);
  const [selectedPageId, setPageId] = useState(page_id);

  function handleValidSubmit(event, values) {

    if (!(values.title && values.title.trim())) {
      return
    }
    console.log(!+selectedPageId && !values.link, !+selectedPageId, !values.link);
    /*is default page*/
    let pageId = selectedPageId;
    let menu_type = menuType;
    if (!+selectedPageId && !values.link) {
      values.link = selectedPageId;
      pageId = '';
      menu_type = 'custom';
    }
    onCloseClick({
      title: values.title && values.title.trim(),
      link: values.link,
      menuType, selectedPageId: pageId
    });
    resetMenuData()
  }

  function resetMenuData() {
    menuForm.reset();
    setPageId(null);
    setMenuType("custom")
  }

  return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-3">
          <div>
            <CardBody>

              <AvForm className="needs-validation"
                      ref={c => (setMenuForm(c))}
                      onValidSubmit={handleValidSubmit}>
                {
                  !editItem.id ?
                      <Row className="text-left">
                        <div className="custom-control custom-radio mb-3 ml-3">
                          <input
                              type="radio"
                              id="customLink"
                              name="customLink"
                              className="custom-control-input"
                              checked={menuType === "custom"}
                              value="custom"
                              onChange={(e) => setMenuType(e.target.value)}/>
                          <label
                              className="custom-control-label"
                              htmlFor="customLink">Custom Link
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-3 ml-2">
                          <input
                              type="radio"
                              id="externalLink"
                              name="customLink"
                              value="external_link"
                              checked={menuType === "external_link"}
                              onChange={(e) => setMenuType(e.target.value)}
                              className="custom-control-input"/>
                          <label
                              className="custom-control-label"
                              htmlFor="externalLink">External Link</label>
                        </div>
                        <div className="custom-control custom-radio mb-3 ml-2">
                          <input
                              type="radio"
                              id="innerPage"
                              name="customLink"
                              value="page"
                              checked={menuType === "page"}
                              onChange={(e) => setMenuType(e.target.value)}
                              className="custom-control-input"
                          />
                          <label
                              className="custom-control-label"
                              htmlFor="innerPage"
                          >Page</label>
                        </div>
                      </Row>
                      : ""
                }
                <Row>
                  <Col md="12">
                    {
                      menuType === "page" ?
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Page </Label>
                            <Select
                                value={pages.filter(page => page.value === selectedPageId)}
                                onChange={(e) => {
                                  setTitle(pages.find(page => page.value === e.value).name)
                                  setPageId(e.value)
                                }}
                                options={pages}
                                classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                          : <FormGroup>
                            <Label htmlFor="validationCustom02">Link</Label>
                            <AvField
                                name="link"
                                value={editItem.link}
                                placeholder={
                                  menuType === "external_link" ?
                                      "https://example.com" :
                                      "/contact-us"
                                }
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                            />
                          </FormGroup>
                    }
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="validationCustom01">Title</Label>
                      <AvField
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Home"
                          type="text"
                          errorMessage="Enter Title"
                          className="form-control"
                          id="validationCustom01"
                          required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Button color="primary" className="py-2" type="submit">
                      Save
                    </Button>
                    <Button color="danger" className="py-2 ml-2" type="reset"
                            onClick={() => {
                              resetMenuData();
                              menuForm.reset();
                              onCloseClick()
                            }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </div>
        </ModalBody>
      </Modal>
  )
};

MenuModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  editItem: PropTypes.object,
  pages: PropTypes.array,
  page_id: PropTypes.number
};

export default MenuModal
