import { Link } from "react-router-dom";
import Moment from "react-moment";
import React from "react";
import { maxLength } from "../../helpers/util.helper";

const DashboardCall = ({ task }) => {
  return <div className="align-self-center overflow-hidden me-auto mt-3">
    <Link to={"/leads/" + task.wu_id + "/profile?tab=3"}>

      <div>
        <h5 className="font-size-14 ">
          <div className="row">
            <span className="text-dark col-8">
            {task.task_title}
          </span>
            <div className="col-4">
              <span className="badge badge-primary  ">
              {
                task.isToday ? <span>Today <Moment format={"hh mm A"}>{task.task_due_date}</Moment></span> :
                  <Moment format="MMM DD hh:mm A">{task.task_due_date}</Moment>
              }
          </span>

            </div>
          </div>
        </h5>
        <p className="text-muted mb-1">
          {maxLength(task.task_description, 50)}
        </p>
        <small>To: {task.name}</small><br/>
      </div>
    </Link>

  </div>;
};

export default DashboardCall;
