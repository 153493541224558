import React, {useEffect, useState} from "react"
import {Card, CardBody, Col, Row} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AETable from "../../components/Common/Table"
import {del, get} from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import {page_limit} from "../../config"
import pageColumns from "../../page.columns"
import {useHistory} from "react-router-dom"
import DeleteModal from "../../common/DeleteModal"
import {showError, showSuccess} from "../../helpers/util.helper";
import toastr from "toastr"
import {TableLoader} from "../../components/Loader"
import NoDataAvailable from "../../components/NoDataAvailable"

const WebPages = ({match}) => {
  useEffect(() => {
    getPageData({limit, offset})
  }, []);
  let history = useHistory();
  const actions = [
    {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editPage
    }, {
      name: "View",
      icon: "fas fa-eye",
      fn: viewPage
    }, {
      name: "Copy Link",
      icon: "fas fa-clipboard",
      fn: copyLink
    }, {
      name: "Delete",
      color: "danger",
      icon: "fas fa-trash",
      fn: openDeleteModal
    }];

  const limit = page_limit;
  const offset = 0;
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingPageId, setDeletingPageId] = useState(null);

  const [data, setData] = useState({
    columns: pageColumns.web_pages,
    rows: []
  });

  function viewPage(page) {
    let website = JSON.parse(localStorage.getItem("website"));
    if (website && website.base_url) {
      let url = website.base_url + page.page_slug;
      if (!url.startsWith("http")) {
        url = "https://" + url
      }
      window.open(url)
    }
  }

  function copyLink(page) {
    let website = JSON.parse(localStorage.getItem("website"));
    if (website && website.base_url) {
      let url = website.base_url + page.page_slug;
      if (!url.startsWith("http")) {
        url = "https://" + url
      }
      navigator.clipboard.writeText(url).then(function () {
        showSuccess({message: 'Saved in clipboard.'})
      }, function (err) {
        // console.error('Async: Could not copy text: ', err);
      });
    }
  }

  function pageChange(e) {
    getPageData({limit, offset: (limit * (e - 1))})
  }

  const getPageData = async ({limit, offset}) => {
    setPageLoading(1);
    const pageData = await get(url.WEB_PAGE_LIST, {
      params: {
        limit, offset
      }
    });
    setPageLoading(0);
    if(pageData.api_status) {
      return toastr.error('Something went wrong.');
    }
    let _d = {...data, rows: pageData.data};
    setData(_d);
    setTotalPages(pageData.total_count)
  };

  function addPage() {
    history.push("/addPage")
  }

  function editPage({page_id}) {
    history.push("/pages/" + page_id)
  }


  async function deleteBlog() {
    const response = await del(url.PAGE_ADD + "/" + deletingPageId);
    if (!response.api_status) {
      let _d = data.rows.filter(d => d.page_id !== deletingPageId);
      setData({...data, rows: _d});
      setDeletingPageId(null);
      setDeleteModal(false);
      toastr.success("Page deleted successfully.", "Success")
    } else {
      showError(response)
    }
  }

  function openDeleteModal({page_id}) {
    setDeletingPageId(page_id);
    setDeleteModal(true)
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid ">
            <Breadcrumbs title="Dashboard" breadcrumbItem="Pages List"/>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {
                      pagesLoading ?
                          <TableLoader/>
                          :
                          data.rows.length
                              ? <AETable columns={data.columns}
                                         rows={data.rows}
                                         itemsPerPage={limit}
                                         addLink={addPage}
                                         loading={pagesLoading}
                                         actions={actions}
                                         totalCount={totalPages}
                                         pageFn={pageChange}/>
                              :
                              <NoDataAvailable module="page"/>
                    }
                  </CardBody>

                  <DeleteModal
                      onCloseClick={() => {
                        setDeletingPageId(null);
                        setDeleteModal(false)
                      }}
                      onDeleteClick={deleteBlog}
                      show={deleteModal}>

                  </DeleteModal>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
  )
};

export default WebPages
