import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { get, post, put } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import toastr from "toastr";
import { showError } from "../../../helpers/util.helper";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import AddCategoryModal from "../../../common/AddCategory";

const AddPortfolio = ({ match }) => {

  const [data, setData] = useState({});
  const [convertedContent, setConvertedContent] = useState("");
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );
  let history = useHistory();
  useEffect(async function() {
    let categories = await getCategories();
    if (match && match.params && match.params.portfolio_id) {
      await getPortfolio({ portfolio_id: match.params.portfolio_id, categories });
    }
  }, []);

  async function handleValidSubmit(e, values) {
    onEditorStateChange();
    let portfolio = {
      ...data,
      ...values,
      portfolio_description: convertedContent || data.portfolio_description,
      categories: selectedCategories.map(category => category.value)
    };
    let response;
    if (portfolio.portfolio_id) {
      response = await put(url.PORTFOLIO + "/" + data.portfolio_id, portfolio);
    } else {
      response = await post(url.PORTFOLIO, portfolio);
    }
    if (!response.api_status) {
      toastr.success("Portfolio added successfully.", "Success");
      back();
    } else {
      showError(response);
    }
  }

  function back() {
    history.push("/portfolio");

  }

  function onEditorStateChange(e) {
    if (e) {
      setEditorState(e);
    }
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const value = draftToHtml(
      rawContentState
    );
    setConvertedContent(value);
  };

  function handleFile(e, key) {
    const reader = new FileReader();
    reader.onload = function(e) {
      setData({ ...data, [key]: e.target.result });
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  async function getPortfolio({ portfolio_id, categories }) {
    const response = await get(url.PORTFOLIO + "/" + portfolio_id);
    if (!response.api_status) {
      const blocksFromHtml = htmlToDraft(response.portfolio_description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const state = EditorState.createWithContent(contentState);
      setEditorState(state);
      setData(response);
      prepareCategories({ categories: categories, data: response });
    } else {
      showError(response);
    }
  }

  async function getCategories() {
    const response = await get(url.PORTFOLIO + "/category");
    if (!response.api_status) {
      prepareCategories({ categories: response });
      return response;
    } else {
      showError(response);
    }
  }

  function prepareCategories({ categories, data }) {
    let list = categories;
    const optionGroup = [
      {
        label: "Categories",
        options: []
      }
    ];
    list.forEach(category => {
      optionGroup[0].options.push({
        value: category.pct_id,
        label: category.category_name
      });
    });
    if (data && data.categories && data.categories.length) {
      setSelectedCategories(optionGroup[0].options.filter(category => data.categories.includes(category.value)));
    }
    setCategories(optionGroup);
  }

  async function saveCategory(category_name) {
    const response = await post(url.PORTFOLIO + "/category", { category_name });
    if (!response.api_status) {
      prepareCategories({ categories: response });
    } else {
      showError(response);
    }
  }

  return (
    <React.Fragment>
      <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>

        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Add Portfolio" breadcrumbItem="Portfolio Add"/>
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Portfolio info</h4>
                  <p className="card-title-desc">Add information regarding your project.</p>
                  <Row>
                    <Col md="8">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Project title</Label>
                          <AvField
                            name="portfolio_title"
                            value={data.portfolio_title}
                            placeholder="Lorum Ipsum.."
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <Label htmlFor="validationCustom01">Project Description</Label>
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                        />
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Client Name</Label>
                          <AvField
                            name="client_name"
                            value={data.client_name}
                            placeholder="John Doe"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Client Link</Label>
                          <AvField
                            name="client_link"
                            value={data.client_link}
                            placeholder="https://example.com"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Client Description</Label>
                          <AvField
                            name="client_description"
                            value={data.client_description}
                            placeholder="Lorum Ipsum.."
                            type="textarea"
                            rows="5"
                            className="form-control"
                            id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>

                    </Col>
                    <Col md="4">
                      <Row>
                        <Col md="10">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Categories</Label>
                            <Select
                              value={selectedCategories}
                              isMulti={true}
                              onChange={(values) => {
                                setSelectedCategories(values);
                              }}
                              options={categories}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <Label htmlFor="validationCustom01">&nbsp;</Label>
                          <div className="d-flex">
                            <span
                              onClick={() => {
                                setAddCategoryModal(true);
                              }}
                              className="btn btn-primary p-0 m-0 py-2 col-12">
                              <span className="fas fa-plus-square"></span>
                            </span>
                          </div>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Project Photo</Label>
                            <AvField
                              name="post"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={event =>
                                handleFile(event, "portfolioPhoto")
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          {
                            <img src={data.portfolioPhoto || data.portfolio_photo}
                                 alt="" className="img img-thumbnail"/>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12 text-center">
              <Card>
                <CardBody>
                  <Button color="secondary" className="py-2 mr-2" type="submit" onClick={back}>
                    Back
                  </Button>
                  <Button color="primary" className="py-2" type="submit">
                    {
                      data.portfolio_id ?
                        "Update"
                        : "Save"
                    }
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </AvForm>
      <AddCategoryModal
        onClose={({ category_name }) => {
          if(category_name && category_name.trim()) {
            saveCategory(category_name);
          }
          setAddCategoryModal(false);
          // setDeleteModal(false);
        }}
        openModal={addCategoryModal}>
      </AddCategoryModal>
    </React.Fragment>
  );
};

export default AddPortfolio;
