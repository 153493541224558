import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {Card, CardBody, Col, Row} from "reactstrap";
import {TableLoader} from "../../components/Loader";
import AETable from "../../components/Common/Table";
import NoDataAvailable from "../../components/NoDataAvailable";
import pageColumns from "../../page.columns";
import {page_limit} from "../../config";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {toggleAdminMenu} from "../../store/layout/actions";
import {ComponentsType, showError} from "../../helpers/util.helper";

const WebsiteList = (props) => {
  let style = {
    listStyle: {
      padding: "0",
      "list-style": "none",
      "color": "blue"
    },
    websiteLink: {
      cursor: "pointer",
      display: "inline"
    }
  };
  return (
    <>
      <ol style={style.listStyle}>
        {
          props.websites.map(website => <li>
            <a
              color="btn btn-primary stretched-link"
              onClick={() => {
                props.gotoWebsite(website);
              }}
              style={style.websiteLink}>{website.base_url}</a>
          </li>)
        }
      </ol>
    </>
  );
};
const Users = (props) => {
  let limit = page_limit;
  let offset = 0;
  const history = useHistory();
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [filterFields, setFilterFields] = useState([]);
  const [data, setData] = useState({
    columns: pageColumns.users,
    rows: []
  });
  useEffect(() => {
    (async () => {
      await getWebsites();
      await getPageData({limit, offset});
    })()
  }, []);
  useEffect(() => {
    setFilterFields(filterFields.map(fields => {
      fields.value = filters[fields.name];
      return fields;
    }));
    pageChange(1);
  }, [filters]);
  const getPageData = async ({limit, offset}) => {
    setPageLoading(1);
    const userData = await get(url.USERS, {
      params: {
        limit, offset, ...filters
      }
    });
    setPageLoading(0);
    let _d = {...data, rows: userData.data};
    _d.rows.forEach(user => {
      if (user && user.websites.length) {
        user.websites = <WebsiteList websites={user.websites} gotoWebsite={gotoWebsite}/>;
      }
    });
    setData(_d);
    setTotalPages(userData.total_count);
  };

  function pageChange(e) {
    setCurrentPage(e);
    getPageData({ limit, offset: (limit * (e - 1)) });
  }

  const actions = [
    {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editUser
    }];

  function editUser(user) {
    history.push("/editUser/" + user.user_id);
  }

  function addUser() {
    history.push("/addUser");
  }

  function gotoWebsite(website) {
    props.toggleAdminMenu(false);
    localStorage.setItem("website_id", website.website_id);
    localStorage.setItem("website", JSON.stringify(website));
    history.push("/settings");
  }

  async function getWebsites() {
    const response = await get(url.WEBSITES + '?limit=10000');
    if (!response.api_status) {
      setFilterFields([{
        type: "select",
        name: "website_id",
        label: 'Website',
        options: response.data.map(d => ({value: d.website_id, label: d.base_url}))
      }]);
    } else {
      showError(response);
    }
  }


  return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Users" breadcrumbItem="Users List"/>
            <Row>
              <Col className="col-12">
                <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                        <AETable columns={data.columns}
                                 rows={data.rows}
                                 itemsPerPage={limit}
                                 loading={pagesLoading}
                                 currentPage={currentPage}
                                 actions={actions}
                                 addLink={addUser}

                                 onFilter={setFilters}
                                 filters={filterFields}
                                 totalCount={totalPages}
                                 pageFn={pageChange}/>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = state => {
  const {
    adminMenu
  } = state.Layout;
  return { adminMenu };
};

export default connect(mapStatetoProps, {
  toggleAdminMenu
})(Users);
