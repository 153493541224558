import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row, Spinner} from "reactstrap";
import {AvField, AvForm} from 'availity-reactstrap-validation';
import './post-share.css'
import ImageSlider from "./image-slider";
import PostPreviewModal from "./post-preview.modal";
import {get, post} from "../../helpers/api_helper";
import * as url from '../../helpers/url_helper';
import {showError, showSuccess} from "../../helpers/util.helper";
import {useHistory, useLocation} from "react-router-dom";
import ContentLoader from "react-content-loader";

const PostShare = (props) => {
    const history = useHistory();
    const [description, setDescription] = useState();
    const [page, setPage] = useState();
    const [loading, setLoading] = useState(false);
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");
    const type = new URLSearchParams(search).get("type");
    const [posting, setPosting] = useState(false);
    const [activeImage, setActiveImage] = useState(false);
    const [images, setImages] = useState([]);
    const [postPreviewModal, setPostPreviewModal] = useState({
        showModal: false,
        closeModal: () => {
            setPostPreviewModal({...postPreviewModal, showModal: false})
        }
    });
    useEffect(async () => {
        setLoading(true);
        if (type === 'testimonial') {
            setDescription('We are very happy to share our client\'s feedback. #happyClient #customerFeedback');
            await getTestimonialImages();
        } else if (type === 'property') {
            setDescription('Here is fabulous property just listed.');
            await getPropertyImages();
        }
        setLoading(false);
    }, []);
    useEffect(() => {
        console.log(images, 'are images');
    }, [images]);

    async function getTestimonialImages() {
        const response = await get(url.TESTIMONIAL_POST + '?testimonial_id=' + id);
        if (!response.api_status) {
            setPage(response.facebook);
            setImages(response.images.map(image => {
                return {
                    url: image,
                    caption: ''
                }
            }))
        } else {
            showError(response);
            history.goBack()
        }
    }

    async function getPropertyImages() {
        const response = await get(url.PROPERTY_POST + '?mls_id=' + id);
        if (!response.api_status) {
            setPage(response.facebook);
            setImages(response.images.map(image => {
                return {
                    url: image,
                    caption: ''
                }
            }))
        } else {
            showError(response);
            history.goBack()
        }
    }

    async function publishPost() {
        if (!description) {
            return showError(({message: 'Please enter post description to continue.'}));
        }
        setPosting(true);
        const response = await post(url.FACEBOOK_POST, {
            image: activeImage?.url,
            description,
            type: 'testimonial',
            type_id: '25'
        });
        setPosting(false);
        setPostPreviewModal({...postPreviewModal, showModal: false});
        if (!response.api_status) {
            history.push('/facebook-posts');
            return showSuccess({message: 'Posted successfully'})
        } else {
            return showError(response)
        }
    }

    function previewPost() {
        setPostPreviewModal({...postPreviewModal, showModal: true, description, image: activeImage?.url})
    }

    return (
      <>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Platforms" breadcrumbItem="Share Post" />
          </div>
          <Row>
            <Col md="6">
              <Card>
                <CardBody>
                  {loading ? (
                    <ContentLoader />
                  ) : (
                    <>
                      <div className="image-slider mb-2">
                        <ImageSlider
                          images={images}
                          setActiveImage={setActiveImage}
                        />
                      </div>
                      <p>
                        <i>
                          <small>
                            Prior consent must be obtained before sharing this
                            property on Facebook.
                          </small>
                        </i>
                      </p>
                      <AvForm>
                        <AvField
                          type="textarea"
                          name="description"
                          label="Description"
                          value={description}
                          onChange={e => setDescription(e.target.value)}
                        />
                      </AvForm>
                      <div className="text-center">
                        {posting ? (
                          <Spinner />
                        ) : (
                          <>
                            <button
                              className="btn btn-sm btn-dark"
                              onClick={() => history.goBack()}
                            >
                              Back
                            </button>
                            <button
                              className="btn btn-sm btn-success ml-2"
                              onClick={previewPost}
                            >
                              Preview
                            </button>
                            <button
                              className="btn btn-sm btn-primary ml-2 btn-icon"
                              onClick={publishPost}
                            >
                              <span className="mdi mdi-facebook" /> Post Now
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <PostPreviewModal
            {...postPreviewModal}
            publishPost={publishPost}
            posting={posting}
            page={page}
          />
        </div>
      </>
    )
};
export default PostShare;
