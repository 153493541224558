import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card, CardBody, Col, Row} from "reactstrap";
import {useHistory, useLocation} from "react-router-dom";
import {page_limit} from "../../config";
import pageColumns from "../../page.columns";
import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {formatMoney} from "../../helpers/util.helper";
import SettingsImagePreview from "../Settings/util/image-preview";
import {TableLoader} from "../../components/Loader";
import AETable from "../../components/Common/Table";
import Moment from "react-moment";
import {searchLocation} from "../Leads/leads.service";
import PropertyFiltersModal from "./property-filters.modal";
import {connect} from "react-redux";

const Properties = (props) => {
    let history = useHistory();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({});
    const [moreFilters, setMoreFilters] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionsLoading, setOptionsLoading] = useState(false);
    useEffect(() => {
        console.log(props.user, 'is user')
        let search = filters;
        try {
            let query = location.search.substring(1);
            search = JSON.parse('{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            /*setting filters will call the hook and fetch the properties*/
            setFilters(search);
        } catch (e) {
            getProperties({limit, offset});
            console.log('error', e)
        }
        fetchLocationSearch({query: "Brampton", setData: true});

    }, []);
    useEffect(function () {
        getProperties({limit, offset});
    }, [props.user]);

    const actions = [
        {
            name: "Share",
            icon: "fas fa-share",
            fn: shareProperty
        }];
    const limit = page_limit;
    const offset = 0;
    const [pagesLoading, setPageLoading] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState({
        columns: pageColumns.properties,
        rows: []
    });

    function pageChange(e) {
        setCurrentPage(e);
        getProperties({limit, offset: (limit * (e - 1))});
    }

    async function fetchLocationSearch({query}) {
        if (!(query && query.length > 1)) {
            return;
        }
        setOptionsLoading(true);
        let locations = await searchLocation(query);
        if (!(locations && locations.length)) {
            return;
        }
        setOptionsLoading(false);

        setFilterFields(function () {
            filterFields.forEach(field => {
                if (field.type === 'ajax') {
                    field.options = locations.map(location => {
                        return {label: prepareLocation(location), value: location};
                    })
                }
            });
            return filterFields;
        })

    }

    function prepareLocation(location) {
        return [location.Community, location.Municipality, location.Area].filter(d => d).join(", ");
    }

    const getProperties = async ({limit, offset, filters$ = filters}) => {
        if (!(props.user && props.user.website)) {
            return
        }
        setPageLoading(1);
        const properties = await get(url.PROPERTY, {
            params: {
                limit, offset, ...filters$
            }
        });
        setPageLoading(0);
        properties.data = setPropertyData(properties.data);
        let _d = {...data, rows: properties.data};
        setData(_d);
        setTotalPages(properties.total_count);
    };


    function shareProperty({mls_id}) {
        history.push({pathname: 'share', search: '?id=' + mls_id + '&type=property'})
    }

    function setPropertyData(properties) {
        if (properties && properties.length) {
            return properties.map(property => {
                property.property_image =
                    <SettingsImagePreview image={"https://cdn.agentedge.ca/" + property.MLS + '/0.webp'}
                                          fallback={"https://cdn.agentedge.ca/" + property.MLS + '/0.jpg'} width="100px"
                                          height="100px"/>;
                property.ListPrice = formatMoney(property.ListPrice);
                property.bed_bath = <><span className="fa fa-bed"/> {property.Bedrooms || '-'} <span
                    className="fa fa-bath ml-2"/> {property.Washrooms || '-'}</>;
                property.Address = property.Address + ', ' + property.Municipality + ', ' + property.Area;
                property.ago = property.time_ago;
                property.MLS =
                    <a href={'https://' + props.user.website.base_url + '/property/' + property.type + '/' + property.mls_id}
                       target="_blank">{property.mls_id}</a>;
                return property
            });
        }
        return [];
    }

    useEffect(() => {
        const params = new URLSearchParams(filters);
        history.replace({pathname: location.pathname, search: params.toString()});
        setFilterFields(filterFields.map(fields => {
            fields.value = filters[fields.name];
            return fields;
        }));
        pageChange(1);
    }, [filters]);

    const [filterFields, setFilterFields] = useState([
        {
            type: "text",
            name: 'MLS',
            placeholder: ''
        }, {
            type: "text",
            name: "min_price",
            placeholder: ''
        }, {
            type: "text",
            name: "max_price",
            placeholder: ''
        }, {
            type: 'select',
            name: 'bed',
            placeholder: '',
            value: filters.bed,
            options: [
                {value: '1', label: '1+'},
                {value: '2', label: '2+'},
                {value: '3', label: '3+'},
                {value: '4', label: '4+'},
                {value: '5', label: '5+'}
            ]
        }, {
            type: 'select',
            name: 'bath',
            placeholder: '',
            value: filters.bath,
            options: [
                {value: '1', label: '1+'},
                {value: '2', label: '2+'},
                {value: '3', label: '3+'},
                {value: '4', label: '4+'},
                {value: '5', label: '5+'}
            ]
        }]);

    function resetFilter() {
        setFilters({});
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Property List"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {
                                        pagesLoading ?
                                            <TableLoader/>
                                            :
                                            <AETable columns={data.columns}
                                                     rows={data.rows}
                                                     itemsPerPage={limit}
                                                     currentPage={currentPage}
                                                     filters={filterFields}
                                                     moreFilters={() => setMoreFilters(true)}
                                                     onFilter={(f) => setFilters({...filters, ...f})}
                                                     resetFilter={resetFilter}
                                                     loading={pagesLoading}
                                                     actions={actions}
                                                     totalCount={totalPages}
                                                     pageFn={pageChange}/>
                                    }

                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <PropertyFiltersModal openModal={moreFilters}
                                  setFilters={(filters) => {
                                      setMoreFilters(false);
                                      setFilters(filters);
                                  }}
                                  data={filters} closeModal={() => setMoreFilters(false)}/>
        </React.Fragment>
    );
};

function mapStateToProps(state) {
    const {user} = state.user;
    return {user}

}

export default connect(mapStateToProps, null)(Properties);
