import { BehaviorSubject } from "rxjs";
import { websiteNotifications } from "./helpers/main-backend.helper";

const initialState = {
  status: "",
  data: [],
  newDataCount: 0,
  error: ""
};
const subject = new BehaviorSubject(initialState);

let state = initialState;

const notificationStore = {
  state: state,
  init: async () => {
    const notificationData = await websiteNotifications();
    if (notificationData && !notificationData.api_status) {
      notificationStore.addNotification(notificationData.data);
    }
    state = { ...state, newDataCount: unreadNotificationsCount(state.data) };
    subject.next(state);
  },
  subscribe: setState => subject.subscribe(setState),
  addNotification: notification => {
    let data = [];
    if (Array.isArray(notification)) {
      data = [...notification, ...state.data];
    } else {
      data = [notification, ...state.data];
    }
    state = {
      ...state,
      data,
      newDataCount: unreadNotificationsCount(data)
    };
    console.log(state, "to next");
    subject.next(state);
  },
  clearNewNotificationCount: () => {
    state = {
      ...state, newDataCount: 0
    };
    subject.next(state);
  },
  clearData: () => {
    state = { ...state, data: [] };
    subject.next(state);
  },
  initialState
};

function unreadNotificationsCount(notifications) {
  let newDataCount = 0;
  notifications.forEach(n => {
    newDataCount = !n.isRead ? newDataCount + 1 : newDataCount;
  });
  return newDataCount;

}

export default notificationStore;
