import { USER_ACCESS_TOKEN_LOGIN, USER_ACCESS_TOKEN_LOGIN_FAILED, USER_ACCESS_TOKEN_LOGIN_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  user: {},
  error: {}
}

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_ACCESS_TOKEN_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case USER_ACCESS_TOKEN_LOGIN:
      return {
        ...state
      }
    case USER_ACCESS_TOKEN_LOGIN_FAILED:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default user
