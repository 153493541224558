import {Col, FormGroup, Label, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import React from "react"
import ComponentWidthInput from "./ComponentWidthInput";

const TeamSettings = (props) => {
    function handleValidSubmit(event, values) {
        props.updateConfig({team_title: values.team_title, team_description: values.team_description, ...values})
    }

    return (
        <AvForm onValidSubmit={handleValidSubmit}>
            <Row className="py-2">
                <Col md="12">
                    <FormGroup>
                        <Label htmlFor="validationCustom01">Heading</Label>
                        <AvField
                            name="team_title"
                            value={props.config.team_title}
                            placeholder="Meet Our Team"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <Label htmlFor="validationCustom02">Sub Heading</Label>
                        <AvField
                            name="team_description"
                            value={props.config.team_description}
                            placeholder="Creative people are all here"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <ComponentWidthInput {...props.config}/>
                </Col>
            </Row>
            <div className="modal-footer">
                <button
                    onClick={() => props.close()}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                >Cancel</button>
                <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                >
                    Save changes
                </button>
            </div>
        </AvForm>
    )
};

export default TeamSettings
