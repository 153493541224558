import {Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation"
import React from "react"
import PropTypes from "prop-types"
import FormWrapper from "../util/form-wrapper"
import SettingsImagePreview from "../util/image-preview"
import InputMask from "react-input-mask";

const salesmanSettings = (props) => {
    return (
        <Card>
            <CardBody>
                <h4 className="card-title">Agent Info</h4>
                <p className="card-title-desc">Information regarding your public relations manager</p>
                <FormWrapper
                    fields={<Row>
                        {
                            props.webSettings.salesman_name && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="salesman-name">Salesman Name</Label>
                                    <AvField
                                        name="website_config.salesman_name"
                                        value={props.data.website_config.salesman_name}
                                        placeholder="John Doe"
                                        type="text"
                                        className="form-control"
                                        id="salesman-name"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.salesman_email && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="salesman-email">Primary Email</Label>
                                    <AvField
                                        name="website_config.salesman_email"
                                        value={props.data.website_config.salesman_email}
                                        placeholder="abc@example.com"
                                        type="email"
                                        className="form-control"
                                        id="salesman-email"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.salesman_contact && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="salesman-contact">Contact</Label>
                                    <AvField
                                        name="website_config.salesman_contact"
                                        value={props.data.website_config.salesman_contact}
                                        tag={[Input, InputMask]}
                                        placeholder="(999) 999-9999"
                                        type="tel"
                                        mask="(999) 999-9999"
                                        className="form-control"
                                        id="salesman-contact"

                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.salesman_fax && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="salesman-fax">Fax</Label>
                                    <AvField
                                        name="website_config.salesman_fax"
                                        value={props.data.website_config.salesman_fax}
                                        tag={[Input, InputMask]}
                                        placeholder="(999) 999-9999"
                                        type="tel"
                                        mask="(999) 999-9999"
                                        className="form-control"
                                        id="salesman-fax"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.salesman_designation && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="salesman-fax">Agent Designation</Label>
                                    <AvField
                                        name="website_config.salesman_designation"
                                        value={props.data.website_config.salesman_designation}
                                        type="text"
                                        placeholder="Sales Representative"
                                        className="form-control"
                                        id="salesman-fax"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.salesman_location && <Col md="6">
                                <FormGroup>
                                    <Label htmlFor="location">Agent Location</Label>
                                    <AvField
                                        name="website_config.salesman_location"
                                        value={props.data.website_config.salesman_location}
                                        type="textarea"
                                        rows={2}
                                        mask="Lorum ipsum dolar sit amet.."
                                        className="form-control"
                                        id="salesman-location"
                                    />
                                </FormGroup>
                            </Col>
                        }

                        {
                            props.webSettings.salesman_about && <Col md="12">
                                <FormGroup>
                                    <Label htmlFor="salesman-about">About</Label>
                                    <AvField
                                        name="website_config.salesman_about"
                                        value={props.data.website_config.salesman_about}
                                        type="textarea"
                                        rows={5}
                                        mask="Lorum ipsum dolar sit amet.."
                                        className="form-control"
                                        id="salesman-about"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            props.webSettings.salesman_image && <>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="salesman-image">Salesman Image</Label>
                                        <AvField
                                            name="salesman_image"
                                            placeholder="Lorum ipsum..."
                                            type="file"
                                            className="form-control"
                                            id="salesman-image"
                                            onChange={event =>
                                                props.handleFile(event, "salesman_image")
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <SettingsImagePreview image={props.data.website_config.salesman_image}/>
                                </Col>
                            </>
                        }
                    </Row>}
                    data={props.data}
                    handleSubmit={props.handleSubmit}
                />
            </CardBody>
        </Card>
    )
};

salesmanSettings.propTypes = {
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    handleFile: PropTypes.func
};

export default salesmanSettings
