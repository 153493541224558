module.exports = {
  page_limit: 10,
  google: {
    API_KEY: "",
    CLIENT_ID:"",
    SECRET: "",
  },
  facebook: {
    APP_ID: "783909299102809",
  },
}
