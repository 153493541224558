import {
  GET_WEBSITE_DETAIL_FAIL,
  GET_WEBSITE_DETAIL_SUCCESS,
  GET_WEBSITES_FAIL,
  GET_WEBSITES_LOADING,
  GET_WEBSITES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  websitesLoading: false,
  websites: [],
  limit: 10000,
  offset: 0,
  totalWebsites: 0,
  pageDetail: {},
  error: {}
}

const Websites = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WEBSITES_LOADING:
      return {
        ...state,
        websites: [],
        websitesLoading: true
      }
    case GET_WEBSITES_SUCCESS:
      return {
        ...state,
        websites: action.payload.data,
        totalWebsites: action.payload.total_count,
        websitesLoading: false
      }

    case GET_WEBSITES_FAIL:
      return {
        ...state,
        error: action.payload,
        websitesLoading: false
      }

    case GET_WEBSITE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
        websitesLoading: false
      }

    case GET_WEBSITE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        websitesLoading: false
      }

    default:
      return state
  }
}

export default Websites
