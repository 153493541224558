import { Modal, ModalBody } from "reactstrap";
import React from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { showError } from "../../../helpers/util.helper";

const UpdatePasswordModal = ({ isOpen, closeModal }) => {
  function updatePassword(e, values) {
    if (values.password1 === values.password2) {
      closeModal({password: values.password1})
    } else {
      showError({message: 'Both passwords should be same.'})
    }
  }

  return (<>
    <Modal isOpen={isOpen} toggle={() => {
      closeModal();
    }}>
      <ModalBody>
        <h5 className="modal-spacer mt-0 mb-3" id="myModalLabel">Update Password</h5>
        <AvForm onValidSubmit={updatePassword}>
          <AvField name="password1" type="text" placeholder="Password" validate={{
            required: {value: true, errorMessage: 'Please enter password'},
            minLength: {value: 6, errorMessage: 'Password must be between 6 and 16 characters'},
            maxLength: {value: 10, errorMessage: 'Password be between 6 and 16 characters'}
          }}
          />
          <AvField name="password2" type="text" placeholder="Retype Password" validate={{match:{value:'password1'}}}/>
          <button className="btn btn-danger btn-sm" type="button" onClick={closeModal}>Cancel</button>
          <button className="btn btn-primary btn-sm ml-2">Update</button>
        </AvForm>
      </ModalBody>
    </Modal>
  </>);
};

export default UpdatePasswordModal;
