import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import {del, get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {page_limit} from "../../config";
import pageColumns from "../../page.columns";
import {useHistory} from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import {showError} from "../../helpers/util.helper";
import toastr from "toastr";
import NoDataAvailable from "../../components/NoDataAvailable";
import {TableLoader} from "../../components/Loader";
import useFetch from "../../helpers/useFetch";

const ThemeImages = ({ match }) => {
    let history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingComponentsId, setDeletingComponentsId] = useState(null);
    const [themes, setThemes] = useState([]);
    const actions = [
        {
            name: "Delete",
            icon: "fas fa-trash",
            color: "danger",
            fn: openDeleteModal
        }];
    const limit = page_limit;
    const offset = 0;
    const [pagesLoading, setPageLoading] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState({
        columns: pageColumns.theme_images,
        rows: []
    });


    useEffect(() => {
        (async () => await getImages({limit, offset}))()
    }, []);

    async function pageChange(e) {
        setCurrentPage(e);
        await getImages({limit, offset: (limit * (e - 1))});
    }

    const getImages = async ({limit, offset}) => {
        setPageLoading(1);
        const componentsData = await get(url.THEME_IMAGES, {
            params: {
                limit, offset
            }
        });
        setPageLoading(0);
        if (componentsData && componentsData.data) {
            componentsData.data.forEach(components => {
                components.image_path = <img src={components.image_path} width={100} alt="preview image"/>
            });
            let _d = {...data, rows: componentsData.data};
            setData(_d);
            setTotalPages(componentsData.total_count);
        }
    };

    function addComponents() {
        history.push("/uploadImage");
    }

    async function deleteComponents() {
        const response = await del(url.THEME_IMAGES + "/" + deletingComponentsId);
        if (!response.api_status) {
            let _d = data.rows.filter(d => d.ti_id !== deletingComponentsId);
            setData({ ...data, rows: _d });
            setDeletingComponentsId(null);
            setDeleteModal(false);
            toastr.success("Image deleted successfully.", "Success");
        } else {
            showError(response);
        }
    }

    function openDeleteModal({ ti_id }) {
        setDeletingComponentsId(ti_id);
        setDeleteModal(true);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Components List"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {
                                        pagesLoading ?
                                            <TableLoader/>
                                            :
                                            <AETable columns={data.columns}
                                                     rows={data.rows}
                                                     itemsPerPage={limit}
                                                     loading={pagesLoading}
                                                     addLink={addComponents}
                                                     actions={actions}
                                                     currentPage={currentPage}
                                                     totalCount={totalPages}
                                                     pageFn={pageChange}/>
                                    }
                                </CardBody>
                                <DeleteModal
                                    onCloseClick={() => {
                                        setDeletingComponentsId(null);
                                        setDeleteModal(false);
                                    }}
                                    onDeleteClick={deleteComponents}
                                    show={deleteModal}>
                                </DeleteModal>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ThemeImages;
