import {Button, Col, FormGroup, Label, Modal, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";


const GalleryModal = (props) => {
    let [rows, setRows] = useState(props.config.rows);


    function handleValidSubmit(event, values) {
        let rowsGroup = [];
        values.caption.forEach((item, index) => {
            rowsGroup.push({
                caption: values.caption[index],
                image: rows[index].image
            });
        });
        props.onCloseGridModal({
            config: {
                rows: rowsGroup,
                gallery_title: values.gallery_title,
                gallery_description: values.gallery_description
            }, el: props.el
        });
    }

    function addNewRow() {
        let r = {title: "", description: ""};
        setRows([...rows, r]);
    }

    function deleteRow(id) {
        let r = rows.filter((d, i) => i !== id);
        setRows(r);
    }

    function handleFile(e, index) {
        const reader = new FileReader();
        reader.onload = function (e) {
            setRows(rows.map((row, i) => {
                if (i === index) {
                    row.image = e.target.result;
                }
                return row;
            }));
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    function deleteImage(index) {
        setRows(rows.map((row, i) => {
            if (i === index) {
                row.image = undefined;
            }
            return row;
        }));
    }

    function closeModal() {
        props.onCloseGridModal({config: props.config, el: props.el})
    }

    return (
        <Modal
            isOpen={props.openModal}
            toggle={closeModal}
        >
            <AvForm onValidSubmit={handleValidSubmit}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
                    <button
                        type="button"
                        onClick={closeModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">Gallery Title</Label>
                                <AvField
                                    name="gallery_title"
                                    value={props.config.gallery_title}
                                    placeholder="Lorum Ipsum"
                                    type="text"
                                    className="form-control"
                                    id="validationCustom03"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="validationCustom01">Gallery Description</Label>
                                <AvField
                                    name="gallery_description"
                                    value={props.config.gallery_description}
                                    placeholder="Lorum Ipsum"
                                    type="text"
                                    className="form-control"
                                    id="validationCustom03"
                                />
                            </FormGroup>
                        </Col>
                        {
                            rows.map((row, idx) => {
                                return (
                                    <>
                                        <Col md="12" className="mt-4">
                                            <p key={idx + "para"}
                                               className="d-flex align-items-center justify-content-between">
                                                <b>Image {idx + 1}</b> <span className="fas fa-minus-circle"
                                                                            onClick={() => {
                                                                                deleteRow(idx);
                                                                            }}
                                                                            style={{color: 'red', cursor: 'pointer'}}/>
                                            </p>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup key={idx + "title"}>
                                                <Label htmlFor="validationCustom01">Image Caption</Label>
                                                <AvField
                                                    name={"caption." + idx}
                                                    value={row.caption}
                                                    placeholder="Lorum Ipsum"
                                                    type="text"
                                                    rows="20"
                                                    className="form-control"
                                                    id="validationCustom03"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <Label htmlFor="validationCustom01"
                                                       className="d-flex align-items-center justify-content-between">
                                                    <span>Photo</span>
                                                    {
                                                        row.image && <span className="fas fa-minus-circle"
                                                                           onClick={() => {
                                                                               deleteImage(idx);
                                                                           }}
                                                                           style={{color: 'red', cursor: 'pointer'}}/>
                                                    }
                                                </Label>
                                                {
                                                    !row.image && <AvField
                                                        name="post"
                                                        type="file"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        onChange={event =>
                                                            handleFile(event, idx)
                                                        }
                                                    />
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            {
                                                <img src={row.image}
                                                     alt="" className="img img-thumbnail" style={{maxHeight: '200px'}}/>
                                            }
                                        </Col>
                                    </>);
                            })
                        }
                        <Col md="12">
                            <Button
                                onClick={() => {
                                    addNewRow();
                                }}
                                color="success"
                                className="mt-1">
                                Add Image
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        Save changes
                    </button>
                </div>
            </AvForm>
        </Modal>
    );
};

GalleryModal.propTypes = {
    el: PropTypes.object,
    config: PropTypes.object,
    openModal: PropTypes.bool,
    onCloseGridModal: PropTypes.func
};

export default GalleryModal;
