import {Col, FormGroup, Label, Modal, Row} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import PropTypes from "prop-types"
import ComponentWidthInput from "./ComponentWidthInput";


const HTMLRawSettings = (props) => {


  function handleValidSubmit(event, values) {
    props.updateConfig({ ...props.config, ...values })
  }

  return (

      <AvForm onValidSubmit={handleValidSubmit}>

        <Row className="py-2">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">HTML</Label>
              <AvField
                  name="html"
                  value={props.config.html || '<h1>Replace this code..</h1>'}
                  placeholder=""
                  type="textarea"
                  className="form-control"
                  rows={10}
                  id="validationCustom03"
              />
            </FormGroup>
          </Col>
            <Col md="12">
                <ComponentWidthInput {...props.config}/>
            </Col>
        </Row>
        <div className="modal-footer">
          <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
  )
};


export default HTMLRawSettings
