import Moment from "react-moment";
import { Col, Row } from "reactstrap";
import React from "react";
import { markQueryResolved } from "../leads.service";
import { formatMoney } from "../../../helpers/util.helper";

const SupportQueries = ({ queries, setModal, lead, getQueries }) => {
  async function resolveQuery(query) {
    const response = await markQueryResolved({ wu_id: lead.wu_id, wuq_id: query.wuq_id });
    getQueries();
  }

  return <>
    {
      queries && queries.length > 0 ?
        <div className="list-group w-100">
          {queries.map((query, key) => (
            <div className="list-group-item  flex-column align-items-start" key={key}>
              <div className="d-flex flex-row ">
                <div className="d-flex w-100">
                  <div className="flex-column w-100">
                    {
                      query.property && <div
                        className="w-100 mb-1 font-size-16 font-weight-bold camelCase align-content-between justify-content-between d-flex">
                        <a target="_blank"
                           href={query.query_property_url}>{query.property.Address}, {query.property.Community}, {query.property.Municipality}</a>
                        <small className="d-flex flex-column font-size-10">
                          <Moment format="MMM DD, YYYY hh:mm A">{query.created}</Moment>
                        </small>
                      </div>
                    }
                    <Row>
                      <Col md={12}>
                        {
                          query.property && <>
                            <p className="mb-1">
                              <span className="bx bx-bed"/> {query.property.Bedrooms} bd. | <span
                              className="bx bxs-bath"/> {query.property.Washrooms} ba.
                            </p>
                            <p>{formatMoney(query.property.ListPrice, true)}</p>
                          </>
                        }
                      </Col>
                    </Row>
                    <div
                      className="w-100 mb-1   camelCase align-content-between justify-content-between d-flex">
                      <p className="mb-2">
                        <b>Message</b>: {query.query_message}
                      </p>
                      {
                        !query.property && <small className="d-flex flex-column">
                          <Moment format="MMM DD, YYYY hh:mm A">{query.created}</Moment>
                        </small>
                      }
                    </div>
                    <Row>


                      <Col md={8}>
                        <div>
                          <button className="btn btn-sm btn-primary"
                                  onClick={() => setModal({ showModal: true, email: true })}><span
                            className="fa fa-envelope"/> Send Email
                          </button>
                          {
                            query.query_property &&
                            <a href={query.query_property_url} target="_blank"
                               className="btn btn-sm btn-primary ml-2"><span
                              className="fa fa-home"/> View Property
                            </a>
                          }
                          <a href={"tel:" + lead.phone} className="btn btn-sm btn-info ml-2"> <span
                            className="fa fa-phone"/> Call Now</a>
                          {
                            query.isResolved === 0 &&
                            <button onClick={() => resolveQuery(query)} className="btn btn-sm btn-success ml-2">
                              <span className="fa fa-check-circle"/> Mark As Resolve
                            </button>
                          }
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="text-right">
                          {
                            query.isResolved ? <span>
                              <span className="badge badge-success">Resolved</span>
                              <br/>
                              <small><Moment fromNow>{query.modified}</Moment></small>
                              </span>
                              : <span className="badge badge-danger">Not Resolved</span>
                          }
                        </div>
                      </Col>
                    </Row>

                  </div>
                </div>
              </div>
            </div>
          ))
          }
        </div>


        :
        <Col md={12} className="text-center mt-2">
          <p>No data available.</p>
        </Col>
    }
  </>;
};

export default SupportQueries;
