import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import * as classnames from "classnames";
import {connect} from "react-redux";
import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import LeadAlertsList from "./Alerts/lead-alerts-list";
import LeadDetailsTasksList from "./Tasks/lead-tasks-list";
import LeadDashboardHeader from "./lead-dashboard-header";
import * as moment from "moment-timezone";
import {
  addAlert,
  getAlerts,
  getCallLogs,
  getCallTitle,
  getLeadNotes,
  getPropertyHistory,
  getSupportQueries,
  saveTask,
  sendEmail,
  updateAlert,
  updateLead
} from "./leads.service";
import LeadProfile from "./lead-profile";
import LeadTaskAddModal from "./components/lead-task-add,modal";
import LeadAlertModal from "./Alerts/lead-alert.modal";
import LeadEmailsList from "./Emails/lead-emails-list";
import DeleteModal from "../../common/DeleteModal";
import LeadPropertySearch from "./Visited/lead-property-search";
import LeadEmailModal from "./components/email-modal";
import LeadNotes from "./Notes/lead-notes";
import LeadUpcomingTask from "./lead-upcoming-task";
import Valuation from "./Valuation";
import NeighbourhoodWatch from "./NeighbourhoodWatch";
import LeadCalls from "./Calls/CallLogsModal";
import CallLogsList from "./Calls/call-logs-list";
import SupportQueries from "./SupportQueries";
import { formatMoney } from "helpers/util.helper";
const frequency = {
  1: "Daily",
  7: "Weekly",
  14: "Biweekly",
  30: "Monthly"
};
const LeadsDashboardHorizontal = ({ match, user }) => {
  const wu_id = match.params.wu_id;
  let { search } = useLocation();
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState({ show: false });
  const [lead, setLead] = useState({});
  const [tasks, setTasks] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [neighbourhoodWatch, setNeighbourhoodWatch] = useState([]);
  const [properties, setProperties] = useState([]);
  const [modal, setModal] = useState({ showModal: false });
  const [notes, setNotes] = useState([]);
  const [logs, setLogs] = useState([]);
  const [queries, setQueries] = useState([]);
  useEffect(async () => {

  }, []);

  const [tabTitle, setTabTitle] = useState({
    "Home Valuation": 0,
    "Alerts": 0,
    "Neighbourhood Watch": 0,
    "Emails": 0,
    "Support Queries": 0,
    "Visited": 0
  });
  const [upcomingTask, setUpcomingTask] = useState();
  const [emails, setEmails] = useState([]);
  const [callsModal, setCallsModal] = useState({
    show: false, closeModal: () => {
      setCallsModal({ ...callsModal, show: false });
    }
  });
  useEffect(async () => {
    await getLead();
    await getEmails();
    await getTasks();
    await getProperties();
    await getAlerts$();
    await getNotes();
    await getLogs();
    await getQueries();
  }, []);

  useEffect(function() {
  }, [tabTitle]);

  async function getLogs() {
    let logs = await getCallLogs({ wu_id });
    if (logs) {
      logs.forEach(log => getCallTitle(log));
      setLogs(logs);
    }
  }

  async function getNotes() {
    let n = await getLeadNotes({ wu_id });
    if (n) {
      setNotes(n);
    }
  }

  async function getQueries() {
    let n = await getSupportQueries({ wu_id });
    if (n) {
      setTabTitle(tabTitle => {
        return { ...tabTitle, "Support Queries": n.length };
      });
      setQueries(n);
    } else {
      setQueries([]);
    }
  }

  async function getAlerts$() {
    let al = await getAlerts({ wu_id });
    let listingAlert = al.filter(d => d.alert_type === "LISTING_ALERT");
    let neighbourhood = al.filter(d => d.alert_type === "NEIGHBOURHOOD_WATCH");
    let evaluation = al.filter(d => d.alert_type === "HOME_EVALUATION");

    if(listingAlert.length) {
      listingAlert.forEach(alert => {
        if(alert.min_price) {
          alert.min_price$ = formatMoney(alert.min_price)
        }
        if(alert.max_price) {
          alert.max_price$ = formatMoney(alert.max_price)
        }
        if(alert.frequency) {
          alert.frequency$ = frequency[alert.frequency] || 'Daily';
        }
      })
    }
    setEvaluation(evaluation);
    setNeighbourhoodWatch(neighbourhood);
    setAlerts(listingAlert);
    setTabTitle(tabTitle => {
      return {
        ...tabTitle,
        "Alerts": listingAlert.length,
        "Neighbourhood Watch": neighbourhood.length,
        "Home Valuation": evaluation.length
      };
    });
  }

  async function getEmails() {
    const response = await get(url.LEAD + "/" + wu_id + "/mail");
    if (!response.api_status) {
      setEmails(response);
      setTabTitle(tabTitle => {
        return { ...tabTitle, "Emails": response.length };
      });
    } else {
      setEmails([]);
    }
  }


  async function getTasks() {
    const response = await get(url.LEAD + '/' + wu_id + '/tasks');
    if (!response.api_status) {
      setTasks(response);
      setTabTitle(tabTitle => {
        return { ...tabTitle, "Tasks": response.length };
      });
      let upcoming = response.filter(d => moment(d.task_due_date).isAfter(moment()))
        .sort((a, b) => moment(a.task_due_date).isBefore(b.task_due_date) ? -1 : 1);
      if (upcoming.length) {
        setUpcomingTask(upcoming[0]);
      } else {
        setUpcomingTask(null);
      }
    } else {
      setTabTitle(tabTitle => {
        return { ...tabTitle, "Tasks": 0 };
      });
      setTasks([]);
    }
  }


  async function getLead() {
    const response = await get(url.LEAD + "/" + wu_id);
    if (!response.api_status) {
      response.address = [response.city, response.postal_code, response.state, response.country].filter(d => d).join(',');
      setLead(response);
    } else {
      history.push('/leads');
    }
  }

  async function getProperties() {
    let data = await getPropertyHistory({ wu_id });
    if (data && data.length) {
      setProperties(data);
      setTabTitle(tabTitle => {
        return {
          ...tabTitle, Visited: data.length
        }
      })
    }
  }


  search = new URLSearchParams(search);
  let tabId = "1";
  if (search.get("tab") && search.get("tab").toString()) {
    tabId = search.get("tab") && search.get("tab").toString();
  }
  const [activeTab, setActiveTab] = useState(tabId);


  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }


  let tabSettings = [{
    id: "1",
    title: "Profile",
    Component: LeadProfile
  }, {
    id: "2",
    title: "Call Logs",
    Component: CallLogsList
  }, {
    id: "3",
    title: "Tasks",
    Component: LeadDetailsTasksList
  }, {
    id: "4",
    "title": "Alerts",
    Component: LeadAlertsList
  }, {
    id: "5",
    "title": "Emails",
    Component: LeadEmailsList
  }, {
    id: "6",
    "title": "Visited",
    Component: LeadPropertySearch
  }, {
    id: "7",
    "title": "Home Valuation",
    Component: Valuation,
    showOnlyData: true
  }, {
    id: "9",
    "title": "Neighbourhood Watch",
    Component: NeighbourhoodWatch,
    showOnlyData: true
  }, {
    id: "10",
    "title": "Support Queries",
    Component: SupportQueries,
    showOnlyData: true
  }];
  tabSettings = tabSettings.filter(tab => {
    if (tab && tab.industries && tab.industries.length && user && user.website && user.website.industry_id) {
      return tab.industries.includes(user.website.industry_id);
    }
    return true;
  });

  async function saveTask$(data) {
    await saveTask({ data, wu_id });
    await getTasks();
  }

  async function sendEmail$(data) {
    await sendEmail(data);
    await getEmails();
  }

  async function saveLead$(_lead) {
    await updateLead(_lead);
    setLead({ ...lead, ..._lead });
  }

  async function saveAlert$(alert) {
    if (alert.la_id) {
      await updateAlert({ alert, wu_id, la_id: alert.la_id });

    } else {
      await addAlert({ alert, wu_id });

    }
    getAlerts$();
    setModal({ showModal: false });
  }

  function showCallModal() {
    setCallsModal(modal => {
      return { ...modal, show: true };
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <LeadDashboardHeader lead={lead} updateLead={saveLead$} saveTask={saveTask$} sendEmail={sendEmail$}
                                   setDeleteModal={setDeleteModal}
                                   getTasks={getTasks}
                                   getLogs={getLogs}
                                   goBack={() => history.push("/leads/")}
                                   showCallModal={showCallModal} logs={tasks.filter(d => moment(d.task_due_date).isAfter(moment() && d.lcl_id))}
                                   modal={modal} setModal={setModal}/>
              {
                user && lead && Object.keys(lead).length ? <div>
                  <Row>
                    <Col md={9}>
                      <Nav tabs>
                        {
                          tabSettings.map((tabData) => {
                            return (
                              (!tabData.showOnlyData || (+tabTitle[tabData.title]>0)) && <NavItem key={tabData.id}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab === tabData.id
                                  })}
                                  onClick={() => {
                                    const params = new URLSearchParams();
                                    params.tab = tabData.id;
                                    history.push("/leads/" + wu_id + "/profile?tab=" + tabData.id);
                                    toggleTab(tabData.id);
                                  }}
                                >{tabData.title} {
                                  tabTitle[tabData.title] > 0 && <span className="badge badge-primary">{
                                    tabTitle[tabData.title]
                                  }</span>
                                }</NavLink>
                              </NavItem>
                            );
                          })
                        }
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        {
                          tabSettings.map(({ Component, id }) => {
                            return (
                              activeTab === id && <TabPane tabId={id} key={id}>
                                <Component lead={lead} tasks={tasks}
                                           wu_id={wu_id}
                                           setDeleteModal={setDeleteModal}
                                           getAlerts={getAlerts$}
                                           neighbourhoodWatch={neighbourhoodWatch}
                                           evaluation={evaluation}
                                           properties={properties}
                                           getQueries={getQueries}
                                           logs={logs}
                                           queries={queries}
                                           getLogs={getLogs}
                                           saveLead={saveLead$} modal={modal} setModal={setModal}
                                           getTasks={getTasks} saveAlert={saveAlert$} alerts={alerts} emails={emails}/>
                              </TabPane>
                            );
                          })
                        }
                      </TabContent>
                    </Col>
                    <Col md={3}>
                      <LeadUpcomingTask task={upcomingTask} setModal={setModal}/>
                      <LeadNotes wu_id={wu_id} notes={notes} getNotes={getNotes}/>
                    </Col>
                  </Row>
                </div> : ""
              }
            </Col>
          </Row>
        </Container>
      </div>
      {
        modal.showModal && modal.task && <LeadTaskAddModal
          {...modal}
          openModal={modal.showModal}
          saveTask={async (data) => {
            setModal({ showModal: false });
            await saveTask$(data);
          }
          }
          closeModal={() => {
            setModal({ showModal: false });
          }
          }
          lead={lead}
        />
      }
      {
        modal.showModal && modal.alert && <LeadAlertModal openModal={modal.showModal}
                                                          data={modal.data}
                                                          saveAlert={saveAlert$}
                                                          closeModal={() => setModal({ showModal: false })}/>
      }
      {

        deleteModal.show ? <DeleteModal
          onCloseClick={() => {
            setDeleteModal({ show: false });
          }}
          onDeleteClick={() => {
            deleteModal.fn(deleteModal.id);
            setDeleteModal({ show: false });
          }
          }
          show={deleteModal}>
        </DeleteModal> : ""
      }
      {
        modal.showModal && modal.email &&
        <LeadEmailModal
          openModal={modal.showModal}
          sendEmail={(data) => {
            setModal({ showModal: false });
            sendEmail$({ data, wu_id });
          }}
          closeModal={() => {
            setModal({ showModal: false });
          }}
          email={lead.email}
        />
      }

      {
        callsModal.show && <LeadCalls {...callsModal} lead={lead} wu_id={wu_id} getLogs={getLogs} getTasks={getTasks}/>
      }
    </React.Fragment>

  );
};
const mapStateToProps = (state) => {
  const { user } = state.user;
  return { user };
};
export default connect(mapStateToProps, null)(LeadsDashboardHorizontal);
