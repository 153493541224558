import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import { del, get } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { page_limit } from "../../config";
import pageColumns from "../../page.columns";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import { showError } from "../../helpers/util.helper";
import toastr from "toastr";
import NoDataAvailable from "../../components/NoDataAvailable";
import { TableLoader } from "../../components/Loader";

const Photos = ({ match }) => {
  let history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingPhotoId, setDeletingPhotoId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getPhoto({ limit, offset });
  }, []);

  const actions = [
   {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editPhoto
    }, {
      name: "Delete",
      icon: "fas fa-trash",
      color: "danger",
      fn: openDeleteModal
    }];
  const limit = page_limit;
  const offset = 0;
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState({
    columns: pageColumns.photo,
    rows: []
  });

  function pageChange(e) {
    setCurrentPage(e);
    getPhoto({ limit, offset: (limit * (e - 1)) });
  }

  const getPhoto = async ({ limit, offset }) => {
    setPageLoading(1);
    const photoData = await get(url.PHOTO_LIST, {
      params: {
        limit, offset
      }
    });
    setPageLoading(0);

    if(photoData.api_status) {
      return toastr.error('Something went wrong.');
    }
    setPageLoading(0);
    photoData.data.forEach(photo => {
      photo.photo = <img src={photo.photo_path} style={{width: '75px', height: '75px'}}/>;
    });
    let _d = { ...data, rows: photoData.data };
    setData(_d);
    setTotalPages(photoData.total_count);
  };

  function viewPhoto({ photo_id, photo_title }) {
    try {
      let website = JSON.parse(localStorage.getItem("website"));
      if (website && website.base_url) {
        window.open(website.base_url + "/photo/" + photo_title + "?photo_id=" + photo_id);
      }
    } catch (e) {
      showError({ message: "Something went wrong." });
    }
  }

  function editPhoto({ photo_id }) {
    history.push("/editPhoto/" + photo_id);
  }

  function addPhoto() {
    history.push("/addPhoto");
  }

  async function deletePhoto() {
    const response = await del(url.PHOTO_LIST + "/" + deletingPhotoId);
    if (!response.api_status) {
      let _d = data.rows.filter(d => d.photo_id !== deletingPhotoId);
      setData({ ...data, rows: _d });
      setDeletingPhotoId(null);
      setDeleteModal(false);
      toastr.success("Photo deleted successfully.", "Success");
    } else {
      showError(response);
    }
  }

  function openDeleteModal({ photo_id }) {
    setDeletingPhotoId(photo_id);
    setDeleteModal(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Photo List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                      data.rows.length
                        ? <AETable columns={data.columns}
                                   rows={data.rows}
                                   itemsPerPage={limit}
                                   loading={pagesLoading}
                                   addLink={addPhoto}
                                   actions={actions}
                                   currentPage={currentPage}
                                   totalCount={totalPages}
                                   pageFn={pageChange}/>
                        :
                        <NoDataAvailable module="photos"/>
                  }

                </CardBody>
                <DeleteModal
                  onCloseClick={() => {
                    setDeletingPhotoId(null);
                    setDeleteModal(false);
                  }}
                  onDeleteClick={deletePhoto}
                  show={deleteModal}>
                </DeleteModal>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Photos;
