import * as url from "../../helpers/url_helper";
import { del, get, post, put } from "../../helpers/api_helper";
import { capitalize, showError, showSuccess, unMaskNumber } from "../../helpers/util.helper";
import React from "react";

export const updateLead = async ({ ...lead }) => {
  try {
    lead.phone = lead.phone && unMaskNumber(lead.phone);
    lead.phone_2 = lead.phone_2 && unMaskNumber(lead.phone_2);
    lead.work_phone = lead.work_phone && unMaskNumber(lead.work_phone);
    const response = await put(url.LEAD + "/" + lead.wu_id, lead);
    if (!response.api_status) {
      return showSuccess({ message: "Lead updated successfully." });
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};
export const markAsVerified = async ({ ...lead }) => {
  try {
    const response = await post(url.LEAD + "/" + lead.wu_id + '/markAsVerified');
    if (!response.api_status) {
      // showSuccess({ message: "Lead verified successfully." });
      return true;
    }
    showError(response);
    return false;
  } catch (e) {
    showError({ message: "Something went wrong." });
    return false;
  }
};

export const addAlert = async ({ alert, wu_id }) => {
  try {
    const response = await post(url.LEAD + "/" + wu_id + "/alerts", alert);
    if (!response.api_status) {
      return showSuccess({ message: "Lead updated successfully." });
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};
export const updateAlert = async ({ alert, wu_id, la_id }) => {
  try {
    const response = await put(url.LEAD + "/" + wu_id + "/alerts/" + la_id, alert);
    if (!response.api_status) {
      return showSuccess({ message: "Lead updated successfully." });
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};
export const deleteAlert = async ({ la_id, wu_id }) => {
  try {
    const response = await del(url.LEAD + "/" + wu_id + "/alerts/" + la_id, alert);
    if (!response.api_status) {
      return showSuccess({ message: "Alert deleted successfully." });
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};
export const sendEmail = async function({ data, wu_id }) {
  const response = await post(url.LEAD + "/" + wu_id + "/mail", data);
  if (response.api_status) {
    return showError(response);
  }
  return showSuccess({ message: "Email sent successfully" });
};

export const saveTask = async function({ data, wu_id }) {
  let response, success;
  if (data.lead_task_id) {
    response = await put(url.LEAD + "/" + wu_id + "/tasks/" + data.lead_task_id, data);
    success = "Task updated successfully";
  } else {
    response = await post(url.LEAD + "/" + wu_id + "/tasks", data);
    success = "Task added successfully";
  }
  if (response.api_status) {
    return showError(response);
  }
  return showSuccess({ message: success });
};

export const getAlerts = async function({ wu_id }) {
  const response = await get(url.LEAD + "/" + wu_id + "/alerts");
  if (!response.api_status) {
    return response;
  }
  return [];
};

export const getPropertyHistory = async ({ wu_id }) => {
  try {
    const response = await get(url.LEAD + "/" + wu_id + "/propertyHistory");
    if (!response.api_status) {
      return response;
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};

export const addLeadNotes = async ({ note, wu_id }) => {
  try {
    const response = await post(url.LEAD + "/" + wu_id + "/notes", { note });
    if (!response.api_status) {
      showSuccess({ message: "Note added." });
      return response;
    }
    showError(response);
    return false;
  } catch (e) {
    showError({ message: "Something went wrong." });
    return false;
  }
};
export const deleteLeadNote = async ({ note_id, wu_id }) => {
  try {
    const response = await del(url.LEAD + "/" + wu_id + "/notes/" + note_id);
    if (!response.api_status) {
      showSuccess({ message: "Note deleted." });
      return true;
    }
    showError(response);
    return false;
  } catch (e) {
    showError({ message: "Something went wrong." });
    return false;
  }
};


export const getLeadNotes = async ({ wu_id }) => {
  try {
    const response = await get(url.LEAD + "/" + wu_id + "/notes");
    if (!response.api_status) {
      return response;
    }
    showError(response);
    return false;
  } catch (e) {
    showError({ message: "Something went wrong." });
    return false;
  }
};

export const getSupportQueries = async ({ wu_id }) => {
  try {
    const response = await get(url.LEAD + "/" + wu_id + "/supportQueries");
    if (!response.api_status) {
      return response;
    }
    showError(response);
    return false;
  } catch (e) {
    showError({ message: "Something went wrong." });
    return false;
  }
};
export const markQueryResolved = async ({ wu_id, wuq_id }) => {
  try {
    const response = await put(url.LEAD + "/" + wu_id + "/supportQueries", { wuq_id, isResolved: 1 });
    if (!response.api_status) {
      showSuccess({message: 'Query resolved successfully.'});
      return true;
    }
    showError(response);
    return false;
  } catch (e) {
    showError({ message: "Something went wrong." });
    return false;
  }
};

export const addCSV = async (leads) => {
  try {
    const response = await post(url.LEAD + "/csv", { leads: leads });
    if (!response.api_status) {
      return showSuccess({ message: "CSV saved successfully." });
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};


export const sendBulkEmail = async (data) => {
  try {
    const response = await post(url.LEAD + "/sendBulkEmail", data);
    if (!response.api_status) {
      return showSuccess({ message: "Emails sent successfully." });
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};

export const bulkArchive = async (data) => {
  try {
    const response = await post(url.LEAD + "/bulkArchive", data);
    if (!response.api_status) {
      return showSuccess({ message: "Leads archived successfully." });
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};


export const searchLocation = async (query) => {
  try {
    const response = await get(process.env.REACT_APP_WEB_URL + '/api/util/locationSearch?clubbed=true&search=' + (query || ''));
    if (!response.api_status) {
      return response;
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};


export const alertProperties = async (alert) => {
  try {
    const response = await get(url.LEAD + "/" + alert.wu_id + "/alerts/properties?la_id=" + alert.la_id);
    if (!response.api_status) {
      return response;
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};

export const sendPropertyAlertEmail = async ({properties, wu_id}) => {
  try {
    const response = await post(url.LEAD + "/" + wu_id + "/alerts/propertyAlertEmail", {properties});
    if (!response.api_status) {
      return showSuccess({ message: "Email sent successfully." });
      return true;
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};


export const addCallLogs = async ({ data, wu_id }) => {
  try {
    const response = await post(url.LEAD + "/" + wu_id + "/callLogs", data);
    if (!response.api_status) {
      return showSuccess({ message: "Call log added successfully." });
      return true;
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};

export const getCallLogs = async ({ wu_id }) => {
  try {
    const response = await get(url.LEAD + "/" + wu_id + "/callLogs");
    if (!response.api_status) {
      return response;
    }
    return showError(response);
  } catch (e) {
    return showError({ message: "Something went wrong." });
  }
};


export const  getCallTitle = (log) =>  {
  switch (log.call_response) {
    case "bad":
      log.call_response = <span className="badge badge-danger">{capitalize(log.call_response)}</span>;
      break;
    case "not home":
      log.call_response = <span className="badge badge-info">{capitalize(log.call_response)}</span>;
      break;
    case "connected":
      log.call_response = <span className="badge badge-success">{capitalize(log.call_response)}</span>;
      break;
    default:
      break;
  }
  let msg = "";
  switch (log.call_type) {
    case "inbound":
      log.call_type = "Inbound Call";
      break;
    case "outbound":
      log.call_type = "Outbound Call";
      break;
    default:
      break;
  }
}
