import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useEffect, useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {get, post, put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import {showError} from "../../helpers/util.helper";
import {useHistory} from "react-router-dom";
import * as moment from 'moment';

export const EditCoupon = ({match}) => {

    const [data, setData] = useState({});
    const [convertedContent, setConvertedContent] = useState("");
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );
    let history = useHistory();
    useEffect(async function () {
        if (match && match.params && match.params.coupon_id) {
            getCoupon({coupon_id: match.params.coupon_id});
        }
    }, []);

    async function handleValidSubmit(e, values) {
        onEditorStateChange();
        let coupon = {
            ...data,
            ...values,
            coupon_description: convertedContent || data.coupon_description,
        };
        let response;
        if (coupon.coupon_id) {
            response = await put(url.COUPONS + "/" + data.coupon_id, coupon);
        } else {
            response = await post(url.COUPONS, coupon);
        }
        if (!response.api_status) {
            toastr.success(`Coupon ${coupon.coupon_id ? 'updated' : 'added'} successfully.`, "Success");
            back();
        } else {
            showError(response);
        }
    }


    function back() {
        history.push("/coupons");

    }

    function onEditorStateChange(e) {
        if (e) {
            setEditorState(e);
        }
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const value = draftToHtml(
            rawContentState
        );
        setConvertedContent(value);
    };

    function handleFile(e, key) {
        const reader = new FileReader();
        reader.onload = function (e) {
            setData({...data, [key]: e.target.result});
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    async function getCoupon({coupon_id}) {
        const response = await get(url.COUPONS + "/" + coupon_id);
        if (!response.api_status) {
            const blocksFromHtml = htmlToDraft(response.coupon_description);
            const {contentBlocks, entityMap} = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const state = EditorState.createWithContent(contentState);
            setEditorState(state);
            response.coupon_start_date = response.coupon_start_date ? moment(response.coupon_start_date).format('YYYY-MM-DD') : null;
            response.coupon_end_date = response.coupon_end_date ? moment(response.coupon_end_date).format('YYYY-MM-DD') : null;
            setData(response);
        } else {
            showError(response);
        }
    }


    return (
        <React.Fragment>
            <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>

                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Add Coupon"
                                     breadcrumbItem={"Coupon " + (data.coupon_id ? 'update' : 'add')}/>
                    </div>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <h4 className="card-title">Coupon</h4>
                                        </Col>
                                        <Col md="12">
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom04">Coupon Title</Label>
                                                        <AvField
                                                            name="coupon_title"
                                                            value={data.coupon_title}
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom04"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <FormGroup>
                                                        <Label htmlFor="coupon_code">Coupon Code</Label>
                                                        <AvField
                                                            name="coupon_code"
                                                            value={data.coupon_code}
                                                            type="text"
                                                            className="form-control"
                                                            id="coupon_code"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md={3} sm={6}>
                                            <FormGroup>
                                                <Label htmlFor="coupon_start_date">Start Date</Label>
                                                <AvField
                                                    name="coupon_start_date"
                                                    value={data.coupon_start_date}
                                                    type="date"
                                                    className="form-control"
                                                    id="coupon_start_date"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={6}>
                                            <FormGroup>
                                                <Label htmlFor="coupon_end_date">End Date </Label>
                                                <AvField
                                                    name="coupon_end_date"
                                                    value={data.coupon_end_date}
                                                    type="date"
                                                    className="form-control"
                                                    id="coupon_end_date"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={6}>
                                            <FormGroup>
                                                <Label htmlFor="coupon_discount_amount">Coupon Discount(In CAD)</Label>
                                                <AvField
                                                    name="coupon_discount_amount"
                                                    value={data.coupon_discount_amount}
                                                    type="number"
                                                    className="form-control"
                                                    id="coupon_discount_amount"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={6}>
                                            <FormGroup>
                                                <Label htmlFor="max_uses">Max Usage</Label>
                                                <AvField
                                                    name="max_uses"
                                                    value={data.max_uses}
                                                    type="number"
                                                    className="form-control"
                                                    id="max_uses"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={6} className={"mt-1"}>
                                            <FormGroup>
                                                <Label htmlFor="coupon_end_date">Valid on </Label>
                                                <div className="d-flex" style={{gap: '1rem'}}>
                                                    <AvField
                                                        value={data.isValidOn1m}
                                                        name="isValidOn1m"
                                                        className={'p-0'}
                                                        type="checkbox"
                                                        label={"1 Month"}
                                                        id={'isValidOn1m'}
                                                        checked={data.isValidOn1m}
                                                        trueValue={1}
                                                    />
                                                    <AvField
                                                        value={data.isValidOn6m}
                                                        name="isValidOn6m"
                                                        className={'p-0'}
                                                        type="checkbox"
                                                        label={"6 Months"}
                                                        id={'isValidOn6m'}
                                                        checked={data.isValidOn6m}
                                                        trueValue={1}
                                                    />
                                                    <AvField
                                                        value={data.isValidOn12m}
                                                        name="isValidOn12m"
                                                        className={'p-0'}
                                                        type="checkbox"
                                                        defaultValue={data.isValidOn12m}
                                                        label={"12 Months"}
                                                        id={'isValidOn12m'}
                                                        checked={data.isValidOn12m}
                                                        trueValue={1}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <Label htmlFor="validationCustom01">Coupon Details</Label>

                                            <Editor
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                editorState={editorState}
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12 text-center">
                            <Card>
                                <CardBody>
                                    <Button color="secondary" className="py-2 mr-2" type="button" onClick={back}>
                                        Back
                                    </Button>
                                    <Button color="primary" className="py-2" type="submit">
                                        {
                                            data.coupon_id ?
                                                "Update"
                                                : "Save"
                                        }
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </AvForm>

        </React.Fragment>
    );
};
