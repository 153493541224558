import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";

const AddMemberCard = props => {
  let style= {
    addButton: {
      color: '#0f29e3d4',
      opacity: '0.7',
      transform: 'rotate(0)',
      transition: 'all 1s'
    },
    transformButton : {
      color: '#0f29e3d4',
      opacity: '1',
      transform: 'scale(1.1)',
      transition: 'all 1s'
    },
  };
  const [currentStyle, setCurrentStyle] = useState(style.addButton);
  function onMouseOver() {
    setCurrentStyle(style.transformButton)
  }
  function onMouseOut() {
    setCurrentStyle(style.addButton)
  }
  return (
    <React.Fragment>
      <Col xl="3" sm="6" className="pin">
        <Link to="/addTeam">
          <Card className="text-center">
            <CardBody>
              <h1
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseOut}
                style={currentStyle}
                className="display-2">
                <span className="bx bx-user-plus" color="primary"></span>
              </h1>
            </CardBody>
          </Card>
        </Link>
      </Col>
    </React.Fragment>
  );
};


export default AddMemberCard;
