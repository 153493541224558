import React from "react";
import { Link } from "react-router-dom";
import { buttons } from "./no-data-buttons";
import PropTypes from "prop-types";

const NoDataAvailable = (props) => {
  return (
    <div className="text-center">
      <p className="p-0 m-0">No {props.module} found.</p>
      <br/>
      {
        props.module && buttons[props.module] && buttons[props.module].buttons.length ?
          buttons[props.module].buttons.map(btn => (
            props.addLink ?
              <button onClick={props.addLink} className="btn btn-primary">{btn.title}</button>
              :
              <Link to={btn.link}>
                <button className="btn btn-primary">{btn.title}</button>
              </Link>
          ))
          : ""
      }
    </div>
  )
}
NoDataAvailable.propTypes = {
  module: PropTypes.string
}
export default NoDataAvailable
