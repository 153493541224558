import {Col, FormGroup, Label, Modal} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import React from "react"


export const PlanExtendModal = ({isCharge = false, onClose, onSave, plan_end_date}) => {
    function handleValidSubmit(event, values) {
        onSave({isCharge, ...values});
    }

    return (
        <Modal
            isOpen={true}
            toggle={onClose}
        >
            <AvForm onValidSubmit={handleValidSubmit}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
                    <button
                        type="button"
                        onClick={onClose}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Col md="12">
                        <FormGroup>
                            <Label htmlFor="datetime">Plan End Date</Label>
                            <AvField
                                name="plan_end_date"
                                value={plan_end_date}
                                type="date"
                                className="form-control"
                                id="datetime"
                            />
                        </FormGroup>
                    </Col>
                    {
                        isCharge && <>
                            <Col md="12">
                                <FormGroup>
                                    <Label htmlFor="chargeAmount">Charge Amount</Label>
                                    <AvField
                                        name="amount"
                                        type="number"
                                        className="form-control"
                                        id="chargeAmount"
                                    />
                                </FormGroup>
                                {/*<p><small>Billing address will be picked from last payment</small></p>*/}
                            </Col>
                        </>
                    }
                </div>
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        Extend
                    </button>
                </div>
            </AvForm>
        </Modal>
    )
}
