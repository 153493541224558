import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row, Input } from "reactstrap";
import InputMask from 'react-input-mask';
import { AvField, AvForm } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import SpanCheck from "../../components/Common/SpanCheck";

const LeadProfile = ({ lead, saveLead }) => {
  const [editMode, setEditMode] = useState(false);
  return <>
    <Card>
      <CardBody>
        <Row className="mb-2">
          <Col md={12} className="text-right">

            {
              !editMode ?
                <Button type="submit" color="primary" className="btn-sm ml-2" onClick={() => setEditMode(true)}>
                  Edit
                </Button>
                :
                <>

                  <Button type="submit" color="primary" className="btn-sm ml-2" onClick={() => {
                    document.getElementById('saveLead').click();
                  }}>
                    Save
                  </Button>
                  <Button type="submit" color="dark" className=" btn-sm ml-2" onClick={() => setEditMode(false)}>
                    Cancel
                  </Button>
                </>

            }
          </Col>
        </Row>

        {
          !editMode ? <Row>
              <Col md={4}>
                <h5 className="text-muted">Personal Details</h5>
                <table className="table table-borderless">
                  <tbody>
                  <tr>
                    <td className="px-0"><b>Name</b></td>
                    <td className="px-0">{lead.name || "-"}</td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>Email</b></td>
                    <td className="px-0">{lead.email || "-"}</td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>Phone</b></td>
                    <td className="px-0">{lead.phone || '-'}</td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>Phone 2</b></td>
                    <td className="px-0">{lead.phone_2 || "-"}</td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>Work Phone</b></td>
                    <td className="px-0">{lead.work_phone || "-"}</td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>Home Phone</b></td>
                    <td className="px-0">{lead.home_phone || "-"}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col md={4}>
                <h5 className="text-muted">Primary Address</h5>
                <table className="table table-borderless">
                  <tbody>
                  <tr>
                    <td className="px-0"><b>Address</b></td>
                    <td className="px-0">
                      {lead.address || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>City</b></td>
                    <td className="px-0">{lead.city || "-"}</td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>Province</b></td>
                    <td className="px-0">{lead.state || "-"}</td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>Postal Code</b></td>
                    <td className="px-0">{lead.postal_code || "-"}</td>
                  </tr>
                  <tr>
                    <td className="px-0"><b>Country</b></td>
                    <td className="px-0">{lead.country || "-"}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col md={4}>
                <h5 className="text-muted">Communication Mode</h5>
                <div>
                  <table className="table table-borderless">
                    <tbody>
                    <tr>
                      <td className="px-0" width="40%">Can Text:</td>
                      <td className="px-0">{lead.canText ? <SpanCheck/> : <SpanCheck type="danger"/>}</td>
                    </tr>
                    <tr>
                      <td className="px-0">Can Email:</td>
                      <td className="px-0">{lead.canEmail ? <SpanCheck/> : <SpanCheck type="danger"/>}</td>
                    </tr>
                    <tr>
                      <td className="px-0">Can Call:</td>
                      <td className="px-0">{lead.canCall ? <SpanCheck/> : <SpanCheck type="danger"/>}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            :
            <AvForm onValidSubmit={(e, values) => {
              setEditMode(false);
              saveLead({ ...lead, ...values });
            }}>

              <Row>
                <Col md={4}>
                  <h5>Personal Details</h5>
                  <AvField
                    label="Name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={lead.name}
                  />
                  <AvField
                    label={"Email"}
                    name="email"
                    type="email"
                    placeholder="john@example.com"
                    value={lead.email}
                  />
                  <AvField
                    label={"Phone"}
                    name="phone"
                    type="tel"
                    mask="(999) 999-9999"
                    tag={[Input, InputMask]}
                    placeholder=""
                    value={lead.phone}
                  />
                  <AvField
                    label={"Phone 2"}
                    name="phone_2"
                    type="tel"
                    mask="(999) 999-9999"
                    tag={[Input, InputMask]}
                    placeholder=""
                    value={lead.phone_2}
                  />
                  <AvField
                    label={"Work Phone"}
                    name="work_phone"
                    type="tel"
                    mask="(999) 999-9999"
                    tag={[Input, InputMask]}
                    placeholder=""
                    value={lead.work_phone}
                  />

                  <AvField
                    label={"Home Phone"}
                    name="home_phone"
                    type="number"
                    placeholder=""
                    value={lead.home_phone}
                  />
                </Col>
                <Col md={4}>
                  <h5>Primary Address</h5>

                  <AvField
                    label={"Address"}
                    name="address"
                    type="textarea"
                    placeholder=""
                    value={lead.address}
                  />
                  <AvField
                    label={"City"}
                    name="city"
                    type="text"
                    placeholder="Brampton"
                    value={lead.city}
                  />
                  <AvField
                    label={"Province"}
                    name="state"
                    type="text"
                    placeholder="Ontario"
                    value={lead.state}
                  />
                  <AvField
                    label={"Postal Code"}
                    name="postal_code"
                    type="text"
                    placeholder="12343"
                    value={lead.postal_code}
                  />
                  <AvField
                    label={"Country"}
                    name="country"
                    type="text"
                    placeholder="Canada"
                    value={lead.country}
                  />
                </Col>
                <Col md={4}>
                  <h5>Communication Mode</h5>

                  <AvField
                    label={"Can Text"}
                    name="canText"
                    type="checkbox"
                    placeholder=""
                    value={!!lead.canText}
                  />

                  <AvField
                    label={"Can Email"}
                    name="canEmail"
                    type="checkbox"
                    placeholder=""
                    value={!!lead.canEmail}
                  />
                  <AvField
                    label={"Can Call"}
                    name="canCall"
                    type="checkbox"
                    placeholder=""
                    value={!!lead.canCall}
                  />
                </Col>
              </Row>
              <button type="submit" id="saveLead" className="d-none"/>
            </AvForm>
        }

      </CardBody>
    </Card>
  </>;
};
LeadProfile.propTypes = {
  saveLead: PropTypes.func
};

export default LeadProfile;
