import {Button, Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useEffect, useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {get, post, put} from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import toastr from "toastr";
import {showError} from "../../../helpers/util.helper";
import {useHistory} from "react-router-dom";
import SettingsImagePreview from "../../Settings/util/image-preview";
import InputMask from "react-input-mask";

const AddTeam = ({match}) => {

  const [data, setData] = useState({});
  const [convertedContent, setConvertedContent] = useState("");
  const [isAgent, setIsAgent] = useState(false);
  const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty()
  );
  let history = useHistory();
  useEffect(function () {
    if (match && match.params && match.params.tm_id) {
      getTeam(match.params.tm_id);
    }
  }, []);

  async function handleValidSubmit(e, values) {
    onEditorStateChange();
    let team = {
      ...data,
      ...values,
      isSalesman: isAgent,
      member_description: convertedContent || data.member_description
    };
    let response;
    if (team.tm_id) {
      response = await put(url.TEAMS + "/" + data.tm_id, team);
    } else {
      response = await post(url.TEAMS, team);
    }
    if (!response.api_status) {
      toastr.success("Team added successfully.", "Success");
      back();
    } else {
      showError(response);
    }
  }

  function back() {
    history.push("/teams");

  }

  function onEditorStateChange(e) {
    if (e) {
      setEditorState(e);
    }
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const value = draftToHtml(
        rawContentState
    );
    setConvertedContent(value);
  };

  function handleFile(e, key) {
    const reader = new FileReader();
    reader.onload = function (e) {
      setData({...data, [key]: e.target.result});
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  async function getTeam(tm_id) {
    const response = await get(url.TEAMS + "/" + tm_id);
    if (!response.api_status) {
      const blocksFromHtml = htmlToDraft(response.member_description);
      const {contentBlocks, entityMap} = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const state = EditorState.createWithContent(contentState);
      setEditorState(state);
      setData(response);
      if (response.isSalesman) {
        setIsAgent(true);
      }
    } else {
      showError(response);
    }
  }

  return (
      <React.Fragment>
        <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>

          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Add Team" breadcrumbItem="Team Add"/>
            </div>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Team info</h4>
                    <p className="card-title-desc">Add information regarding your new team post</p>
                    <Row>
                      <Col md="8">
                       <Row>
                         <Col md="12">
                           <FormGroup>
                             <Label htmlFor="validationCustom01">Member Name</Label>
                             <AvField
                                 name="member_name"
                                 value={data.member_name}
                                 placeholder="John Doe"
                                 type="text"
                                 className="form-control"
                                 id="validationCustom03"
                             />
                           </FormGroup>
                         </Col>
                         <Col md="12">
                           <FormGroup>
                             <Label htmlFor="validationCustom01">Member Role</Label>
                             <AvField
                                 name="member_designation"
                                 value={data.member_designation}
                                 placeholder="John Doe"
                                 type="text"
                                 className="form-control"
                                 id="validationCustom03"
                             />
                           </FormGroup>
                         </Col>
                         <Col md="12">
                           <FormGroup>
                             <Label htmlFor="validationCustom01">Email</Label>
                             <AvField
                                 name="member_email"
                                 value={data.member_email}
                                 placeholder="john@example.com"
                                 type="text"
                                 className="form-control"
                                 id="validationCustom03"
                             />
                           </FormGroup>
                         </Col>
                         <Col md="12">
                           <FormGroup>
                             <Label htmlFor="validationCustom01">Contact No.</Label>
                             <AvField
                                 name="member_contact"
                                 value={data.member_contact}
                                 placeholder="(999) 999-9999"
                                 type="tel"
                                 mask="(999) 999-9999"
                                 tag={[Input, InputMask]}
                                 className="form-control"
                                 id="validationCustom03"
                             />
                           </FormGroup>
                         </Col>
                         <Col md="12">
                           <Label htmlFor="validationCustom01">Member Info</Label>
                           <Editor
                               toolbarClassName="toolbarClassName"
                               wrapperClassName="wrapperClassName"
                               editorClassName="editorClassName"
                               editorState={editorState}
                               onEditorStateChange={onEditorStateChange}
                           />
                         </Col>
                         <Col md="12">
                           <FormGroup>
                             <Label htmlFor="validationCustom01">Facebook</Label>
                             <AvField
                                 name="facebook"
                                 value={data.facebook}
                                 placeholder="https://facebook.com/username"
                                 type="text"
                                 className="form-control"
                                 id="validationCustom04"
                             />
                           </FormGroup>
                         </Col>
                         <Col md="12">
                           <FormGroup>
                             <Label htmlFor="validationCustom01">Twitter</Label>
                             <AvField
                                 name="twitter"
                                 value={data.twitter}
                                 placeholder="https://twitter.com/username"
                                 type="text"
                                 className="form-control"
                                 id="validationCustom05"
                             />
                           </FormGroup>
                         </Col>
                         <Col md="12">
                           <FormGroup>
                             <Label htmlFor="validationCustom01">Linkedin</Label>
                             <AvField
                                 name="linkedin"
                                 value={data.linkedin}
                                 placeholder="https://linkedin.com/username"
                                 type="text"
                                 className="form-control"
                                 id="validationCustom06"
                             />
                           </FormGroup>
                         </Col>
                       </Row>
                      </Col>
                      <Col md="4">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="validationCustom01">Member Photo</Label>
                              <AvField
                                  name="post"
                                  type="file"
                                  className="form-control"
                                  id="validationCustom03"
                                  onChange={event =>
                                      handleFile(event, "memberPhoto")
                                  }
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <SettingsImagePreview image={data.memberPhoto || data.member_photo} width="250px"
                                                  height="250px"/>
                          </Col>

                          <Col md="12 mt-2">

                            <div
                                className="custom-control custom-switch custom-switch-md d-inline-block"
                                dir="ltr"
                            >

                              <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="externalPage1"
                                  checked={isAgent}
                                  onChange={(e, v) => {
                                    if (e.target.checked) {
                                      setIsAgent(true);
                                    } else {
                                      setIsAgent(false);
                                    }
                                  }}
                              />
                              <label
                                  className="custom-control-label"
                                  htmlFor="externalPage1"
                              >Is Agent</label>

                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12 text-center">
                <Card>
                  <CardBody>
                    <Button color="secondary" className="py-2 mr-2" type="submit" onClick={back}>
                      Back
                    </Button>
                    <Button color="primary" className="py-2" type="submit">
                      {
                        data.tm_id ?
                            "Update"
                            : "Save"
                      }
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </AvForm>
      </React.Fragment>
  );
};

export default AddTeam;
