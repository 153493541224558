import {Col, FormGroup, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import React, {useState} from "react";
import SettingsImagePreview from "../../Settings/util/image-preview";

const ComponentWidthInput = ({width, bgColor, showBg, bgImage, bgSize, bgPosition, bgRepeat, component_css, id, editCss}) => {
    const [image, setImage] = useState(bgImage);
    let [showBg$, setShowBg] = useState(showBg);
    let [showCss, setShowCss] = useState(editCss);
    let [width$, setWidth] = useState(width);
    function changeWidthType(wi) {
        setWidth(wi);
    }
    function handleFile(e) {
        const reader = new FileReader();
        reader.onload = function (e) {
            setImage(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    function deleteImage() {
        setImage(null);
    }

    return <>
        <div className="row">
            <div className="col-12">
                <FormGroup>
                    <Label htmlFor="validationCustom04">Width</Label>
                    <AvField
                        name="width"
                        value={width || 'container'}
                        onChange={(e) => changeWidthType(e.target.value)}
                        type="select"
                        className="form-control"
                        id="validationCustom04"
                    >
                        <option value=""/>
                        <option value="container">Container Width</option>
                        <option value="container-fluid">Full Width</option>
                    </AvField>
                </FormGroup>
            </div>
            <div className="col-12">
                <FormGroup className="d-flex align-items-start " style={{gap: '5px'}}>
                    <AvField type="checkbox" id={"showBgCheck"}
                             name={"showBg"}
                             value={showBg$}
                             defaultChecked={showBg}
                             onChange={(e) => setShowBg(e.target.checked)}/>
                    <Label htmlFor="showBgCheck" className="m-0 p-0">Show Background </Label>
                </FormGroup>
            </div>
            {
                showBg$ && <>
                    <div className="col-12">
                        <FormGroup>
                            <Label htmlFor="color">Color</Label>
                            <AvField
                                name="bgColor"
                                value={bgColor || "#ffffff"}
                                type="color"
                                className="form-control"
                                id="color"
                            />
                        </FormGroup>
                    </div>
                    <Col md="12">
                        <FormGroup>
                            <Label htmlFor="validationCustom01"
                                   className="d-flex flex-column  justify-content-between">
                                <span>Background Image</span>
                                {
                                    image &&
                                    <SettingsImagePreview removeImage={deleteImage} image={image} height={250}/>
                                }
                            </Label>
                            <AvField
                                name="post"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={event =>
                                    handleFile(event, "bg")
                                }
                            />
                            <AvField
                                name="bgImage"
                                type="text"
                                className="form-control"
                                id="validationCustom03"
                                value={image}
                                style={{display:'none'}}
                            />
                        </FormGroup>
                    </Col>
                    {
                        image && <>
                            <Col md={12}>
                                <AvField
                                    name="bgPosition"
                                    label={"Background Position"}
                                    type="select"
                                    value={bgPosition}
                                    className="form-control"
                                >
                                    <option value=""/>
                                    <option value="center">Center</option>
                                    <option value="top">Top</option>
                                    <option value="bottom">Bottom</option>
                                </AvField>
                            </Col>
                            <Col md={12}>
                                <AvField
                                    name="bgSize"
                                    label={"Background Size"}
                                    type="select"
                                    value={bgSize}
                                    className="form-control"
                                >
                                    <option value=""/>
                                    <option value="cover">Cover</option>
                                    <option value="contain">Contain</option>
                                </AvField>
                            </Col>
                            <Col md={12}>
                                <AvField
                                    name="bgRepeat"
                                    label={"Background Repeat"}
                                    type="select"
                                    value={bgRepeat}
                                    className="form-control"
                                >
                                    <option value=""/>
                                    <option value="no-repeat">No Repeat</option>
                                    <option value="repeat">Repeat</option>
                                </AvField>
                            </Col>

                        </>
                    }
                </>
            }
            <div className="col-12">
                <FormGroup className="d-flex align-items-start " style={{gap: '5px'}}>
                    <AvField type="checkbox" id={"editCss"}
                             name={"editCss"}
                             value={showCss}
                             label={"Edit CSS"}
                             defaultChecked={showBg}
                             onChange={(e) => setShowCss(e.target.checked)}/>
                </FormGroup>
            </div>
            {
                showCss &&   <Col md="12">
                    <FormGroup>
                        <Label htmlFor="cssValidation">Component CSS (#{id})</Label>
                        <AvField
                            name="component_css"
                            value={component_css}
                            type="textarea"
                            rows="5"
                            className="form-control"
                            id="cssValidation"
                            style={{display: showCss? 'block': 'none'}}
                        />
                    </FormGroup>
                </Col>
            }
        </div>
    </>
};
export default ComponentWidthInput;
