import {Col, FormGroup, Label, Modal} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const optionGroup = [
  {
    label: "Muncipality",
    options: [
      { label: "Brampton", value: "Brampton" },
      { label: "Toronto", value: "Toronto" },
      { label: "Mississauga", value: "Mississauga" },
      { label: "Vaughan", value: "Vaughan" },
      { label: "Caledon", value: "Caledon" },
      { label: "RichmondHill", value: "RichmondHill" },
      { label: "Oakville", value: "Oakville" }
    ]
  }
];


const PropertyModal = (props) => {
  const [showSlider, setShowSlider] = useState(props.config.showSlider);

  const [selectedMulti, setSelectedMulti] = useState(null);
  useState(function () {
    if (props.config && props.config.properties) {
      setSelectedMulti(props.config.properties);
    }
  });

  function handleMulti(s) {
    console.log(s, "selected multi");
    setSelectedMulti(s);
  }

  function handleValidSubmit(event, values) {
    props.onCloseModal({ config: { ...props.config, properties: selectedMulti, showSlider }, el: props.el });
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseModal({ config: props.config, el: props.el });
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={() => {
              props.onCloseModal({ config: props.config, el: props.el });
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">City/Municipality</Label>
              <Select
                value={selectedMulti}
                isMulti={true}
                onChange={(values) => {
                  setSelectedMulti(values);
                }}
                options={optionGroup}
                classNamePrefix="select2-selection"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="d-flex align-items-center justify-content-between">
              <Label htmlFor="validationCustom01">Show Slider</Label>
              <div
                className="custom-control custom-switch custom-switch-md d-inline-block"
                dir="ltr"
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="externalPage"
                  checked={showSlider}
                  onChange={(e, v) => {
                    setShowSlider(e.target.checked);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="externalPage"
                />
              </div>
            </FormGroup>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

PropertyModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseModal: PropTypes.func
};

export default PropertyModal;
