import { Card, CardBody } from "reactstrap";
import Moment from "react-moment";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { addLeadNotes, deleteLeadNote } from "../leads.service";

const LeadNotes = (props) => {
  const [notes, setNotes] = useState([]);
  const [form, setForm] = useState();
  const [showMore, setShowMore] = useState(false);


  async function addNote$(e, values) {
    if (values.note && values.note.trim()) {
      form.reset();
      let note = await addLeadNotes({ wu_id: props.wu_id, note: values.note.trim() });
      if (note) {
        setNotes(notes => [note, ...notes]);
      }
    }
  }

  useEffect(function() {
    setNotes(props.notes);
  }, [props.notes]);

  async function deleteNote(key) {
    await deleteLeadNote({ wu_id: props.wu_id, note_id: key });
    props.getNotes();
  }

  useEffect(function() {
    if (notes.length > 2) {
      setShowMore(true);
    }
  }, []);

  return (
    <Card>
      <CardBody>
        <h6 className="text-muted mb-3 ">Notes</h6>
        <div>
          <AvForm
            className="mb-2"
            onValidSubmit={addNote$} ref={(c) => setForm(c)}>
            <AvField
              id={"noteInput"}
              name="note" type="textarea" placeholder="Write anything..."/>
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary btn-sm text-right"
                      onClick={() => $("#noteInput").val(" ")}
              >Add
              </button>
            </div>
          </AvForm>
        </div>
        <div className="list-group list-group-flush">
          {
            notes.filter((k, i) => {
              if (!showMore) {
                return true;
              } else {
                return i < 2;
              }
            }).map((note, key) => <div className="list-group-item px-0" key={key}>
              <div>
                {note.note}
                <p className="p-0 m-0 font-size-10 text-right">
                  <Moment  withTitle titleFormat="LLL" fromNow>{note.created}</Moment>
                  <span className="fa fa-trash text-danger ml-2" style={{ cursor: "pointer" }}
                        onClick={() => deleteNote(note.note_id)}
                  />
                </p>
              </div>
            </div>)
          }
        </div>
        {showMore &&
        <p className="text-primary text-center font-size-10" style={{ cursor: "pointer" }}><a
          onClick={() => setShowMore(value => !value)}>Show More</a></p>
        }
      </CardBody>
    </Card>
  );
};
export default LeadNotes;
