import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
// import * as Socket from "./socket-connection";
// Import Routes all
import {authRoutes, userRoutes} from './routes/allRoutes';
// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';
import {ErrorBoundary} from 'react-error-boundary';
// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
// Import scss
import './assets/scss/theme.scss';
import {init} from './Gmb.connect';
import {userAccessTokenLogin} from './store/user/actions';

// notificationStore.init();
// Socket.connect();
init();

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case 'horizontal':
        // layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  useEffect(() => {
    props.userAccessTokenLogin();
    if (typeof FB !== 'undefined') {
    } else {
      window.fbAsyncInit = () => {
        FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v14.0',
        });
      };
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  }, []);

  const Layout = getLayout();
  return (
    <React.Fragment>
      <ErrorBoundary
        FallbackComponent={({ error }) => (
          <>
            <div role='alert alert-secondary'>
              <p>Something went wrong:</p>
              <pre>{error.message}</pre>
            </div>
          </>
        )}
      >
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                redirectTo={route.redirectTo}
                user={props.user}
                exact
                superAdmin={route.superAdmin}
              />
            ))}
          </Switch>
        </Router>
      </ErrorBoundary>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    user: state.user && state.user.user,
  };
};

export default connect(mapStateToProps, { userAccessTokenLogin })(App);
