import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {searchLocation} from "../Leads/leads.service";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Select from "react-select";
import { capitalize, debounce } from "helpers/util.helper";

const PropertyFiltersModal = ({closeModal, openModal, filters, data = {}, setFilters}) => {
    const [selectedLocations, setSelectedLocations] = useState(null);
    const [options, setOptions] = useState([]);
    const [optionsLoading, setOptionsLoading] = useState(false);
    useEffect(function () {
        fetchLocationSearch({query: "Brampton", setData: true});
        if (data.location && data.location.length) {
            try {
                let location = JSON.parse(data.location);
                let selectedIds = location.map(d => d.id);
                let sel = location.map(location => {
                    return {label: prepareLocation(location), value: location};
                });
                setSelectedLocations(sel);
            } catch (e) {

            }

        }
    }, []);

    function prepareLocation(location) {
        return capitalize([location.Community, location.Municipality, location.Area].filter(d => d).join(", "));
    }
   
    const fetchLocationSearch = debounce(fetchLocationSearch$, 500)
    async function fetchLocationSearch$({ query }) {
      if (!(query && query.length > 1 && !optionsLoading)) {
        return
      }
      setOptionsLoading(true)
      let locations = await searchLocation(query)
      if (!(locations && locations.length)) {
        return
      }
      setOptionsLoading(false)

      setOptions(
        locations.map(location => {
          return { label: prepareLocation(location), value: location }
        })
      )
    }

    return <Modal
        isOpen={openModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
            closeModal();

        }}
    >
        <div className="modal-content">
            <ModalHeader
                toggle={() => {
                    closeModal();

                }}
            >Filter Properties</ModalHeader>
            <ModalBody>
                <AvForm onValidSubmit={(e, values) => {
                    if (selectedLocations) {
                        values = {
                            ...values,
                            municipality: selectedLocations.value.Municipality,
                            area: selectedLocations.value.Area,
                            community: selectedLocations.value.Community
                        }
                    }
                    setFilters(values);
                }}>
                    <Row id="lead-add-form">
                        <Col md={12}>
                            <FormGroup className="ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>Locations</Label>
                                <Select
                                    value={selectedLocations}
                                    onInputChange={(e) => {
                                        fetchLocationSearch({query: e});
                                    }}
                                    onChange={(e) => {
                                        setSelectedLocations(e);
                                    }}
                                    options={options}
                                    isLoading={optionsLoading}
                                    isSearchable={true}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} className="py-0 my-0">
                            <p className="m-0 p-0 text-center text-muted">OR</p>
                        </Col>
                        <Col md={12}>
                            <AvField
                                type="text"
                                label="MLS"
                                name="mls"
                                value={data.mls}
                            />
                        </Col>
                        <Col md={12} className="py-0 mb-4">
                            <p className="m-0 p-0  text-muted">More Filters</p>
                        </Col>
                        <Col md={6}>
                            <AvField
                                type="select"
                                label="Beds"
                                name="bed"
                                value={data.bed}
                            >
                                <option value=""/>
                                <option value="1">1+</option>
                                <option value="2">2+</option>
                                <option value="3">3+</option>
                                <option value="4">4+</option>
                                <option value="5">5+</option>
                            </AvField>
                        </Col>
                        <Col md={6}>
                            <AvField
                                type="select"
                                label="Baths"
                                name="bath"
                                value={data.bath}
                            >
                                <option value=""/>
                                <option value="1">1+</option>
                                <option value="2">2+</option>
                                <option value="3">3+</option>
                                <option value="4">4+</option>
                                <option value="5">5+</option>

                            </AvField>
                        </Col>
                        <Col md={6}>

                            <AvField
                                type="number"
                                label="Min Price"
                                name="min_price"
                                value={data.min_price}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                type="number"
                                label="Max Price"
                                name="max_price"
                                value={data.max_price}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                type="number"
                                label="Min Area"
                                name="min_area"
                                value={data.min_area}
                            />
                        </Col>

                        <Col md={6}>
                            <AvField
                                type="number"
                                label="Max Area"
                                name="max_area"
                                value={data.max_area}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                type="select"
                                label="Property Type"
                                name="property_type"
                                value={data.property_type}
                            >
                                <option value=""/>
                                <option value="semiDetached">Semi-Detached</option>
                                <option value="detached">Detached</option>
                                <option value="townhouse">Att/Row/Twnhouse</option>


                            </AvField>
                        </Col>
                        <Col md={6}>
                            <AvField
                                type="select"
                                label="Sale/Lease"
                                name="sale_lease"
                                value={data.sale_lease}
                            >
                                <option value=""/>
                                <option value="Sale">Sale</option>
                                <option value="Lease">Lease</option>


                            </AvField>
                        </Col>

                    </Row>
                    <button type="submit" className="d-none" id="saveBtn"/>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                        closeModal();
                    }}>
                    Close
                </Button>
                <Button type="button" color="primary" onClick={() => {
                    $('#saveBtn').click();
                }}>
                    Search
                </Button>
            </ModalFooter>
        </div>
    </Modal>

};

export default PropertyFiltersModal;
