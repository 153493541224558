import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { del, get, post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { page_limit } from "../../config";
import pageColumns from "../../page.columns";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import { showError } from "../../helpers/util.helper";
import toastr from "toastr";
import NoDataAvailable from "../../components/NoDataAvailable";
import { TableLoader } from "../../components/Loader";
import SettingsImagePreview from "../Settings/util/image-preview";
import MemberCard from "./util/MemberCard";
import AddMemberCard from "./util/AddMenberCard";
import "./util/Team.css";

const Teams = ({ match }) => {
  let history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingTeamId, setDeletingTeamId] = useState(null);
  useEffect(() => {
    getTeam({ limit, offset });
  }, []);

  const actions = [
    {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editTeam
    }, {
      name: "Delete",
      icon: "fas fa-trash",
      color: "danger",
      fn: openDeleteModal
    }];
  const limit = page_limit;
  const offset = 0;
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState({
    columns: pageColumns.team,
    rows: []
  });

  function pageChange(e) {
    getTeam({ limit, offset: (limit * (e - 1)) });
  }

  const getTeam = async ({ limit, offset }) => {
    setPageLoading(1);
    const teamData = await get(url.TEAMS, {
      params: {
        limit, offset
      }
    });
    setPageLoading(0);
    teamData.data = setTeamData(teamData.data);
    let _d = { ...data, rows: teamData.data };
    setData(_d);
    setTotalPages(teamData.total_count);
  };


  function editTeam({ tm_id }) {
    history.push("/editTeam/" + tm_id);
  }

  function addTeam() {
    history.push("/addTeam");
  }

  async function deleteTeam() {
    const response = await del(url.TEAMS + "/" + deletingTeamId);
    if (!response.api_status) {
      let _d = data.rows.filter(d => d.tm_id !== deletingTeamId);
      setData({ ...data, rows: _d });
      setDeletingTeamId(null);
      setDeleteModal(false);
      toastr.success("Team deleted successfully.", "Success");
    } else {
      showError(response);
    }
  }

  function openDeleteModal({ tm_id }) {
    setDeletingTeamId(tm_id);
    setDeleteModal(true);
  }

  function setTeamData(team) {
    if (team && team.length) {
      return team.map(m => {
        if (m.member_photo) {
          m.member_photo_original = m.member_photo;
          m.member_photo = <SettingsImagePreview image={m.member_photo} width="100px" height="100px"/>;
        }
        if (m.member_description) {
          m.member_description = <div dangerouslySetInnerHTML={{ __html: m.member_description }}/>;
        }
        return m;
      });
    }
    return [];
  }

  useEffect(function() {

    let sortable = $("#sortable").sortable({
      items: "> div:not(.pin)", stop: function(e) {
        updateListOrder(sortable.sortable('toArray'))
      }
    });
    $("#sortable").disableSelection();
  });
  async function updateListOrder(order) {
    const response = await post(url.TEAMS + "/sort", {order});
    if (!response.api_status) {
      toastr.success("List updated successfully", "Success");
    } else {
      showError(response);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Team List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                      data.rows.length
                        ?
                        <>

                          <Row id="sortable">
                            {data.rows.map((user, key) => (
                              <MemberCard user={user} key={"_user_" + key} deleteMember={openDeleteModal}/>
                            ))}
                            <AddMemberCard/>
                          </Row>
                          {/* <AETable columns={data.columns}
                                   rows={data.rows}
                                   itemsPerPage={limit}
                                   loading={pagesLoading}
                                   addLink={addTeam}
                                   actions={actions}
                                   totalCount={totalPages}
                                   pageFn={pageChange}/>*/}
                        </>
                        :
                        <NoDataAvailable module="teams"/>
                  }

                </CardBody>
                <DeleteModal
                  onCloseClick={() => {
                    setDeletingTeamId(null);
                    setDeleteModal(false);
                  }}
                  onDeleteClick={deleteTeam}
                  show={deleteModal}>
                </DeleteModal>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Teams;
