import { Col, Modal } from "reactstrap";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";

const ParagraphModal = (props) => {
  const [convertedContent, setConvertedContent] = useState("");
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );

  useState(function() {
    const blocksFromHtml = htmlToDraft(props.config.paragraph);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const state = EditorState.createWithContent(contentState);
    setEditorState(state);
  });

  function saveSettings() {
    props.onCloseParagraphModal({ paragraph: convertedContent, el: props.el });
  }

  function onEditorStateChange(e) {
    if (e) {
      setEditorState(e);
    }
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const value = draftToHtml(
      rawContentState
    );
    setConvertedContent(value);
  };
  return (
    <Modal
      size='lg'
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseParagraphModal({ paragraph: props.paragraph, el: props.el });
      }}
    >
      <div className="modal-header">
        <h5 className="modal-paragraph mt-0" id="myModalLabel">Paragraph Settings</h5>
        <button
          type="button"
          onClick={() => {
            props.onCloseParagraphModal({ paragraph: props.paragraph, el: props.el });
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Col md="12">
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </Col>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-primary waves-effect waves-light"
          onClick={() => {
            saveSettings();
          }}
        >
          Save changes
        </button>
      </div>
    </Modal>
  );
};

ParagraphModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseParagraphModal: PropTypes.func
};

export default ParagraphModal;
