import React, {useState} from "react";
import * as moment from 'moment-timezone';
import {Pill} from "../../../components/Common/pill";

export const WebsitePlanDetails = ({paymentsData}) => {
    const [chargeModal, setChargeModal] = useState({isOpen: false});
    const [planCloseModal, setPlanCloseModal] = useState({isCharge: false, isOpen: false});
    const planEndDate = moment('01-15-2022').format('YYYY-MM-DD')

    function onChargeExtend(values) {
        if (values.isCharge) {

        }
    }

    function getPrice() {
        const price = (
          paymentsData.website.promo_price &&
          paymentsData.website.promo_expiry &&
          moment(paymentsData.website.promo_expiry).isAfter(moment())
            ? paymentsData.website.promo_price
            : paymentsData.website.plan_price
        )?.toFixed(2)
        return price
    }

    return (
      <>
        <table className="table table-condensed">
          <tr>
            <td>Plan</td>
            <td>{paymentsData.website.plan_name}</td>
          </tr>
          <tr>
            <td>Promo Expiry</td>
            <td>
              {moment(paymentsData.website.promo_expiry).format("MM-DD-YYYY")}
            </td>
          </tr>
          <tr>
            <td>Monthly Pricing</td>
            <td>CAD {getPrice()}</td>
          </tr>
          <tr>
            <td>Trial</td>
            <td>
              {paymentsData.website.isTrial ? (
                <Pill color="success">Yes</Pill>
              ) : (
                <Pill color="danger">No</Pill>
              )}
            </td>
          </tr>
          {paymentsData.website.isTrial > 0 && (
            <tr>
              <td>Trial End Date</td>
              <td>{paymentsData.website.trial_end_date || "-"}</td>
            </tr>
          )}
          <tr>
            <td>Launch Date</td>
            <td>{moment(paymentsData.website.created).format("MM-DD-YYYY")}</td>
          </tr>

          <tr>
            <td>Plan End Date</td>
            <td>
              {moment(paymentsData.website.plan_end_date).format("MM-DD-YYYY")}
            </td>
          </tr>
          <tr>
            <td>Is Recurring Plan</td>
            <td>
              {paymentsData.website.planStop ? (
                <Pill color="danger">No</Pill>
              ) : (
                <Pill color="success">Yes</Pill>
              )}
            </td>
          </tr>
          <tr>
            <td>Website Status</td>
            <td>
              {paymentsData.website.isActive ? (
                <Pill color="success">Active</Pill>
              ) : (
                <Pill color="danger">InActive</Pill>
              )}
            </td>
          </tr>
        </table>
      </>
    )
};
