import {Col, Modal, Row} from "reactstrap";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import ComponentWidthInput from "./ComponentWidthInput";

const HTMLSettings = (props) => {
    const [convertedContent, setConvertedContent] = useState("");
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );

    useState(function() {
        const blocksFromHtml = htmlToDraft(props.config.paragraph);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const state = EditorState.createWithContent(contentState);
        setEditorState(state);
    });

    function saveSettings() {
        props.updateConfig({ paragraph: convertedContent});
    }

    function onEditorStateChange(e) {
        if (e) {
            setEditorState(e);
        }
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const value = draftToHtml(
            rawContentState
        );
        setConvertedContent(value);
    };
    return <>
        <Col md="12">
            <Editor
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
            />
        </Col>
        <div className="modal-footer">
            <button
                onClick={() => props.close()}
                type="button"
                className="btn btn-danger waves-effect waves-light"
            >Cancel</button>
            <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                onClick={() => {
                    saveSettings();
                }}
            >
                Save changes
            </button>
        </div>

    </>
};
export default HTMLSettings;
