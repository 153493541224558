import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import {NavLink, withRouter} from "react-router-dom";
//i18n
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toggleAdminMenu} from "../../store/layout/actions";
import nav from "./nav";

const SidebarContent = props => {
  const [navData, setNavData] = useState([]);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
      let navType = props.adminMenu ? "admin" : "user";
      setNavData(nav[navType]);
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      return false;
    }
    return false;
  }

  useEffect(function () {
    let navType = props.adminMenu ? "admin" : "user";
    setNavData(nav[navType]);
  }, [props.user]);
  return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title" key="t-menu">Menu</li>
            {
              navData.filter(item => {
                if (item.role) {
                  if (!(props.user && props.user && props.user.role_id && item.role.includes(+props.user.role_id))) {
                    return false;
                  }
                }
                if (!item.category) {
                  return true;
                }
                return props.user && props.user.website && props.user.website.industry_id && item.category.includes(+props.user.website.industry_id)
              }).map((navItem, navIdKey) => {
                return (
                    <li key={navIdKey + "navid"}>
                      <NavLink  activeStyle={{color: "#fff"}} exact={true} to={navItem.path} className=" waves-effect">
                        <i className={'fa fa-' + navItem.icon}/>
                        <span>{props.t(navItem.title)} </span>
                      </NavLink>
                    </li>
                );
              })
            }
          </ul>
        </div>
      </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  adminMenu: PropTypes.bool,
  user: PropTypes.object,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const {
    adminMenu
  } = state.Layout;
  const {user} = state.user;
  return {adminMenu, user};
};
export default connect(mapStatetoProps, {
  toggleAdminMenu
})(withRouter(withTranslation()(SidebarContent)));
