import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import PropTypes from "prop-types";
import ComponentWidthInput from "./ComponentWidthInput";


const FaqSettings = (props) => {
  let [rows, setRows] = useState(props.config.rows || []);

  function handleValidSubmit(event, values) {
    let rowsGroup = [];
    console.log(values, "are values");
    values.title.forEach((item, index) => {
      rowsGroup.push({title: values.title[index], description: values.description[index], image: rows[index].image});
    });
    props.updateConfig({rows: rowsGroup, accordion_title: values.accordion_title, ...values});
  }

  function addNewRow() {
    let r = {title: "", description: ""};
    setRows([...rows, r]);
  }

  function deleteRow(id) {
    let r = rows.filter((d, i) => i !== id);
    setRows(r);
  }

  function handleFile(e, id) {
    const reader = new FileReader();
    reader.onload = function (e) {
      let r = rows.map((d, i) => {
        if (id === i) {
          d.image = e.target.result;
        }
        return d;
      });
      setRows(r);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  function deleteImage(id) {
    let r = rows.map((d, i) => {
      ;
      if (i === id) {
        delete d.image;
      }
      return d;
    });
    setRows(r);
  }


  return (

      <AvForm onValidSubmit={handleValidSubmit}>

        <Row className="py-2">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Accordion Title</Label>
              <AvField
                  name="accordion_title"
                  value={props.config.accordion_title}
                  placeholder="Lorum Ipsum"
                  type="text"
                  className="form-control"
                  id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <ComponentWidthInput {...props.config}/>
          </Col>
          {
            rows.map((row, idx) => {
              return (
                  <Col md="12" key={"main" + idx}>
                    <p key={idx + "para"} className="d-flex align-items-center justify-content-between">
                      <b>Question {idx + 1}</b> <span className="fas fa-minus-circle"
                                                  onClick={() => {
                                                    deleteRow(idx);
                                                  }}
                                                  style={{color: 'red', cursor: 'pointer'}}/></p>
                    <FormGroup key={idx + "title"}>
                      <Label htmlFor="validationCustom01">Heading</Label>
                      <AvField
                          name={"title." + idx}
                          value={row.title}
                          placeholder="Lorum Ipsum"
                          type="text"
                          rows="20"
                          className="form-control"
                          required
                          id="validationCustom03"
                      />
                    </FormGroup>
                    <FormGroup key={idx + "description"}>
                      <Label htmlFor="validationCustom01">Description</Label>
                      <AvField
                          name={"description." + idx}
                          value={row.description}
                          placeholder="Lorum Ipsum"
                          type="textarea"
                          rows="3"
                          className="form-control"
                          required
                          id={"validationDescription" + idx}
                      />
                    </FormGroup>

                  </Col>);
            })
          }
          <Col md="12">
            <Button
                onClick={() => {
                  addNewRow();
                }}
                color="success"
                className="mt-2">
              Add Step
            </Button>
          </Col>
        </Row>
        <div className="modal-footer">
          <button
              onClick={() => props.close()}
              type="button"
              className="btn btn-danger waves-effect waves-light"
          >Cancel</button>
          <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>

  );
};

FaqSettings.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseAccordionSettings: PropTypes.func
};

export default FaqSettings;
