import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { get } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { Card, CardBody, Col, Row } from "reactstrap"
import { TableLoader } from "../../components/Loader"
import AETable from "../../components/Common/Table"
import NoDataAvailable from "../../components/NoDataAvailable"
import pageColumns from "../../page.columns"
import { page_limit } from "../../config"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { toggleAdminMenu } from "../../store/layout/actions"

const Websites = (props) => {
  let limit = page_limit
  let offset = 0
  const history = useHistory()
  const [pagesLoading, setPageLoading] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState({
    columns: pageColumns.websites,
    rows: []
  })
  useEffect(() => {
    getPageData({ limit, offset })
  }, [])
  const getPageData = async ({ limit, offset }) => {
    setPageLoading(1)
    const blogData = await get(url.WEBSITES, {
      params: {
        limit, offset
      }
    })
    setPageLoading(0)
    let _d = { ...data, rows: blogData.data }
    setData(_d)
    setTotalPages(blogData.total_count)
  }

  function pageChange(e) {
    setCurrentPage(e)
    getPageData({ limit, offset: (limit * (e - 1)) })
  }

  function addWebsite() {
    history.push("/addWebsite")
  }

  const actions = [
    {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editWebsite
    }];

  function editWebsite(website) {
    props.toggleAdminMenu(false);
    localStorage.setItem("website_id", website.website_id);
    localStorage.setItem("website", JSON.stringify(website));
    history.push("/settings")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Websites" breadcrumbItem="Websites List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                      data.rows.length
                        ? <AETable columns={data.columns}
                                   rows={data.rows}
                                   itemsPerPage={limit}
                                   loading={pagesLoading}
                                   currentPage={currentPage}
                                   totalCount={totalPages}
                                   actions={actions}
                                   addLink={addWebsite}
                                   pageFn={pageChange}/>
                        :
                        <NoDataAvailable module="websites"></NoDataAvailable>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const {
    adminMenu
  } = state.Layout
  return { adminMenu }
}

export default connect(mapStatetoProps, {
  toggleAdminMenu
})(Websites)
