import {Col, FormGroup, Label, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import React from "react"
import PropTypes from "prop-types"
import ComponentWidthInput from "./ComponentWidthInput";


const BlogSettings = (props) => {


  function handleValidSubmit(event, values) {
    props.updateConfig({max_posts: values.max_posts, ...values})
  }

  return (
      <AvForm onValidSubmit={handleValidSubmit}>

          <Row className="py-2">
              <Col md="12">
                  <FormGroup>
                      <Label htmlFor="validationCustom01">Heading</Label>
                      <AvField
                          name="blog_title"
                          value={props.config.blog_title}
                          placeholder="Blog List"
                          type="text"
                          className="form-control"
                          id="validationCustom03"
                      />
                  </FormGroup>
              </Col>

              <Col md="12">
                  <FormGroup>
                      <Label htmlFor="validationCustom03">Sub Heading</Label>
                      <AvField
                          name="blog_description"
                          value={props.config.blog_description}
                          placeholder="Read what’s happening in Real Estate"
                          type="text"
                          className="form-control"
                          id="validationCustom03"
                      />
                  </FormGroup>
              </Col>
              <Col md="12">
                  <FormGroup>
                      <Label htmlFor="validationCustom01">Total Blog</Label>
                      <AvField
                          name="max_posts"
                          value={props.config.max_posts}
                          placeholder="3"
                          type="number"
                          className="form-control"
                          id="validationCustom03"
                />
            </FormGroup>
          </Col>
              <Col md="12">
                  <ComponentWidthInput {...props.config}/>
              </Col>
          </Row>
          <div className="modal-footer">
              <button
                  onClick={() => props.close()}
                  type="button"
                  className="btn btn-danger waves-effect waves-light"
              >Cancel</button>
              <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
              >
                  Save changes
              </button>
          </div>
      </AvForm>

  )
}

BlogSettings.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseBlogSettings: PropTypes.func
}

export default BlogSettings
