import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import LeadEmailDetails from "../Emails/lead-email-details";
import { post } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import { maxLength, setTasksPriority } from "../../../helpers/util.helper";
import { AvForm } from "availity-reactstrap-validation";

const LeadDetailsTasksList = ({ wu_id, tasks, getTasks, setModal, setDeleteModal }) => {
  const [emailModal, setEmailModal] = useState({ open: false });
  const [checkedTasks, setCheckedTasks] = useState([]);
  const [filter, setFilter] = useState({ today: true, upcoming: true, pastDue: true, completed: true });
  const [tasks$, setTasks] = useState(false);
  useEffect(() => {
    if (tasks && tasks.length && !tasks$) {
      setTasks(tasks);
    }
  });
  useEffect(function() {
    updateTasks();
  }, [filter, tasks]);

  function updateTasks() {
    if (tasks$) {
      let today = tasks$.filter(task => filter.today ? task.isToday : !task.isToday);
      let upcoming = tasks$.filter(task => filter.upcoming ? task.isUpcoming : !task.isUpcoming);
      let pastDue = tasks$.filter(task => filter.pastDue ? task.isExpired : !task.isExpired);
      let allIds = [...today.map(d => d.lead_task_id), ...upcoming.map(d => d.lead_task_id), ...pastDue.map(d => d.lead_task_id)];
      setTasks(tasks$.filter(d => allIds.includes(d.lead_task_id)));
    }
  }

  function setTasksChecked(checked, task_id) {
    if (checked) {
      setCheckedTasks(checkedTasks => [...checkedTasks, task_id]);
    } else {
      setCheckedTasks(checkedTasks => checkedTasks.filter(task => task !== task_id));
    }
  }

  async function markTaskAsDone(isCompleted = 1) {
    await post(url.LEAD + "/" + wu_id + "/tasks/markAsDone", { tasks: checkedTasks, isCompleted });
    getTasks();
    setCheckedTasks([]);
  }

  async function removeTasks() {
    await post(url.LEAD + "/" + wu_id + "/tasks/remove", { tasks: checkedTasks });
    getTasks();
    setCheckedTasks([]);
  }

  async function removeTask(task_id) {
    await post(url.LEAD + "/" + wu_id + "/tasks/remove", { tasks: [task_id] });
    getTasks();
    setCheckedTasks([]);
  }

  function setPreviewData(task) {
    return {
      title: task.task_title,
      description: task.task_description,
      time: task.task_due_date
    };
  }

  useEffect(function() {
    console.log("checked tasks are ...", checkedTasks);
  }, [checkedTasks]);
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 justify-content-between d-flex">
            <AvForm className="w-100">
              <Row>
                <Col md="6" sm="12" className="font-size-12 d-none">
                  <div className="d-flex">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="todayTasks"
                        className="custom-control-input"
                        onChange={e => setFilter({ ...filter, today: e.target.checked })}
                        checked={filter.today}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="todayTasks"
                      >Today</label>
                    </div>
                    <div className="custom-control custom-checkbox ml-2">
                      <input
                        type="checkbox"
                        id="upcoming"
                        onChange={e => setFilter({ ...filter, upcoming: e.target.checked })}
                        checked={filter.upcoming}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="upcoming"
                      >Upcoming</label>
                    </div>
                    <div className="custom-control custom-checkbox ml-2">
                      <input
                        type="checkbox"
                        id="pastDue"
                        checked={filter.pastDue}
                        onChange={e => setFilter({ ...filter, pastDue: e.target.checked })}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="pastDue"
                      >Past Due</label>
                    </div>
                    <div className="custom-control custom-checkbox ml-2">
                      <input
                        type="checkbox"
                        id="completed"
                        checked={filter.completed}
                        onChange={e => setFilter({ ...filter, completed: e.target.checked })}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="completed"
                      >Completed</label>
                    </div>
                  </div>
                </Col>
                <Col md="6" sm="12" className="text-left text-md-right mt-2 mt-md-0">
                  {checkedTasks && checkedTasks.length > 0 &&
                  <>
                    <button className="btn-pill btn badge badge-pill font-size-11 badge-soft-primary"
                            onClick={() => markTaskAsDone(1)}>Mark as Done
                    </button>
                    <button className="btn-pill btn badge badge-pill font-size-11 badge-soft-dark ml-2"
                            onClick={() => markTaskAsDone(0)}>Mark as Incomplete
                    </button>
                    <button className="btn-pill btn badge badge-pill font-size-11 badge-soft-danger ml-2"
                            onClick={removeTasks}>Archive
                    </button>
                  </>
                  }
                </Col>
              </Row>
            </AvForm>

          </CardTitle>

          <Row>
            {
              tasks && tasks.length ?
                <div className="list-group w-100">
                  {tasks.map(task => (
                    <div className="list-group-item  flex-column align-items-start ">
                      <div className="d-flex flex-row ">
                        <div className="d-flex flex-column mr-3 text-center justify-content-center ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkedTasks.includes(task.lead_task_id)}
                              onChange={() => {
                                setTasksChecked(event.target.checked, task.lead_task_id);
                              }}
                              className="custom-control-input"
                              id={task.lead_task_id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={task.lead_task_id}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column mr-3 text-center justify-content-center "
                             style={{ width: "75px" }}>
                          <small> {setTasksPriority(task.isCompleted? 'COMPLETED': task.task_priority)}</small>

                        </div>
                        <div className="d-flex w-100">
                          <div className="flex-column w-100">
                            <div
                              className="w-100 mb-1 font-size-16 font-weight-bold camelCase align-content-between justify-content-between d-flex">
                              <div className="d-flex flex-column">
                                <span className="text-truncate">
                                  <span>{task.task_title}</span>
                                </span>

                                <small className="" style={{ whiteSpace: "break-spaces" }}>
                                  {maxLength(task.task_description, 100)}
                                </small>
                              </div>
                              <small className="d-flex flex-column">
                                <span>Task Date: <Moment format="MMM DD, YYYY hh:mm A">{task.task_due_date}</Moment></span>
                                {
                                  task.task_notification>=0 &&
                                      <p className="">
                                        Notify me: {task.task_notification} mins before
                                      </p>
                                }
                              </small>
                            </div>

                            <div className="text-right">
                                 <span className="fa fa-pencil-alt text-primary" style={{ cursor: "pointer" }}
                                       onClick={() => setModal({
                                         showModal: true,
                                         task: true,
                                         data: task
                                       })}/>
                              <span className="fa fa-trash-alt text-danger ml-2"
                                    onClick={() => {
                                      setDeleteModal({ show: true, id: task.la_id, fn: removeTask });
                                    }}
                                    style={{ cursor: "pointer" }}/>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  ))
                  }
                </div>

                :
                <Col md={12} className="text-center">
                  <h5>No Tasks set</h5>
                  <button className="btn btn-primary mt-3" onClick={() => setModal({ showModal: true, task: true })}>Add
                    Task
                  </button>
                </Col>
            }
          </Row>
        </CardBody>
        {emailModal.open && <LeadEmailDetails
          openModal={emailModal.open}
          closeModal={() => {
            setEmailModal({ open: false });
          }
          }
          email={emailModal.email}
        />}
      </Card>
    </>
  );
};

export default LeadDetailsTasksList;
