import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import {del, get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {page_limit} from "../../config";
import pageColumns from "../../page.columns";
import {useHistory} from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import {showError} from "../../helpers/util.helper";
import toastr from "toastr";
import {TableLoader} from "../../components/Loader";

export const Coupons = () => {
    let history = useHistory();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingCouponId, setDeletingCouponId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        getCoupon({limit, offset});
    }, []);

    const actions = [
        {
            name: "Edit",
            icon: "fas fa-pencil-alt",
            fn: editCoupon
        }, {
            name: "Delete",
            icon: "fas fa-trash",
            color: "danger",
            fn: openDeleteModal
        }];
    const limit = page_limit;
    const offset = 0;
    const [pagesLoading, setPageLoading] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState({
        columns: pageColumns.coupons,
        rows: []
    });

    function pageChange(e) {
        setCurrentPage(e);
        getCoupon({limit, offset: (limit * (e - 1))});
    }

    const getCoupon = async ({limit, offset}) => {
        setPageLoading(1);
        const couponData = await get(url.COUPONS, {
            params: {
                limit, offset
            }
        });
        setPageLoading(0);

        if (couponData.api_status) {
            return toastr.error('Something went wrong.');
        }
        couponData.data.forEach(coupon => {
            coupon.coupon_discount_amount = coupon.coupon_discount_amount ? 'CAD ' + coupon.coupon_discount_amount.toFixed(2) : '-';
        });

        let _d = {...data, rows: couponData.data};
        setData(_d);
        setTotalPages(couponData.total_count);
    };

    function editCoupon({coupon_id}) {
        history.push("/editCoupon/" + coupon_id);
    }

    function addCoupon() {
        history.push("/addCoupon");
    }

    async function deleteCoupon() {
        const response = await del(url.COUPONS + "/" + deletingCouponId);
        if (!response.api_status) {
            let _d = data.rows.filter(d => d.coupon_id !== deletingCouponId);
            setData({...data, rows: _d});
            setDeletingCouponId(null);
            setDeleteModal(false);
            toastr.success("Coupon deleted successfully.", "Success");
        } else {
            showError(response);
        }
    }

    function openDeleteModal({coupon_id}) {
        setDeletingCouponId(coupon_id);
        setDeleteModal(true);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Coupon List"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {
                                        pagesLoading ?
                                            <TableLoader/>
                                            :
                                            <AETable columns={data.columns}
                                                     rows={data.rows}
                                                     itemsPerPage={limit}
                                                     loading={pagesLoading}
                                                     addLink={addCoupon}
                                                     actions={actions}
                                                     currentPage={currentPage}
                                                     totalCount={totalPages}
                                                     pageFn={pageChange}/>
                                    }

                                </CardBody>
                                <DeleteModal
                                    onCloseClick={() => {
                                        setDeletingCouponId(null);
                                        setDeleteModal(false);
                                    }}
                                    onDeleteClick={deleteCoupon}
                                    show={deleteModal}>
                                </DeleteModal>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

