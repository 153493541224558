import { Card, CardBody, Col, Row } from "reactstrap";
import React from "react";
import { deleteAlert } from "../leads.service";
import Moment from "react-moment";
import { formatPhone, maxLength } from "../../../helpers/util.helper";
import SpanCheck from "../../../components/Common/SpanCheck";
import LeadBasicInfo from "../components/lead-basic-info";

let purposeSelling = {
  JustCurious: "Just curious",
  NeedToSellASAP: "Need to sell ASAP",
  ThinkingAboutSelling: "Thinking about selling",
  ThinkingAboutBuying: "Thinking about buying",
  NeedToRefinance: "Need to refinance",
  NeedToSellAndThenBuy: "Need to sell and then buy"

};
let sellingTime = {
  OneMonth: "Within 1 month",
  ThreeMonths: "Next 3 months",
  SixMonths: "Next 6 months",
  TwelveMonths: "Next 12 months",
  AYearOrMore: "1 year or more",
  NotRightNow: "Just curious"

};
const Valuation = ({ evaluation, setModal, modal, setDeleteModal, getAlerts, wu_id }) => {
  async function deleteAlert$(alert_id) {
    await deleteAlert({ la_id: alert_id, wu_id });
    getAlerts();
  }

  return <Card>
    <CardBody>
      <Row>
        {/*
          <div className="table-responsive" id="tasks-list">
              <table className="table table-nowrap table-centered mb-0">
                <thead>
                <tr>
                  <td>Address</td>
                  <td>Unit No</td>
                  <td>Beds</td>
                  <td>Baths</td>
                  <td>Year Built</td>
                  <td>Verified</td>
                  <td className="text-center">Action</td>
                </tr>
                </thead>
                <tbody>
                {map(evaluation, (item, i) => (
                  <tr key={i}>

                    <td>
                      <h5 className="text-truncate font-size-14 m-0" style={{ "width": "300px" }}>
                        <a target="_blank"
                           title={item.location}
                           href={"https://www.google.com/maps/search/?api=1&query=" + item.lat + "," + item.lng}>{item.location}</a>
                      </h5>
                    </td>
                    <td>
                      <h5 className="text-truncate font-size-14 m-0" style={{ "width": "100px" }}>
                        {item.unit_no}
                      </h5>
                    </td>
                    <td>
                      <h5 className="text-truncate font-size-14 m-0" style={{ "maxWidth": "100px" }}>
                        {(item.beds && item.beds + "+") || "-"}
                      </h5>
                    </td>
                    <td>
                      <h5 className="text-truncate font-size-14 m-0" style={{ "maxWidth": "100px" }}>
                        {(item.baths && item.baths + "+") || "-"}
                      </h5>
                    </td>


                    <td>
                      <h5 className="text-truncate font-size-14 m-0" style={{ "maxWidth": "50px" }}>
                        {(item.year_built && item.year_built + "+") || "-"}
                      </h5>
                    </td>
                    <td>
                      <h5 className="text-truncate font-size-14 m-0" style={{ "maxWidth": "100px" }}>
                        <SpanCheck type={item.emailVerified ? "success" : "danger"}/>
                      </h5>
                    </td>
                    <td className="text-center">

                      <span className="fa fa-trash text-danger ml-2"
                            onClick={() => {
                              setDeleteModal({ show: true, id: item.la_id, fn: deleteAlert$ });
                            }}
                            style={{ cursor: "pointer" }}/>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
        */}

        {
          evaluation && evaluation.length > 0 ?
            <div className="list-group w-100">
              {evaluation.map((item, key) => (
                <div className="list-group-item  flex-column align-items-start" key={key}>
                  <div className="d-flex flex-row ">
                    <div className="d-flex w-100">
                      <div className="flex-column w-100">
                        <div
                          className="w-100 mb-1 font-size-16 font-weight-bold camelCase align-content-between justify-content-between d-flex">
                          <a title={item.location}
                             target="_blank"
                             href={"https://www.google.com/maps/search/?api=1&query=" + item.location}>{item.location}</a>
                          <small className="d-flex flex-column">
                            <Moment format="MMM DD, YYYY hh:mm A">{item.created}</Moment>
                          </small>
                        </div>
                        <Row>
                          <Col md={8} sm={12}>

                            <p className="mb-2">
                              {item.unit_no && <span>Unit No {item.unit_no} | </span>}
                              <span className="bx bx-bed"/> {item.beds} bd. | <span
                              className="bx bxs-bath"/> {item.baths} ba.

                              | Built {item.year_built || ""}
                            </p>
                            <p className="mb-1">
                              <b>Message</b>: {maxLength(item.message, 100)}
                            </p>
                            <p className="mb-1">
                              <b>Selling Reason</b>: {purposeSelling[item.selling_purpose]}
                            </p>
                            <p className="mb-1">
                              <b>Selling within</b>: {sellingTime[item.selling_time]}
                            </p>
                            <p className="mb-2">
                              <SpanCheck
                                type={item.emailVerified ? "success" : "danger"}/> {item.emailVerified ? "Email Verified" : "Email Not Verified"}
                            </p>

                          </Col>
                          <Col md={4} sm={12}>
                            <LeadBasicInfo lead={item}/>
                          </Col>
                          <Col md={12}>
                            <div>
                              <button className="btn btn-sm btn-primary"
                                      onClick={() => setModal({ showModal: true, email: true })}><span
                                className="fa fa-envelope"/> Send Estimate
                              </button>
                              <a href={"tel:" + item.contact} className="btn btn-sm btn-success ml-2"> <span
                                className="fa fa-phone"/> Call Now</a>
                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </div>
                </div>
              ))
              }
            </div>


            :
            <Col md={12} className="text-center mt-2">
              <p>No data available.</p>
            </Col>
        }
      </Row>
    </CardBody>

  </Card>;
};


export default Valuation;
