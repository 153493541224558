import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { get, post, put } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import { showError } from "../../helpers/util.helper";
import { useHistory } from "react-router-dom";
import AddCategoryModal from "../../common/AddCategory";
import Select from "react-select";

const AddAward = ({ match }) => {

    const [data, setData] = useState({});
    const [convertedContent, setConvertedContent] = useState("");
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );
    let history = useHistory();
    useEffect(async function() {
        if (match && match.params && match.params.award_id) {
            getAward({ award_id: match.params.award_id });
        }
    }, []);

    async function handleValidSubmit(e, values) {
        onEditorStateChange();
        let award = {
            ...data,
            ...values,
            award_description: convertedContent || data.award_description,
        };
        let response;
        if (award.award_id) {
            response = await put(url.AWARD_LIST + "/" + data.award_id, award);
        } else {
            response = await post(url.AWARD_LIST, award);
        }
        if (!response.api_status) {
            toastr.success("Award added successfully.", "Success");
            back();
        } else {
            showError(response);
        }
    }


    function back() {
        history.push("/awards");

    }

    function onEditorStateChange(e) {
        if (e) {
            setEditorState(e);
        }
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const value = draftToHtml(
            rawContentState
        );
        setConvertedContent(value);
    };

    function handleFile(e, key) {
        const reader = new FileReader();
        reader.onload = function(e) {
            setData({ ...data, [key]: e.target.result });
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    async function getAward({ award_id }) {
        const response = await get(url.AWARD_LIST + "/" + award_id);
        if (!response.api_status) {
            const blocksFromHtml = htmlToDraft(response.award_description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const state = EditorState.createWithContent(contentState);
            setEditorState(state);
            setData(response);
        } else {
            showError(response);
        }
    }


    return (
        <React.Fragment>
            <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>

                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Add Award" breadcrumbItem="Award Add"/>
                    </div>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <h4 className="card-title">Award</h4>
                                            <p className="card-title-desc">Add award info</p>
                                        </Col>
                                        <Col md="12">
                                           <Row>
                                               <Col md={6} sm={12}>
                                                   <FormGroup>
                                                       <Label htmlFor="validationCustom04">Award Title</Label>
                                                       <AvField
                                                           name="award_title"
                                                           value={data.award_title}
                                                           placeholder="Lorum Ipsum.."
                                                           type="text"
                                                           className="form-control"
                                                           id="validationCustom04"
                                                       />
                                                   </FormGroup>
                                               </Col>
                                           </Row>
                                        </Col>
                                        <Col md="12">
                                            <Label htmlFor="validationCustom01">Award Details</Label>

                                            <Editor
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                editorState={editorState}
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom01">Award</Label>
                                                        <AvField
                                                            name="award_photo_"
                                                            type="file"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            onChange={event =>
                                                                handleFile(event, "award_image")
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    {
                                                        <img src={data.award_image}
                                                             alt="" className="img img-thumbnail"/>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12 text-center">
                            <Card>
                                <CardBody>
                                    <Button color="secondary" className="py-2 mr-2" type="button" onClick={back}>
                                        Back
                                    </Button>
                                    <Button color="primary" className="py-2" type="submit">
                                        {
                                            data.award_id ?
                                                "Update"
                                                : "Save"
                                        }
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </AvForm>

        </React.Fragment>
    );
};

export default AddAward;
