import React, {useEffect} from "react";
import FacebookConnect from "./FacebookConnect";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import FacebookLeadForms from "./FacebookLeadForms";
import FacebookLeadsList from "./FacebookLeadsList";

const FacebookMarketingHome = (props) => {

  let facebookRoutes = [
    { component: FacebookLeadForms, path: '/forms' },
    { component: FacebookLeadsList, path: '/leads' },
    { component: FacebookLeadForms, path: '*' },
  ];
  const history = useHistory();
  useEffect(() => {
  }, [props.user]);

  return (
    <>
      <FacebookConnect/>
    </>
  );
};

const mapStatetoProps = state => {
  const {
    user
  } = state.user;
  return { user };
};

export default connect(mapStatetoProps)(FacebookMarketingHome);
