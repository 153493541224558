import {Col, FormGroup, Label, Modal, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ComponentWidthInput from "./ComponentWidthInput";


const PageHeaderSettings = (props) => {
    const [showButton, setShowButton] = useState(props.config.showButton);
    const [showOverlay, setShowOverlay] = useState(props.config.showOverlay);
    const [image, setImage] = useState(props.config.image);
    let [showBg$, setShowBg] = useState(props.config.showBg);
    function handleValidSubmit(event, values) {
        props.updateConfig({
            heading: values.heading,
            description: values.description,
            showButton,
            button_title: showButton && values.button_title,
            button_link: showButton && values.button_link,
            height: values.height, el: props.el,
            image,
            ...values
        });
    }

    function deleteImage() {
        setImage(null);
    }
    function handleFile(e) {
        const reader = new FileReader();
        reader.onload = function (e) {
            setImage(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    return (

        <AvForm onValidSubmit={handleValidSubmit}>

            <Row className="py-2">
                <Col md="12">
                    <FormGroup>
                        <Label htmlFor="validationCustom01">Heading</Label>
                        <AvField
                            name="heading"
                            value={props.config.heading}
                            placeholder="Lorum Ipsum"
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                        />
                    </FormGroup>
                </Col> <Col md="12">
                <FormGroup>
                    <Label htmlFor="validationCustom01">Sub Heading</Label>
                    <AvField
                        name="description"
                        value={props.config.description}
                        placeholder="Lorum Ipsum"
                        type="textarea"
                        rows="5"
                        className="form-control"
                        id="validationCustom04"
                    />
                </FormGroup>
            </Col>
                <Col md="12">
                    <ComponentWidthInput {...props.config}/>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <Label htmlFor="validationCustom01">Height(in Pixels)</Label>
                        <AvField
                            name="height"
                            value={props.config.height}
                            placeholder="400px"
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                        />
                    </FormGroup>
                </Col>

                <Col md="12">
                    <FormGroup>
                        <Label htmlFor="validationCustom01" className="d-flex align-items-center justify-content-between">
                            <span>Background</span>
                            {
                                image && <span className="fas fa-minus-circle"
                                               onClick={() => {
                                                   deleteImage();
                                               }}
                                               style={{ color: 'red', cursor: 'pointer' }}/>
                            }
                        </Label>
                        {
                            !image && <AvField
                                name="post"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={event =>
                                    handleFile(event, "portfolioPhoto")
                                }
                            />
                        }
                    </FormGroup>
                </Col>
                <Col md="12">
                    {
                        <img src={image}
                             alt="" className="img img-thumbnail"/>
                    }
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label htmlFor="validationCustom05">Background position</Label>
                        <AvField
                            name="bgPosition"
                            value={props.config.bgPosition}
                            type="select"
                            className="form-control"
                            id="validationCustom05"
                        >
                            <option value="center">Center</option>
                            <option value="top">Top</option>
                            <option value="left">Left</option>
                            <option value="right">Right</option>
                            <option value="bottom">Bottom</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label htmlFor="validationCustom05">Background Size</Label>
                        <AvField
                            name="bgSize"
                            value={props.config.bgSize}
                            type="select"
                            className="form-control"
                            id="validationCustom05"
                        >
                            <option value="cover">Cover</option>
                            <option value="contain">Contain</option>
                            <option value="auto">Auto</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <div className="col-6">
                    <FormGroup className="d-flex " style={{gap: '5px'}}>
                        <AvField type="checkbox" id={"showOverlay"}
                                 name={"showOverlay"}
                                 value={showOverlay}
                                 defaultChecked={props.config.showOverlay}
                                 onChange={(e) => setShowOverlay(e.target.checked)}/>
                        <Label htmlFor="showOverlay" className="m-0 p-0">Show Overlay</Label>
                    </FormGroup>
                </div>
                {
                    showOverlay && <div className="col-6">
                        <FormGroup>
                            <Label htmlFor="opacity">Overlay Opacity</Label>
                            <AvField
                                name="opacity"
                                value={props.config.opacity}
                                type="select"
                                className="form-control"
                                id="opacity"
                            >
                                <option value="10">10%</option>
                                <option value="20">20%</option>
                                <option value="30">30%</option>
                                <option value="40">40%</option>
                                <option value="50">50%</option>
                                <option value="60">60%</option>
                                <option value="70">70%</option>
                                <option value="80">80%</option>
                                <option value="90">90%</option>
                                <option value="100">100%</option>
                            </AvField>
                        </FormGroup>
                    </div>
                }

            </Row>
            <div className="modal-footer">
                <button
                    onClick={() => props.close()}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                >Cancel</button>
                <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                >
                    Save changes
                </button>
            </div>
        </AvForm>
    );
};

PageHeaderSettings.propTypes = {
    el: PropTypes.object,
    config: PropTypes.object,
    openModal: PropTypes.bool,
    onCloseParallaxSettings: PropTypes.func
};

export default PageHeaderSettings;
