import React from "react";

export const WebsiteCards = ({paymentsData, removeCard}) => {
    if (!paymentsData.cards?.length) {
        return 'No data available';
    }
    return <>
        <table className="table">
            <tbody>
            {
                paymentsData.cards.map((card) => <tr key={card.upm_id}>
                        <td>**** {card.card_last4}</td>
                        <td>{card.card_brand}</td>
                        <td>
                            {
                                card.isPrimary>0 && <button className="btn btn-outline-success btn-sm mr-1">
                                     Primary
                                </button>
                            }
                            {/* {
                                paymentsData.cards.length > 1 &&
                                <button className="btn btn-outline-danger btn-sm" onClick={() => removeCard(card.upm_id)}>
                                    Remove
                                </button>
                            } */}
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </>
}
