import { Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import React, { useState } from "react";
import LeadCallButtons from "./lead-call-buttons";
import * as moment from 'moment-timezone';
const LeadScheduleCall = ({closeModal, saveCallSchedule, lead}) => {
  const [startDate, setstartDate] = useState(new Date());
  const startDateChange = date => {
    setstartDate(date);
  };

  return <>
    <Row className="mt-2">
      <Col>
        <AvForm onValidSubmit={(e, values) => saveCallSchedule({...values, schedule_date: moment(startDate)})}>
          <AvField name="note" type="textarea" label="Note" required rows="5"/>
          <DatePicker
            minDate={new Date()}
            showTimeSelect={true}
            className="form-control mb-2"
            selected={startDate}
            dateFormat="MM/dd/yyyy h:mm aa"
            onChange={startDateChange}
          />
          <LeadCallButtons lead={lead} closeModal={closeModal} />
        </AvForm>
      </Col>
    </Row>
  </>;
};
export default LeadScheduleCall;
