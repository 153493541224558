import {Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {alertProperties, sendPropertyAlertEmail} from "../leads.service";
import {capitalize, formatMoney, showError} from "../../../helpers/util.helper";
import Moment from "react-moment";

const AlertProperties = ({closeModal, openModal, alert, wu_id}) => {
  let [properties, setProperties] = useState([]);
  let [isSelectedAll, setIsSelectedAll] = useState(false);
  let [sendingEmail, setSendingEmail] = useState(false);
  useEffect(async function () {
    let props = await alertProperties(alert);
    setProperties(props);
  }, []);

  useEffect(function () {
    setIsSelectedAll(properties.every(property => property.selected));
  }, [properties]);

  async function sendEmail() {
    let props = properties.filter(d => d.selected);
    if (!props.length) {
      return showError({message: "Please select at lease one property."});
    }
    setSendingEmail(true);
    let response = await sendPropertyAlertEmail({properties: props, wu_id});
    setSendingEmail(false);
    if (response) {
      closeModal();
    }
  }

  const getImage = (property) => {
    if (property.source === 'TREB') {
      return `url('https://cdn.agentedge.ca/${property.MLS.toUpperCase()}/0.webp'),url('https://cdn.agentedge.ca/${property.MLS.toUpperCase()}/0.jpg')`;
    } else {
      return property.thumbnail;
    }
  };

  return <>
    <Modal isOpen={openModal}
           role="dialog"
           size="xl"
           autoFocus={true}
           centered={true}
           className="exampleModal"
           tabIndex="-1"
           toggle={() => {
             closeModal();

           }}>
      <div className="modal-content">
        <ModalHeader toggle={() => {
          closeModal();

        }}>
          Listing Properties
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12} className="text-right">
              {
                properties.length > 0 &&
                <Button type="submit" color="primary" className="btn-icon btn-sm ml-2"
                        onClick={() => setProperties(properties => properties.map(property => {
                          property.selected = !isSelectedAll;
                          return property;
                        }))}>
                  <span className={isSelectedAll ? " fa fa-times-circle" : " fa fa-check-circle"}/>
                  {
                    isSelectedAll ? " Clear All" : " Select All"
                  }
                </Button>
              }

            </Col>
            {properties.length > 0 ?
              properties.map((property, key) => (
                <Col xl="3" sm="6" key={"_col_" + key}>
                  <Card style={{ cursor: "pointer" }} onClick={() => setProperties(properties => properties.map(p => {
                    if (p.MLS === property.MLS) {
                      p.selected = !p.selected;
                    }
                    return p;
                  }))}>
                    <CardBody>
                      <div className="property-img position-relative">

                        <div className="w-100"
                             style={{height: '200px', background: getImage(property), backgroundPosition: 'center', backgroundSize: 'cover'}}/>
                      </div>
                      <div className="mt-2 ">
                        {property.selected ? (
                            <div className="avatar-sm product-ribbon">
                                <span className="avatar-title rounded-circle  ">
                                  <span className={"fa fa-check fa-2x"}/>
                                </span>
                            </div>
                        ) : null}
                        <h5 className="mb-2">
                          <b>{formatMoney(property.ListPrice, true)}</b>
                        </h5>
                        <p className="font-size-16 font-weight-bold mb-0">
                          {property.Address}{" "}
                        </p>
                        <p className="text-muted mb-2">{capitalize(property.Community)}, {capitalize(property.Municipality)}, {capitalize(property.Area)}</p>
                        <p className="mb-1 "><span className="bx bx-bed"/> {property.Bedrooms} bd. <span
                            className="bx bxs-bath"/> {property.Washrooms} ba.</p>
                        <p className="m-0 p-0 text-muted">Listed <Moment fromNow withTitle
                                                                         titleFormat="LLL">{property.TimestampSql}</Moment>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
              :
              <Col md={12}>
                <p className="mt-2 text-center">No Properties Available.</p>
              </Col>
            }
          </Row>

        </ModalBody>
        <ModalFooter>
          {
            sendingEmail ?
              <div className="spinner-border " role="status">
                <span className="sr-only">Loading...</span>
              </div>
              :
              <>
                <button className="btn btn-dark btn-sm" onClick={() => closeModal()}>Close</button>
                {
                  properties.length > 0 && <button className="btn btn-primary btn-sm" onClick={sendEmail}>Send Email
                    ({properties.reduce((a, b) => b.selected ? a + 1 : a, 0)})
                  </button>
                }
              </>
          }

        </ModalFooter>
      </div>

    </Modal>
  </>;
};

export default AlertProperties;
