import {AvForm} from "availity-reactstrap-validation";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import {WebsiteSettingContext} from "../website-settings.context";

const formWrapper = function (props) {
    const websiteSettings = useContext(WebsiteSettingContext);
    return (
        <AvForm className="needs-validation" onValidSubmit={props.handleSubmit} modal={props.data}>
            {props.fields}
            <Row>
                <Col className="text-center">
                    {
                        websiteSettings.saveBtn
                    }
                </Col>
            </Row>
        </AvForm>
    );
};

formWrapper.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  fields: PropTypes.element
};

export default formWrapper;

