import React from "react";
import {Link} from "react-router-dom";

const PageNotFound = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="text-center mb-5">
              <h1 className="display-2 fw-medium">4<i className="bx bx-buoy bx-spin text-primary display-3"></i>4</h1>
              <h4 className="text-uppercase">Sorry, page not found</h4>
              <div className="mt-5 text-center">
                <Link to="/">
                  <a className="btn btn-primary waves-effect waves-light" >Back to Dashboard</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;
