import React, {useEffect, useState} from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import {get, post, put} from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import toastr from "toastr"
import {useHistory} from "react-router-dom"
import {showError} from "../../helpers/util.helper"
import {useParams} from "react-router-dom"

const AddTestimonial = () => {
  const history = useHistory();
  let {testimonial_id} = useParams();
  const [data, setData] = useState({});
  useEffect(async function () {
    console.log(testimonial_id, 'testimonial id')
    if (testimonial_id) {
      await getTestimonial()
    }
  }, []);

  async function handleValidSubmit(event, values) {
    let d = {...data, ...values};
    await saveTestimonial(d)
  }


  async function getTestimonial() {
    const response = await get(url.TESTIMONIAL + "/" + testimonial_id);
    if (!response.api_status) {
      setData(response)
    } else {
      showError(response)
    }
  }

  async function saveTestimonial(data) {
    let response;
    if (data.testimonial_id) {
      response = await put(url.TESTIMONIAL + '/' + data.testimonial_id, data)
    } else {
      response = await post(url.TESTIMONIAL, data)
    }
    if (!response.api_status) {
      toastr.success("Testimonial updated sucessfully.", "Success");
      history.push("/testimonials")
    } else {
      showError(response)
    }
  }

  function back() {
    history.push("/testimonials")
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Add Testimonial" breadcrumbItem="Testimonial Add"/>
          </div>
          <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>
            <Row>
              <Col xl="6">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Testimonial info</h4>
                    <p className="card-title-desc">Add information regarding your recent testimonial.</p>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Reviewer Name</Label>
                          <AvField
                              name="reviewer_name"
                              value={data.reviewer_name}
                              placeholder="John Doe"
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Reviewer Designation</Label>
                          <AvField
                              name="reviewer_designation"
                              value={data.reviewer_designation}
                              placeholder="Doctor"
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Review</Label>
                          <AvField
                              name="review"
                              value={data.review}
                              placeholder="Lorum Ipsum.."
                              type="textarea"
                              rows="7"
                              className="form-control"
                              id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12 text-center">
                <Card>
                  <CardBody>
                    <Button color="secondary" className="py-2 mr-2" type="submit" onClick={back}>
                      Back
                    </Button>
                    <Button color="primary" className="py-2" type="submit">
                      {
                        data.testimonial_id ?
                            "Update"
                            : "Save"
                      }
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </AvForm>
        </div>
      </React.Fragment>
  )
};

export default AddTestimonial
