import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import {post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {page_limit} from "../../config";
import pageColumns from "../../page.columns";
import {useHistory} from "react-router-dom";
import {TableLoader} from "../../components/Loader";
import TransactionDetailsModal from "./TransactionDetails.modal";

const Transactions = ({match}) => {
    let history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [transactionModal, setTransactionModal] = useState({
        show: false,
        transaction: {},
        onClose: () => setTransactionModal({...transactionModal, show: false, transaction: {}})
    });
    const actions = [{
        name: 'View',
        icon: 'fa fa-eye',
        fn: (transaction) => {
            setTransactionModal({...transactionModal, show: true, transaction});
        }
    }];
    const limit = page_limit;
    const offset = 0;
    const [pagesLoading, setPageLoading] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState({
        columns: pageColumns.transactions,
        rows: []
    });
    const [filters, setFilters] = useState({});
    const [filterFields, setFilterFields] = useState([]);


    useEffect(() => {
        getTransactions({limit, offset});

    }, []);


    useEffect(() => {
        setFilterFields(filterFields.map(fields => {
            fields.value = filters[fields.name];
            return fields;
        }));
        pageChange(1);
    }, [filters]);

    function pageChange(e) {
        setCurrentPage(e);
        getTransactions({limit, offset: (limit * (e - 1))});

    }

    const getTransactions = async ({limit, offset}) => {
        setPageLoading(1);
        const transactions = await post(url.TRANSACTIONS, {
            limit, offset, ...filters
        });
        setPageLoading(0);
        let _d = {
            ...data, rows: transactions.data.map(transaction => {
                if (transaction.payment_status == 1) {
                    transaction.status = <span className="bg-success rounded px-2 text-white">Success</span>
                } else {
                    transaction.status = <span className="bg-danger rounded px-2 text-white">Failed</span>
                }
                return transaction;
            })
        };
        setData(_d);
        setTotalPages(transactions.total_count);
    };





    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Transactions"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {
                                        pagesLoading ?
                                            <TableLoader/>
                                            :
                                            <AETable columns={data.columns}
                                                     rows={data.rows}
                                                     itemsPerPage={limit}
                                                     loading={pagesLoading}
                                                     actions={actions}
                                                     currentPage={currentPage}
                                                     totalCount={totalPages}
                                                     onFilter={setFilters}
                                                     filters={filterFields}
                                                     pageFn={pageChange}/>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <TransactionDetailsModal {...transactionModal}/>
        </React.Fragment>
    );
};

export default Transactions;
