import {Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import React from "react";

const PostPreviewModal = ({image, description, closeModal, showModal, publishPost, posting, page}) => {
    return (
        <Modal
            isOpen={showModal}
            toggle={closeModal}
        >
            <ModalHeader toggle={() => closeModal()}>
                Post Preview
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <div>
                            <div
                                className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom">
                                <div className="d-flex flex-row align-items-center feed-text px-2"><img
                                    className="rounded-circle" src={(page && page.sub_account_image)} width="45"/>
                                    <div className="d-flex flex-column flex-wrap ml-2"><span
                                        className="font-weight-bold">{(page && page.sub_account_name) || '-'}</span><span
                                        className="text-black-50 time">Just Now</span></div>
                                </div>
                                <div className="feed-icon px-2">
                                    <i className="fa fa-ellipsis-v text-black-50"/>
                                </div>
                            </div>
                        </div>
                        <div className="feed-image p-2 tpx-3">
                            <img className="img-fluid img-responsive"
                                 src={image}/>
                        </div>
                        <div className="p-2 px-3">
                                <span>
                                    {description}
                                </span>
                        </div>
                        <div className="d-flex justify-content-end socials p-2 py-3">'
                            <i className="fa fa-thumbs-up"/>
                            <i className="fa fa-comments-o"/>
                            <i className="fa fa-share"/>
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                {
                    posting ? <Spinner/>
                        : <>
                            <button className="btn btn-sm btn-danger" onClick={closeModal}>Cancel</button>
                            <button className="btn btn-sm btn-primary btn-icon" onClick={() => publishPost()}>
                                <span className="mdi mdi-facebook"/>
                                Post Now
                            </button>
                        </>
                }
            </ModalFooter>
        </Modal>
    )
}
export default PostPreviewModal;
