import { Col, FormGroup, Label, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useState } from 'react';
import ComponentWidthInput from './ComponentWidthInput';

const TestimonialSettings = (props) => {
  const [component, setComponent] = useState(props.config.component_id);

  function handleValidSubmit(event, values) {
    props.updateConfig({
      total_testimonial: values.total_testimonial,
      el: props.el,
      component_id: component,
      ...values,
    });
  }

  return (
    <AvForm onValidSubmit={handleValidSubmit}>
      <Row className='py-2'>
        <Col md='12'>
          <FormGroup>
            <Label htmlFor='validationCustom01'>Heading</Label>
            <AvField
              name='heading'
              value={props.config.heading}
              placeholder='Lorum Ipsum'
              type='text'
              className='form-control'
              id='validationCustom04'
            />
          </FormGroup>
        </Col>{' '}
        <Col md='12'>
          <FormGroup>
            <Label htmlFor='validationCustom01'>Sub Heading</Label>
            <AvField
              name='description'
              value={props.config.description}
              placeholder='Lorum Ipsum'
              type='textarea'
              rows='5'
              className='form-control'
              id='validationCustom04'
            />
          </FormGroup>
        </Col>
        <Col md='12'>
          <FormGroup>
            <Label htmlFor='validationCustom01'>Total Items</Label>
            <AvField
              name='total_testimonial'
              value={props.config.total_testimonial}
              placeholder='3'
              type='number'
              className='form-control'
              id='validationCustom03'
            />
          </FormGroup>
        </Col>
        <Col md='12'>
          <ComponentWidthInput {...props.config} />
        </Col>
      </Row>
      <div className='modal-footer'>
        <button
          onClick={() => props.close()}
          type='button'
          className='btn btn-danger waves-effect waves-light'
        >
          Cancel
        </button>
        <button type='submit' className='btn btn-primary waves-effect waves-light'>
          Save changes
        </button>
      </div>
    </AvForm>
  );
};

export default TestimonialSettings;
