import {Col, Modal, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {get} from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import '../builder.scss';

const GallerySelectPhotos = ({closeModal, selectedImages}) => {
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const limit = 2000;
    useEffect(function () {
        pageChange(1);
    }, []);


    function pageChange(e) {
        getPhotos({limit, offset: (limit * (e - 1))});
    }

    const getPhotos = async ({limit, offset}) => {
        setLoading(true);
        const photoData = await get(url.PHOTO_LIST, {
            params: {
                limit, offset
            }
        });
        let selectedIds = selectedImages.map(d => d.photo_id);
        photoData.data?.forEach(photo => {
            photo.selected = selectedIds.includes(photo.photo_id);
        });
        setPhotos([...photos, ...photoData.data]);
        setLoading(false);
    };

    function close() {
        closeModal({})
    }

    function save() {
        closeModal({images: photos.filter(d => d.selected)})
    }

    function selectImage(photo) {
        setPhotos(() => {
            let p = JSON.parse(JSON.stringify((photos))).map(d => {
                if (d.photo_id === photo.photo_id) {
                    d.selected = !d.selected;
                }
                return d;
            });
            return p;
        })
    }

    return <>
        <Modal
            isOpen={true}
            toggle={close}
            size="lg"
        >
            <div>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">Select Photos</h5>
                    <button
                        type="button"
                        onClick={close}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        {
                            !loading ?
                                photos?.map((photo, key) => (
                                    <Col md={4} key={key}>
                                        <img src={photo.photo_path}
                                             className={(photo.selected && 'selected-image ') + ' img-thumbnail img-fluid gallery-image'}
                                             onClick={() => selectImage(photo)}/>
                                    </Col>

                                ))
                                :
                                <p className="text-center">Loading images...</p>
                        }
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={save}
                    >
                        Save changes
                    </button>
                </div>
            </div>
        </Modal>

    </>
};
export default GallerySelectPhotos;
