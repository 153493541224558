import {Col, FormGroup, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import PropTypes from "prop-types";
import ComponentWidthInput from "./ComponentWidthInput";


const InfographicSettings = (props) => {
  const [imageDir, setImageDir] = useState(props.config.imageDir);
  const [image, setImage] = useState(props.config.image);
  const [showButton, setShowButton] = useState(props.config.showButton);

  function handleValidSubmit(event, values) {
    props.updateConfig({
      heading: values.heading,
      description: values.description,
      imageDir: imageDir || values.imageDir || 'left',
      background: values.background,
      image: image || values.image,
      showButton,
      button_title: showButton && values.button_title,
      button_link: showButton && values.button_link,
      infographic: values.infographic,
      el: props.el,
      ...values
    });
  }


  function handleFile(e) {
    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  function deleteImage() {
    setImage(null);
  }

  return (

      <AvForm onValidSubmit={handleValidSubmit}>

        <Row className="py-2">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Heading</Label>
              <AvField
                  name="heading"
                  value={props.config.heading}
                  placeholder="Lorum Ipsum"
                  type="text"
                  className="form-control"
                  id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Description</Label>
              <AvField
                  name="description"
                  value={props.config.description}
                  placeholder="Lorum Ipsum"
                  type="textarea"
                  rows="5"
                  className="form-control"
                  id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <ComponentWidthInput {...props.config}/>
          </Col>
          <Col md="12">
            <FormGroup className="d-flex align-items-center justify-content-between">
              <Label htmlFor="validationCustom01">Show Button</Label>
              <div
                  className="custom-control custom-switch custom-switch-md d-inline-block"
                  dir="ltr"
              >
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id="externalPage"
                    checked={showButton}
                    onChange={(e, v) => {
                      setShowButton(e.target.checked)
                    }}
                />
                <label
                    className="custom-control-label"
                    htmlFor="externalPage"
                />
              </div>
            </FormGroup>
          </Col>
          {
            showButton &&
            <>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="validationCustom01">Button Title</Label>
                  <AvField
                      name="button_title"
                      value={props.config.button_title}
                      placeholder="Submit"
                      type="text"
                      className="form-control"
                      id="validationCustom03"
                      required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="validationCustom01">Button Link</Label>
                  <AvField
                      name="button_link"
                      value={props.config.button_link}
                      placeholder="https://example.com"
                      type="url"
                      className="form-control"
                      id="validationCustom03"
                      required
                  />
                </FormGroup>
              </Col>
            </>
          }

          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01" className="d-flex align-items-center justify-content-between">
                <span>Project Photo</span>
                {
                  image && <span className="fas fa-minus-circle"
                                 onClick={() => {
                                   deleteImage();
                                 }}
                                 style={{color: 'red', cursor: 'pointer'}}/>
                }
              </Label>
              {
                !image && <AvField
                    name="post"
                    type="file"
                    className="form-control"
                    id="validationCustom03"
                    onChange={event =>
                        handleFile(event, "portfolioPhoto")
                    }
                />
              }
            </FormGroup>
          </Col>
          <Col md="12">
            {
              <img src={image}
                   alt="" className="img img-thumbnail"/>
            }
          </Col>

          {
            image && <Col md="12" className="mt-3">
              <FormGroup className="d-flex align-items-center justify-content-between">
                <Label htmlFor="validationCustom01">Image Direction</Label>
                <div
                    className="custom-control custom-switch custom-switch-md d-inline-block"
                    dir="ltr"
                >
                  <input
                      type="checkbox"
                      className="custom-control-input"
                      id="externalPage1"
                      checked={imageDir === 'right'}
                      onChange={(e, v) => {
                        if (e.target.checked) {
                          setImageDir('right');
                        } else {
                          setImageDir('left');
                        }
                      }}
                  />
                  <label
                      className="custom-control-label"
                      htmlFor="externalPage1"
                  >{imageDir === 'right' ? 'Right' : 'Left'}</label>
                </div>
              </FormGroup>
            </Col>
          }
        </Row>
        <div className="modal-footer">
          <button
              onClick={() => props.close()}
              type="button"
              className="btn btn-danger waves-effect waves-light"
          >Cancel</button>
          <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
  );
};

InfographicSettings.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseInfographicSettings: PropTypes.func
};

export default InfographicSettings;
