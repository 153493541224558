import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {map} from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {formatPhone} from "../../helpers/util.helper";
import Select from "react-select";

const AETable = props => {
    let {rows, columns, totalCount = 1, itemsPerPage = 5, pageFn, loading, actions = []} = props;
    const [preAvailable, setPrev] = useState(0);
    const [nextAvailable, setNext] = useState(0);
    let [pagesNumber, setPageNumber] = useState([]);
    let [currentPage, setCurrentPage] = useState(1);
    rows = rows.slice(0, itemsPerPage);
    let totalPages = totalCount / itemsPerPage;
    let allowedPages = totalPages > 10 ? 10 : totalPages;
    pagesNumber = new Array(Math.ceil(totalPages)).fill("x").map((j, i) => i + 1);
    if (totalPages > 10) {
        if (currentPage + 5 < totalPages) {
            pagesNumber = pagesNumber.filter(d => (d < currentPage + (currentPage - 5 > 0 ? 5 : 10 - currentPage)) || typeof d === 'object');
            let nextBtn = {label: '...', value: pagesNumber[pagesNumber.length - 1] + 1};
            pagesNumber = [...pagesNumber, nextBtn]
        }
        if ((currentPage - 5 > 0)) {
            pagesNumber = pagesNumber.filter(d => d > currentPage - 5 || typeof d === 'object');
            pagesNumber = [{label: '...', value: pagesNumber[0] - 1}, ...pagesNumber]
        }
    }

    // setPageNumber([...pagesNumber]);

    function isPrevAvailable() {
        if (currentPage === 1) {
            setPrev(0);
        } else {
            setPrev(1);
        }
    }

    function isNextAvailable() {
        if (currentPage === pagesNumber.reverse()[0]) {
            setNext(0);
        } else {
            setNext(1);
        }
    }


    function pageClicked(page) {
        if (page === "prev") {
            page = currentPage - 1;
        } else if (page === "next") {
            page = currentPage + 1;
        }
        setCurrentPage(page);
        pageFn(page);
    }

    useEffect(() => {
        if (props.currentPage) {
            setCurrentPage(props.currentPage);
        }
        isNextAvailable();
        isPrevAvailable();
    });

    function getFieldValue({row, field}) {
        switch (field.type) {
            case "datetime":
                return row[field.field] && row[field.field] !== "NULL" ? moment(row[field.field]).format("LLL") : "-";
            case "date":
                return row[field.field] && row[field.field] !== "NULL" ? moment(row[field.field]).format("MM-DD-YYYY") : "-";
            case "list":
                return row[field.field] || "-";
            case "phone":
                return row[field.field] ?
                    <a href={"tel:" + formatPhone(row[field.field])}>{formatPhone(row[field.field]) || "-"}</a> : '-';
            default:
                return row[field.field] || "-";
        }

    }

    function titleCase(str) {
        str = str.split(" ");
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(" ");
    }

    function prepareFields(field, key) {
        switch (field.type) {
            case "select":
                return <FormGroup className="col-sm-auto" key={key}>
                    <Label htmlFor={field.name}>{field.label || titleCase(field.name.split("_").join(" "))}</Label>
                    <AvField
                        name={field.name}
                        type="select"
                        className="form-control"
                        id={field.name}
                        placeholder={field.placeholder}
                        value={field.value}
                    >
                        <option value=" "/>
                        {field.options.map((option, key) => <option key={key}
                                                                    value={option.value}>{option.label}</option>)}
                    </AvField>
                </FormGroup>;
            case 'ajax':
                return <FormGroup className="ajax-select col-sm-auto" style={{minWidth: '20%'}}>
                    <Label htmlFor={field.name}>{titleCase(field.name.split("_").join(" "))}</Label>
                    <Select
                        id={field.name}
                        name={field.name}
                        value={field.value}
                        onInputChange={(e) => {
                            field.search({query: e})
                        }}
                        options={field.options}
                        isLoading={field.isLoading}
                        isSearchable={true}
                        classNamePrefix="select2-selection"
                    />
                </FormGroup>;
            default:
                return <FormGroup className="col-sm-auto" key={key}>
                    <Label htmlFor={field.name}>{titleCase(field.name.split("_").join(" "))}</Label>
                    <AvField
                        name={field.name}
                        value={field.value}
                        type={field.type}
                        placeholder={field.placeholder}
                        className="form-control"
                        id={field.name}
                    />
                </FormGroup>;
        }
    }

    let hasBtn = props.extraButtons || props.addLink;

    return (
        <Row>
            <Col sm="12">
                <Row>
                    {
                        props.filters?.length > 0 ?
                            <Col md={hasBtn ? '9' : '12'}>
                                <AvForm className="row gy-2 gx-3 align-items-center" onValidSubmit={(event, values) => {
                                    if (props.onFilter) {
                                        for (let key in values) {
                                            if (values.hasOwnProperty(key) && values[key]) {
                                                values[key] = values[key].trim();
                                            }
                                        }
                                        props.onFilter(values);
                                    }
                                }
                                }>
                                    {
                                        props.filters.map((filter, key) => prepareFields(filter, key))
                                    }
                                    <div className="col-sm-auto form-group mt-2">
                                        <Button color="primary" type="submit"><span className="fa fa-search"/></Button>
                                        {
                                            props.moreFilters &&
                                            <Button
                                                onClick={props.moreFilters}
                                                color="primary" className="ml-2" type="button" title="filter"><span
                                                className="fa fa-filter"/></Button>
                                        } {
                                        props.resetFilter &&
                                        <Button
                                            onClick={props.resetFilter}
                                            color="danger" className="ml-2" type="button" title="Reset"><span
                                            className="fa fa-undo"/></Button>
                                    }
                                    </div>
                                </AvForm>
                            </Col>
                            : ''
                    }
                    {
                        (hasBtn) &&
                        <Col md={props.filters?.length > 0 ? 3 : 12} className="align-self-center text-right">
                            <div className="text-sm-right ">
                                {props.extraButtons}
                                {
                                    props.addLink && <Button
                                        type="button"
                                        color="success"
                                        onClick={props.addLink}
                                        className="form-group btn-sm"
                                    >
                                        <i className="mdi mdi-plus mr-1"/>
                                        {props.t("Add")}
                                    </Button>
                                }


                            </div>
                        </Col>
                    }
                </Row>

            </Col>

            <Col xs="12">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            {
                                props.selectable && <th scope="col" width="20"/>
                            }
                            {
                                map(columns, (col, idx) => (
                                    <th scope="col" width={col.width} key={idx + "ac"}>{props.t(col.label)}</th>
                                ))
                            }
                            {
                                !props.hideAction && <th scope="col" width={props.action_width || "10%"}>Actions</th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !loading ?
                                map(rows, (row, idx) => (
                                    <tr key={idx + "z"}>
                                        {
                                            props.selectable &&
                                            <td style={{verticalAlign: "middle"}}><input type="checkbox"
                                                                                         checked={row.checked}
                                                                                         onChange={(e) => {
                                                                                             props.onSelected({
                                                                                                 row,
                                                                                                 type: e.target.checked ? "push" : "pop"
                                                                                             });
                                                                                         }}/></td>
                                        }
                                        {map(columns, (col, idx2) => (
                                            <td scope="col" key={idx2 + "b"} style={{verticalAlign: "middle"}}>
                       <span>
                         {
                             getFieldValue({field: col, row})
                         }
                       </span>
                                            </td>
                                        ))}
                                        {
                                            !props.hideAction &&
                                            <td scope="col" key={idx + "d"} style={{verticalAlign: "middle"}}>
                      <div className="d-flex align-items-center" style={{height: '40px'}}>
                        {
                            actions
                                .filter(item => item.conditionColumn ? row[item.conditionColumn] : true)
                                .map((action, idy) => {
                                        if (action.type === 'toggle') {
                                            return <ToggleButton onChange={action.fn} name={action.name} id={idx}
                                                                 label={action.title}
                                                                 row={row}
                                                                 key={idx}
                                                                 value={row[action.name]}/>
                                        }
                                        return <Button
                                            key={idy + "e"}
                                            onClick={() => action.fn(row)}
                                            color={action.color ? action.color : "primary"}
                                            className="btn waves-effect waves-light mr-2  btn-xs"
                                            data-toggle="tooltip" data-placement="right" title={action.name}
                                        >
                                            <i className={"font-size-12 align-middle " + action.icon} title={action.title}/>
                                        </Button>
                                    }
                                )

                        }
                      </div>
                                            </td>
                                        }
                                    </tr>
                                )) : "Loading"
                        }
                        </tbody>
                    </table>
                </div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                        <li className={preAvailable ? "page-item" : "page-item disabled"}>
                            <a className="page-link"
                               onClick={() => {
                                   pageClicked("prev");
                               }}
                               tabIndex="-1">{props.t("Previous")}</a>
                        </li>
                        {
                            map(pagesNumber, (num, idx) => (
                                <li className={currentPage == num ? "page-item active" : "page-item"} key={idx + "c"}>
                                    {
                                        typeof num === 'object' ?
                                            <a className="page-link " onClick={() => {
                                                pageClicked(num.value);
                                            }}>
                                                {num.label}
                                            </a>
                                            :
                                            <a className="page-link " onClick={() => {
                                                pageClicked(num);
                                            }}>
                                                {
                                                    currentPage == num ?
                                                        <span className="sr-only">(current)</span>
                                                        : ""
                                                }
                                                {num}
                                            </a>
                                    }
                                </li>
                            ))
                        }
                        <li className={nextAvailable ? "page-item" : "page-item disabled"}>
                            <a className="page-link" onClick={() => {
                                pageClicked("next");
                            }}>{props.t("Next")}</a>
                        </li>
                    </ul>
                </nav>
            </Col>
        </Row>
    );
};

function ToggleButton({onChange, label, value, name, id, row}) {
    return <button className="btn  noti-icon waves-effect " key={'btn' + id}>
        <div
            className="custom-control custom-switch custom-switch-md d-inline-block"
            dir="ltr"
        >
            <input
                type="checkbox"
                className="custom-control-input"
                id={id}
                checked={value}
                onChange={(e) => onChange(row, e.target.checked)}
            />
            <label className="custom-control-label" htmlFor={id}>{label}</label>
        </div>
    </button>
}

AETable.propTypes = {
    columns: PropTypes.array,
    rows: PropTypes.array,
    totalCount: PropTypes.number,
    itemsPerPage: PropTypes.number,
    pageFn: PropTypes.func,
    loading: PropTypes.any,
    actions: PropTypes.array,
    currentPage: PropTypes.number,
    action_width: PropTypes.string,
    hideAction: PropTypes.bool,
    onFilter: PropTypes.func,
    filters: PropTypes.array,
    extraButtons: PropTypes.element
};

export default (withTranslation()(AETable));
