import { call, put, takeEvery } from 'redux-saga/effects';
import { accessTokenLogin } from '../../helpers/main-backend.helper';
import { userAccessTokenLoginFailed, userAccessTokenSuccess } from './actions';
import { USER_ACCESS_TOKEN_LOGIN, USER_ACCESS_TOKEN_LOGIN_FAILED } from './actionTypes';

function* fetchUser() {
  try {
    const response = yield call(accessTokenLogin);
    let website = localStorage.getItem('website');

    if (website) {
      try {
        website = JSON.parse(website);
      } catch (e) {
        website = {};
      }
    }
    response.website = website;
    if (!response.api_status) {
      yield put(userAccessTokenSuccess(response));
    } else {
      yield put(userAccessTokenLoginFailed(response));
    }
  } catch (error) {
    yield put(userAccessTokenLoginFailed(error));
  }
}

function clearUserSession() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refreshToken');
}

function* usersSaga() {
  yield takeEvery(USER_ACCESS_TOKEN_LOGIN, fetchUser);
  yield takeEvery(USER_ACCESS_TOKEN_LOGIN_FAILED, clearUserSession);
}

export default usersSaga;
