import { Col, FormGroup, Label, Modal } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import PropTypes from "prop-types"


const TitleModal = (props) => {

  useState(function() {
    console.log(props.el, "---- else")
  })

  function handleValidSubmit(event, values) {
    props.onCloseTitleModal({ title: values.title, el: props.el })
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseTitleModal({ title: props.title, el: props.el })
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={() => {
              props.onCloseTitleModal({ title: props.title, el: props.el })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">HTML</Label>
              <AvField
                name="title"
                value={props.config.title}
                placeholder="Lorum Ipsum"
                type="textarea"
                rows="20"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

TitleModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseTitleModal: PropTypes.func
}

export default TitleModal
