import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

const SettingsImagePreview = (props) => {
  const style = {
    image: {
      maxWidth: props.width || "300px",
      maxHeight: props.height || "300px"
    }
  };
  const [imgSrc, setImgSrc] = useState(props.image);
  useEffect(function () {
    setImgSrc(props.image)
  }, [props.image]);
  const onError = () => setImgSrc(props.fallback);
  return (
      imgSrc ? <div className="position-relative">
            <img src={imgSrc} alt="" className="img img-thumbnail" style={style.image}
                 onError={onError}/>
            {
              props.removeImage &&
              <span className="position-absolute fa fa-trash text-danger text-center"
                    style={{top: '5px', right: '5px', cursor: 'pointer'}}
                    onClick={() => props.removeImage()}/>
            }
          </div>
          : <></>
  );
};
SettingsImagePreview.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  removeImage: PropTypes.func
};
export default SettingsImagePreview;
