import { call, put, takeEvery } from "redux-saga/effects"
// Crypto Redux States
import { GET_WEBPAGE_DETAIL, GET_WEBPAGES } from "./actionTypes"
import { getWebPagesFail, getWebPagesSuccess, webPageLoading } from "./actions"
import { webPageList } from "../../helpers/main-backend.helper"

//Include Both Helper File with needed methods

function* fetchWebPages({ limit, offset }) {
  try {
    console.log(limit, offset, "limit offset")
    yield put(webPageLoading())
    const response = yield call(webPageList, { limit, offset })
    yield put(getWebPagesSuccess(response))
  } catch (error) {
    yield put(getWebPagesFail(error))
  }
}

function* fetchWebPageDetail({ invoiceId }) {
  try {
    const response = yield call(getWebPageDetail, invoiceId)
    yield put(getWebPageDE(response))
  } catch (error) {
    yield put(getWebPageDetailFail(error))
  }
}

function* webPagesSaga() {
  yield takeEvery(GET_WEBPAGES, fetchWebPages)
  yield takeEvery(GET_WEBPAGE_DETAIL, fetchWebPageDetail)
}

export default webPagesSaga
