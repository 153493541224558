import React from 'react';
import { List } from 'react-content-loader';

const FormLoader = () => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-12 col-md-8 col-lg-6'>
            <List />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormLoader;
