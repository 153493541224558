import {Col, FormGroup, Label, Modal} from "reactstrap"
import {AvField, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation"
import React from "react"
import PropTypes from "prop-types"


const VisitorToolsEditModal = (props) => {
    return (
        <Modal
            isOpen={true}
            toggle={() => {
                props.onClose(props.data)
            }}
        >
            <AvForm onValidSubmit={(event, values) => props.onClose(values)}>
                <div className="modal-header">
                    <h5 className="modal-spacer mt-0" id="myModalLabel">Nav Link</h5>
                    <button
                        type="button"
                        onClick={() => {
                            props.onClose(props.data)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Col md="12">
                        <FormGroup>
                            <Label htmlFor="title">Title</Label>
                            <AvField
                                name="title"
                                value={props.data.title}
                                placeholder="Lorum Ipsum"
                                type="text"
                                className="form-control"
                                id="title"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <Label htmlFor="link">Link</Label>
                            <AvField
                                name="path"
                                value={props.data.path}
                                placeholder="https://example.com"
                                type="text"
                                className="form-control"
                                id="link"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12 d-flex align-items-center" style={{gap: '10px'}}>
                        <AvGroup check>
                            <Label check>
                                <AvInput type="checkbox" name="newTab"
                                         value={props.data.newTab}
                                         trueValue={true} falseValue={false} /> Open in new tab?
                            </Label>
                        </AvGroup>
                    </Col>
                </div>
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                    >Update
                    </button>
                </div>
            </AvForm>
        </Modal>
    )
};

VisitorToolsEditModal.propTypes = {
    onClose: PropTypes.func
};

export default VisitorToolsEditModal
