import FacebookMarketingLayout from "./FacebookMarketingLayout";
import React, {useEffect, useState} from "react";
import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {showError} from "../../helpers/util.helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card, CardBody, Col, Row} from "reactstrap";
import {TableLoader} from "../../components/Loader";
import AETable from "../../components/Common/Table";
import NoDataAvailable from "../../components/NoDataAvailable";
import pageColumns from "../../page.columns";
import {page_limit} from "../../config";
import {useHistory} from "react-router-dom";

const FacebookLeadForms = (props) => {
  const [forms, setForms] = useState({
    columns: pageColumns.leadForm,
    rows: [],
    page: 1
  });
  const [totalPages, setTotalPages] = useState(0);
  const history = useHistory();
  const limit = page_limit;
  const offset = 0;
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(async function () {
    await getLeadForms();

  }, []);
  const actions = [
    {
      name: "Leads",
      icon: "fas fa-eye",
      fn: viewLeads
    }];

  async function getLeadForms() {
    setPageLoading(true);
    const response = await get(url.LEAD_FORMS);
    setPageLoading(false);
    if ( !response.api_status) {
      setTotalPages(response.data.length);
      response.data.forEach(form => {
        form.page = form.page.name;
      })
      setForms({ ...forms, rows: response.data });
    } else {
      showError(response?.message);
    }
  }

  function viewLeads(page) {
    history.push('/fb-marketing/leads/' + page.id);
  }

  function pageChange(e) {
    setForms({ ...forms, page: e });
  }

  function getList() {
    return forms.rows.slice((limit * (forms.page - 1)));
  }

  return (
    <>
      <FacebookMarketingLayout>
        <Breadcrumbs title="Lead Forms" breadcrumbItem="Lead Forms"/>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {
                  pageLoading ?
                    <TableLoader/>
                    :
                    forms.rows.length
                      ? <AETable columns={forms.columns}
                                 rows={getList()}
                                 itemsPerPage={limit}
                                 loading={pageLoading}
                                 actions={actions}
                                 totalCount={totalPages}
                                 pageFn={pageChange}/>
                      :
                      <NoDataAvailable module="Lead Forms"/>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </FacebookMarketingLayout>
    </>
  );
};

export default FacebookLeadForms;
