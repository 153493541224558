import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import PropTypes from "prop-types";


const StepperSettings = (props) => {
  let [rows, setRows] = useState(props.config.rows);

  function handleValidSubmit(event, values) {
    let rowsGroup = [];
    values.title.forEach((item, index) => {
      rowsGroup.push({title: values.title[index], description: values.description[index]});
    });
    props.updateConfig({rows: rowsGroup, stepper_title: values.stepper_title});
  }

  function addNewRow() {
    let r = {title: "", description: ""};
    setRows([...rows, r]);
  }

  function deleteRow(id) {
    let r = rows.filter((d, i) => i !== id);
    setRows(r);
  }

  return (

      <AvForm onValidSubmit={handleValidSubmit}>
        <Row className="py-2">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Title</Label>
              <AvField
                  name="stepper_title"
                  value={props.config.stepper_title}
                  placeholder="Lorum Ipsum"
                  type="text"
                  className="form-control"
                  id="validationCustom03"
              />
            </FormGroup>
            {
              rows.map((row, idx) => {
                return (
                    <>
                      <p key={idx + "para"} className="d-flex align-items-center justify-content-between">
                        <b>Step {idx + 1}</b> <span className="fas fa-minus-circle"
                                                    onClick={() => {
                                                      deleteRow(idx);
                                                    }}
                                                    style={{color: 'red', cursor: 'pointer'}}/></p>
                      <FormGroup key={idx + "title"}>
                        <Label htmlFor="validationCustom01">Heading</Label>
                        <AvField
                            name={"title." + idx}
                            value={row.title}
                            placeholder="Lorum Ipsum"
                            type="text"
                            rows="20"
                            className="form-control"
                            id="validationCustom03"
                        />
                      </FormGroup>
                      <FormGroup key={idx + "description"}>
                        <Label htmlFor="validationCustom01">Description</Label>
                        <AvField
                            name={"description." + idx}
                            value={row.description}
                            onChange={() => {
                              console.log("updating item", idx);
                            }
                            }
                            placeholder="Lorum Ipsum"
                            type="textarea"
                            rows="3"
                            className="form-control"
                            id="validationCustom03"
                        />
                      </FormGroup>
                    </>);
              })
            }
            <Button
                onClick={() => {
                  addNewRow();
                }}
                color="success"
                className="mt-1">
              Add Step
            </Button>
          </Col>
        </Row>
        <div className="modal-footer">
          <button
              onClick={() => props.close()}
              type="button"
              className="btn btn-danger waves-effect waves-light"
          >Cancel</button>
          <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
  );
};

StepperSettings.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseStepperModal: PropTypes.func
};

export default StepperSettings;
