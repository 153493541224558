import {Card, CardBody, Col, FormGroup, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import FormWrapper from "../util/form-wrapper";
import {WebsiteSettingContext} from "../website-settings.context";

const WebsiteOtherSettings = (props) => {
    const websiteSettings = useContext(WebsiteSettingContext);


    function save(e, values) {
        props.handleSubmit(e, {
            ...values,
        })
    }

    return (
        <Card>
            <CardBody>
                <h4 className="card-title">Settings</h4>
                <FormWrapper
                    handleSubmit={save}
                    data={props.data}
                    savePageButton={websiteSettings.saveBtn}
                    fields={
                        <>
                            <Row>
                                <Col md="12" className="d-flex align-items-center" style={{gap: '1rem'}}>

                                    <FormGroup className={"d-flex flex-row align-items-center"} style={{gap: '10px'}}>

                                        <AvField
                                            value={props.data.disableEmailNotifications}
                                            name="disableEmailNotifications"
                                            className={'p-0'}
                                            type="checkbox"
                                            label={"Stop Email Notifications"}
                                            id={'disableEmailNotifications'}
                                            trueValue={1}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </>
                    }
                />
            </CardBody>
        </Card>
    );
};

WebsiteOtherSettings.propTypes = {
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    handleFile: PropTypes.func,
    savePageButton: PropTypes.element,
    isAdmin: PropTypes.bool
};

export default WebsiteOtherSettings;
