import {GMB_CONNECT} from "./helpers/url_helper";
import {post} from "./helpers/api_helper";
import {showError} from "./helpers/util.helper";

let GoogleAuth; // Google Auth object.
export const init = () => {
  function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }

  setTimeout(function () {
    $(document).ready(function () {
      try {
        gapi.load("auth2", function () {
          GoogleAuth = gapi.auth2.init({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope: "profile email"
          });
        });
      } catch (e) {
        console.log('gapi error', e)
      }
    });
  }, 1000);
  return AddLibrary('https://apis.google.com/js/platform.js?onload=onLoadCallback')
};

export const signInUser = async function () {
  try {
    const user = await GoogleAuth.signIn({
      scope: "https://www.googleapis.com/auth/business.manage"
    });
    let u = user.getAuthResponse();
    const {code} = await user.grantOfflineAccess();
    const response = await post(GMB_CONNECT, {
      code,
      redirect_url: window.location.origin
    });
    if (!response.api_status && response.locations && response.locations.length) {
      return response.locations;
    } else {
      showError(response);
      return false;
    }
  } catch (e) {
    return false;
  }
};
