import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import AddUserForm from "./addUserForm";
import { get, post, put } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import toastr from "toastr";
import { showError } from "../../../helpers/util.helper";
import { useHistory } from "react-router-dom";

const AddUser = function(props) {
  const [data, setData] = useState({});
  const [websites, setWebsites] = useState([]);
  const [selectedWebsites, setSelectedWebsites] = useState([]);

  const history = useHistory();
  const { match: { params } } = props;


  function handleSubmit(event, values) {
    let d = { ...data, ...values };
    d.websites = selectedWebsites.map(website => website.value);
    addUser(d);
  }

  async function addUser(data) {
    let response;
    if (data.user_id) {
      response = await put(url.USERS + "/" + data.user_id, data);
    } else {
      response = await post(url.USERS, data);
    }
    if (!response.api_status) {
      toastr.success("User updated successfully.", "Success");
      history.push("/users");
    } else {
      showError(response);
    }
  }

  async function getWebsites() {
    const websites = await get(url.WEBSITES, { params: { limit: 1000 } });
    if (websites && websites.data && websites.data.length) {
      const optionGroup = [
        {
          label: "Websites",
          options: []
        }
      ];
      if (websites && websites.data && websites.data.length) {
        websites.data.forEach(website => {
          optionGroup[0].options.push({
            label: website.website_name,
            value: website.website_id
          });
        });
      }
      if (params.user_id) {
        let userData = await getUser({ websites: optionGroup });
        userData.user && setData(userData.user);
        userData.websites && setSelectedWebsites(userData.websites);
      }
      setWebsites(optionGroup);
    }
  }

  async function getUser({ websites }) {
    const response = await get(url.USERS + "/" + params.user_id);
    let output = {};
    if (!response.api_status) {
      if (response.websites && response.websites.length) {
        output.websites = websites[0].options.filter(website => response.websites.includes(website.value));
      }
      output.user = response;
    } else {
      showError(response);
    }
    return output;
  }

  useEffect(async function() {
    await getWebsites();
  }, []);
  return (
    <>
      <div className="page-content" style={{height: '100vh'}}>
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <Breadcrumbs title="Add User" breadcrumbItem="User Add"/>
              <AddUserForm data={data} handleSubmit={handleSubmit}
                           selectedWebsites={selectedWebsites}
                           setSelectedWebsites={setSelectedWebsites}
                           websites_list={websites}
                           back={() => {
                             history.push("/users");
                           }}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
