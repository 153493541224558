import React from "react";

const SpanCheck = ({ type = "success" }) => {
  const classType = type === "success" ? "fa-check-circle text-success" : "fa-times-circle text-danger";
  return (<>
    <span className={"fa  " + classType}/>
  </>);
};

export default SpanCheck;
