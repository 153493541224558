import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useEffect, useState} from "react";
import {get, post, put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import {ComponentsType, showError} from "../../helpers/util.helper";
import {useHistory} from "react-router-dom";

import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import Select from 'react-select';

const EditComponent = ({match}) => {

  const [data, setData] = useState({});
  const [code, setCode] = useState('Add your code here....');
  const [css, setCss] = useState('Add your css here....');
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [usage, setUsage] = useState({count: 0, modified: 0});

  let history = useHistory();
  useEffect(async function () {
    await getThemes();
    await getComponentUsage();
    if (match && match.params && match.params.tc_id) {
      await getComponent({tc_id: match.params.tc_id});
    }
  }, []);

  async function handleValidSubmit(e, values) {
    if (!data.component_image) {
      return showError({message: 'Please add component image.'});
    }
    let component = {
      ...data,
      ...values,
      component_content: code,
      component_css: css,
      themes: selectedTheme.map(d => d.value)
    };
    let response;
    if (component.tc_id) {
      response = await put(url.COMPONENT_LIST + "/" + data.tc_id, component);
      toastr.success("Component updated successfully.", "Success");
    } else {
      response = await post(url.COMPONENT_LIST, component);
      toastr.success("Component added successfully.", "Success");
    }
    if (!response.api_status) {
      back();
    } else {
      showError(response);
    }
  }

  function back() {
    // history.push("/components");
    if (match.params.tc_id) {
      getComponent({tc_id: match.params.tc_id});
    } else {
      history.push("/components");
    }
  }

  function handleFile(e, key) {
    const reader = new FileReader();
    reader.onload = function (e) {
      setData({...data, [key]: e.target.result});
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  async function getComponent({tc_id}) {
    const response = await get(url.COMPONENT_LIST + "/" + match.params.tc_id);
    if (!response.api_status) {
      if (response.component_content) {
        setCode(response.component_content);
      } else {
        setCode('');
      }
      if (response.component_css) {
        setCss(response.component_css);
      } else {
        setCss('');
      }
      let theme_ids = response.theme_id.split(',').map(d => +d);
      setThemes(themes => {
        let d = themes.filter(theme => theme_ids.includes(+theme.theme_id)).map(d => ({
          label: d.theme_name,
          value: d.theme_id
        }));
        setSelectedTheme(n => {
          return d;
        });
        return themes;
      });
      setData(response);
    } else {
      showError(response);
    }
  }

  async function getThemes() {
    const response = await get(url.THEME);
    if (!response.api_status) {
      setThemes(response);
    } else {
      showError(response);
    }
  }

  const handleChange = (selectedOption) => {
    setSelectedTheme(selectedOption);
  };

  async function getComponentUsage() {
    const response = await get(url.COMPONENT_LIST + '/' + match.params.tc_id + '/usage');
    if (!response.api_status) {
      setUsage(response);
    }
  }

  async function updatePages() {
    const response = await post(url.COMPONENT_LIST + '/' + match.params.tc_id + '/usage', {});
    if (!response.api_status) {
      setUsage(response);
      toastr.success(response.message);
    }
    await getComponent({tc_id: match.params.tc_id});
    await getComponentUsage({tc_id: match.params.tc_id});
  }

  return (
      <React.Fragment>
        <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>

          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs breadcrumbItem={match.params.tc_id ? 'Update Component' : 'Add Component'}/>
            </div>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <h4 className="card-title">Component info </h4>
                        <p className="card-title-desc">Add information regarding your new component.</p>
                      </Col>
                      <Col md="6" sm={12}>
                        <Row>
                          <Col md={12}>
                            <FormGroup>

                              <Label htmlFor="validationCustom01">Template</Label>
                              <Select value={selectedTheme}
                                      onChange={setSelectedTheme}
                                      isMulti={true}
                                      options={themes.map(d => ({label: d.theme_name, value: d.theme_id}))}/>
                            </FormGroup>

                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <Label htmlFor="validationCustom04">Component Name</Label>
                              <AvField
                                  name="component_name"
                                  value={data.component_name}
                                  type="text"
                                  required={true}
                                  className="form-control"
                                  id="validationCustom04"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="validationCustom01">Component Group</Label>
                              <AvField
                                  name="component_type"
                                  value={data.component_type}
                                  type="select"
                                  className="form-control"
                                  id="validationCustom03"
                                  required={true}
                              >
                                <option value=""></option>
                                {
                                  ComponentsType.sort((a, b) => a.name.localeCompare(b.name)).map((component) =>
                                      <option key={component.key}
                                              value={component.key}>{component.name}</option>)
                                }
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <div className="d-flex flex-column">
                              <Label htmlFor="validationCustom01">Code</Label>
                              <div className="bg-light">
                                <Editor
                                    value={code}
                                    onValueChange={c => setCode(c)}
                                    highlight={code => highlight(code, languages.js, 'handlebars')}
                                    padding={10}
                                    style={{
                                      fontFamily: '"Fira code", "Fira Mono", monospace',
                                      fontSize: 12,
                                    }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="d-flex flex-column">
                              <Label htmlFor="validationCustom01">Theme CSS</Label>
                              <div className="bg-light">
                                <Editor
                                    value={css}
                                    onValueChange={c => setCss(c)}
                                    highlight={code => highlight(code, languages.js, 'css')}
                                    padding={10}
                                    style={{
                                      fontFamily: '"Fira code", "Fira Mono", monospace',
                                      fontSize: 12,
                                    }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md="12" className="mt-3">
                            <FormGroup>
                              <Label htmlFor="validationCustom01">Component Image</Label>
                              <AvField
                                  name="post"
                                  type="file"
                                  className="form-control"
                                  id="validationCustom03"
                                  onChange={event =>
                                      handleFile(event, "component_image")
                                  }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            {
                              <img src={data.component_image}
                                   alt="" className="img img-thumbnail"/>
                            }
                          </Col>
                        </Row>
                      </Col>
                      {
                        match.params.tc_id && <Col md={6} sm={12}>
                          <h6>Usage Stats</h6>
                          <table className="table table-striped">
                            <thead>
                            <tr>
                              <td>Pages</td>
                              <td>Components</td>
                              <td>Modified</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>{usage.pages} Page(s)</td>
                              <td>{usage.count} Component(s)</td>
                              <td>{usage.modified > 0 ? usage.modified : '-'} </td>
                            </tr>
                            </tbody>
                          </table>
                          {
                            data.pagesUpdateRequire > 0 &&
                            <>
                              <p className="text-muted">Update Component in all pages.</p>
                              <button className="btn btn-primary" type="button" onClick={updatePages}>Update</button>
                            </>
                          }
                        </Col>
                      }
                    </Row>
                    <Row>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12 text-center">
                <Card>
                  <CardBody>
                    <Button color="secondary" className="py-2 mr-2" type="button"
                            onClick={() => history.push("/components")}>
                      Back
                    </Button>
                    <button type={"submit"} id="draftBtn" className="d-none"/>

                    <Button color="primary" className="py-2" type="submit">
                      {
                        data.post_id ?
                            "Update"
                            : "Save"
                      }
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </AvForm>

      </React.Fragment>
  );
};

export default EditComponent;
