import { Link } from "react-router-dom";
import Moment from "react-moment";
import React from "react";
import { maxLength } from "../../helpers/util.helper";

const DashboardTask = ({ task }) => {
  return <div className="align-self-center overflow-hidden me-auto mt-3">
    <div>
      <h5 className="font-size-14 ">
        <Link to={"/leads/" + task.wu_id + "/profile?tab=3"}>
          <div className="row">
            <span className="text-dark col-8">
            {maxLength(task.task_title, 50)}
          </span>
            <div className="col-4">
              <span className="badge badge-primary  ">
                  {
                    task.isToday ? <span>Today <Moment format={"hh mm A"}>{task.task_due_date}</Moment></span> :
                      <Moment format="MMM DD hh:mm A">{task.task_due_date}</Moment>
                  }
              </span>
            </div>
          </div>
        </Link>
      </h5>
      <p className="text-muted mb-1">
        <Link to={"/leads/" + task.wu_id + "/profile?tab=3"}>
          {task.name}
        </Link>
      </p>

    </div>
  </div>;
};

export default DashboardTask;
