import React, {useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {map} from "lodash";
import LeadEmailDetails from "../Emails/lead-email-details";
import {capitalize, formatMoney} from "../../../helpers/util.helper";
import {deleteAlert} from "../leads.service";
import SpanCheck from "../../../components/Common/SpanCheck";
import AlertDetails from "./alert-details";
import AlertProperties from "./alert-properties";
import Moment from "react-moment";

const LeadAlertsList = ({ alerts, setModal, modal, getAlerts, setDeleteModal, wu_id }) => {
  const [alertDetails, setAlertDetails] = useState({
    openModal: false, closeModal: () => {
      setAlertDetails(modal => {
        return { ...modal, openModal: false };
      });
    }
  });
  const [alertPropertiesModal, setAlertPropertiesModal] = useState({
    openModal: false, closeModal: () => {
      getAlerts();
      setAlertPropertiesModal(modal => {
        return { ...modal, openModal: false };
      });
    }
  });

  async function deleteAlert$(alert_id) {
    await deleteAlert({ la_id: alert_id, wu_id });
    getAlerts();
  }

  function getLocationData(location) {
    try {
      if (location.startsWith("[")) {
        let locations = JSON.parse(location);
        return <>
          <ol className="list-group list-group-flush">
            {
              locations.map(location => {
                if (location.Community) {
                  location.Community = capitalize(location.Community)
                }
                if (location.Area) {
                  location.Area = capitalize(location.Area)
                }
                if (location.Municipality) {
                  location.Municipality = capitalize(location.Municipality)
                }
                if (location.Province) {
                  location.Province = capitalize(location.Province)
                }
                if (location.Community && location.Community !== "All") {
                  return location.Community + ", " + location.Municipality + ", " + (location.Area || "");
                } else if (location.Municipality && location.Municipality !== "All") {
                  return location.Municipality + ", " + (location.Area || "");
                } else {
                  return location.Area;
                }
              }).map((d, id) => <li key={id} className="list-group-item pl-0" title={d}>{d}</li>)
            }
          </ol>
        </>;
      } else {
        return <ol className="list-group list-group-flush">
          <li title={location} className="list-group-item pl-0">{location}</li>
        </ol>;
      }
    } catch (e) {
      return location;
    }
  }

  function openDetails(alert) {
    alert.location = getLocationData(alert.location);
    try {
      if (alert.property_type && alert.property_type.startsWith("[")) {
        alert.property_type = JSON.parse(alert.property_type);
      }
      if (typeof alert.property_type === "string") {
        alert.property_type = alert.property_type.split(",");
      }
      alert.property_type = <ul className="list-group list-group-flush">{
        alert.property_type.map((d, k) =>
            <li className="list-group-item pl-0" key={k}>{d}</li>
        )
      }</ul>;
    } catch (e) {
      console.log(e, alert);
    }

    setAlertDetails(modal => {
      return { ...modal, openModal: true, data: alert };
    });
  }

  function openAlertProperties(alert) {
    setAlertPropertiesModal(modal => {
      return { ...modal, openModal: true, alert, wu_id };
    });
  }

  return (
    <Card>
      <CardBody>
        <Row>

          {
            alerts && alerts.length ?
              <div className="table-responsive" id="tasks-list">
                <table className="table  table-centered mb-0">
                  <thead>
                  <tr>
                    <td>Address</td>
                    <td>Beds</td>
                    <td>Baths</td>
                    <td>Price</td>
                    <td>Year Built</td>
                    <td>Last Alert</td>
                    <td>Frequency</td>
                    <td>Verified</td>
                    <td className="text-center">Action</td>
                  </tr>
                  </thead>
                  <tbody>
                  {map(alerts, (item, i) => (
                    <tr key={i}>

                      <td>
                        <div className=" font-size-14 m-0" style={{ "width": "200px" }}>
                          {getLocationData(item.location)}
                        </div>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0" style={{ 'maxWidth': '100px' }}>
                          {(item.beds && item.beds + '+') || '-'}
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0" style={{ 'maxWidth': '100px' }}>
                          {(item.baths && item.baths + '+') || '-'}
                        </h5>
                      </td>

                      <td>
                        <h5 className="text-break font-size-14 m-0">
                          {item.min_price$} {item.max_price && +item.max_price > 0 ? "-" + item.max_price$ : " - Max"}
                        </h5>
                      </td>

                      <td>
                        <h5 className="text-truncate font-size-14 m-0" style={{ "maxWidth": "50px" }}>
                          {(item.year_built && item.year_built + "+") || "-"}
                        </h5>
                      </td>
                      <td>
                        <h5 className=" font-size-12 m-0" style={{ "maxWidth": "50px" }}>
                          {(item.last_alert_time && <Moment fromNow  withTitle titleFormat="LLL">{item.last_alert_time}</Moment>) || "-"}
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0" style={{ "maxWidth": "100px" }}>
                          {item.frequency$}
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0" style={{ "maxWidth": "100px" }}>
                          <SpanCheck type={item.emailVerified === 1 ? "success" : "danger"}/>
                        </h5>
                      </td>
                      <td>
                       <div className="d-flex">
                          <span
                              style={{ cursor: "pointer" }}
                              onClick={() => openAlertProperties(item)}
                              className="fa fa-bell text-primary " title="Alerts"/>
                         <span
                             style={{ cursor: "pointer" }}
                             onClick={() => openDetails(item)}
                             className="fa fa-eye text-primary ml-2" title="View Details"/>
                         <span
                             style={{ cursor: "pointer" }}
                             onClick={() => setModal({ showModal: true, alert: true, data: item })}
                             className="fa fa-pencil-alt text-primary ml-2" title="Edit"/>
                         <span className="fa fa-trash text-danger ml-2"
                               onClick={() => {
                                 setDeleteModal({ show: true, id: item.la_id, fn: deleteAlert$ });
                               }}
                               style={{ cursor: "pointer" }} title="Delete"/>
                       </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>

              :
              <Col md={12} className="text-center">
                <h5>No alerts added</h5>
                <button className="btn btn-primary mt-3" onClick={() => setModal({ showModal: true, alert: true })}>Add
                  Alert
                </button>
              </Col>
          }
        </Row>
      </CardBody>
      {modal.open && <LeadEmailDetails
        openModal={modal.open}
        closeModal={() => {
          setModal({ open: false });
        }
        }
        email={modal.email}
      />}
      {alertDetails.openModal && <AlertDetails {...alertDetails} />}
      {alertPropertiesModal.openModal && <AlertProperties {...alertPropertiesModal} />}
    </Card>
  );
};

export default LeadAlertsList;
