import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import { del, get } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { page_limit } from "../../config";
import pageColumns from "../../page.columns";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import { showError } from "../../helpers/util.helper";
import toastr from "toastr";
import NoDataAvailable from "../../components/NoDataAvailable";
import { TableLoader } from "../../components/Loader";

const AwardsList = ({ match }) => {
    let history = useHistory();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingAwardId, setDeletingAwardId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        getAward({ limit, offset });
    }, []);

    const actions = [
        {
            name: "Edit",
            icon: "fas fa-pencil-alt",
            fn: editAward
        }, {
            name: "Delete",
            icon: "fas fa-trash",
            color: "danger",
            fn: openDeleteModal
        }];
    const limit = page_limit;
    const offset = 0;
    const [pagesLoading, setPageLoading] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState({
        columns: pageColumns.awards,
        rows: []
    });

    function pageChange(e) {
        setCurrentPage(e);
        getAward({ limit, offset: (limit * (e - 1)) });
    }

    const getAward = async ({ limit, offset }) => {
        setPageLoading(1);
        const awardData = await get(url.AWARD_LIST, {
            params: {
                limit, offset
            }
        });
        setPageLoading(0);

        if(awardData.api_status) {
            return toastr.error('Something went wrong.');
        }
        awardData.data.forEach(award => {
            award.award_image = <img src={award.award_image} style={{width: '75px', height: '75px'}}/>;
        });
        let _d = { ...data, rows: awardData.data };
        setData(_d);
        setTotalPages(awardData.total_count);
    };

    function editAward({ award_id }) {
        history.push("/editAward/" + award_id);
    }

    function addAward() {
        history.push("/addAward");
    }

    async function deleteAward() {
        const response = await del(url.AWARD_LIST + "/" + deletingAwardId);
        if (!response.api_status) {
            let _d = data.rows.filter(d => d.award_id !== deletingAwardId);
            setData({ ...data, rows: _d });
            setDeletingAwardId(null);
            setDeleteModal(false);
            toastr.success("Award deleted successfully.", "Success");
        } else {
            showError(response);
        }
    }

    function openDeleteModal({ award_id }) {
        setDeletingAwardId(award_id);
        setDeleteModal(true);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Award List"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {
                                        pagesLoading ?
                                            <TableLoader/>
                                            :
                                            <AETable columns={data.columns}
                                                     rows={data.rows}
                                                     itemsPerPage={limit}
                                                     loading={pagesLoading}
                                                     addLink={addAward}
                                                     actions={actions}
                                                     currentPage={currentPage}
                                                     totalCount={totalPages}
                                                     pageFn={pageChange}/>
                                    }

                                </CardBody>
                                <DeleteModal
                                    onCloseClick={() => {
                                        setDeletingAwardId(null);
                                        setDeleteModal(false);
                                    }}
                                    onDeleteClick={deleteAward}
                                    show={deleteModal}>
                                </DeleteModal>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AwardsList;
