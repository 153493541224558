import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React from "react";
import PropType from "prop-types";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const LeadEmailDetails = (props) => {


  return <>
    <Modal
      isOpen={props.openModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        props.closeModal();

      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            props.closeModal();

          }}
        >
          Details
        </ModalHeader>
        <ModalBody>
          <h5>
            <Link to="blog-details" className="text-dark">{props.email.title}</Link>
          </h5>
          <p className="text-muted">
            {props.email.time && <p>Scheduled At: <Moment format='MMM, DD, YYYY hh:mm A'>{props.email.time}</Moment></p>}
          </p>
          <p dangerouslySetInnerHTML={{ __html: props.email.description }}/>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              props.closeModal();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  </>;
};
LeadEmailDetails.propTypes = {
  closeModal: PropType.func,
  email: PropType.object
};
export default LeadEmailDetails;
