import { Col, FormGroup, Label, Modal } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import PropTypes from "prop-types"
import Testimonial1 from "../../../assets/images/ae/components/testimonial-1.png";
import Testimonial2 from "../../../assets/images/ae/components/testimonial-2.png";
import SettingsImagePreview from "../../Settings/util/image-preview";



const TestimonialModal = (props) => {

  let components = [
    {id: '1', preview: Testimonial1},
    {id: '2', preview: Testimonial2},
  ];
  const [component, setComponent] = useState(props.config.component_id);

  function handleValidSubmit(event, values) {
    props.onCloseTestimonialModal({ total_testimonial: values.total_testimonial, el: props.el, component_id: component })
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseTestimonialModal({ total_testimonial: props.total_testimonial, el: props.el })
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={() => {
              props.onCloseTestimonialModal({ total_testimonial: props.total_testimonial, el: props.el })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Total Testimonials</Label>
              <AvField
                name="total_testimonial"
                value={props.config.total_testimonial}
                placeholder="3"
                type="number"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <Label>Select Component </Label>
          </Col>
          {
            components.map((background, key) => (
                <Col md="12" key={key}>
                  <div className="form-check mb-3">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="background"
                        id={"background+" + key}
                        onChange={() => {
                          setComponent(background.id)
                        }}
                        checked={component === background.id}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={"background+" + key}
                    >
                      <SettingsImagePreview image={background.preview} width={'100%'}/>
                    </label>
                  </div>

                </Col>
            ))
          }
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

TestimonialModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseTestimonialModal: PropTypes.func
}

export default TestimonialModal
