import PropTypes from "prop-types"
import React from "react"
import Moment from "react-moment"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import "./transactions.scss"

const TransactionDetailsModal = ({ show, onClose, transaction }) => {
  if (!(transaction && transaction.meta)) {
    return <></>
  }
  function formatStripeMoney(money) {
    return (money / 100).toFixed(2)
  }
  return (
    <Modal isOpen={show} toggle={onClose} centered={true}>
      <ModalBody className="py-3 px-5 transaction-details">
        <h5>Transaction Details (#{transaction.transaction_id})</h5>
        {transaction.meta.error && (
          <Row
            className="border py-3 mb-3"
            style={{ backgroundColor: "#ff000020" }}
          >
            <Col lg={12}>
              <h6>Error</h6>
              {transaction.meta.error.sqlMessage ||
                transaction.meta.error.message ||
                (transaction.meta.error.raw &&
                  transaction.meta.error.raw.message)}
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={12}>
            <div className=" table-responsive w-100">
              <div className="table w-100 d-print-block d-print-table  ">
                <tbody className="w-100">
                  <tr>
                    <td>Payment Total</td>
                    <td>
                      CAD {transaction.payment_amount} <br /> (
                      {formatStripeMoney(
                        transaction.meta.subtotal_excluding_tax
                      )}{" "}
                      + {formatStripeMoney(transaction.meta.tax)} tax)
                    </td>
                  </tr>

                  {transaction.meta.coupon_discount > 0 && (
                    <tr>
                      <td>Coupon Discount</td>
                      <td>CAD {transaction.meta.coupon_discount}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Invoice Id</td>
                    <td>{transaction.invoice_id || "-"}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{transaction.status}</td>
                  </tr>
                </tbody>
              </div>
            </div>
          </Col>
        </Row>{" "}
        
        <h5>Other Info</h5>
        <Row>
          <Col lg={12}>
            <div className=" table-responsive w-100">
              <div className="table w-100 d-print-block d-print-table  ">
                <tbody className="w-100">
                  <tr>
                    <td>Plan Price</td>
                    <td>CAD {transaction.plan_price}</td>
                  </tr>
                  <tr>
                    <td>Plan Duration</td>
                    <td>{transaction.plan_duration}</td>
                  </tr>
                  <tr>
                    <td>Plan End Date</td>
                    <td>
                      <Moment format="MM-DD-YYYY">
                        {transaction.plan_end_date}
                      </Moment>
                    </td>
                  </tr>
                  <tr>
                    <td>Stripe Transaction ID</td>
                    <td>{transaction.stripe_transaction_id}</td>
                  </tr>
                </tbody>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className=" mt-3">
              <button
                type="button"
                className="btn btn-secondary btn-lg mr-2"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

TransactionDetailsModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.any,
}

export default TransactionDetailsModal
