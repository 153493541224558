import React, {createContext, useState} from "react";
import {Button, Col, Spinner} from "reactstrap";
import {put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import {showError} from "../../helpers/util.helper";


export const WebsiteSettingContext = createContext({});
export const WebsiteSettingProvider = ({children}) => {
    const [saving, setSaving] = useState(false);
    async function saveWebsiteData(websiteData) {
        setSaving(true);
        const response = await put(
            url.WEBSITE + "/" + localStorage.getItem("website_id"),
            websiteData
        );
        setSaving(false);

        if (!response.api_status) {
            toastr.success("Website updated sucessfully.", "Success");
            getWebsiteData()
        } else {
            showError(response)
        }
    }
    return <WebsiteSettingContext.Provider
        value={{
            saving,
            setSaving,
            saveBtn: saving ? <Col md="12 text-center"><Spinner/></Col> : <Col md="12 text-center"><Button color="primary" className="py-2 mt-4" type="submit">
                Update
            </Button></Col>
        }}> {children}</WebsiteSettingContext.Provider>;
};
