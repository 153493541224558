import PropTypes from "prop-types"
import {AvField, AvForm} from "availity-reactstrap-validation"
import {Button, Col, FormGroup, Label, Row} from "reactstrap"
import React from "react"
import Select from "react-select"

const AddUserForm = function (props) {
  return (
      <AvForm className="needs-validation" onValidSubmit={props.handleSubmit} modal={props.data}>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="validationCustom01">Full Name</Label>
            <AvField
              name="name"
              value={props.data.name}
              placeholder="John Doe"
              type="text"
              className="form-control"
              id="validationCustom03"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="validationCustom01">Email</Label>
            <AvField
              name="email"
              value={props.data.email}
              placeholder="abc@example.com"
              type="email"
              className="form-control"
              id="validationCustom03"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="validationCustom01">Password</Label>
            <AvField
              name="password"
              value={props.data.password}
              placeholder=""
              type="password"
              className="form-control"
              id="validationCustom03"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="validationCustom01">Websites</Label>
            <Select
              value={props.selectedWebsites}
              isMulti={true}
              onChange={(values) => {
                props.setSelectedWebsites(values)
              }}
              options={props.websites_list}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12 text-center mt-3">
              <Button color="secondary" className="py-2 mr-2" type="button" onClick={() => {
                props.back()
              }}>
                Back
              </Button>
              <Button color="primary" className="py-2" type="submit">
                {
                  props.data.user_id ?
                    "Update"
                    : "Save"
                }
              </Button>
        </Col>
      </Row>
    </AvForm>
  )
}

AddUserForm.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  back: PropTypes.func,
  websites_list: PropTypes.array,
  setSelectedWebsites: PropTypes.func,
  selectedWebsites: PropTypes.array
}

export default AddUserForm

