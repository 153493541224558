import {Col, Modal} from "reactstrap"
import {AvForm} from "availity-reactstrap-validation"
import React, {useState} from "react"
import PropTypes from "prop-types"
import Editor from "react-simple-code-editor";
import {highlight, languages} from "prismjs/components/prism-core";


const EditCodeModal = (props) => {
    const [data, setData] = useState(props.code || '');

    function handleValidSubmit() {
        props.onCloseEditCodeModal({code: data, codeUpdated: true})
    }

    return (
        <Modal
            isOpen={true}
            size="lg"
            toggle={() => {
                props.onCloseEditCodeModal({ code: props.code })
            }}
        >
            <AvForm onValidSubmit={handleValidSubmit}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">Code {/*<small
                        className={" text-white rounded-pill px-2 " + (props.codeUpdated ? 'bg-warning' : 'bg-success')}>{props.codeUpdated ? 'Modified' : 'Not modified'}</small>*/}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            props.onCloseEditCodeModal({code: props.code})
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Col md="12">
                        <div className="d-flex flex-column mt-3">
                            <div className="bg-light">
                                <Editor
                                    value={data}
                                    onValueChange={c => setData(c)}
                                    highlight={d => highlight(d, languages.js, 'handlebars')}
                                    padding={10}
                                    style={{
                                        fontFamily: '"Fira code", "Fira Mono", monospace',
                                        fontSize: 12,
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </div>
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        Save changes
                    </button>
                </div>
            </AvForm>
        </Modal>
    )
};

EditCodeModal.propTypes = {
    el: PropTypes.object,
    config: PropTypes.object,
    openModal: PropTypes.bool,
    codeUpdated: PropTypes.bool,
    onCloseEditCodeModal: PropTypes.func
};

export default EditCodeModal
