import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { BreadcrumbItem, Button, Col, Row } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <div className=" d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18 pr-4">{props.breadcrumbItem}</h4>
            {
              props.addBtnLink ?
                <Link to={props.addBtnLink}>
                  <Button color="primary">
                    <span className="fa fa-plus"></span>
                  </Button>
                </Link>
                : ""
            }
          </div>

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                {/*<Link to="#">{props.title}</Link>*/}
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {/*<Link to="#">{props.breadcrumbItem}</Link>*/}
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
