import { Modal, ModalBody, ModalHeader } from "reactstrap";
import React from "react";

const AlertDetails = ({ closeModal, openModal, data = {} }) => {
  let tableKeyValues = {
    "location": "Location",
    "min_price$": "Min Price",
    "max_price$": "Max Price",
    "beds": "Beds",
    "baths": "Baths",
    "min_area": "Min Area",
    "max_area": "Max Area",
    "frequency": "Email Frequency"
  };

  return <>
    <Modal
      isOpen={openModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        closeModal();
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            closeModal();

          }}
        >
          Alert Details
        </ModalHeader>
        <ModalBody>
          <table className="table table-borderless">
            <tbody>
            {
              Object.keys(tableKeyValues).map((i, k) => {
                return <tr key={k}>
                  <td>{tableKeyValues[i]}</td>
                  <td>{data[i]}</td>
                </tr>
              })
            }
            </tbody>
          </table>
        </ModalBody></div>
    </Modal>
  </>;
};

export default AlertDetails;
