import { Modal, ModalBody, ModalHeader } from "reactstrap";
import React, { useEffect } from "react";
import PropType from "prop-types";
import AddLeadTask from "../Tasks/add-task";

const LeadTaskAddModal = (props) => {
  function closeModal() {

  }

  return <>
    <Modal
      size="xl"
      isOpen={props.openModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        props.closeModal();

      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            props.closeModal();

          }}
        >
          New Message
        </ModalHeader>
        <ModalBody>
          <AddLeadTask
            data={props.data}
            save={props.saveTask} task_id={props.data?.task_id} close={props.closeModal}/>
        </ModalBody>
      </div>
    </Modal>
  </>;
};
LeadTaskAddModal.propTypes = {
  sendEmail: PropType.func,
  closeModal: PropType.func,
  openModal: PropType.bool,
  saveTask: PropType.func
};
export default LeadTaskAddModal;
