import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import * as url from "../../helpers/url_helper";
import {get} from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FacebookMarketingLayout from "./FacebookMarketingLayout";
import {Card, CardBody, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import FacebookInsightsCards from "./SuccessModal/FacebookInsightsCards";
import {TableLoader} from "../../components/Loader";
import NoInsightsData from "./NoInsightsData";
import {connect} from "react-redux";
import NoDataAvailableImage from "../../components/Common/NoDataAvailableImage";

const FacebookMarketingHome = (props) => {
  let [insights, setInsights] = useState({});
  let [insightsLoading, setInsightsLoading] = useState(false);
  const history = useHistory();
  useEffect(async () => {
    await getInsights();
  }, []);

  async function getInsights() {
    setInsightsLoading(true);
    const response = await get(url.FACEBOOK_INSIGHTS);
    setInsightsLoading(false);
    if (!response.api_status) {
      response.all.actions = arrayToObj(response.all.actions);
      response.genderStats.forEach(gender => {
        gender.actions = arrayToObj(gender.actions);
      });
      response.platformStats.forEach(platform => {
        platform.actions = arrayToObj(platform.actions);
      });
      setInsights(response);
    } else {
      setInsights(false);
    }
  }

  function arrayToObj(arr) {
    let obj = {};
    if (Array.isArray(arr)) {
      arr.forEach(a => {
        obj[a.action_type] = a.value;
      });
      return obj;
    }
    return arr;
  }


  return (
    <>
      <FacebookMarketingLayout>
        <Breadcrumbs title="Insights(This year)" breadcrumbItem="Insights"/>
        {insightsLoading ? <TableLoader/>
          :
          typeof insights === "boolean"
            ?
            <NoDataAvailableImage/>
            :
            <Row>
              <Col lg="10">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        {
                          insights && insights.all &&
                            <div>

                              <FacebookInsightsCards stats={insights.all}/>

                            </div>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <h4 className="card-title mb-4 font-size-20">Platform</h4>
                        {
                          insights && insights.platformStats && insights.platformStats.map((platform, idx) => <>
                            <div key={idx + 'platform'}>
                              <div>
                                <div className="d-flex align-items-center justify-content-center">
                                  <i className={'text-primary bx bx-md bxl-' + platform.publisher_platform}></i>
                                  <span
                                    className="font-size-18 ml-2 font-weight-bold">{platform.publisher_platform.toUpperCase()}</span>
                                </div>
                                <FacebookInsightsCards stats={platform}/>

                              </div>
                            </div>
                          </>)
                        }
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <h4 className="card-title mb-4 font-size-20">Gender</h4>
                        {
                          insights && insights.genderStats && insights.genderStats.map((platform, idx) => <>
                            <div key={idx + 'gender'}>
                              <div>
                                <div className="d-flex align-items-center justify-content-center">
                                  <i className={'text-primary bx bx-md bx-' + platform.gender}></i>
                                  <span
                                    className="font-size-18 ml-2 font-weight-bold">{platform.gender.toUpperCase()}</span>
                                </div>
                                <FacebookInsightsCards stats={platform}/>
                              </div>
                            </div>
                          </>)
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md="2">
                <Card>
                  {
                    insights && insights.all &&
                    <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <i className="bx bx-bulb bx-sm" style={{ color: "green" }}/>
                        <span className="font-size-16 font-weight-bold">Facts</span></div>
                      <ListGroup>
                        {
                          insights.all.cpc &&
                          <ListGroupItem>
                            <p>Your average CPC(Cost Per Click) is <b>${(+insights.all.cpc).toFixed(2)}</b></p>
                          </ListGroupItem>
                        }
                        {
                          insights.all.cpm &&
                          <ListGroupItem>
                            <p>Your average CPM(Cost per Milestone) is <b>${(+insights.all.cpm).toFixed(2)}</b></p>
                          </ListGroupItem>
                        }
                      </ListGroup>
                    </CardBody>

                  }
                </Card>
              </Col>
            </Row>
        }
      </FacebookMarketingLayout>
    </>
  );
}
;

const mapStatetoProps = state =>
{
  const {
    user
  } = state.user;
  return { user };
}
;

export default connect(mapStatetoProps)(FacebookMarketingHome);
