import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useEffect, useState} from "react";

export const GMBLocationModal = ({onClose, locations, onSave}) => {
    const [selectedLocation, setLocation] = useState(locations?.length && locations[0].location_id);

    return <>
        <Modal
            isOpen={true}
        >

            <ModalHeader>
                Select Location
            </ModalHeader>
            <ModalBody>
                <div className="list-group">
                    {
                        locations?.map((location) =>
                            <div className="list-group-item d-flex align-items-center"
                                 key={location.location_id}
                                 style={{gap: '10px', cursor: 'pointer'}}>
                                <div>
                                    <input type="radio" name="location" id={location.location_id}
                                           onChange={e => setLocation(e.target.value)} value={location.location_id}/>
                                </div>
                                <label htmlFor={location.location_id} style={{cursor: 'pointer'}}>
                                    <p className="mb-0">{location.title}</p>
                                </label>
                            </div>)
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type={"button"} size="small" onClick={() => onClose()}>Close</Button>
                <Button type={"button"} size="small" color="primary" onClick={() => onSave({location_id: selectedLocation})}>Fetch Testimonials</Button>

            </ModalFooter>
        </Modal>

    </>
};
