import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState } from "react";

const CSVModal = (props) => {
  let [leads, setLeads] = useState([]);
  let leadsToShow = 20;
  function uploadCsv(e) {
    if (!(e.target.files && e.target.files.length)) {
      return;
    }
    const reader = new FileReader();
    let headers = ["name", "email", "phone", "address", "city", "province"];
    reader.onload = function(e) {
      let lines = e.target.result.split("\n");
      let leads = lines.map((line) => {
        let person = {};
        let lineValues = line.split(",").map(value => value.replace(/\"/g, ""));
        for (let i = 0; i < lineValues.length; i += 1) {
          person[headers[i]] = lineValues[i];
        }
        return person;
      }).filter(d => d.name && d.name.toLowerCase() !== "name");
      setLeads(leads);
      $(e)[0].value = null;
    };
    reader.readAsBinaryString(e.target.files[0]);
  }

  return <>
    <Modal
      isOpen={props.open}
      role="dialog"
      size="lg"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        props.closeModal();

      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            props.closeModal();
          }}
        >
          CSV Upload
        </ModalHeader>
        <ModalBody>
          <div className="row">

            <table className="table col-12">
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>City</th>
                <th>Province</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {
                leads.slice(0, leadsToShow).map((lead, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{lead.name}</td>
                      <td>{lead.email}</td>
                      <td>{lead.phone}</td>
                      <td>{lead.address}</td>
                      <td>{lead.city}</td>
                      <td>{lead.province}</td>
                      <td><i className="fa fa-trash text-danger" style={{ cursor: "pointer" }}
                             onClick={() => setLeads(leads.filter((lead, i) => i !== idx))}/></td>
                    </tr>
                  );
                })
              }
              </tbody>
            </table>
            {
              leads.length === 0 && <div className="col-12 text-center mt-4">
                <button className="btn btn-outline-light p-3">
                  <i className="fa-2x  fa fa-upload  text-primary text-center" onClick={() => $("#csvUpload").click()}/>
                </button>
                <input type="file" accept=".csv" id="csvUpload" className="d-none" onChange={uploadCsv}/>
              </div>
            }
            {
              leads.length > leadsToShow && <div className="col-12 font-size-xs text-muted mt-1 text-center">
                and {leads.length - leadsToShow} more leads..
              </div>
            }
            <div className="col-12 font-size-xs text-muted mt-4">
              <p className="p-0 m-0">Please upload the CSV in above format.</p>
              <p className="p-0 m-0 text-danger">Duplicate Email ids will be discarded.</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {
            leads.length > 0 &&
            <Button
              type="button"
              color="success"
              onClick={() => props.saveCSVData(leads)}
            >
              Save
            </Button>
          }
        </ModalFooter>
      </div>
    </Modal>

  </>;
};
export default CSVModal;
