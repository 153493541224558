import {Col, FormGroup, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import PropTypes from "prop-types";
import ComponentWidthInput from "./ComponentWidthInput";


const AboutUsSettings = (props) => {
  const [image, setImage] = useState(props.config.image);

  function handleValidSubmit(event, values) {
    props.updateConfig({
      image: image || values.image,
      ...values,
    });
  }

  function handleFile(e) {
    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  function deleteImage() {
    setImage(null);
  }

  return (

      <AvForm onValidSubmit={handleValidSubmit}>

        <Row className="py-2">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01" className="d-flex align-items-center justify-content-between">
                <span>Agent Photo</span>
                {
                  image && <span className="fas fa-minus-circle"
                                 onClick={() => {
                                   deleteImage();
                                 }}
                                 style={{color: 'red', cursor: 'pointer'}}/>
                }
              </Label>
              {
                !image && <AvField
                    name="post"
                    type="file"
                    className="form-control"
                    id="validationCustom03"
                    onChange={event =>
                        handleFile(event, "portfolioPhoto")
                    }
                />
              }
            </FormGroup>
          </Col>
          <Col md="12">
            {
              <img src={image}
                   alt="" className="img img-thumbnail mb-3"/>
            }
          </Col>
          <Col md="12">
            <ComponentWidthInput  {...props.config}/>
          </Col>
        </Row>
        <div className="modal-footer">
          <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
  );
};

AboutUsSettings.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseInfographicSettings: PropTypes.func
};

export default AboutUsSettings;
