import React from "react";
import PropTypes from "prop-types";
import {Redirect, Route} from "react-router-dom";

const Authmiddleware = ({
                            component: Component,
                            layout: Layout,
                            isAuthProtected,
                            superAdmin = false,
                            ...rest
                        }) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("access_token")) {
        return (
          <Redirect
              to={{pathname: "/login", state: {from: props.location}}}
          />
        );
      } else if (rest.redirectTo) {
          return (
              <Redirect
                  to={{pathname: rest.redirectTo, state: {from: props.location}}}
              />
          );
      }
  /*      if (superAdmin && +rest.user.role_id !== 1) {
            return (
                <Redirect
                    to={{pathname: '/', state: {from: props.location}}}
                />
            );
        }*/
        let allRoutes = rest.path.split('/').filter(d => d && d.length);
        let fbConnected = rest.user && rest.user.fbAdAccountId;
        if (allRoutes.length && allRoutes[0] === 'fb-marketing') {
            if (allRoutes.length === 1 && fbConnected) {
                return (<Redirect
                        to={{pathname: '/fb-marketing/insights', state: {from: props.location}}}
                    />
                );
            } else if (allRoutes.length > 1 && !fbConnected) {
                return (<Redirect
              to={{ pathname: '/fb-marketing', state: { from: props.location } }}
            />
          );
        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  redirectTo: PropTypes.string,
  layout: PropTypes.any,
  user: PropTypes.object
};

export default Authmiddleware;
