import {Col, FormGroup, Label, Modal} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import React, {useState} from "react"
import PropTypes from "prop-types"
import SettingsImagePreview from "../../Settings/util/image-preview";
import Team1 from '../../../assets/images/ae/components/team-1.png';
import Team2 from '../../../assets/images/ae/components/team-2.png';
import Team3 from '../../../assets/images/ae/components/team-3.png';
import Team4 from '../../../assets/images/ae/components/team-4.png';

const TeamModal = (props) => {
  let components = [
    {id: '1', preview: Team1},
    {id: '2', preview: Team2},
    {id: '3', preview: Team3},
    {id: '4', preview: Team4},
  ];
  const [teamComponent, setTeamComponent] = useState(props.config.component_id);

  function handleValidSubmit(event, values) {
    props.onCloseTeamModal({team_title: values.team_title, el: props.el, component_id: teamComponent})
  }

  return (
      <Modal
          isOpen={props.openModal}
          toggle={() => {
            props.onCloseTeamModal({team_title: props.team_title, el: props.el})
          }}
      >
        <AvForm onValidSubmit={handleValidSubmit}>
          <div className="modal-header">
            <h5 className="modal-team_title mt-0" id="myModalLabel">Settings</h5>
            <button
                type="button"
                onClick={() => {
                  props.onCloseTeamModal({team_title: props.team_title, el: props.el})
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Title</Label>
              <AvField
                  name="team_title"
                  value={props.config.team_title}
                  placeholder="Lorum Ipsum"
                  type="text"
                  className="form-control"
                  id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <Label>Select Component </Label>
          </Col>
          {
            components.map((background, key) => (
                <Col md="12" key={key}>
                  <div className="form-check mb-3">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="background"
                        id={"background+" + key}
                        onChange={() => {
                          setTeamComponent(background.id)
                        }}
                        checked={teamComponent === background.id}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={"background+" + key}
                    >
                      <SettingsImagePreview image={background.preview} width={'100%'}/>
                    </label>
                  </div>

                </Col>
            ))
          }
        </div>

        <div className="modal-footer">
          <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

TeamModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseTeamModal: PropTypes.func
}

export default TeamModal
