import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import FormWrapper from "../util/form-wrapper";
import SettingsImagePreview from "../util/image-preview";
import Select from "react-select";
import {WebsiteSettingContext} from "../website-settings.context";

let provinces = [
  {
    "value": "ON",
    "label": "Ontario"
  },
  {
    "value": "PE",
    "label": "Prince Edward Island"
  },
  {
    "value": "NS",
    "label": "Nova Scotia"
  },
  {
    "value": "NB",
    "label": "New Brunswick"
  },
  {
    "value": "QC",
    "label": "Quebec"
  },
  {
    "value": "MB",
    "label": "Manitoba"
  },
  {
    "value": "SK",
    "label": "Saskatchewan"
  },
  {
    "value": "AB",
    "label": "Alberta"
  },
  {
    "value": "BC",
    "label": "British Columbia"
  },
  {
    "value": "YT",
    "label": "Yukon"
  },
  {
    "value": "NT",
    "label": "Northwest Territories"
  },
  {
    "value": "NU",
    "label": "Nunavut"
  }
];
const sources = [{value: 'treb', label: 'TREB'}, {value: 'crea', label: 'CREA'}]
const websiteSettings = (props) => {
  const [themeColor, setThemeColor] = useState(props.data.website_config.primary_color);
  const websiteSettings = useContext(WebsiteSettingContext);
  const [listingProvinces, setListingProvinces] = useState(provinces.filter(d => {
    return props.data.listing_provinces?.includes(d.value)
  }));
  const [listingSource, setListingSource] = useState(sources.filter(d => {
    return props.data.listing_source?.includes(d.value)
  }));

  function save(e, values) {
    props.handleSubmit(e, {
      ...values,
      listing_provinces: listingProvinces?.map(key => key.value) || null,
      listing_source: listingSource?.map(key => key.value) || null
    })
  }

  console.log(websiteSettings, '-seetings')

  return (
      <Card>
        <CardBody>
          <h4 className="card-title">Default Configuration</h4>
          <p className="card-title-desc">Website name and configuration</p>
          <FormWrapper
              handleSubmit={save}
              data={props.data}
              savePageButton={websiteSettings.saveBtn}
              fields={
                <>
                  <Row>
             {/*       <Col md="6">
                      <FormGroup>
                        <Label htmlFor="website-name">Website Name</Label>
                        <AvField
                            name="website_name"
                            placeholder="Website Name"
                            type="text"
                            errorMessage="Enter Website Name"
                            className="form-control"
                            value={props.data.website_name}
                            validate={{required: {value: true}}}
                            id="website-name"
                        />
                      </FormGroup>
                    </Col>
*/}
                    {
                      props.isAdmin && <>
                       {/* <Col md="6">
                          <FormGroup>
                            <Label htmlFor="website-name">Website Details</Label>
                            <AvField
                                name="website_config.website_description"
                                placeholder="This website belong to the real estate agent in York...."
                                type="textarea"
                                className="form-control"
                                value={props.data.website_config.website_description}
                                id="website-details"
                            />
                          </FormGroup>
                        </Col>*/}
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="subdomain">Sub Domain</Label>
                            <AvField
                                name="subdomain"
                                placeholder="ae"
                                type="text"
                                errorMessage="Enter Sub domain"
                                className="form-control"
                                value={props.data.subdomain}
                                validate={{required: {value: true}}}
                              id="subdomain"
                          />
                        </FormGroup>
                      </Col>
                      </>
                    }
                  </Row>
                  {
                    props.isAdmin && <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="website-url">Website URL</Label>
                          <AvField
                              name="base_url"
                              placeholder="http://agentedge.ca"
                              type="text"
                              value={props.data.base_url}
                              errorMessage="Website url required"
                              className="form-control"
                              validate={{required: {value: true}}}
                              id="website-url"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="industry-id">Industry Type</Label>
                          <AvField
                              name="industry_id"
                              type="select"
                              value={props.data.industry_id}
                              errorMessage="Field is required"
                              className="form-control"
                              validate={{required: {value: true}}}
                              id="industry-id"
                          >
                            <option value=""/>
                            <option value="1">Real Estate</option>
                            <option value="2">Portfolio</option>
                          </AvField>
                        </FormGroup>
                      </Col>

                      {
                        props.isAdmin && +props.data.industry_id === 1 &&
                        <>
                          <Col md="2">
                            <FormGroup>
                              <Label htmlFor="industry-id">Listing Delay</Label>
                              <AvField
                                  name="listing_delay"
                                  type="number"
                                  value={props.data.listing_delay}
                                  className="form-control"
                                  helpMessage="In minutes: Put 60 if you want 1 hour delay"
                                  id="lising-delay"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="select2-container">
                              <label className="control-label">
                                Listing Show(Only)
                              </label>
                              <Select
                                  value={listingProvinces}
                                  isMulti={true}
                                  onChange={(value) => {
                                    setListingProvinces(value)
                                  }}
                                  name="listing_provinces"
                                  options={provinces}
                                  classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="select2-container">
                              <label className="control-label">
                                Listing Source
                              </label>
                              <Select
                                  value={listingSource}
                                  isMulti={true}
                                  onChange={(value) => {
                                    setListingSource(value)
                                  }}
                                  name="listing_source"
                                  options={sources}
                                  classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3" className="d-flex align-items-center" style={{gap: '1rem'}}>
                            <FormGroup className={"d-flex flex-row align-items-center"} style={{gap: '10px'}}>

                              <AvField
                                  value={props.data.showListings}
                                  name="showListings"
                                  className={'p-0'}
                                  type="checkbox"
                                  label={"Enable Listings"}
                                  id={'showListings'}
                                  trueValue={1}
                              />
                            </FormGroup>
                            <FormGroup className={"d-flex flex-row align-items-center"} style={{gap: '10px'}}>

                              <AvField
                                  value={props.data.showVow}
                                  name="showVow"
                                  className={'p-0'}
                                  type="checkbox"
                                  label={"Show Vow"}
                                  id={'showVow'}
                                  trueValue={1}
                              />
                            </FormGroup>
                            <FormGroup className={"d-flex flex-row align-items-center"} style={{gap: '10px'}}>

                              <AvField
                                  value={+props.data.website_config.enableChat}
                                  name="website_config.enableChat"
                                  className={'p-0'}
                                  type="checkbox"
                                  label={"Enable Chat"}
                                  id={'enableChat'}
                                  trueValue={1}
                              />
                            </FormGroup>
                            <FormGroup className={"d-flex flex-row align-items-center"} style={{gap: '10px'}}>

                              <AvField
                                  value={props.data.showOnAe}
                                  name="showOnAe"
                                  className={'p-0'}
                                  type="checkbox"
                                  label={"Show on AE as showcase website"}
                                  id={'showOnAe'}
                                  trueValue={1}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="industry-id">Theme</Label>
                              <AvField
                                  name="website_config.theme"
                                  type="select"
                                  value={props.data.website_config.theme}
                                  errorMessage="Field is required"
                                  className="form-control"
                                  validate={{required: {value: true}}}
                                  id="theme"
                              >
                                <option value=""/>
                                {
                                  props.themes.map((theme, key) => (
                                      <option key={key} value={theme.theme_id}>{theme.theme_name}</option>
                                  ))
                                }
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <Label htmlFor="testimonial">Website Testimonial</Label>
                                  <AvField
                                      name="website_config.website_testimonial"
                                      type="textarea"
                                      value={props.data.website_config.website_testimonial}
                                      className="form-control"
                                      id="testimonial"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label htmlFor="websiteScreenshot">Website Screenshot</Label>
                                  <AvField
                                      name="light_logo"
                                      type="file"
                                      className="form-control"
                                      id="websiteScreenshot"
                                      onChange={event =>
                                          props.handleFile(event, "website_screenshot")
                                      }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <SettingsImagePreview image={props.data.website_config.website_screenshot}/>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      }

                    </Row>
                  }

                  {
                    props.webSettings.dark_logo && <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="darklogo">
                            <span>Light Logo (On dark background)</span>
                          </Label>
                          <AvField
                              name="dark_logo"
                              type="file"
                              className="form-control"
                              id="darklogo"
                              onChange={event =>
                                  props.handleFile(event, "dark_logo")
                              }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <SettingsImagePreview image={props.data.website_config.dark_logo}/>
                      </Col>
                    </Row>
                  }
                  {
                    props.webSettings.light_logo && <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="lightlogo">Dark logo (On light background)</Label>
                          <AvField
                              name="light_logo"
                              type="file"
                              className="form-control"
                              id="lightlogo"
                              onChange={event =>
                                  props.handleFile(event, "light_logo")
                              }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <SettingsImagePreview image={props.data.website_config.light_logo}/>
                      </Col>
                    </Row>
                  }
                  {
                    props.webSettings.favicon && <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="favicon">Favicon</Label>
                          <AvField
                              name="favicon"
                              type="file"
                              className="form-control"
                              id="favicon"
                              onChange={event =>
                                  props.handleFile(event, "favicon")
                              }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" className="align-items-center d-flex">
                        <SettingsImagePreview image={props.data.website_config.favicon}/>
                      </Col>
                    </Row>
                  }
                </>
              }
          />
        </CardBody>
      </Card>
  );
};

websiteSettings.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  handleFile: PropTypes.func,
  savePageButton: PropTypes.element,
  isAdmin: PropTypes.bool
};

export default websiteSettings;
