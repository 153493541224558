import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import {del, get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {page_limit} from "../../config";
import pageColumns from "../../page.columns";
import {useHistory} from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import {ComponentsType, showError} from "../../helpers/util.helper";
import toastr from "toastr";
import NoDataAvailable from "../../components/NoDataAvailable";
import {TableLoader} from "../../components/Loader";

const ThemeComponents = ({ match }) => {
    let history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingComponentsId, setDeletingComponentsId] = useState(null);
    const [themes, setThemes] = useState([]);
    const actions = [
        {
            name: "Edit",
            icon: "fas fa-pencil-alt",
            fn: editComponents
        }, {
            name: "Delete",
            icon: "fas fa-trash",
            color: "danger",
            fn: openDeleteModal
        }];
    const limit = page_limit;
    const offset = 0;
    const [pagesLoading, setPageLoading] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState({
        columns: pageColumns.theme_components,
        rows: []
    });
    const [filters, setFilters] = useState({});
    const [filterFields, setFilterFields] = useState([]);


    useEffect(() => {
        getComponents({limit, offset});
        getThemes();

    }, []);
    async function getThemes() {
        const response = await get(url.THEME);
        if (!response.api_status) {
            setFilterFields([{
                type: "select",
                name: "component_type",
                options: ComponentsType.map(d => ({value: d.key, label: d.name}))
            }]);
        } else {
            showError(response);
        }
    }

    useEffect(() => {
        setFilterFields(filterFields.map(fields => {
            fields.value = filters[fields.name];
            return fields;
        }));
        pageChange(1);
    }, [filters]);

    function pageChange(e) {
        setCurrentPage(e);
        getComponents({limit, offset: (limit * (e - 1))});
    }

    const getComponents = async ({limit, offset}) => {
        setPageLoading(1);
        const componentsData = await get(url.COMPONENT_LIST, {
            params: {
                limit, offset, ...filters
            }
        });
        setPageLoading(0);
        componentsData.data.forEach(components => {
            if (components.isDraft) {
                components.post_status = <span className="badge badge-danger">Draft</span>
            } else {
                components.post_status = <span className="badge badge-success">Published</span>
            }
            components.component_type = components.component_type.replaceAll('_', ' ');
            components.preview = <img src={components.component_image} width={100} alt="preview image"/>
        });
        let _d = {...data, rows: componentsData.data};
        setData(_d);
        setTotalPages(componentsData.total_count);
  };

  function editComponents({ tc_id }) {
    history.push("/editComponents/" + tc_id);
  }

  function addComponents() {
    history.push("/addComponents");
  }

  async function deleteComponents() {
    const response = await del(url.COMPONENT_LIST + "/" + deletingComponentsId);
    if (!response.api_status) {
      let _d = data.rows.filter(d => d.tc_id !== deletingComponentsId);
      setData({ ...data, rows: _d });
      setDeletingComponentsId(null);
      setDeleteModal(false);
      toastr.success("Components deleted successfully.", "Success");
    } else {
      showError(response);
    }
  }

  function openDeleteModal({ tc_id }) {
    setDeletingComponentsId(tc_id);
    setDeleteModal(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Components List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                        <AETable columns={data.columns}
                                 rows={data.rows}
                                 itemsPerPage={limit}
                                 loading={pagesLoading}
                                 addLink={addComponents}
                                 actions={actions}
                                 currentPage={currentPage}
                                 totalCount={totalPages}
                                 onFilter={setFilters}
                                 filters={filterFields}
                                 pageFn={pageChange}/>
                  }
                </CardBody>
                <DeleteModal
                  onCloseClick={() => {
                    setDeletingComponentsId(null);
                    setDeleteModal(false);
                  }}
                  onDeleteClick={deleteComponents}
                  show={deleteModal}>
                </DeleteModal>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThemeComponents;
