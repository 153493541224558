import React, { useEffect, useState } from "react";
import { getCallLogs } from "../leads.service";
import { Card, CardBody, Col, Row } from "reactstrap";
import Moment from "react-moment";
import { capitalize, maxLength } from "../../../helpers/util.helper";
import "../leads.scss";

const CallLogsList = ({ logs }) => {
  return <>
    <Card>
      <CardBody>
        <Row>
          {
            logs && logs.length ?
              <div className="list-group w-100">
                {logs.map((log, key) => (
                  <div className="list-group-item  flex-column align-items-start " key={key}>
                    {
                      log.schedule_date ?
                        <div className="d-flex flex-row ">
                          <div className="d-flex flex-column mr-3 text-center justify-content-center "
                               style={{ width: "75px" }}>
                            <span className="avatar-title rounded">
                              <small>Scheduled</small>
                            </span>
                          </div>
                          <div className="d-flex w-100">
                            <div className="flex-column w-100">
                              <div
                                className="w-100 mb-1 font-size-16 font-weight-bold camelCase align-content-between justify-content-between d-flex">
                                <span>Call Scheduled <span className="font-size-14"> <small>(Created <Moment  withTitle titleFormat="LLL" fromNow>{log.created}</Moment>)</small></span></span>
                                <small><Moment format="MMM DD, YYYY hh:mm A">{log.schedule_date}</Moment></small>
                              </div>
                              <small className="mb-1">{maxLength(log.note, 100)}</small>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="d-flex flex-row ">
                          <div className="d-flex flex-column mr-3 text-center justify-content-center"
                               style={{ width: "75px" }}>
                            <span className="fa fa-phone text-primary "/>
                            {
                              log.created && <small><Moment  withTitle titleFormat="LLL" fromNow>{log.created}</Moment></small>
                            }
                          </div>
                          <div className="d-flex w-100">
                            <div className="flex-column">
                              <p
                                className="mb-1 font-size-16 font-weight-bold camelCase">{log.call_type} - {log.call_response}</p>
                              <small className="mb-1">{maxLength(log.note, 100)}</small>
                            </div>
                          </div>
                        </div>
                    }
                  </div>
                ))}
              </div>

              :
              <Col md={12} className="text-center">
                <h5>No Call logs</h5>
              </Col>
          }
        </Row>


      </CardBody>
    </Card>
  </>;
};

export default CallLogsList;
