import React from 'react';
import {Pill} from "../../../components/Common/pill";
import * as moment from 'moment';

export const WebsiteTransactionsList = ({paymentsData}) => {
    return <table className="table">
        <thead>
        <tr>
            <td>#</td>
            <td>Amount</td>
            <td>Is Manual</td>
            <td>Plan Months</td>
            <td>Transaction Date</td>
            <td>Coupon Discount</td>
            <td>Card</td>
            {/*<td>Invoice</td>*/}
            <td>Status</td>
            <td/>
        </tr>
        </thead>

        <tbody>
        {
            paymentsData.payments.map((payment, id) =>   <tr key={payment.payment_id}>
                    <td>{payment.invoice_id}</td>
                    <td>CAD {payment.payment_amount.toFixed(2) || 0}</td>
                    <td>{payment.isManual? 'Yes': 'No'}</td>
                    <td>{payment.plan_duration}</td>
                    <td>{moment(payment.created).format('MM-DD-YYYY hh:mm A')}</td>
                    <td>{payment.coupon_discount}</td>
                    <td>{payment.card_last4? '**** '+ payment.card_last4 : '-'}</td>
                    {/*<td><a href="#" className="link-dark">Download</a></td>*/}
                    <td>
                        {payment.payment_status? <Pill color="success">Success</Pill>: <Pill color="danger">Failed</Pill>}
                    </td>
                    <td>
                        {/*<a className="nav-link py-0" title="view"> <span className="fa fa-eye"/></a>*/}
                    </td>
                </tr>
            )
        }
        </tbody>
    </table>
};
