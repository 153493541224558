import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import React from "react";
import PropTypes from "prop-types";
import FormWrapper from "../util/form-wrapper";

const SecretKeysSettings = (props) => {
  return (
      <Card>
        <CardBody>
          <h4 className="card-title">Default Configuration</h4>
          <p className="card-title-desc">Website secret keys</p>
          <FormWrapper
              handleSubmit={props.handleSubmit}
              data={props.data}
              fields={
                <>
                  <Row>
                    {
                      props.webSettings.google_recaptcha_site_key && <Col md="6">
                        <FormGroup>
                          <Label htmlFor="recaptcha-key">Recaptcha Key</Label>
                          <AvField
                              name="website_config.google_recaptcha_site_key"
                              placeholder=""
                              type="text"
                              errorMessage="This field is required."
                              className="form-control"
                              value={props.data.website_config.google_recaptcha_site_key}
                              id="recaptcha-key"
                              helpMessage={<span>Don't have keys? <a
                                  href="https://www.google.com/recaptcha/admin/create"
                                  target="_blank">Click Here</a> to get keys </span>}
                          />
                        </FormGroup>
                      </Col>
                    }
                    {
                      props.webSettings.google_recaptcha_site_secret && <Col md="6">
                        <FormGroup>
                          <Label htmlFor="recaptcha-secret">Recaptcha Secret</Label>
                          <AvField
                              name="website_config.google_recaptcha_site_secret"
                              placeholder=""
                              type="text"
                              value={props.data.website_config.google_recaptcha_site_secret}
                              errorMessage="This field is required."
                              className="form-control"
                              id="recaptcha-secret"
                          />
                        </FormGroup>
                      </Col>
                    }

                  </Row>
                  <Row>
                    {
                      props.webSettings.google_maps_key && <Col md="6">
                        <FormGroup>
                          <Label htmlFor="google-maps-key">Google Maps Key</Label>
                          <AvField
                              name="website_config.google_maps_key"
                              placeholder=""
                              type="text"
                              errorMessage="This field is required."
                              className="form-control"
                              value={props.data.website_config.google_maps_key}
                              id="google-maps-key"
                          />
                        </FormGroup>
                      </Col>
                    }
                    {
                      props.webSettings.google_analytics_key && <Col md="6">
                        <FormGroup>
                          <Label htmlFor="recaptcha-secret">Google Analytics Key</Label>
                          <AvField
                              name="website_config.google_analytics_key"
                              placeholder="UA-XXXXX-Y"
                              type="text"
                              value={props.data.website_config.google_analytics_key}
                              // errorMessage="This field is required."
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="recaptcha-secret"
                          />
                        </FormGroup>
                      </Col>
                    }
                    {
                      props.webSettings.google_site_verification_key && <Col md="6">
                        <FormGroup>
                          <Label htmlFor="recaptcha-secret">Google Site verification key</Label>
                          <AvField
                              name="website_config.google_site_verification_key"
                              placeholder=""
                              type="text"
                              value={props.data.website_config.google_site_verification_key}
                              // errorMessage="This field is required."
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="recaptcha-secret"
                          />
                        </FormGroup>
                      </Col>
                    }
                    {
                      props.webSettings.google_tag_manager_key && <Col md="6">
                        <FormGroup>
                          <Label htmlFor="recaptcha-secret">Google Tag Manager Key</Label>
                          <AvField
                              name="website_config.google_tag_manager_key"
                              placeholder="GTM-XXXX"
                              type="text"
                              value={props.data.website_config.google_tag_manager_key}
                              // errorMessage="This field is required."
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="recaptcha-secret"
                          />
                        </FormGroup>
                      </Col>
                    }
                    {
                      props.webSettings.facebook_page_id && <Col md="6">
                        <FormGroup>
                          <Label htmlFor="recaptcha-secret">Facebook Page Id(Messenger widget)</Label>
                          <AvField
                              name="website_config.facebook_page_id"
                              placeholder="1234567890"
                              type="text"
                              value={props.data.website_config.facebook_page_id}
                              // errorMessage="This field is required."
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="recaptcha-secret"
                          />
                        </FormGroup>
                      </Col>
                    }
                  </Row>
                </>
          }
        />
      </CardBody>
    </Card>
  );
};

SecretKeysSettings.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  handleFile: PropTypes.func,
  savePageButton: PropTypes.element
};

export default SecretKeysSettings;
