import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap"
import {AvField} from "availity-reactstrap-validation"
import React, {useState} from "react"
import PropTypes from "prop-types"
import FormWrapper from "../util/form-wrapper"
import {Helmet} from "react-helmet";


const ColorSchemaSettings = props => {
    const [state, setState] = useState(props.data);
    const [fontFamily, setFontFamily] = useState(props.data.font_family);
    const Fonts = [
        {key: 'Roboto', value: 'Roboto'},
        {key: 'Lato', value: 'Lato',},
        {key: 'Open Sans', value: 'Open Sans',},
        {key: 'Oswald', value: 'Oswald',},
        {key: 'Raleway', value: 'Raleway',},
        {key: 'Rajdhani', value: 'Rajdhani',},
        {key: 'Fira Sans', value: 'Fira Sans',},
        {key: 'Ruda', value: 'Ruda',},
        {key: 'Luxurious Roman', value: 'Luxurious Roman',},
        {key: 'Playfair Display', value: 'Playfair Display',},
        {key: 'Cookie', value: 'Cookie',},
        {key: 'Special Elite', value: 'Special Elite',},
        {key: 'Crimson Pro', value: 'Crimson Pro',},
        {key: 'Montserrat', value: 'Montserrat',},
        {key: 'Merriweather Sans', value: 'Merriweather Sans',},
        {key: 'Merriweather', value: 'Merriweather',},
        {key: 'Carrois Gothic SC', value: 'Carrois Gothic SC',},
        {key: 'Carrois Gothic', value: 'Carrois Gothic',},
    ];
    function save(e, values) {
        props.handleSubmit(e, {...values})
    }

    function updateSchema(cs_id) {
        if (!+cs_id) {
            return;
        }
        let config = props.colorSchema.find(cs => +cs.cs_id === +cs_id).schema_config;
        setState(JSON.parse(JSON.stringify({
            ...state, website_config: {
                ...state.website_config, ...config
            }
        })));
    }


    return (
        <Card>
            <CardBody>
                <h4 className="card-title">Theme</h4>
                <Helmet>
                        {
                            Fonts.map(font=> {
                                return <link href={`https://fonts.googleapis.com/css2?family=${font.value}:wght@300;400;600;700&display=swap`} rel="stylesheet"/>
                            })
                        }

                </Helmet>
                <FormWrapper
                    handleSubmit={save}
                    data={state}
                    savePageButton={props.savePageButton}
                    fields={
                        <>
                            <Row>
                                <Col md="6" sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="font-family">Color Scheme</Label>
                                        <AvField
                                            type="select"
                                            name="website_config.cs_id"
                                            value={state.website_config.cs_id}
                                            className="form-control"
                                            id={"theme-scheme"}
                                            onChange={(e) => updateSchema(e.target.value)}
                                        >
                                            <option value=""/>
                                            {
                                                props.colorSchema.map((theme, key) => (
                                                    <option key={key} value={theme.cs_id}>{theme.schema_name}</option>
                                                ))
                                            }
                                        </AvField>
                                    </FormGroup>
                                </Col>
                                {props.webSettings.font_family && (
                                    <Col md="6" sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="font-family">Font Family</Label>
                                            <AvField
                                                type="select"
                                                name="website_config.font_family"
                                                value={state.website_config.font_family}
                                                className="form-control"
                                                id={"font-family"}
                                                onChange={(e) => setFontFamily(e.target.value)}
                                            >
                                                {
                                                    Fonts.map(font => <option value={font.key} key={font.key}>{font.value}</option>)
                                                }
                                            </AvField>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                            <div className="row">
                                <div className="col-12">
                                    <Label htmlFor="color">Font Sample</Label>
                                    <div style={{
                                        background: "#00000020",
                                        'font-family': fontFamily
                                    }} className="p-3">
                                        <h1>Welcome to {state.website_config.salesman_name}</h1>
                                        <h2><i>Welcome to {state.website_config.salesman_name}</i></h2>
                                        <h3>Welcome to {state.website_config.salesman_name}</h3>
                                        <h4><i>Welcome to {state.website_config.salesman_name}</i></h4>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <Row>


                                {props.webSettings.primary_color && (
                                    <>
                                        <Col md="4" sm={12}>
                                            <FormGroup>
                                                <Label htmlFor="color">Primary color
                                                    <small> <span className=" fa fa-info "
                                                                  title={"--bs-primary"}/></small>
                                                </Label>
                                                <AvField
                                                    name="website_config.primary_color"
                                                    value={state.website_config.primary_color}
                                                    type="color"
                                                    className="form-control"
                                                    id="color"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" sm={12}>
                                            <FormGroup>
                                                <Label htmlFor="color">Text on Primary Background

                                                    <small> <span className=" fa fa-info "
                                                                  title={"--text-on-primary"}/></small>
                                                </Label>
                                                <AvField
                                                    name="website_config.text_on_primary"
                                                    value={state.website_config.text_on_primary}
                                                    type="color"
                                                    className="form-control"
                                                    id="color"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </>

                                )}

                            </Row>
                            <Row>
                                {props.webSettings.secondary_color && (
                                    <>
                                        <Col md="4" sm={12}>
                                            <FormGroup>
                                                <Label htmlFor="color">Secondary Color <small> <span
                                                    className=" fa fa-info "
                                                    title={"--bs-secondary"}/></small></Label>
                                                <AvField
                                                    name="website_config.secondary_color"
                                                    value={state.website_config.secondary_color}
                                                    type="color"
                                                    className="form-control"
                                                    id="color"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" sm={12}>
                                            <FormGroup>
                                                <Label htmlFor="color">Text on Secondary Background <small> <span
                                                    className=" fa fa-info "
                                                    title={"--text-on-secondary"}/></small></Label>
                                                <AvField
                                                    name="website_config.text_on_secondary"
                                                    value={state.website_config.text_on_secondary}
                                                    type="color"
                                                    className="form-control"
                                                    id="color"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </>
                                )}

                            </Row>
                            {(props.webSettings.menu_color ||
                                props.webSettings.sub_menu_color ||
                                    props.webSettings.sub_menu_font_color ||
                                props.webSettings.menu_font_color
                            ) && (
                                <p className="card-title-desc mb-2 mt-3">Menu Theming</p>
                            )}
                            <Row>
                                {props.webSettings.menu_color && (
                                    <Col md="3" sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="color">Menu Background Color</Label>
                                            <AvField
                                                name="website_config.menu_color"
                                                value={state.website_config.menu_color}
                                                type="color"
                                                className="form-control"
                                                id="color"
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                                {props.webSettings.sub_menu_color && (
                                    <Col md="3" sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="color">
                                                Contact Settings Background Color
                                            </Label>
                                            <AvField
                                                name="website_config.sub_menu_color"
                                                value={state.website_config.sub_menu_color}
                                                type="color"
                                                className="form-control"
                                                id="color"
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                                {props.webSettings.menu_font_color && (
                                    <Col md="3" sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="color">Main Menu Font Color</Label>
                                            <AvField
                                                name="website_config.menu_font_color"
                                                value={state.website_config.menu_font_color}
                                                type="color"
                                                className="form-control"
                                                id="color"
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                                {props.webSettings.sub_menu_font_color && (
                                    <Col md="3" sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="color">Contact Settings Font Color</Label>
                                            <AvField
                                                name="website_config.sub_menu_font_color"
                                                value={state.website_config.sub_menu_font_color}
                                                type="color"
                                                className="form-control"
                                                id="color"
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>

                            {props.webSettings.footer_background_color && (
                                <>
                                    <p className="card-title-desc mb-2 mt-3">Footer Theming</p>
                                    <Row>
                                        <Col md="3" sm={12}>
                                            <FormGroup>
                                                <Label htmlFor="color">Footer Background Color</Label>
                                                <AvField
                                                    name="website_config.footer_background_color"
                                                    value={
                                                        state.website_config.footer_background_color
                                                    }
                                                    type="color"
                                                    className="form-control"
                                                    id="color"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" sm={12}>
                                            <FormGroup>
                                                <Label htmlFor="color">Footer Text Color</Label>
                                                <AvField
                                                    name="website_config.footer_text_color"
                                                    value={
                                                        state.website_config.footer_text_color
                                                    }
                                                    type="color"
                                                    className="form-control"
                                                    id="color"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label htmlFor="global_css">Website Global CSS</Label>
                                                <AvField
                                                    name="website_config.global_css"
                                                    value={
                                                        state.website_config.global_css
                                                    }
                                                    type="textarea"
                                                    className="form-control"
                                                    id="global_css"
                                                    rows={10}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    }
                />
            </CardBody>
        </Card>
    )
};

ColorSchemaSettings.propTypes = {
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    handleFile: PropTypes.func,
    savePageButton: PropTypes.element,
    isAdmin: PropTypes.bool,
};

export default ColorSchemaSettings
