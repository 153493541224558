import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import { del, get } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { page_limit } from "../../config";
import pageColumns from "../../page.columns";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import { showError } from "../../helpers/util.helper";
import toastr from "toastr";
import NoDataAvailable from "../../components/NoDataAvailable";
import { TableLoader } from "../../components/Loader";
import SettingsImagePreview from "../Settings/util/image-preview";

const Service = ({ match }) => {
  let history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingServiceId, setDeletingServiceId] = useState(null);
  useEffect(() => {
    getService({ limit, offset });
  }, []);

  const actions = [
    {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editService
    }, {
      name: "Delete",
      icon: "fas fa-trash",
      color: "danger",
      fn: openDeleteModal
    }];
  const limit = page_limit;
  const offset = 0;
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState({
    columns: pageColumns.service,
    rows: []
  });

  function pageChange(e) {
    getService({ limit, offset: (limit * (e - 1)) });
  }

  const getService = async ({ limit, offset }) => {
    setPageLoading(1);
    const serviceData = await get(url.SERVICES, {
      params: {
        limit, offset
      }
    });
    setPageLoading(0);
    serviceData.data = setServiceData(serviceData.data);
    let _d = { ...data, rows: serviceData.data };
    setData(_d);
    setTotalPages(serviceData.total_count);
  };


  function editService({ service_id }) {
    history.push("/editService/" + service_id);
  }

  function addService() {
    history.push("/addService");
  }

  async function deleteService() {
    const response = await del(url.SERVICES + "/" + deletingServiceId);
    if (!response.api_status) {
      let _d = data.rows.filter(d => d.service_id !== deletingServiceId);
      setData({ ...data, rows: _d });
      setDeletingServiceId(null);
      setDeleteModal(false);
      toastr.success("Service deleted successfully.", "Success");
    } else {
      showError(response);
    }
  }

  function openDeleteModal({ service_id }) {
    setDeletingServiceId(service_id);
    setDeleteModal(true);
  }

  function setServiceData(services) {
    console.log(services, 'are services--');
    if (services && services.length) {
      return services.map(service => {
        if (service.service_photo) {
          service.service_photo = <SettingsImagePreview image={service.service_photo} width="100px" height="100px"/>;
        }
        return service
      });
    }
    return [];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Service List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                      data.rows.length
                        ? <AETable columns={data.columns}
                                   rows={data.rows}
                                   itemsPerPage={limit}
                                   loading={pagesLoading}
                                   addLink={addService}
                                   actions={actions}
                                   totalCount={totalPages}
                                   pageFn={pageChange}/>
                        :
                        <NoDataAvailable module="services"/>
                  }

                </CardBody>
                <DeleteModal
                  onCloseClick={() => {
                    setDeletingServiceId(null);
                    setDeleteModal(false);
                  }}
                  onDeleteClick={deleteService}
                  show={deleteModal}>
                </DeleteModal>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Service;
