import {get, post, put, resetHeaders} from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
export const loginUserApi = data => post(url.LOGIN, data)
export const websiteNotifications = (config = {}) =>
  get(url.NOTIFICATIONS, config)
export const readAllNotifications = (config = {}) =>
  put(url.NOTIFICATIONS_READ_MARK, config)
export const accessTokenLogin = () => post(url.ACEESS_TOKEN_LOGIN, {})

export const resetLogin = token => resetHeaders(token)

/*web Pages*/

export const webPageList = data =>
  get(url.WEB_PAGE_LIST, {
    params: data,
  })

export const websiteList = data =>
  get(url.WEBSITES, {
    params: data,
  })
