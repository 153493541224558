import PropTypes from "prop-types";
import {AvForm} from "availity-reactstrap-validation";
import {Col, Modal, Row} from "reactstrap";
import React from "react";

const FacebookLeadDetailsModal = (props) => {
  function handleValidSubmit() {
    props.onCloseFacebookModal();
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseFacebookModal();
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-spacer mt-0" id="myModalLabel">Lead Details</h5>
          <button
            type="button"
            onClick={() => {
              props.onCloseFacebookModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="12">
              <table className="table">
                {props.lead.map((details, idx) => <>
                  <tr>
                    <td>{details.label}</td>
                    <td>{details.value}</td>
                  </tr>
                </>)}
              </table>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >Close</button>
        </div>
      </AvForm>
    </Modal>
  );
};

FacebookLeadDetailsModal.propTypes = {
  lead: PropTypes.array,
};

export default FacebookLeadDetailsModal;
