import PropTypes from "prop-types";
import { Col, Modal } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import LeadFormAdd from "./lead-add.form";

const LeadAddModal = (props) => {
  let [data, setData] = useState({});


  return <>
    <Modal
      size="lg"
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseLeadModal();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">Add Contact</h5>
        <button
          type="button"
          onClick={() => {
            props.onCloseLeadModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Col md="12">
          <LeadFormAdd data={data}
                       handleFile={() => {
                         console.log("file");
                       }}
                       handleSubmit={props.onCloseLeadModal}/>
        </Col>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-primary waves-effect waves-light"
          onClick={() => {
            setData({...data, save: !data.save })
          }}
        >
          Save changes
        </button>
      </div>
    </Modal>
  </>;
};

LeadAddModal.propTypes = {
  openModal: PropTypes.bool,
  onCloseLeadModal: PropTypes.func
};

export default LeadAddModal;
