import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//Import Breadcrumb
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { AvField, AvForm } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import * as moment from "moment-timezone";

const taskPriorities = [{
  label: "Normal",
  value: "NORMAL"
}, {
  label: "Important",
  value: "IMPORTANT"
}, {
  label: "High Priority",
  value: "HIGH_PRIORITY"
}];
const AddLeadTask = ({ task_id, save, close, data = {} }) => {
  const [convertedContent, setConvertedContent] = useState("");
  const [priority, setPriority] = useState();
  const [taskNotification, setTaskNotification] = useState();
  const [taskTitle, setTaskTitle] = useState(data.task_title || "");
  const [startDate, setstartDate] = useState(new Date());
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );
  const startDateChange = date => {
    setstartDate(date);
  };
  useEffect(async function() {

    if (data) {
      await setTask(data);
    }
  }, []);

  async function setTask(response) {
    const blocksFromHtml = htmlToDraft(response.task_description || "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const state = EditorState.createWithContent(contentState);
    setEditorState(state);
    setstartDate(moment(response.task_due_date).toDate());
  }

  async function saveTask(values) {
    onEditorStateChange();
    let output = {
      ...values,
      task_title: taskTitle,
      task_due_date: startDate,
      task_priority: priority
    };
    save(output);
  }


  function onEditorStateChange(e) {
    if (e) {
      setEditorState(e);
    }
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const value = draftToHtml(
      rawContentState
    );
    setConvertedContent(value);
  };
  return (
    <>
      <AvForm className="outer-repeater" onValidSubmit={(e, values) => {
        if (data) {
          saveTask({ ...data, ...values });
        } else {
          saveTask({ values });
        }
      }}>

        <FormGroup className="mb-4" row>
          <Label
            htmlFor="taskname"
            className="col-form-label col-lg-2"
          >
            Task Name
          </Label>
          <Col lg="10">
            <Input
              id="taskname"
              name="taskname"
              type="text"
              value={taskTitle}
              className="form-control"
              onChange={(e) => {
                setTaskTitle(e.target.value);
              }}
              placeholder="Enter Task Name..."
              required={true}
            />
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">
            Task Description
          </Label>
          <Col lg="10">
            <AvField name="task_description" type="textarea"
                     value={data.task_description}
                     rows={5} placeholder="Write something about the task..."/>
          </Col>
        </FormGroup>

        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">
            Task Date
          </Label>
          <Col lg="10">
            <Row>
              <Col md={6} className="pr-0">
                <DatePicker
                  minDate={new Date()}
                  showTimeSelect={true}
                  className="form-control mb-2"
                  selected={startDate}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  date
                  onChange={startDateChange}
                />

              </Col>
            </Row>
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">
            Task Date
          </Label>
          <Col lg="10">
            <Row>
              <Col md={6} className="pr-0">
                <AvField type="select" name="task_priority" value={data.task_priority}
                         onChange={(e) => {
                           console.log(e.target.value);
                           setPriority(e.target.value)
                         }}>
                  {
                    taskPriorities.map(pr => <option value={pr.value}>{pr.label}</option>)
                  }
                </AvField>
              </Col>
            </Row>
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">
            Notify Me
          </Label>
          <Col lg="10">
            <Row>
              <Col md={6} className="pr-0">
                <AvField type="select" name="task_notification" value={data.task_notification}
                         onChange={(e) => {
                           setTaskNotification(e.target.value)
                         }}>
                  <option value="0">On Exact Time</option>
                  <option value="15">15 mins before</option>
                  <option value="30">30 mins before</option>
                  <option value="45">45 mins before</option>
                  <option value="60">60 mins before</option>
                  <option value="-1">No notification</option>
                </AvField>
              </Col>
            </Row>
          </Col>
        </FormGroup>
        <hr/>
        <div className="d-flex justify-content-end">
         <div>
           <Button
             type="button"
             color="secondary"
             onClick={() => {
               close();
             }}
           >
             Close
           </Button>
           <Button type="submit" color="primary" className="ml-2">
             Save
           </Button>
         </div>
        </div>
      </AvForm>

    </>
  );
};


AddLeadTask.propTypes = {
  save: PropTypes.func,
  task_id: PropTypes.string,
  close: PropTypes.func
}
export default AddLeadTask;
