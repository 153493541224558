import {Button, Col, FormGroup, Label, Modal} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import PropTypes from "prop-types";


const AccordionModal = (props) => {
  let [rows, setRows] = useState(props.config.rows);

  function handleValidSubmit(event, values) {
    let rowsGroup = [];
    console.log(values, "are values");
    values.title.forEach((item, index) => {
      rowsGroup.push({ title: values.title[index], description: values.description[index], image: rows[index].image });
    });
    props.onCloseAccordionModal({ config: { rows: rowsGroup, accordion_title: values.accordion_title }, el: props.el });
  }

  function addNewRow() {
    let r = { title: "", description: "" };
    setRows([...rows, r]);
  }

  function deleteRow(id) {
    let r = rows.filter((d, i) => i !== id);
    setRows(r);
  }

  function handleFile(e, id) {
    const reader = new FileReader();
    reader.onload = function (e) {
      let r = rows.map((d, i) => {
        if (id === i) {
          d.image = e.target.result;
        }
        return d;
      });
      setRows(r);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  function deleteImage(id) {
    let r = rows.map((d, i) => {
      ;
      if (i === id) {
        delete d.image;
      }
      return d;
    });
    setRows(r);
  }


  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseAccordionModal({ title: props.title, el: props.el });
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={() => {
              props.onCloseAccordionModal({ title: props.title, el: props.el });
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Accordion Title</Label>
              <AvField
                name="accordion_title"
                value={props.config.accordion_title}
                placeholder="Lorum Ipsum"
                type="text"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          {
            rows.map((row, idx) => {
              return (
                <Col md="12" key={"main" + idx}>
                  <p key={idx + "para"} className="d-flex align-items-center justify-content-between">
                    <b>Step {idx + 1}</b> <span className="fas fa-minus-circle"
                                                onClick={() => {
                                                  deleteRow(idx);
                                                }}
                                                style={{ color: 'red', cursor: 'pointer' }}/></p>
                  <FormGroup key={idx + "title"}>
                    <Label htmlFor="validationCustom01">Heading</Label>
                    <AvField
                      name={"title." + idx}
                      value={row.title}
                      placeholder="Lorum Ipsum"
                      type="text"
                      rows="20"
                      className="form-control"
                      required
                      id="validationCustom03"
                    />
                  </FormGroup>
                  <FormGroup key={idx + "description"}>
                    <Label htmlFor="validationCustom01">Description</Label>
                    <AvField
                      name={"description." + idx}
                      value={row.description}
                      placeholder="Lorum Ipsum"
                      type="textarea"
                      rows="3"
                      className="form-control"
                      required
                      id={"validationDescription" + idx}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="validationCustom01" className="d-flex align-items-center justify-content-between">
                      <span>Project Photo</span>
                      {
                        row.image && <span className="fas fa-minus-circle"
                                           onClick={() => {
                                             deleteImage(idx);
                                           }}
                                           style={{ color: 'red', cursor: 'pointer' }}/>
                      }
                    </Label>
                    {
                      !row.image && <AvField
                        name="post"
                        type="file"
                        className="form-control"
                        id="validationCustom03"
                        onChange={event =>
                          handleFile(event, idx)
                        }
                      />
                    }
                  </FormGroup>
                  <Col md="12">
                    {
                      <img src={row.image}
                           alt="" className="img img-thumbnail"/>
                    }
                  </Col>
                </Col>);
            })
          }
          <Col md="12">
            <Button
              onClick={() => {
                addNewRow();
              }}
              color="success"
              className="mt-2">
              Add Step
            </Button>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

AccordionModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseAccordionModal: PropTypes.func
};

export default AccordionModal;
