import {
  USER_ACCESS_TOKEN_LOGIN,
  USER_ACCESS_TOKEN_LOGIN_FAILED,
  USER_ACCESS_TOKEN_LOGIN_SUCCESS,
} from './actionTypes';

export const userAccessTokenLogin = () => ({
  type: USER_ACCESS_TOKEN_LOGIN,
});
export const userAccessTokenSuccess = (user) => ({
  type: USER_ACCESS_TOKEN_LOGIN_SUCCESS,
  payload: user,
});

export const userAccessTokenLoginFailed = (error) => ({
  type: USER_ACCESS_TOKEN_LOGIN_FAILED,
  payload: error,
});
