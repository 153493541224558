import PropTypes from "prop-types";
import {AvForm, AvField} from "availity-reactstrap-validation";
import {Col, FormGroup, Label, Modal} from "reactstrap";
import React, {useEffect, useState} from "react";
import Select from "react-select";

const FacebookConnectAccountModal = (props) => {
  let [selectedPages, setSelectedPages] = useState([]);
  let [selectedAdAccounts, setSelectedAdAccounts] = useState([]);

  function handleValidSubmit() {
    props.onCloseFacebookModal({
      page_id: selectedPages,
      ad_account_id: selectedAdAccounts
    });
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseFacebookModal({});
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-spacer mt-0" id="myModalLabel">Select Credentials</h5>
          <button
            type="button"
            onClick={() => {
              props.onCloseFacebookModal({});
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Page</Label>
              <AvForm>
                <AvField type={'select'} onChange={(e) => setSelectedPages(e.target.value)} name="page" required>
                  <option value=""/>
                  {props.pages.map((page, key) => (
                      <option value={page.id} key={key}>{page.name}</option>
                  ))}
                </AvField>
              </AvForm>
            </FormGroup>
          </Col>
          {props.ad_accounts?.length > 0 && <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Ad Account</Label>
              <AvForm>
                <AvField type={'select'} onChange={(e) => setSelectedAdAccounts(e.target.value)} name="ad_account" required>
                  <option value=""/>
                  {props.ad_accounts.map((page, key) => (
                      <option value={page.id} key={key}>{page.name}</option>
                  ))}
                </AvField>
              </AvForm>
            </FormGroup>
          </Col>}
        </div>
        <div className="modal-footer">
          <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

FacebookConnectAccountModal.propTypes = {
  pages: PropTypes.array,
  ad_accounts: PropTypes.array,
  openModal: PropTypes.bool,
  onCloseFacebookModal: PropTypes.func
};

export default FacebookConnectAccountModal;
