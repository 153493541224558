import {Col, FormGroup, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import Select from "react-select";
import ComponentWidthInput from "./ComponentWidthInput";

const optionGroup = [
  {
    label: "Muncipality",
    options: [
      {label: "Brampton", value: "Brampton"},
      {label: "Toronto", value: "Toronto"},
      {label: "Mississauga", value: "Mississauga"},
      {label: "Vaughan", value: "Vaughan"},
      {label: "Caledon", value: "Caledon"},
      {label: "RichmondHill", value: "RichmondHill"},
      {label: "Oakville", value: "Oakville"}
    ]
  }
];


const PropertyIsotopSettings = (props) => {
  const [showSlider, setShowSlider] = useState(props.config.showSlider);

  const [selectedMulti, setSelectedMulti] = useState(null);
  useState(function () {
    if (props.config && props.config.properties) {
      setSelectedMulti(props.config.properties);
    }
  });

  function handleValidSubmit(event, values) {
    props.updateConfig({...props.config, properties: selectedMulti, showSlider, ...values});
  }

  return (

      <AvForm onValidSubmit={handleValidSubmit}>

        <Row className="py-2">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Heading</Label>
              <AvField
                  name="heading"
                  value={props.config.heading}
                  placeholder="Lorum Ipsum"
                  type="text"
                  className="form-control"
                  id="validationCustom04"
              />
            </FormGroup>
          </Col> <Col md="12">
          <FormGroup>
            <Label htmlFor="validationCustom01">Sub Heading</Label>
            <AvField
                name="description"
                value={props.config.description}
                placeholder="Lorum Ipsum"
                type="textarea"
                rows="5"
                className="form-control"
                id="validationCustom04"
            />
          </FormGroup>
        </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">City/Municipality</Label>
              <Select
                  value={selectedMulti}
                  isMulti={true}
                  onChange={(values) => {
                    setSelectedMulti(values);
                  }}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <ComponentWidthInput {...props.config}/>
          </Col>
        </Row>
        <div className="modal-footer">
          <button
              onClick={() => props.close()}
              type="button"
              className="btn btn-danger waves-effect waves-light"
          >Cancel</button>
          <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
  );
};

export default PropertyIsotopSettings;
