import {Button, Col, FormGroup, Label, Modal, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useState} from "react";
import PropTypes from "prop-types";


const GridModal = (props) => {
  let [rows, setRows] = useState(props.config.rows);

  function handleValidSubmit(event, values) {
    let rowsGroup = [];
    values.title.forEach((item, index) => {
      rowsGroup.push({ title: values.title[index], description: values.description[index], image: rows[index].image });
    });
    props.onCloseGridModal({ config: { rows: rowsGroup, grid_title: values.grid_title, grid_description: values.grid_description }, el: props.el });
  }

  function addNewRow() {
    let r = { title: "", description: "" };
    setRows([...rows, r]);
  }

  function deleteRow(id) {
    let r = rows.filter((d, i) => i !== id);
    setRows(r);
  }

  function handleFile(e, index) {
    const reader = new FileReader();
    reader.onload = function (e) {
      setRows(rows.map((row, i) => {
        if (i === index) {
          row.image = e.target.result;
        }
        return row;
      }));
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  function deleteImage(index) {
    setRows(rows.map((row, i) => {
      if (i === index) {
        row.image = undefined;
      }
      return row;
    }));
  }

  function closeModal() {
    props.onCloseGridModal({ config: props.config, el: props.el })
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={closeModal}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={closeModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="validationCustom01">Grid Title</Label>
                <AvField
                  name="grid_title"
                  value={props.config.grid_title}
                  placeholder="Lorum Ipsum"
                  type="text"
                  className="form-control"
                  id="validationCustom03"
                />
              </FormGroup>
            </Col>

            <Col md="12">
                <FormGroup>
                  <Label htmlFor="validationCustom01">Grid Description</Label>
                  <AvField
                    name="grid_description"
                    value={props.config.grid_description}
                    placeholder="Lorum Ipsum"
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                  />
                </FormGroup>
            </Col>
            {
              rows.map((row, idx) => {
                return (
                  <>
                    <Col md="12" className="mt-4">
                      <p key={idx + "para"} className="d-flex align-items-center justify-content-between">
                        <b>Item {idx + 1}</b> <span className="fas fa-minus-circle"
                                                    onClick={() => {
                                                      deleteRow(idx);
                                                    }}
                                                    style={{ color: 'red', cursor: 'pointer' }}/></p>
                    </Col>
                    <Col md="12">
                      <FormGroup key={idx + "title"}>
                        <Label htmlFor="validationCustom01">Heading</Label>
                        <AvField
                          name={"title." + idx}
                          value={row.title}
                          placeholder="Lorum Ipsum"
                          type="text"
                          rows="20"
                          className="form-control"
                          id="validationCustom03"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup key={idx + "description"}>
                        <Label htmlFor="validationCustom01">Description</Label>
                        <AvField
                          name={"description." + idx}
                          value={row.description}
                          onChange={() => {
                            console.log("updating item", idx);
                          }
                          }
                          placeholder="Lorum Ipsum"
                          type="textarea"
                          rows="3"
                          className="form-control"
                          id="validationCustom03"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="validationCustom01"
                               className="d-flex align-items-center justify-content-between">
                          <span>Photo</span>
                          {
                            row.image && <span className="fas fa-minus-circle"
                                               onClick={() => {
                                                 deleteImage(idx);
                                               }}
                                               style={{ color: 'red', cursor: 'pointer' }}/>
                          }
                        </Label>
                        {
                          !row.image && <AvField
                            name="post"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            onChange={event =>
                              handleFile(event, idx)
                            }
                          />
                        }
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      {
                        <img src={row.image}
                             alt="" className="img img-thumbnail" style={{maxHeight: '200px'}}/>
                      }
                    </Col>
                  </>);
              })
            }
            <Col md="12">
              <Button
                onClick={() => {
                  addNewRow();
                }}
                color="success"
                className="mt-1">
                Add Step
              </Button>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

GridModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseGridModal: PropTypes.func
};

export default GridModal;
