import { Col, FormGroup, Label, Modal } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import PropTypes from "prop-types"


const PropertyModal = (props) => {

  useState(function() {
    console.log(props, 'are props provided')
  })

  function handleValidSubmit(event, values) {
    props.onCloseModal({ config: {...props.config, ...values}, el: props.el })
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onCloseModal({ config: props.config, el: props.el })
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={() => {
              props.onCloseModal({ config: props.config, el: props.el })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Heading</Label>
              <AvField
                name="title"
                value={props.config.title || 'Latest Properties'}
                placeholder=""
                type="text"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">City/Municipality</Label>
              <AvField
                name="municipality"
                value={props.config.municipality}
                placeholder="Brampton"
                type="text"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Min Price</Label>
              <AvField
                name="min_price"
                value={props.config.min_price}
                placeholder="100000"
                type="number"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Max Price</Label>
              <AvField
                name="max_price"
                value={props.config.max_price}
                placeholder="1000000"
                type="number"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Total Properties</Label>
              <AvField
                name="properties_count"
                value={props.config.properties_count}
                placeholder="6"
                type="number"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

PropertyModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseModal: PropTypes.func
}

export default PropertyModal
