import { Col, FormGroup, Label, Modal } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import PropTypes from "prop-types";


const AddCategoryModal = (props) => {

  function handleValidSubmit(event, values) {
    props.onClose({ category_name: values.category_name });
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onClose({ category_name: "" });
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={() => {
              props.onClose({ category_name: "" });
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Category Name</Label>
              <AvField
                name="category_name"
                value={props.category_name}
                placeholder="Marketing"
                type="text"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

AddCategoryModal.propTypes = {
  category_name: PropTypes.string,
  openModal: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddCategoryModal;
