import { map } from "lodash";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import React, { useState } from "react";
import LeadEmailDetails from "../Emails/lead-email-details";
import { Card, CardBody, Col, Row } from "reactstrap";

const LeadEmailsList = (props) => {
  const [modal, setModal] = useState({ open: false });
  return (
    <Card>
      <CardBody>
       <Row>
         {
           props.emails && props.emails.length ?
            <Col md="12">
              <div className="table-responsive" id="tasks-list">
                <table className="table table-nowrap table-centered mb-0">
                  <thead>
                  <tr>
                    <td>Subject</td>
                    <td>Date</td>
                    <td>Action</td>
                  </tr>
                  </thead>
                  <tbody>
                  {map(props.emails, (item, i) => (
                    <tr key={i}>

                      <td>
                        <a onClick={() => setModal({
                          open: true,
                          email: { title: item.email_title, description: item.email_message }
                        })}>{item.email_title}</a>
                      </td>
                      <td>
                        <Moment  withTitle titleFormat="LLL" fromNow >{item.created}</Moment>

                      </td>
                      <td>
                      <span className="btn-pill btn fa fa-eye text-primary" onClick={() => setModal({
                        open: true,
                        email: { title: item.email_title, description: item.email_message }
                      })}/>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                {modal.open && <LeadEmailDetails
                  openModal={modal.open}
                  closeModal={() => {
                    setModal({ open: false });
                  }
                  }
                  email={modal.email}
                />}
              </div>
            </Col>
             :
             <Col md={12} className="text-center">
               <h5>No Email sent</h5>
               <button className="btn btn-primary mt-3" onClick={() => props.setModal({ showModal: true, email: true })}>
                 Send Email
               </button>
             </Col>
         }
       </Row>

      </CardBody>
    </Card>
  );
};

export default LeadEmailsList;
