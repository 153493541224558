import React, {useEffect, useState} from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import {get, post, put} from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import toastr from "toastr"
import {useHistory, useParams} from "react-router-dom"
import {showError} from "../../helpers/util.helper"

const EditPreConstruction = () => {
    const history = useHistory();
    let {pre_construction_id} = useParams();
    const [data, setData] = useState({});
    useEffect(async function () {
        if (pre_construction_id) {
            await getPreConstruction()
        }
    }, []);

    async function handleValidSubmit(event, values) {
        let d = {...data, ...values};
        if (!Array.isArray(d.details)) {
            d.details = d.details.split('\n').filter(d => d.trim()).map(d => {
                let text = d.split(':');
                if (text.length > 1) {
                    return {title: text[0] + ':', text: text[1]}
                }
                return {title: '', text: text[0]};
            });
        }
        await savePreConstruction(d)
    }


    async function getPreConstruction() {
        const response = await get(url.PRE_CONSTRUCTION_DETAILS + "/" + pre_construction_id);
        if (!response.api_status) {
            setData(response)
        } else {
            showError(response)
        }
    }

    async function savePreConstruction(data) {
        let response;
        if (pre_construction_id) {
            response = await put(url.EDIT_PRE_CONSTRUCTION + '/' + pre_construction_id, data)
        } else {
            response = await post(url.EDIT_PRE_CONSTRUCTION, data)
        }
        if (!response.api_status) {
            toastr.success("Pre Construction updated successfully.", "Success");
            history.push("/preConstructions")
        } else {
            showError(response)
        }
    }

    function back() {
        history.push("/preConstructions")
    }


    function handleFile(e, key) {
        const reader = new FileReader();
        reader.onload = function(e) {
            setData({ ...data, [key]: e.target.result });
        };
        reader.readAsDataURL(e.target.files[0]);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Pre Construction Details"
                                 breadcrumbItem={pre_construction_id ? "Edit Pre-Construction" : "Add Pre-Construction"}/>
                </div>
                <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">PreConstruction info</h4>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="project">Project Name</Label>
                                                <AvField
                                                    name="project"
                                                    value={data.project}
                                                    placeholder=""
                                                    type="text"
                                                    className="form-control"
                                                    id="project"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="price">Price Range</Label>
                                                <AvField
                                                    name="price"
                                                    value={data.price}
                                                    placeholder=""
                                                    type="text"
                                                    className="form-control"
                                                    id="price"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="developerName">Developer</Label>
                                                <AvField
                                                    name="developerName"
                                                    value={data.developerName}
                                                    placeholder=""
                                                    type="text"
                                                    className="form-control"
                                                    id="developerName"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label htmlFor="communityAddress">Address</Label>
                                                <AvField
                                                    name="communityAddress"
                                                    value={data.communityAddress}
                                                    placeholder=""
                                                    type="text"
                                                    className="form-control"
                                                    id="communityAddress"
                                                />
                                            </FormGroup>
                                        </Col><Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="summary">Summary</Label>
                                            <AvField
                                                name="summary"
                                                value={data.summary}
                                                placeholder=""

                                                rows={10}
                                                type="textarea"
                                                className="form-control"
                                                id="summary"
                                            />
                                        </FormGroup>
                                    </Col><Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="description">Details</Label>
                                            <AvField
                                                name="description"
                                                value={data.description}
                                                placeholder=""
                                                type="textarea"
                                                rows={10}
                                                className="form-control"
                                                id="description"
                                            />
                                        </FormGroup>
                                    </Col><Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="details">Details</Label>
                                            <AvField
                                                name="details"
                                                value={data.details?.map(d => (d.title + ' ' + d.text)).join("\n")}
                                                placeholder=""
                                                type="textarea"
                                                rows={10}
                                                className="form-control"
                                                id="details"
                                            />
                                        </FormGroup>
                                    </Col>
                                        <Col md={12}>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom01">Photo</Label>
                                                        <AvField
                                                            name="photo"
                                                            type="file"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            onChange={event =>
                                                                handleFile(event, "image")
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    {
                                                        <img src={data.image}
                                                             alt="" className="img img-thumbnail"/>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12 text-center">
                            <Card>
                                <CardBody>
                                    <Button color="secondary" className="py-2 mr-2" type="submit" onClick={back}>
                                        Back
                                    </Button>
                                    <Button color="primary" className="py-2" type="submit">
                                        {
                                            data.pre_construction_id ?
                                                "Update"
                                                : "Save"
                                        }
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </AvForm>
            </div>
        </React.Fragment>
    )
};

export default EditPreConstruction
