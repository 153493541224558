import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
//i18n
import {withTranslation} from "react-i18next";
// Redux
import {connect} from "react-redux";
import {Link, useHistory, withRouter} from "react-router-dom";
import UpdatePasswordModal from './UpdatePassword'
import {put} from "../../../helpers/api_helper";
import {UPDATE_PASSWORD} from "../../../helpers/url_helper";
import {showError, showSuccess} from "../../../helpers/util.helper";
// users

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [passwordModal, setPasswordModal] = useState({isOpen: false, closeModal: savePassword});
  const history = useHistory();

  const [username, setusername] = useState("");
  const {user} = props;

  useEffect(() => {
    if (user && user.user_name) {
      setusername(user.user_name);
    }
    if (localStorage.getItem("authUser")) {
      if (
          process.env.REACT_APP_DEFAULTAUTH === "fake" ||
          process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success, props.user]);

  function openPasswordModal() {
    setPasswordModal({...passwordModal, isOpen: true});
  }

  async function savePassword(data) {
    setPasswordModal({...passwordModal, isOpen: false});
    if (data && data.password) {
      /*updating password*/
      const response = await put(UPDATE_PASSWORD, {password: data.password});
      if (response.api_status) {
        showError(response);
      } else {
        showSuccess({message: 'Password updated successfully.'});
      }
    }
  }

  return (
      <React.Fragment>
        <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block"
        >
          <DropdownToggle
              className="btn header-item waves-effect"
              id="page-header-user-dropdown"
              tag="button"
          >
            {user && user.name ?
                <span className={"user-pic-name"}>{user.name.split(" ").map(d => d[0]).join("")}</span> : ""}
            <span className="d-none d-xl-inline-block ml-2 mr-1">{username}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
          </DropdownToggle>
          <DropdownMenu right>

            <DropdownItem tag="a" onClick={openPasswordModal}>
              {" "}
              <i className="bx bx-pencil font-size-16 align-middle mr-1"/>
              {props.t("Change Password")}{" "}
            </DropdownItem>
            <div className="dropdown-divider"/>
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"/>
              <span>{props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
        <UpdatePasswordModal {...passwordModal}/>
      </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  user: PropTypes.any
};

const mapStatetoProps = state => {
  const {error, success} = state.Profile;
  const {user} = state.user;
  return {error, success, user};
};

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
