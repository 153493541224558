import { formatPhone } from "../../../helpers/util.helper";
import React from "react";

const LeadBasicInfo = ({ lead}) => {
  return <div className="text-muted text-left text-sm-right my-2 mt-sm-0">
    <p className="mb-2"><b>Info Provided</b></p>
    <p className="mb-1">{lead.name}</p>
    <p className="mb-1">
      <a href={"tel:" + lead.contact}>{formatPhone(lead.contact)}</a>
    </p>
    <p className="mb-1">
      <a onClick={() => setModal({ showModal: true, email: true })} href="javascript:void(0)">
        {lead.email}
      </a>
    </p>
  </div>;
}; 
export default LeadBasicInfo;
