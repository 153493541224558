import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import DashboardTask from "./DashboradTask";
import DashboardCall from "./upcoming-calls";

const Dashboard = props => {
  const [data, setData] = useState([]);
  const [statsData, setStatsData] = useState({
    posts: [],
    tasks: [],
    testimonials: [],
    calls: [],
    visits: { today: 0, month: 0, all: 0 }
  });
  const dashboardStats = [
    {
      title: "Total Blog Posts",
      key: "post_count",
      value: "0",
      icon: "fa fa-newspaper",
      link: "/blog"
    }, {
      title: "Total Testimonial",
      value: "0",
      key: 'testimonial_count',
      icon: 'fa fa-comments',
      link: '/testimonials'
    }
  ];
  useEffect(async function () {
    await getDashboardStats();
  }, []);

  async function getDashboardStats() {
    const dashboard = await get(url.DASHBOARD);
    dashboardStats.forEach(stat => {
      if (typeof dashboard[stat.key] !== "undefined") {
        stat.value = dashboard[stat.key];
      }
    });
    setStatsData({
      ...statsData,
      posts: dashboard.posts,
      tasks: (dashboard.tasks && dashboard.tasks.filter(d => !d.lcl_id)) || [],
      calls: (dashboard.tasks && dashboard.tasks.filter(d => d.lcl_id).slice(0, 5)) || [],
      testimonials: dashboard.testimonials,
      visits: {
        daily: dashboard.traffic?.daily,
        monthly: dashboard.traffic?.monthly,
        yearly: dashboard.traffic?.yearly
      }
    });
    setData(dashboardStats);

  }

  function upcomingCalls() {
    let todayCalls = statsData.calls.filter(c => c.isToday);
    let todayIds = todayCalls.map(d => d.lead_task_id);
    let otherCalls = statsData.calls.filter(c => !todayIds.includes(c.lead_task_id));
    return [...todayCalls, ...otherCalls];
  }

  function upcomingTasks() {
    return statsData.tasks.filter(d => !d.isExpired && typeof d.isExpired === "boolean").slice(0, 5);
  }

  function pastDueTasks() {
    return statsData.tasks.filter(d => d.isExpired && typeof d.isExpired === "boolean").slice(0, 5);
  }

  function todayTasks() {
    return statsData.tasks.filter(d => d.isToday).slice(0, 5);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Dashboard</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <Row>
            <Col md="4">
              <Card>
                <CardBody>
                  <CardTitle>Today Tasks</CardTitle>
                  <ul className="list-group">
                    {todayTasks().length ? todayTasks().map((task, id) =>
                      <li className="list-group-item py-3" key={id}>
                        <DashboardTask task={task}/>
                      </li>
                    ) : "No data available"}
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <CardTitle>Upcoming Tasks</CardTitle>
                  <ul className="list-group">
                    {upcomingTasks().length ? upcomingTasks().map((task, id) =>
                      <li className="list-group-item py-3" key={id}>
                        <DashboardTask task={task}/>
                      </li>
                    ) : "No data available"}
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <CardTitle>Past Due Tasks</CardTitle>
                  <ul className="list-group">
                    {pastDueTasks().length ? pastDueTasks().map((task, id) =>
                      <li className="list-group-item py-3" key={id}>
                        <DashboardTask task={task}/>
                      </li>
                    ) : "No data available"}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Card>
                <CardBody>
                  <CardTitle>

                    Upcoming Calls</CardTitle>
                  <ul className="list-group">
                    {upcomingCalls().length ? upcomingCalls().map((task, id) =>
                      <li className="list-group-item py-3" key={id}>
                        <DashboardCall task={task}/>
                      </li>
                    ) : "No data available"}
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <CardTitle>Traffic</CardTitle>
                  <Row className=" mt-2">
                    <Col md={4}>
                      <p className="mb-1 text-muted">Daily</p>
                      <h4 className="m-0 p-0">{statsData.visits.daily}</h4>
                    </Col>
                    <Col md={4}>
                      <p className="mb-1 text-muted">Monthly</p>
                      <h4 className="m-0 p-0">{statsData.visits.monthly}</h4>
                    </Col>
                    <Col md={4}>
                      <p className="mb-1 text-muted">Yearly</p>
                      <h4 className="m-0 p-0">{statsData.visits.yearly}</h4>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {
              data.map((stat, id) =>
                <Col md="4" key={id}>
                  <Link to={stat.link}>
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <div className="media">
                          <div className="media-body">
                            <p className="text-muted fw-medium">{stat.title}</p>
                            <h4 className="mb-0">{stat.value}</h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title"><i className={stat.icon + " font-size-24"}/>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>

                </Col>
              )
            }
          </Row>

          <Row>
            <Col md="4">
              <Card>
                <CardBody>
                  <CardTitle>Posts</CardTitle>
                  <ul className="list-group">
                    {statsData.posts && statsData.posts.length && statsData.posts.map((post, id) =>
                      <li className="list-group-item py-3" key={id}>
                        <div className="me-3">
                          <img src={post.post_main_image} alt=""
                               className="mx-auto d-block rounded img img-thumbnail" style={{maxHeight: '200px'}}/>
                        </div>

                        <div className="align-self-center overflow-hidden me-auto mt-3">
                          <div>
                            <h5 className="font-size-14 text-truncate">
                              <Link to={'/editBlog/' + post.post_id}>
                                {post.post_title}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0">{post.created}</p>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <CardTitle>Testimonials</CardTitle>

                  <ul className="list-group">
                    {statsData.testimonials && statsData.testimonials.length ? statsData.testimonials.map((testimonial, id) =>
                      <li className="list-group-item py-3" key={id}>

                        <div className="align-self-center overflow-hidden me-auto mt-3">
                          <div>
                            <h5 className="font-size-14 text-truncate">
                              <Link to={'/editTestimonial/' + testimonial.testimonial_id}>
                                {testimonial.review}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0">{testimonial.reviewer_name}</p>
                          </div>
                        </div>
                      </li>
                    ) : 'No data availble.'}
                  </ul>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
