import { Col, FormGroup, Label, Modal } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import PropTypes from "prop-types"
import SettingsImagePreview from "../../Settings/util/image-preview";

let apiPath = process.env.REACT_APP_PUBLIC_URL;
const PageBackgroundModal = (props) => {
  let backgrounds = [
    apiPath + '/img/landing-bg-1.jpg',
    apiPath + '/img/landing-bg-2.jpg',
    apiPath + '/img/landing-bg-3.jpg'
  ]
  const [selectedBackground, setSelectedBackground] = useState();
  function handleValidSubmit(e, values) {
    props.onClosePageBackgroundModal({background: selectedBackground})
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => {
        props.onClosePageBackgroundModal({})
      }}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={() => {
              props.onClosePageBackgroundModal({})
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {
            backgrounds.map((background, key) => (
              <Col md="12" key={key}>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="background"
                    id={"background+" + key}
                    onChange={() => {
                      setSelectedBackground(background)
                    }}
                    checked={selectedBackground === background}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={"background+" + key}
                  >
                    <SettingsImagePreview image={background} width={'100%'}/>
                  </label>
                </div>

              </Col>
            ))
          }
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

PageBackgroundModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onClosePageBackgroundModal: PropTypes.func
}

export default PageBackgroundModal
