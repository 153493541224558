import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import {AvField} from "availity-reactstrap-validation";
import React from "react";
import PropTypes from "prop-types";
import FormWrapper from "../util/form-wrapper";

const WebsiteSEO = (props) => {
    return (
        <Card>
            <CardBody>
                <h4 className="card-title">Website SEO</h4>
                <FormWrapper
                    handleSubmit={props.handleSubmit}
                    data={props.data}
                    fields={
                        <>
                            <Row>
                                {
                                    props.webSettings.website_title && <Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="website_title">Website Title</Label>
                                            <AvField
                                                name="website_config.website_title"
                                                placeholder=""
                                                type="text"
                                                maxLength={60}
                                                errorMessage="This field is required."
                                                className="form-control"
                                                value={props.data.website_config.website_title}
                                                id="website_title"
                                                helpMessage={"Title length should be lesser than 60 characters"}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    props.webSettings.website_description && <Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="website-description">Website Description</Label>
                                            <AvField
                                                name="website_config.website_description"
                                                placeholder=""
                                                type="textarea"
                                                value={props.data.website_config.website_description}
                                                maxLength={150}
                                                errorMessage="This field is required."
                                                className="form-control"
                                                id="website-description"
                                                helpMessage={"Description length should be lesser than 150 characters"}
                                            />
                                        </FormGroup>
                                    </Col>
                                }

                            </Row>

                            <Row>
                                {
                                    props.webSettings.website_keywords && <Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="keywords">Website Keywords</Label>
                                            <AvField
                                                name="website_config.website_keywords"
                                                placeholder=""
                                                type="text"
                                                errorMessage="This field is required."
                                                className="form-control"
                                                value={props.data.website_config.website_keywords}
                                                id="keywords"
                                                helpMessage={"All keywords should be comma separated."}
                                            />
                                        </FormGroup>
                                    </Col>
                                }

                            </Row>
                            <Row>
                                {
                                    props.webSettings.property_title_meta && <Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="property_title_meta">Property meta title</Label>
                                            <AvField
                                                name="website_config.property_title_meta"
                                                placeholder=""
                                                type="textarea"
                                                rows="10"
                                                className="form-control"
                                                value={props.data.website_config.property_title_meta}
                                                id="property_title_meta"
                                            />
                                        </FormGroup>
                                    </Col>
                                }
     {
                                    props.webSettings.property_description_meta && <Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="property_description_meta">Property meta description</Label>
                                            <AvField
                                                name="website_config.property_description_meta"
                                                placeholder=""
                                                rows="10"
                                                type="textarea"
                                                className="form-control"
                                                value={props.data.website_config.property_description_meta}
                                                id="property_description_meta"
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                   {
                                    props.webSettings.property_description_meta && <Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="property_details_page_outline">Property Details Page Outline</Label>
                                            <AvField
                                                name="website_config.property_details_page_outline"
                                                placeholder=""
                                                rows="10"
                                                type="textarea"
                                                className="form-control"
                                                value={props.data.website_config.property_details_page_outline}
                                                id="property_details_page_outline"
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                
                                {
                                    props.webSettings.property_description_meta && <Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="property_details_page_keywords">Property Details Keywords</Label>
                                            <AvField
                                                name="website_config.property_details_page_keywords"
                                                placeholder=""
                                                rows="10"
                                                type="textarea"
                                                className="form-control"
                                                value={props.data.website_config.property_details_page_keywords}
                                                id="property_details_page_keywords"
                                            />
                                        </FormGroup>
                                    </Col>
                                }

                            </Row>
                        </>
                    }
                />
            </CardBody>
        </Card>
    );
};

WebsiteSEO.propTypes = {
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    handleFile: PropTypes.func,
    savePageButton: PropTypes.element
};

export default WebsiteSEO;
