import PropTypes from "prop-types"
import React, {useState} from "react"

import {connect} from "react-redux"
import {Link, useHistory} from "react-router-dom"
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logoLightPng from "../../assets/images/ae/default/agentedge.png"
import logoDark from "../../assets/images/ae/default/agentedge.png"
import logoSm from "../../assets/images/ae/default/ae.png"
// import images
//i18n
import {withTranslation} from "react-i18next"
// Redux Store
import {
    changeSidebarType,
    showRightSidebarAction,
    toggleAdminMenu,
    toggleLeftmenu,
    userAccessTokenLogin,
} from "../../store/actions"
import WebsiteDropdown from "../CommonForBoth/TopbarDropdown/websites-list"
// Reactstrap

const Header = props => {
  const [search, setsearch] = useState(false)
  const history = useHistory()

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu)
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile)
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile)
    }
  }

  function redirectUser() {
    if (!props.adminMenu) {
      history.push("/websites")
    } else {
      history.push("/pages")
    }
  }


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="50" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {!props.adminMenu && <WebsiteDropdown />}
            {/*<LanguageDropdown/>*/}

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {!props.adminMenu && <NotificationDropdown />}
            {props.user && +props.user.role_id === 1 && (
              <>
                <button className="btn header-item noti-icon waves-effect">
                  <div
                    className="custom-control custom-switch custom-switch-md d-inline-block"
                    dir="ltr"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitchsizemd"
                      checked={props.adminMenu}
                      onChange={() => {
                        props.toggleAdminMenu(!props.adminMenu)
                        redirectUser()
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitchsizemd"
                    >
                      {props.adminMenu ? "Admin" : "User"} {!props.adminMenu}
                    </label>
                  </div>
                </button>
              </>
            )}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  adminMenu: PropTypes.bool,
  toggleAdminMenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType, adminMenu } =
    state.Layout
  const { user } = state.user
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    adminMenu,
    user,
  }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  userAccessTokenLogin,
  toggleAdminMenu,
})(withTranslation()(Header))
