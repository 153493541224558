import {Col, FormGroup, Label, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import React from "react"
import PropTypes from "prop-types"
import ComponentWidthInput from "./ComponentWidthInput";


const DefaultSettings = (props) => {


    function handleValidSubmit(event, values) {
        props.updateConfig({...values})
    }

    return (
        <AvForm onValidSubmit={handleValidSubmit}>

            <Row className="py-2">

                <Col md="12">
                    <ComponentWidthInput {...props.config}/>
                </Col>
            </Row>
            <div className="modal-footer">
                <button
                    onClick={() => props.close()}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                >Cancel</button>
                <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                >
                    Save changes
                </button>
            </div>
        </AvForm>

    )
};

DefaultSettings.propTypes = {
    el: PropTypes.object,
    config: PropTypes.object,
    openModal: PropTypes.bool,
    onCloseBlogSettings: PropTypes.func
};

export default DefaultSettings;
