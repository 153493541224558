import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Modal, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import { get, post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { page_limit } from "../../config";
import pageColumns from "../../page.columns";
import { TableLoader } from "../../components/Loader";
import { Link } from "react-router-dom";
import LeadAddModal from "./components/lead-add.modal";
import { showError, showSuccess } from "../../helpers/util.helper";
import CSVModal from "./components/csv.modal";
import { addCSV, bulkArchive, sendBulkEmail } from "./leads.service";
import LeadEmailModal from "./components/email-modal";
import DeleteModal from "../../common/DeleteModal";

const Leads = ({ match }) => {
  useEffect(() => {
    getPageData({ limit, offset });
  }, []);

  const actions = [{
    name: "View",
    icon: "fas fa-eye",
    fn: toggleViewModal
  }, {
    name: "Call",
    conditionColumn: "contact",
    color: "success",
    icon: "fas fa-phone",
    fn: callUser
  }];
  const limit = page_limit;
  const offset = 0;
  const [pagesLoading, setPageLoading] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [onViewEmail, setOnViewEmail] = useState(0);
  const [selected, setSelected] = useState([]);
  const [deleteModal, setDeleteModal] = useState({open: false});
  const [bulkEmail, setBulkEmail] = useState({
    open: false,
    closeModal: () => setBulkEmail({ ...bulkEmail, open: false }),
    sendEmail
  });
  const [leadModal, setLeadModal] = useState({ showModal: false });
  const [csvModal, setCsvModal] = useState({
    open: false,
    closeModal: () => setCsvModal({ ...csvModal, open: false }),
    saveCSVData
  });
  const [filters, setFilters] = useState({});

  const [filterFields, setFilterFields] = useState([{
    type: "search",
    name: "search",
    placeholder: "name, contact, city.."
  }]);

  useEffect(() => {
    setFilterFields(filterFields.map(fields => {
      fields.value = filters[fields.name];
      return fields;
    }));
    pageChange(1);
  }, [filters]);

  const [data, setData] = useState({
    columns: pageColumns.leads,
    rows: []
  });

  function pageChange(e) {
    setCurrentPage(e);
    getPageData({ limit, offset: (limit * (e - 1)) });
  }

  const getPageData = async ({ limit, offset }) => {
    setPageLoading(1);
    const leadData = await get(url.EMAIL_LIST, {
      params: {
        limit, offset, ...filters
      }
    });
    setPageLoading(0);
    prepareData(leadData.data);
    let _d = { ...data, rows: leadData.data };
    setData(_d);
    setTotalPages(leadData.total_count);
  };

  function prepareData(data) {
    data && data.length && data.forEach(lead => {
      lead.name = <Link to={"/leads/" + lead.wu_id + '/profile'}>{lead.name}</Link>;
      lead.modified = lead.modified || lead.created;
      if (lead.isVerified) {
        lead.isVerified = <div className="text-center"><span className="fa fa-check-circle text-success"/></div>;
      } else {
        lead.isVerified = <div className="text-center"><span className="fa fa-times-circle text-danger"/></div>;

      }
    });
  }

  function toggleViewModal(email) {
    if (!viewModal && email) {
      setOnViewEmail(email);
    } else {
      setOnViewEmail({});
    }
    setViewModal(!viewModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function addLeadModal() {
    setLeadModal({ showModal: true });
  }

  function callUser({ contact }) {
    window.open("tel:" + contact, "_blank", "noopener,noreferrer");
  }

  async function saveLeadData(data) {
    setLeadModal({ showModal: false });
    if (data) {
      setPageLoading(1);
      const leadData = await post(url.LEAD, data);
      getPageData({ limit, offset });
      if (!leadData.api_status) {
        showSuccess({ message: "Lead Added successfully." });
      } else {
        showError(leadData);
      }
    }
  }

  async function sendEmail(data) {
    await sendBulkEmail({
      ...data, to: selected.map(d => d.email).join(","), wu_ids: selected.map(w => w.wu_id)
    });

    setBulkEmail(e => {
      return { ...e, open: false };
    });

    setSelected([]);
    setData(data => {
      return { ...data, rows: [] };
    });
    pageChange(1);
  }

  async function deleteLeads() {
    setDeleteModal({ open: false });
    await bulkArchive({
      wu_ids: selected.map(w => w.wu_id)
    });
    setSelected([]);
    setData(data => {
      return { ...data, rows: [] };
    });
    pageChange(1);

  }

  async function saveCSVData(leads) {
    setCsvModal({ ...csvModal, open: false });
    await addCSV(leads);
    await getPageData({ offset: 0, limit });
  }

  function getExtraButton() {
    return <span>

      {
        selected.length > 0 &&
        <>
          <Button
            type="button"
            color="danger"
            onClick={() => setDeleteModal({ open: true })}
            className="form-group mr-1 btn-sm"
          >
            <i className="mdi mdi-upload mr-1"/>
            Archive ({selected.length})
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => setBulkEmail({ ...bulkEmail, email: selected.map(d => d.email), open: true })}
            className="form-group mr-1 btn-sm"
          >
            <i className="mdi mdi-upload mr-1"/>
            Bulk Email ({selected.length})
          </Button>

        </>
      }
      <Button
        type="button"
        color="info"
        onClick={() => setCsvModal({ ...csvModal, open: true })}
        className="form-group mr-1 btn-sm"
      >
                    <i className="mdi mdi-upload mr-1"/>
                    CSV
                  </Button>
    </span>;
  }

  function setSelectedId({ row, type = "push" }) {
    let s = [...selected];
    if (type === "push" && !s.some(n => n.wu_id === row.wu_id)) {
      s.push(row);
    } else {
      s = s.filter(d => d.wu_id !== row.wu_id);
    }
    let ids = s.map(d => d.wu_id);
    data.rows.forEach(r => {
      r.checked = ids.includes(r.wu_id);
    });
    setSelected(s);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Leads List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                      <AETable columns={data.columns}
                               rows={data.rows || []}
                               itemsPerPage={limit}
                               loading={pagesLoading}
                               currentPage={currentPage}
                               actions={actions}
                               addLink={addLeadModal}
                               action_width="10%"
                               hideAction={true}
                               totalCount={totalPages}
                               extraButtons={getExtraButton()}
                               onFilter={setFilters}
                               filters={filterFields}
                               selectable={true}
                               onSelected={setSelectedId}
                               selected={selected}
                               pageFn={pageChange}/>
                  }

                  <Modal
                    isOpen={viewModal}
                    toggle={() => {
                      toggleViewModal();
                    }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myModalLabel">Email Details</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {
                        onViewEmail.from_email ?
                          <div>
                            <p><b>Full Name:</b> {onViewEmail.full_name}</p>
                            <p><b>Email Address:</b> {onViewEmail.from_email}</p>
                            <p><b>Message:</b> {onViewEmail.message}</p>
                            {
                              onViewEmail.contact ?
                                <p><b>Contact:</b> {onViewEmail.contact}</p>
                                : ""
                            }
                            {
                              onViewEmail.property_url ?
                                <p><b>Property:</b>
                                  <a href={onViewEmail.property_url} className="" target="_blank"> View</a>
                                </p>
                                : ""
                            }
                          </div>
                          : ""
                      }
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          toggleViewModal();
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {
        leadModal.showModal && <LeadAddModal openModal={leadModal.showModal} onCloseLeadModal={saveLeadData}/>
      }
      {
        bulkEmail.open && <LeadEmailModal
          openModal={bulkEmail.open}
          sendEmail={sendEmail}
          closeModal={bulkEmail.closeModal}
          email={bulkEmail.email}
        />
      }
      {
        csvModal.open && <CSVModal {...csvModal}/>
      }
      {
        deleteModal.open && <DeleteModal
          onCloseClick={() => {
            setDeleteModal({open: false});
          }}
          onDeleteClick={deleteLeads}
          show={deleteModal.open}>
        </DeleteModal>
      }
    </React.Fragment>
  );
};

export default Leads;
