import BlogSettings from "./page-component-settings/blog.settings";
import HTMLSettings from "./page-component-settings/html.settings";
import GallerySettings from "./page-component-settings/gallary.settings";
import AccordionSettings from "./page-component-settings/accordion.settings";
import InfographicSettings from "./page-component-settings/info-graphic.settings";
import ParallaxSettings from "./page-component-settings/parallax.settigs";
import PropertySettings from "./page-component-settings/properties.settings";
import PropertyIsotopSettings from "./page-component-settings/properties_isotop.settings";
import TeamSettings from "./page-component-settings/team.settings";
import TestimonialSettings from "./page-component-settings/testimonial.settings";
import StepperSettings from "./page-component-settings/stepper.settings";
import FaqSettings from "./page-component-settings/faq.settings";
import InfoGraphicsGridSettings from "./page-component-settings/infographic-grid.settings";
import paragraph from "../../assets/images/ae/components-new/html.png";
import faq from "../../assets/images/ae/rentup/faq.png";
import infographic from "../../assets/images/ae/components-new/infographic.png";
import parallax from "../../assets/images/ae/components-new/parralax.png";
import team from "../../assets/images/ae/components-new/team.png";
import testimonial from "../../assets/images/ae/components-new/testimonial.png";
import HTMLRawSettings from "./page-component-settings/html-raw.settings";
import DefaultSettings from "./page-component-settings/default.settings";
import PageHeaderSettings from "./page-component-settings/page-header.settings";
import AwardsSettings from "./page-component-settings/awards.settings.";
import SearchSettings from "./page-component-settings/search.settings";
import AboutUsSettings from "./page-component-settings/about.settings";

export const getComponentUrl = (path) => {
    return process.env.REACT_APP_PUBLIC_URL + '/img/components/' + path + '.png';
};

let getComponentSettings = (type) => {
    switch (type) {
        case 'ACCORDION':
            return {
                key: 'faq',
                title: 'Accordion',
                ui: true,
                config: {rows: [], accordion_title: 'Accordion Title'},
                Settings: AccordionSettings
            };
        case 'BLOG':
            return {
                key: 'latest_blog',
                ui: true,
                title: 'Recent Blog Posts',
                Settings: BlogSettings,
                config: {max_posts: 6},
            };
        case 'GALLERY':
            return {
                key: 'gallery',
                ui: true,
                title: 'Gallery',
                config: {rows: []},
                Settings: GallerySettings
            };
        case 'HTML':
            return {
                key: 'paragraph',
                title: 'Text Content',
                config: {paragraph: ''},
                Settings: HTMLSettings
            };
        case 'HTML_RAW':
            return {
                key: 'html',
                title: 'HTML',
                config: {html: ''},
                Settings: HTMLRawSettings
            };
        case 'BUYER_SELLER_GUIDE':
            return {
                key: 'buyer-seller-guide',
                title: 'Buyer/Seller Guide',
                config: {title: 'Buyer/Seller Guide'},
                Settings: DefaultSettings
            };
        case 'INFO_GRAPHIC_BLOCK':
            return {
                key: 'infographic',
                ui: true,
                title: 'Image/Content Block',
                config: {},
                Settings: InfographicSettings
            };
        case 'INFO_GRAPHIC_GRID':
            return {
                key: 'infographic_grid', ui: true, title: 'Image/Content List',
                Settings: InfoGraphicsGridSettings,
                config: {rows: []}
            };
        case 'PARALLAX':
            return {
                key: 'parallax',
                ui: true,
                title: 'Parallax Block',
                Settings: ParallaxSettings,
                config: {}
            };
        case 'PAGE_HEADER':
            return {
                key: 'page_header',
                ui: true,
                title: 'Header Block',
                Settings: PageHeaderSettings,
                config: {}
            };
        case 'PROPERTIES_LIST':
            return {

                key: 'propertyList',
                ui: true,
                title: 'Latest Properties',
                Settings: PropertySettings,
                config: {}

            };
        case 'PROPERTIES_AREA':
            return {
                key: 'properties_isotop',
                ui: true,
                title: 'Properties by Cities',
                Settings: PropertyIsotopSettings,
                config: {}
            };
        case 'TEAM':
            return {
                key: 'team', ui: true,
                title: 'Our Team',
                Settings: TeamSettings, config: {}
            };
        case 'TESTIMONIAL':
            return {
                key: 'testimonial',
                ui: true,
                title: 'Testimonial',
                Settings: TestimonialSettings,
                config: {}
            };
        case 'CALL_TO_ACTION':
            return {
                Settings: DefaultSettings,
                key: 'landing_cta',
                ui: true,
                title: 'Call to Actions',
                config: {}
            };
        case 'STEPPER':
            return {
                ui: true,
                title: 'Timeline',
                Settings: StepperSettings,
                config: {rows: []}
            };
        case 'FAQ':
            return {

                key: 'faq',
                ui: true,
                title: 'FAQ\'s',
                Settings: FaqSettings,
                config: {rows: [], accordion_title: 'FAQ\'s'}
            };
        case 'MORTGAGE_CALCULATOR':
            return {
                Settings: DefaultSettings,
                key: 'mortgage_calculator',
                ui: true,
                title: 'Mortgage Calculator',
                config: {}
            };
        case 'PRE_CONSTRUCTIONS':
            return {
                Settings: AwardsSettings,
                key: 'pre_constructions',
                ui: true,
                title: 'Pre Constructions',
                config: {}
            };
        case 'SEARCH':
            return {
                Settings: SearchSettings,
                key: 'search',
                ui: true,
                title: 'Search',
                config: {}
            };
        case 'CONTACT_US_BAR':
            return {
                Settings: DefaultSettings,
                key: 'contact_us_bar',
                ui: true,
                title: 'Contact Us',
                config: {}
            };
        case 'ABOUT_US':
            return {
                Settings: AboutUsSettings,
                key: 'about_us',
                ui: true,
                title: 'About Us',
                config: {}
            };
        case 'AWARDS':
            return {
                Settings: AwardsSettings,
                key: 'awards',
                ui: true,
                title: 'Awards/Achievements',
                config: {}
            };
        case 'MAP':
            return {
                Settings: DefaultSettings,
                key: 'map',
                ui: true,
                title: 'Map',
                config: {}
            };
        default:
            return false;
    }
};

export const setUiElements = (elements) => {
    let group = {};
    elements.map(element => {
        let setting = getComponentSettings(element.component_type);
        if (!group[element.component_type]) {
            group[element.component_type] = {title: setting.title, key: setting.key, components: []};
        }
        group[element.component_type].components.push({
            ...element, ...getComponentSettings(element.component_type),
            component_key: setting.key,
            images: [{image: element.component_image, key: element.key}]
        })
    });
    return group;

};
