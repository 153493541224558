import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import components from "./builder-components-2";
import TitleModal from "./builder-modals/title-modal";
import ParagraphModal from "./builder-modals/paragraph-modal";
import PropertyModal from "./builder-modals/properties.modal";
import PropertyIsotopModal from "./builder-modals/properties_isotop.modal";
import TestimonialModal from "./builder-modals/testimonial.modal";
import PropTypes from "prop-types";
import StepperModal from "./builder-modals/stepper.modal";
import TeamModal from "./builder-modals/team.modal";
import InfographicModal from "./builder-modals/info-graphic.modal";
import AccordionModal from "./builder-modals/accordion.modal";
import ParallaxModal from "./builder-modals/parallax.modal";
import SpacerModal from "./builder-modals/spacer.modal";
import GridModal from "./builder-modals/infographic-grid.modal";
import * as moment from 'moment-timezone';
import GalleryModal from "./builder-modals/gallery-modal";
import './builder.scss';
$(document).on("click", '.remove', function (e) {
  $(this).parent().remove();
});
const draggerHTML = `<span style="right: 100px;color: white;font-weight: 600;cursor: pointer;background: gray;font-size: 13px;padding: 6px;position: absolute;top: -15px;z-index: 10;opacity: 1;" class="btn-primary mr-2 handle">Drag</span>`;

const Builder = forwardRef((props, ref) => {
  console.log(props.page_config, 'is page config')
  const [titleModalData, setTitleModalData] = useState({ el: null, config: {}, showModal: false });
  const [paragraphModalData, setParagraphModalData] = useState({ el: null, config: {}, showModal: false });
  const [propertyModalData, setPropertyModalData] = useState({ el: null, config: {}, showModal: false });
  useImperativeHandle(ref, () => ({
    saveLayout: saveLayout,
    pagePreview: pagePreview
  }));
  let newDropzoneId = moment().unix();
  let draggingEl, dragged, dragged$;

  function drag(event) {
    $(draggingEl).css({ left: event.pageX - 150 + "px", top: event.pageY - 50, width: "50%", "z-index": 1000 });
  }

  function dragstart(event) {
    // store a ref. on the dragged elem
    dragged = event.target;
    if (typeof dragged !== 'string' && !Array.isArray(dragged) && Object.keys(dragged).length) {
      let width = event.target.getBoundingClientRect().width;
      let height = event.target.getBoundingClientRect().height;
      dragged$ = event;
      draggingEl = $(dragged).clone();
      $(draggingEl[0]).css({
        opacity: 0.1,
        position: "absolute",
        "width": width,
        "height": height,
        "pointer-events": "none"
      });
      $(draggingEl).attr("id", "draggingEl");
      $("body").append(draggingEl);
      // dragged.style.pointerEvents = 'none';
      // make it half transparent
      event.target.style.opacity = 0;
    }
  }

  function dragend(event) {
    // reset the transparency
    // dragged.style.pointerEvents = 'auto';
    if (typeof dragged !== 'string' && !Array.isArray(dragged) && Object.keys(dragged).length) {
      draggingEl = null;
      if (document.getElementById("draggingEl")) {
        document.getElementById("draggingEl").remove();
      }
      event.target.style.opacity = "";
    }

  }

  function dragover(event) {
    // prevent default to allow drop
    event.preventDefault();
  }

  function dragenter(event) {
    // highlight potential drop target when the draggable element enters it

    if (event.target.classList.contains("dropzone")) {
      event.target.style.background = "purple";
    }

  }

  function dragleave(event) {
    // reset background of potential drop target when the draggable element leaves it
    if (event.target.classList.contains("dropzone")) {
      event.target.style.background = "";
    }
  }

  function mapComponentToModal({ el }) {
    let component = $(el).data("col");
    let config = $(el).attr("data-config");
    try {
      config = JSON.parse(config);
    } catch (e) {
      config = {};
    }
    /*reset config from json*/
    console.log('config before', config, props.page_config)
    if (props.page_config[config.key]) {
      config = props.page_config[config.key];
    }
    console.log('config after', config, el, 'is componetns')

    component = component.trim();
    console.log(component, 'is component', component !== "infographic_grid");
    if (component === "html") {
      setTitleModalData({
        el: el,
        config: {title: config.title || $(el).find('.paragraph-content').html() || ""},
        showModal: true
      });
    } else if (component === "paragraph") {
      setParagraphModalData({
        el: el,
        config: { paragraph: config.paragraph || $(el).find("p").html() || "" },
        showModal: true
      });
    } else if (component === "parallax") {
      config.parallax = true;
      if (typeof config.height === "undefined") {
        config.height = '400px';
      }
      setTitleModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "propertyList") {
      config.properties_count = config.properties_count || 6;
      setPropertyModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "properties_isotop") {
      config.properties_isotop = true;
      setPropertyModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "stepper_x" || component === "stepper_y") {
      config.stepper = true;
      if (!config.rows) {
        config.rows = [{ title: "", description: "" }];
      }
      setPropertyModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "latest_blog") {
      config.total_count = config.total_count || 3;
      setTitleModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "accordion") {
      config.accordion = true;
      if (!config.rows) {
        config.rows = [{ title: "", description: "" }];
      }
      setPropertyModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "testimonial") {
      config.total_testimonial = config.total_testimonial || 3;
      setTitleModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "team") {
      config.team_title = config.team_title || 'Our Team';
      config.team_title = config.team_title || 'Our Team';
      setTitleModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "spacer") {
      config.spacer = true;
      if (typeof config.height === 'undefined') {
        config = { ...config, height: '40px' };
      }
      setTitleModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "infographic") {
      if (!Object.keys(config).length) {
        config = { heading: '', description: '', imageDir: 'right', background: '', image: '', infographic: true };
      }
      setTitleModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "gallery") {
      config.gallery = true;
      if (!config.rows) {
        config.rows = [{ title: "", description: "" }];
      }
      setPropertyModalData({
        el: el,
        config,
        showModal: true
      });
    } else if (component === "infographic_grid") {
      config.infographic_grid = true;
      if (!config.rows) {
        config.rows = [{ title: "", description: "" }];
      }
      console.log(component, 'infographic', config);
      setPropertyModalData({
        el: el,
        config,
        showModal: true
      });
    }
  }

  useEffect(function () {
    console.log(titleModalData, 'title modal data');
  });

  function drop(event) {
    // prevent default action (open as link for some elements)
    event.preventDefault();
    // move dragged elem to the selected drop target;
    if (event.target.classList.contains("dropzone")) {
      event.target.style.background = "";
      // dragged.parentNode.removeChild( dragged );
      let el = $(dragged).clone();
      attachEventsToElement(el, event);
    }
  }

  function attachEventsToElement(el, event) {
    $(el[0]).css({ opacity: 1 });
    if ($(draggingEl).data() && $(draggingEl).data().component) {
      let componentType = $(draggingEl).data().component;
      let componentDesign = Object.values(components).reduce((a, b) => [...a, ...b]).find(d => d.key === componentType);
      console.log(componentDesign, 'sds0--s')
      let el = $(componentDesign.getDesign());
      if (!["c12", "c6", "c4"].includes(componentType)) {
        let edit = $(`<span class="edit btn-primary mr-2" data-toggle="modal" data-target="#myModal">Edit</span>`);
        edit.on("click", function () {
          mapComponentToModal({ el });
        });
        $(el).data("col", componentType);
        // el.prepend(upDownArrow);
        el.prepend(edit);
        el.prepend(draggerHTML);
        edit.click();
      } else {
        /*layout added*/
        $(el).find('.dropzone').each(function () {
          let id = newDropzoneId++;
          $(this).attr('id', 'dropzone' + id);
          addSortable(id);
        });
      }
      $(el).addClass("fade-in").addClass("fade-out").addClass("ae-component")
        .attr("data-config", JSON.stringify(componentDesign.config));
      $(event.target)
        .append(el.prepend("<span class=\"remove\">Remove</span>\n"));
      $(".remove").on("click", function (e) {
        console.log("remove called");
        $(this).parent().slideUp("slow", function () {
          $(this).remove();
        });
      });
    } else {
      $(event.target).append(el);
    }
  }


  $(".sortable").disableSelection();

  // $(".dropzone").disableSelection();


  function addSortable(index) {
    console.log('dropzone in dindex', index);
    setTimeout(function () {
      let sortable = $("#dropzone" + index).sortable({
        handle: ".handle",
        connectWith: ".dropzone",
        placeholder: "ui-state-highlight",
        forcePlaceholderSize: true,
        items: ".ui-state-default",
        helper: 'clone',
        appendTo: 'body',
        zIndex: 10000,
        forceHelperSize: true,
        distance: 10,
        scrollSpeed: 100,
        containment: $("#builder-container"),
      });
    }, 1000);
  }

  useEffect(function () {
    /*if (localStorage.getItem("layout")) {
      $(".layout-area").html(localStorage.getItem("layout"))
    }*/
    if (props.page_html) {

      $(".layout-area").html((props.page_html || ""));
      const mainComponents = components.components.map(d => d.key);
      // let otherComponents = components.layout.map(d => d.key);
      // otherComponents = [...otherComponents, 'col-12', 'col-6', 'col-6'];
      $(".layout-area").find('div:data').each(function () {
        if ($(this).data('col') && $(this).find('.dropzone')) {
          $(this).find('.dropzone').each(function () {
            let index = newDropzoneId + 1;
            newDropzoneId++;
            $(this).attr('id', 'dropzone' + index);
            addSortable(index);
          });
        }
      });
    }
    if (!$(".page-content").hasClass("listener")) {
      $(document).on('click', '.edit', function () {
        console.log('edit cliekds', $(this).parent(".ae-component"));
        mapComponentToModal({ el: ($(this).parent(".ae-component")) });
      });
      document.querySelector(".page-content").addEventListener("drag", drag, false);
      document.querySelector(".page-content").addEventListener("dragstart", dragstart, false);
      document.querySelector(".page-content").addEventListener("dragend", dragend, false);
      /* events fired on the drop targets */
      document.querySelector(".page-content").addEventListener("dragover", dragover, false);
      document.querySelector(".page-content").addEventListener("dragenter", dragenter, false);
      document.querySelector(".page-content").addEventListener("dragleave", dragleave, false);
      document.querySelector(".page-content").addEventListener("drop", drop, false);
      $(".page-content").addClass("listener");
    }
  }, []);
  const style = {
    mainContainer: {
      // "minHeight": "800px"
    },
    "dSticky": {
      "position": "fixed",
      "zindex": "1",
      "transform": "translate(0%, 10%)",
      "left": "50%",
      "bottom": "0%"
    }
  };

  function pagePreview() {
    let layout = getLayoutData();
    props.onPreviewPage({config: layout, html: $(".layout-area").html()});
  }

  function getLayoutData() {
    let addedKeys = [];
    let key = 1;

    function updateKey() {
      key = key + 1;
      if (addedKeys.includes(key)) {
        return updateKey();
      }
      addedKeys.push(key);
      return key;
    }


    function dive(that) {
      let element_width = that.data("col");
      if (!element_width) {
        return;
      }
      let config = {};
      try {
        if (typeof that.data('config') === 'object') {
          config = that.data('config');
        } else {
          config = JSON.parse(that.data('config'));
        }
      } catch (e) {
        config = {};
      }
      config = {...config, key: updateKey().toString()};
      that.attr('data-config', JSON.stringify(config));
      let el = {el: that.data("col"), children: [], config};
      let child = that.children().filter(function (a, b) {
        return $(b).data("col") !== "undefined";
      });
      if (child.length > 0) {
        el.children = child.map(function () {
          return dive($(this));
        }).get();
      }
      return el;
    }

    return dive($(".layout-area"));
  }

  function saveLayout() {
    let addedKeys = [];
    let key = 1;

    function updateKey() {
      key = key + 1;
      if (addedKeys.includes(key)) {
        return updateKey();
      }
      addedKeys.push(key);
      return key;
    }

    function dive(that) {
      let element_width = that.data("col");
      if (!element_width) {
        return;
      }
      let config = {};
      try {
        if (typeof that.data('config') === 'object') {
          config = that.data('config');
        } else {
          config = JSON.parse(that.data('config'));
        }
      } catch (e) {
        config = {};
      }
      config = {...config, key: updateKey().toString()};
      that.attr('data-config', JSON.stringify(config));
      let el = {el: that.data("col"), children: [], config};
      let child = that.children().filter(function (a, b) {
        return $(b).data("col") !== "undefined";
      });
      if (child.length > 0) {
        el.children = child.map(function () {
          return dive($(this));
        }).get();
      }
      return el;
    }

    function removeKeys(that) {
      that.find(".remove").remove();
      that.find("span").remove();
      let element_width = that.data("col");
      if (!element_width) {
        return;
      }
      let child = that.children().filter(function (a, b) {
        return $(b).data("col") !== "undefined";
      });
      if (child.length > 0) {
        child.map(function () {
          return dive($(this));
        })
      }
    }

    let layout = getLayoutData();
    removeKeys($(".layout-area").clone());
    // localStorage.setItem("layout", $(".layout-area").html())
    props.onSavePage({page_html: $(".layout-area").html(), page_config: layout});
  }

  async function saveAccordionSettings({ config, el }) {
    setPropertyModalData({ config: {}, el: null, showModal: false });
    if (config && config.rows && config.rows.length) {
      for (let row of config.rows) {
        if (row.image && row.image.startsWith('data:')) {
          const { image_path } = await props.saveImage({ file: row.image });
          row.image = image_path;
        }
      }
    }
    $(el).attr("data-config", JSON.stringify(config));
  }

  async function saveInfoGraphicSettings(config) {
    setTitleModalData({ config: {}, el: null, showModal: false });
    if (config.image && config.image.startsWith('data:')) {
      const { image_path } = await props.saveImage({ file: config.image });
      config.image = image_path;
    }
    $(config.el).attr("data-config", JSON.stringify({
      heading: config.heading,
      description: config.description,
      imageDir: config.imageDir,
      background: config.background,
      image: config.image,
      infographic: true,
      showButton: config.showButton,
      button_title: config.button_title,
      button_link: config.button_link,
    }));

  }

  async function saveParallaxSettings(config) {
    if (config.image && config.image.startsWith('data:')) {
      const { image_path } = await props.saveImage({ file: config.image });
      config.image = image_path;
    }
    $(config.el).attr("data-config", JSON.stringify({
      heading: config.heading,
      description: config.description,
      height: config.height,
      showButton: config.showButton,
      button_title: config.button_title,
      button_link: config.button_link,
      image: config.image,
    }));
    setTitleModalData({ config: {}, el: null, showModal: false });
  }

  async function saveInfographicGrid(config, el) {
    if (config && config.rows) {
      for (let row of config.rows) {
        if (row.image && row.image.startsWith('data:')) {
          const {image_path} = await props.saveImage({file: row.image});
          row.image = image_path;
        }
      }
      $(el).attr("data-config", JSON.stringify(config));
      setPropertyModalData({config: {}, el: null, showModal: false});
    }
  }


  return (
      <>
        <div className=" d-relative mb-4" style={style.mainContainer}>
          <div className="container-fluid">
            {propertyModalData.config.gallery}

            <div className="row  mt-4">
              <div className="col-9  ">
                <div className="container-area layout-area container dropzone" id="builder-container"
                     data-col="layout"
                     style={{
                       height: "1000px",
                       overflowY: "scroll"
                     }}>

                </div>
              </div>
              <div className="col-3">
                <div className="sticky">
                  <div className="">
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="mb-0 text-muted">
                          UI Elements
                        </h5>
                      </div>
                      <div  style={{
                        height: "500px",
                        overflowY: "scroll"
                      }}>
                        <div className="card-body p-0" style={{maxHeight: '865px'}}>
                          <div className="list-group ">
                            {
                              [...components.components].map((d, idx) => <div className="list-group-item "
                                                                              draggable="true"
                                                                              key={idx + 'components'}
                                                                              data-component={d.key}>
                                <div className="justify-content-around d-flex pointer">
                                  <span className="flex-grow-1"> <span className={d.icon}/> {d.title}</span>
                                  <i className='bx bx-move'/>
                                </div>
                              </div>)
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

        </div>
        {
          titleModalData.config && typeof titleModalData.config.infographic !== 'undefined' &&
          <InfographicModal
            config={titleModalData.config}
            el={titleModalData.el}
            openModal={titleModalData.showModal} onCloseInfographicModal={saveInfoGraphicSettings}/>
        }
        {
          titleModalData.config && titleModalData.config.team_title &&
          <TeamModal config={titleModalData.config}
                     el={titleModalData.el}
                     openModal={titleModalData.showModal} onCloseTeamModal={(config) => {
            $(config.el).attr("data-config", JSON.stringify({
              team_title: config.team_title,
              component_id: config.component_id
            }));
            setTitleModalData({config: {}, el: null, showModal: false});
          }}/>

        }{
        typeof titleModalData.config.total_testimonial !== "undefined" ?
          <TestimonialModal config={titleModalData.config}
                            el={titleModalData.el}
                            openModal={titleModalData.showModal} onCloseTestimonialModal={(config) => {
            $(config.el).attr("data-config", JSON.stringify({
              total_testimonial: config.total_testimonial,
              component_id: config.component_id
            }));
            setTitleModalData({config: {}, el: null, showModal: false});
          }}/>
          : ""
      }
        {
          typeof titleModalData.config.title === "string" ?
            <TitleModal config={titleModalData.config}
                        el={titleModalData.el}
                        openModal={titleModalData.showModal} onCloseTitleModal={(config) => {
              console.log(config, 'on save');
              $(config.el).attr("data-config", JSON.stringify({ title: config.title }));
              $(config.el).find(".paragraph-content").text(config.title);
              setTitleModalData({ config: {}, el: null, showModal: false });
            }}/>
            : ""
        }
        {
          typeof titleModalData.config.parallax === "boolean" ?
            <ParallaxModal config={titleModalData.config}
                           el={titleModalData.el}
                           openModal={titleModalData.showModal} onCloseParallaxModal={saveParallaxSettings}/>
            : ""
        }
        {
          typeof paragraphModalData.config.paragraph === "string" ?
            <ParagraphModal config={paragraphModalData.config}
                            el={paragraphModalData.el}
                            openModal={paragraphModalData.showModal} onCloseParagraphModal={(config) => {
              $(config.el).attr("data-config", JSON.stringify({ paragraph: config.paragraph }));
              $(config.el).find(".paragraph-content").html(config.paragraph);
              setParagraphModalData({ config: {}, el: null, showModal: false });
            }}/>
            : ""
        }
        {
          typeof propertyModalData.config.properties_count !== "undefined" ?
            <PropertyModal config={propertyModalData.config}
                           el={propertyModalData.el}
                           openModal={propertyModalData.showModal} onCloseModal={({ config, el }) => {
              $(el).attr("data-config", JSON.stringify(config));
              setPropertyModalData({ config: {}, el: null, showModal: false });
            }}/>
            : ""
        } {
        typeof propertyModalData.config.properties_isotop !== "undefined" ?
          <PropertyIsotopModal config={propertyModalData.config}
                               el={propertyModalData.el}
                               openModal={propertyModalData.showModal} onCloseModal={({ config, el }) => {
            $(el).attr("data-config", JSON.stringify(config));
            setPropertyModalData({ config: {}, el: null, showModal: false });
          }}/>
          : ""
      } {
        typeof propertyModalData.config.stepper !== "undefined" ?
          <StepperModal config={propertyModalData.config}
                        el={propertyModalData.el}
                        openModal={propertyModalData.showModal} onCloseStepperModal={({ config, el }) => {
            $(el).attr("data-config", JSON.stringify(config));
            setPropertyModalData({ config: {}, el: null, showModal: false });

          }}/>
          : ""
      }{
        typeof propertyModalData.config.infographic_grid !== "undefined" ?
          <GridModal config={propertyModalData.config}
                     el={propertyModalData.el}
                     openModal={propertyModalData.showModal} onCloseGridModal={({ config, el }) => {
            saveInfographicGrid(config, el);

          }}/>
          : ""
      }{
        typeof propertyModalData.config.gallery !== "undefined" ?
          <GalleryModal config={propertyModalData.config}
                     el={propertyModalData.el}
                     openModal={propertyModalData.showModal} onCloseGridModal={({ config, el }) => {
            saveInfographicGrid(config, el);

          }}/>
          : ""
      } {
        typeof propertyModalData.config.accordion !== "undefined" ?
          <AccordionModal config={propertyModalData.config}
                          el={propertyModalData.el}
                          openModal={propertyModalData.showModal} onCloseAccordionModal={saveAccordionSettings}/>
          : ""
      } {
        typeof titleModalData.config.spacer !== "undefined" ?
          <SpacerModal config={titleModalData.config}
                       el={titleModalData.el}
                       openModal={titleModalData.showModal} onCloseSpacerModal={(config) => {
            if (config.height && config.height.length) {
              config.height = config.height.split('px')[0] + 'px';
            }
            $(config.el).attr("data-config", JSON.stringify({ height: config.height }));
            $(config.el).find(".paragraph-content").html(`<div style="height: ${config.height}">Height: ${config.height}</div>`);
            setTitleModalData({ config: {}, el: null, showModal: false });
          }}/>
          : ""
      }
      </div>
    </>
  );
});

Builder.propTypes = {
  page_html: PropTypes.string,
  onSavePage: PropTypes.func,
  onGetHtml: PropTypes.func,
  saveImage: PropTypes.func
};

export default Builder;
