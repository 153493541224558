import paragraph from "../../assets/images/ae/components-new/html.png";
import galleryImage from "../../assets/images/ae/rentup/gallery.png";
import propertySearchBar from "../../assets/images/ae/rentup/property-search.png";
import propertyImage from "../../assets/images/ae/rentup/properties-slider.png";
import isotop from "../../assets/images/ae/rentup/property-in-area.png";
import CTA from "../../assets/images/ae/components-new/cta.png";
import mortgageCalculatorComplete from "../../assets/images/ae/rentup/mortgage-calculator-complete.png";
import blogImage from "../../assets/images/ae/rentup/blog.png";
import testimonial from "../../assets/images/ae/rentup/testimonial.png";
import contactUsBar from "../../assets/images/ae/rentup/contact-us.png";
import faq from "../../assets/images/ae/rentup/faq.png";
import parallax from "../../assets/images/ae/rentup/parralax.png";
import team from '../../assets/images/ae/rentup/team.png';

import GallerySettings from "./page-component-settings/gallary.settings";
import PropertySettings from "./page-component-settings/properties.settings";
import PropertyIsotopSettings from "./page-component-settings/properties_isotop.settings";
import TeamSettings from "./page-component-settings/team.settings";
import TestimonialSettings from "./page-component-settings/testimonial.settings";
import FaqSettings from "./page-component-settings/faq.settings";
import ParallaxSettings from "./page-component-settings/parallax.settigs";
import HTMLSettings from "./page-component-settings/html.settings";


const components = [
    {
        key: 'paragraph',
        textual: true,
        ui: false,
        title: 'Text Content',
        image: paragraph,
        content: ``,
        Settings: HTMLSettings,
        config: {paragraph: ''}
    },
    {
        key: 'gallery',
        ui: true,
        title: 'Gallery',
        image: galleryImage,
        Settings: GallerySettings,
        config: {rows: []}
    },
    {key: 'property_search_bar', ui: true, title: 'Properties Search Form', image: propertySearchBar},
    {
        key: 'propertyList',
        ui: true,
        title: 'Latest Properties',
        image: propertyImage,
        Settings: PropertySettings,
        config: {}
    },
    {
        key: 'properties_isotop',
        ui: true,
        title: 'Properties In Area',
        image: isotop,
        Settings: PropertyIsotopSettings,
        config: {}
    },
    {
        key: 'team', ui: true,
        title: 'Team',
        image: [
            {key: 'team', image: team},
        ],
        Settings: TeamSettings, config: {}
    },
    {key: 'landing_cta', ui: true, title: 'Landing Page Links', image: CTA},
    {
        key: 'mortgage_calculator',
        ui: true,
        title: 'Mortgage Calculator(Complete)',
        image: mortgageCalculatorComplete
    },
    {key: 'latest_blog', ui: true, title: 'Recent Blog Posts', image: blogImage},

    {
        key: 'testimonial',
        ui: true,
        title: 'Testimonial',
        image: testimonial,
        Settings: TestimonialSettings,
        config: {}
    },

    {key: 'contact_us_bar', ui: true, title: 'Contact Us Form', image: contactUsBar},

    {
        key: 'faq',
        ui: true,
        title: 'FAQ\'s',
        image: faq,
        Settings: FaqSettings,
        config: {rows: [], accordion_title: 'FAQ\'s'}
    },
    {
        key: 'parallax',
        ui: true,
        title: 'Image Background CTA',
        image: parallax,
        Settings: ParallaxSettings,
        config: {}
    },
]
export default components;
