export default {
    blog: [
        {
            label: "Post Title",
            field: "post_title",
            width: '30%'
        },
        {
            label: "Status",
            field: "post_status",
            width: '10%'
        },
        {
            label: "Last Modified",
            type: 'datetime',
            field: "modified",
            width: '20%'
        }
    ],
    web_pages: [
        {
            label: "Page Name",
            field: "page_name",
            width: "20%"
        },
        {
            label: "Page Path",
            field: "page_slug",
            width: "20%"
        },
        {
            label: "Page Type",
            field: "page_type",
            width: "20%"
        },
        {
            label: "Last Modified",
            field: "modified",
            type: 'datetime',
            width: "20%"
        }],
    testimonials: [
        {
            label: "Reviewer Name",
            field: "reviewer_name",
            width: '20%'
        },
        {
            label: "Platform",
            field: "platform",
            width: '10%'
        },
        {
            label: "Rating",
            field: "rating",
            width: '10%'
        },
        {
            label: "Review",
            field: "review",
            text_limit: 100,
            width: '30%'
        },
        {
            label: "Date",
            field: "created",
            type: "date",
            width: '10%'
        }],
    leads: [
        {
            label: "Name",
            field: "name"
        },
        {
            label: "Email",
            field: "email"
        },
        {
            label: "Phone",
            field: "phone",
            type: "phone"
        },
        {
            label: "City",
            field: "city"
        },
        {
            label: "Last Email",
            field: "last_email_date",
            type: 'datetime'
        },
        {
            label: "Verified",
            field: "isVerified"
        },
        {
            label: "Source",
            field: "source_name"
        },
        {
            label: "Last Modified",
            field: "modified",
            type: "date"
        }
    ],
    websites: [
        {
            label: "Website Name",
            field: "website_name"
        }, {
            label: "Sub-domain",
            field: "subdomain"
        }, {
            label: "URL",
            field: "base_url"
        }, {
            label: "Date",
            field: "created",
            type: "date"
        }

    ],
    users: [
        {
            field: "name",
            label: "Name"
        }, {
            field: "email",
            label: "Email"
        },
        {
            field: "websites",
            label: "Websites",
            type: "list"
        },
        {
            field: "stripe_customer_id",
            label: "Stripe user",
            type: "list"
        },
        {
            field: "contact",
            label: "Contact",
            type: "list"
        },
        {
            label: "Date",
            field: "created",
            type: "date"
        }
    ],
    service: [
        {
            field: "service_title",
            label: "Service Name"
        },
        {
            field: "service_info",
            label: "Service Info"
        },
        {
            field: "service_photo",
            label: "Service Photo"
        },
        {
            label: "Date",
            field: "created",
            type: "date"
        }
    ], portfolio: [
        {
            field: "portfolio_title",
            label: "Project Name"
        },
        {
            field: "portfolio_photo",
            label: "Project Photo"
        },
        {
            label: "Categories",
            field: "categories"
        },
        {
            label: "Date",
            field: "created",
            type: "date"
        }
    ],
    team: [
        {
            field: "member_name",
            label: "Name"
        },
        {
            field: "member_description",
            label: "Info"
        },
        {
            field: "member_photo",
            label: "Photo"
        },
        {
            label: "Date",
            field: "created",
            type: "date"
        }
    ], leadForm: [
        {
            field: 'name',
            label: 'Form Name'
        }, {
            field: 'leads_count',
            label: 'Total Leads'
        }, {
            field: 'page',
            label: 'Page'
        }
    ], lead: [
        {
            field: 'full_name',
            label: 'User Name'
        }, {
            field: 'email',
            label: 'Email'
        }, {
            field: 'contact_no',
            label: 'Phone'
        },
        {
            label: "Date",
            field: "created_time",
            type: "date"
        }
    ],
    virtualTours: [
        {
            label: 'Address',
            field: 'address'
        }, {
            label: 'Price',
            field: 'price'
        },
        {
            label: 'Area',
            field: 'area',
        },
        {
            label: 'Bed',
            field: 'bed',
        },
        {
            label: 'Bath',
            field: 'bath',
        },
        {
            label: 'Photo',
            field: 'photo',
        },
        {
            label: "Date",
            field: "created_time",
            type: "date"
        }
    ],
    properties: [
        {
            label: 'MLS',
            field: 'MLS'
        },
        {
            label: 'Address', field: 'Address'
        },

        {label: 'Property Type', field: 'TypeOwn1Out'},
        {label: 'Bed/Bath', field: 'bed_bath'},
        {label: 'Price', field: 'ListPrice'},
        {label: 'Photo', field: 'property_image'},
        {label: 'Listed', field: 'ago'}
    ],
    theme_components: [
        {
            label: 'Component Name',
            field: 'component_name'
        },
        {
            label: 'Template', field: 'theme_name'
        },
        {
            label: 'Type', field: 'component_type'
        },
        {
            label: 'Preview', field: 'preview'
        },
        {
            label: "Last Modified",
            field: "modified",
            type: "date"
        }
    ], transactions: [
        {
            label: 'Invoice ID',
            field: 'invoice_id'
        },
        {
            label: 'Website',
            field: 'website_name'
        },
        {
            label: 'Amount',
            field: 'payment_amount'
        },
        {
            label: 'Status',
            field: 'status'
        },
        {
            label: 'User',
            field: 'name'
        },
        {
            label: 'Email',
            field: 'email'
        },
        {
            label: "Created",
            field: "created",
            type: "date"
        }
    ],
    photo: [
        {
            label: 'Photo Title',
            field: 'photo_title'
        },

        {
            label: 'Photo', field: 'photo'
        },
        {
            label: "Last Modified",
            field: "modified",
            type: "date"
        }
    ],
    theme_images: [
        {
            label: 'Image title',
            field: 'image_title'
        },

        {
            label: 'Image', field: 'image_path'
        },
        {
            label: "Created",
            field: "created",
            type: "date"
        }
    ],
    awards: [
        {
            label: 'Award',
            field: 'award_title'
        },
        {
            label: 'Image', field: 'award_image'
        },
        {
            label: "Created",
            field: "created",
            type: "date"
        }
    ],
    preConstruction: [{
        label: 'Name',
        field: 'project'
    },{
        label: 'Price Range',
        field: 'price'
    }, {
        label: 'Developer',
        field: 'developerName'
    }, {
        label: 'City',
        field: 'city'
    }, {
        label: 'Image',
        field: 'image'
    },  {
        label: 'Last Updated',
        field: 'lastUpdated'
    }],
    coupons: [{
        label: 'Title',
        field: 'coupon_title'
    },{
        label: 'Start Date',
        field: 'coupon_start_date',
        type: 'date'
    }, {
        label: 'End Date',
        field: 'coupon_end_date',
        type: 'date'
    }, {
        label: 'Discount',
        field: 'coupon_discount_amount'
    }, {
        label: 'Code',
        field: 'coupon_code'
    }, {
        label: 'Total Usage',
        field: 'current_usage'
    },  {
        label: 'Last Updated',
        field: 'modified',
        type: 'datetime'
    }]

};
