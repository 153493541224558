//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//agent edge login
export const LOGIN = "/api/auth";
export const NOTIFICATIONS = "/api/website/notifications";
export const NOTIFICATIONS_READ_MARK = "/api/website/notificationsMarkAsRead";
export const ACEESS_TOKEN_LOGIN = "/api/user/accessTokenLogin";
export const GMB_CONNECT = '/api/website/gmbConnect';
export const GMB_UPDATE_LOCATION = '/api/website/gmbUpdateLocation';
export const UPDATE_PASSWORD = '/api/user/password';


//web pages
export const WEB_PAGE_LIST = "/api/webPage";
export const BLOG_LIST = "/api/blog";
export const PHOTO_LIST = "/api/gallery";
export const AWARD_LIST = "/api/award";
export const COUPONS = "/api/coupon";
export const HEADER_LIST = "/api/website/headerList";
export const SERVICES = "/api/service";
export const TEAMS = "/api/team";
export const PORTFOLIO = "/api/portfolio";
export const PRE_CONSTRUCTION = "/api/preConstruction";
export const PRE_CONSTRUCTION_TOGGLE = "/api/preConstruction/togglePreConstructionOnWebsite";
export const TESTIMONIAL = "/api/testimonial";
export const PRE_CONSTRUCTION_DETAILS = "/api/preConstruction/details";
export const EDIT_PRE_CONSTRUCTION = "/api/preConstruction/editPreConstruction";
export const TESTIMONIAL_POST = "/api/testimonial/testimonialShare";
export const PROPERTY_POST = "/api/property/propertyShare";
export const EMAIL_LIST = "/api/leads";
export const EMAIL = "/api/leads";
export const LEAD = '/api/leads';
export const WEBSITE = "/api/website";
export const WEBSITES = "/api/website";
export const TEMPLATES = "/api/website/templates";
export const DASHBOARD = "/api/website/dashboard";
export const FACEBOOK_INSIGHTS = "/api/facebook/insights";
export const FACEBOOK_CONNECT = "/api/facebook/connect";
export const CONNECTED_PLATFORMS = "/api/website/connectedPlatforms";
export const FACEBOOK_FEATURE_CONNECT = "/api/facebook/featureConnect";
export const FACEBOOK_DISCONNECT = "/api/facebook/disconnect";
export const GMB_DISCONNECT = "/api/website/gmbDisconnect";
export const FACEBOOK_POST = "/api/facebook/post";
export const FACEBOOK_POSTS = "/api/facebook/posts";
export const LEAD_FORMS = "/api/facebook/leadForms";
export const LEADS = "/api/facebook/leads";
export const USERS = "/api/user";
export const PAGE_ADD = "/api/webPage";
export const UPDATE_SEO = "/api/webPage/config";
export const BLOG = "/api/blog";
export const UPDATE_MENU = "/api/menu/update";
export const SAVE_IMAGE = '/api/helper/upload';
export const VIRTUAL_TOUR = '/api/virtualTour';
export const VIRTUAL_TOUR_UPLOAD = '/api/virtualTour/image';
export const PROPERTY = '/api/property/list';
export const COMPONENT_LIST = '/api/website/components';
export const TRANSACTIONS = '/api/website/transactions';
export const WEBSITE_COMPONENT_LIST = '/api/website/websiteComponents';
export const THEME = '/api/website/getThemes';
export const THEME_IMAGES = '/api/website/themeImages';

export const END_PLAN = '/api/website/endPlan';
export const CLOSE_WEBSITE = '/api/website/closeWebsite';
export const ENABLE_WEBSITE = '/api/website/enableWebsite';
export const EXTEND_PLAN = '/api/website/extendPlan';
export const EXTEND_AND_CHARGE = '/api/website/extendAndCharge';
export const WEBSITE_PAYMENTS_DATA = '/api/website/getWebsitePaymentDetails';



//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
