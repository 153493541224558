import React from "react";
import {Redirect} from "react-router-dom";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import WebPages from "../pages/WebPages";
import Blog from "../pages/Blog";
import Testimonials from "../pages/Testimonials";
import AddTestimonial from "../pages/Testimonials/add-testimonial";
import Leads from "../pages/Leads";
import Settings from "../pages/Settings";
import AddPage from "../pages/WebPages/add-page";
import AddBlog from "../pages/Blog/addBlog";
import Menu from "../pages/Menu";
import Builder from "../pages/WebPages/builder";
import Websites from "../pages/Websites";
import Users from "../pages/Users";
import AddWebsite from "../pages/Websites/AddWebsite";
import AddUser from "../pages/Users/addUser";
import Service from "../pages/Services";
import AddService from "../pages/Services/AddService";
import Teams from "../pages/Teams";
import AddTeam from "../pages/Teams/AddTeam";
import Portfolio from "../pages/Portfolio";
import AddPortfolio from "../pages/Portfolio/AddPortfolio";
import PageNotFound from "../pages/404";
import FacebookMarketingHome from "../pages/Facebook";
import FacebookLeadForms from "../pages/Facebook/FacebookLeadForms";
import FacebookLeadsList from "../pages/Facebook/FacebookLeadsList";
import FacebookInsights from "../pages/Facebook/FacebookInsights";
import VirtualTour from "../pages/VirtualTour";
import AddVirtualTour from "../pages/VirtualTour/add-virtual-tour";

import LeadsDashboardHorizontal from "../pages/Leads/lead-dashboard-horizontal";
import Notifications from "../pages/Notifications";
import Profile from "../pages/Profile";
import Integrations from "../pages/Integrations";
import PostShare from "../pages/Posts/post-share";
import FacebookPosts from "../pages/Posts/facebook-posts";
import Properties from "../pages/Properties";
import ThemeComponents from "../pages/Theming";
import EditComponent from "../pages/Theming/editComponent";
import addPhoto from "../pages/Gallery/addPhoto";
import Photos from "../pages/Gallery";
import ThemeImages from "../pages/ThemeImages";
import UploadThemeImage from "../pages/ThemeImages/upload-theme-image";
import AwardsList from "../pages/Awards";
import AddAward from "../pages/Awards/addAwards";
import Transactions from "../pages/Transactions";
import Payments from "../pages/Payments";
import PreConstruction from "../pages/PreConstructions";
import EditPreConstruction from "../pages/PreConstructions/EditPreConstruction";
import {Coupons} from "../pages/Coupons";
import {EditCoupon} from "../pages/Coupons/EditCoupon";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard"/> },
  { path: "/pages", component: WebPages },
  { path: "/pages/:page_id", component: AddPage },
  { path: "/addPage", component: AddPage },
  { path: "/blog", component: Blog },
  { path: "/addBlog", component: AddBlog },
  { path: "/addTestimonial", component: AddTestimonial },
  { path: "/editTestimonial/:testimonial_id", component: AddTestimonial },
  { path: "/editBlog/:post_id", component: AddBlog },
  { path: "/testimonials", component: Testimonials },
  { path: "/builder", component: Builder },
  { path: "/leads", component: Leads },
  { path: "/menu", component: Menu },
  { path: "/settings", component: Settings },
  { path: "/users", component: Users },
  { path: "/components", component: ThemeComponents, superAdmin: 1 },
  { path: "/addComponents", component: EditComponent, superAdmin: 1 },
  { path: "/editComponents/:tc_id", component: EditComponent, superAdmin: 1 },
  { path: "/addUser", component: AddUser },
  { path: "/editUser/:user_id", component: AddUser },
  { path: "/websites", component: Websites, superAdmin: 1 },
  { path: "/addWebsite", component: AddWebsite, superAdmin: 1 },
  { path: "/services", component: Service },
  { path: "/addService", component: AddService },
  { path: "/editService/:service_id", component: AddService },
  { path: "/teams", component: Teams },
  { path: "/addTeam", component: AddTeam },
  { path: "/photos", component: Photos },
  { path: "/addPhoto", component: addPhoto },
  { path: "/editPhoto/:photo_id", component: addPhoto },
  { path: "/editTeam/:tm_id", component: AddTeam },
  { path: "/portfolio", component: Portfolio },
  { path: "/addProject", component: AddPortfolio },
  { path: "/editProject/:portfolio_id", component: AddPortfolio },
  { path: "/fb-marketing", component: FacebookMarketingHome },
  { path: "/fb-marketing/insights", component: FacebookInsights },
  { path: '/fb-marketing/forms', component: FacebookLeadForms },
  { path: '/fb-marketing/leads/:form_id', component: FacebookLeadsList },
  { path: '/virtual-tour', component: VirtualTour },
  { path: '/addVirtualTour', component: AddVirtualTour },
  { path: '/editVirtualTour/:vt_id', component: AddVirtualTour },
  { path: '/notifications', component: Notifications },
  { path: "/404", component: PageNotFound },
  { path: "/profile", component: Profile },
  { path: "/integrations", component: Integrations },
  { path: "/share", component: PostShare },
  { path: "/facebook-posts", component: FacebookPosts },
  { path: "/preConstructions", component: PreConstruction },
  { path: "/editPreConstruction/:pre_construction_id", component: EditPreConstruction },

  { path: "/leads/:wu_id/*", component: LeadsDashboardHorizontal },

  { path: "/properties", component: Properties },
  {path: '/theme-images', component: ThemeImages, superAdmin: 1},
  {path: '/awards', component: AwardsList},
  {path: '/addAward', component: AddAward},
  {path: '/editAward/:award_id', component: AddAward},
  {
    path: '/uploadImage', component: UploadThemeImage, superAdmin: 1
  },

  {
    path: '/transactions', component: Transactions, superAdmin: 1
  },
  {
    path: '/coupons', component: Coupons, superAdmin: 1
  },
  {
    path: '/editCoupon/:coupon_id', component: EditCoupon, superAdmin: 1
  },
  {
    path: '/addCoupon', component: EditCoupon, superAdmin: 1
  },
  {
    path: '/payments', component: Payments, superAdmin: 1
  }

  /*lead routes*/
  /*{ path: '/leads/:wu_id/profile', component: LeadDetails },
  { path: '/leads/:wu_id/alerts', component: LeadAlerts },
  { path: '/leads/:wu_id/tasks', component: LeadTasks },
  { path: '/leads/:wu_id/tasks/add', component: AddLeadTask },
  */// { path: "*", component: PageNotFound, redirectTo: '404'},

];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register }
];

export {userRoutes, authRoutes};
