import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import { get, post, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import toastr from "toastr"
import { showError } from "../../helpers/util.helper"
import { useHistory } from "react-router-dom"
import AddCategoryModal from "../../common/AddCategory"
import Select from "react-select"
import { EditorX } from "components/Common/Editor"

const addBlog = ({ match }) => {
  const [data, setData] = useState({})
  const [isDraft, setIsDraft] = useState(false)
  const [addCategoryModal, setAddCategoryModal] = useState(false)
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [editorState, setEditorState] = useState("");
  const [editorCurrentState, setEditorCurrentState] = useState();
  let history = useHistory()
  useEffect(async function () {
    let categories = await getCategories()
    if (match && match.params && match.params.post_id) {
      getBlog({ blog_id: match.params.post_id, categories })
    }
  }, [])

  async function handleValidSubmit(e, values) {
    
    let blog = {
      ...data,
      ...values,
      post_description: editorCurrentState,
      categories: selectedCategories.map(category => category.value),
      isDraft,
    }
    let response
    if (blog.post_id) {
      response = await put(url.BLOG + "/" + data.post_id, blog)
    } else {
      response = await post(url.BLOG, blog)
    }
    if (!response.api_status) {
      toastr.success("Blog added successfully.", "Success")
      back()
    } else {
      showError(response)
    }
  }

  async function getCategories() {
    const response = await get(url.PORTFOLIO + "/category?category_type=BLOG")
    if (!response.api_status) {
      prepareCategories({ categories: response })
      return response
    } else {
      showError(response)
    }
  }

  function back() {
    history.push("/blog")
  }

  function onEditorStateChange(e) {
    setEditorCurrentState(e)
  }

  function handleFile(e, key) {
    const reader = new FileReader()
    reader.onload = function (e) {
      setData({ ...data, [key]: e.target.result })
    }
    reader.readAsDataURL(e.target.files[0])
  }

  async function getBlog({ blog_id, categories }) {
    const response = await get(url.BLOG + "/" + blog_id)
    if (!response.api_status) {
      setEditorState(response.post_description);
      setEditorCurrentState(response.post_description);
      setData(response);
      prepareCategories({ categories: categories, data: response });
    } else {
      showError(response);
    }
  }

  async function saveCategory(category_name) {
    const response = await post(url.PORTFOLIO + "/category", {
      category_name,
      category_type: "BLOG",
    })
    if (!response.api_status) {
      prepareCategories({ categories: response });
    } else {
      showError(response);
    }
  }

  function prepareCategories({ categories, data }) {
    let list = categories
    const optionGroup = [
      {
        label: "Categories",
        options: [],
      },
    ]
    list.forEach(category => {
      optionGroup[0].options.push({
        value: category.pct_id,
        label: category.category_name,
      })
    })
    if (data && data.categories && data.categories.length) {
      setSelectedCategories(
        optionGroup[0].options.filter(category =>
          data.categories.includes(category.value)
        )
      )
    }
    setCategories(optionGroup)
  }

  return (
    <React.Fragment>
      <AvForm
        className="needs-validation"
        onValidSubmit={handleValidSubmit}
        modal={data}
      >
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Add Blog" breadcrumbItem="Blog Add" />
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <h4 className="card-title">
                        Post info{" "}
                        {data.isDraft > 0 && (
                          <span className="badge badge-danger">Draft</span>
                        )}
                      </h4>
                      <p className="card-title-desc">
                        Add information regarding your new blog post
                      </p>
                    </Col>
                    <Col md="8">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Post title
                            </Label>
                            <AvField
                              name="post_title"
                              helpMessage={"Max 250 chars"}
                              value={data.post_title}
                              placeholder="Lorum Ipsum.."
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                              validate={{ maxLength: { value: 250 } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Post excerpt
                            </Label>
                            <AvField
                              helpMessage={"Max 250 chars"}
                              name="post_excerpt"
                              value={data.post_excerpt}
                              placeholder="Lorum Ipsum.."
                              type="textarea"
                              className="form-control"
                              id="validationCustom03"
                              validate={{ maxLength: { value: 250 } }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <EditorX
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4">
                      <Row>
                        <Col md="10">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Categories
                            </Label>
                            <Select
                              value={selectedCategories}
                              isMulti={true}
                              onChange={values => {
                                setSelectedCategories(values)
                              }}
                              options={categories}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <Label htmlFor="validationCustom01">&nbsp;</Label>
                          <div className="d-flex">
                            <span
                              onClick={() => {
                                setAddCategoryModal(true)
                              }}
                              className="btn btn-primary p-0 m-0 py-2 col-12"
                            >
                              <span className="fas fa-plus-square"></span>
                            </span>
                          </div>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">
                              Blog Image
                            </Label>
                            <AvField
                              name="post"
                              type="file"
                              className="form-control"
                              id="validationCustom01"
                              onChange={event => handleFile(event, "postImage")}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          {
                            <img
                              src={data.postImage || data.post_main_image}
                              alt=""
                              className="img img-thumbnail"
                            />
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row></Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12 text-center">
              <Card>
                <CardBody>
                  <Button
                    color="secondary"
                    className="py-2 mr-2"
                    type="button"
                    onClick={back}
                  >
                    Back
                  </Button>
                  <button type={"submit"} id="draftBtn" className="d-none" />
                  <Button
                    color="info"
                    className="py-2 mr-2"
                    onClick={() => {
                      setIsDraft(true)
                      $("#draftBtn").click()
                    }}
                    type="button"
                  >
                    Save Draft
                  </Button>
                  <Button color="primary" className="py-2" type="submit">
                    {data.post_id ? "Update" : "Save"}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </AvForm>
      <AddCategoryModal
        onClose={({ category_name }) => {
          if (category_name && category_name.trim()) {
            saveCategory(category_name)
          }
          setAddCategoryModal(false)
          // setDeleteModal(false);
        }}
        openModal={addCategoryModal}
      ></AddCategoryModal>
    </React.Fragment>
  )
}

export default addBlog
