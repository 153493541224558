import {
  GET_WEBSITE_DETAIL,
  GET_WEBSITE_DETAIL_FAIL,
  GET_WEBSITE_DETAIL_SUCCESS,
  GET_WEBSITES,
  GET_WEBSITES_FAIL,
  GET_WEBSITES_LOADING,
  GET_WEBSITES_SUCCESS
} from "./actionTypes"

export const websiteLoading = () => ({
  type: GET_WEBSITES_LOADING
});
export const getWebsites = ({limit, offset}) => ({
  type: GET_WEBSITES,
  limit,
  offset
});

export const getWebsitesSuccess = websites => ({
  type: GET_WEBSITES_SUCCESS,
  payload: websites
});

export const getWebsitesFail = error => ({
  type: GET_WEBSITES_FAIL,
  payload: error
});

export const getInvoiceDetail = invoiceId => ({
  type: GET_WEBSITE_DETAIL,
  invoiceId
});

export const getInvoiceDetailSuccess = websites => ({
  type: GET_WEBSITE_DETAIL_SUCCESS,
  payload: websites
});

export const getInvoiceDetailFail = error => ({
  type: GET_WEBSITE_DETAIL_FAIL,
  payload: error
});
