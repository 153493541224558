import React, {createContext, useContext, useEffect, useState} from "react"
import {Button, Col, Container, Nav, NavItem, NavLink, Spinner, TabContent, TabPane,} from "reactstrap"
//Import Breadcrumb
import {get, put} from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import "@vtaits/react-color-picker/dist/index.css"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {showError} from "../../helpers/util.helper"
import * as classnames from "classnames"
import CompanySettings from "./components/company.settings"
import WebsiteSettings from "./components/website.settings"
import SalesmanSettings from "./components/salesman.settings"
import SocialSettings from "./components/social.settings"
import HomepageBannerSettings from "./components/homepage-banner.settings"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"
import SecretKeysSettings from "./components/secret-keys.settings"
import WebsiteSEO from "./components/website-seo"
import ColorSchemaSettings from "./components/color-schema.settings"
import PrivacyPolicySettings from "./components/privacy-policy.settings"
import FormLoader from "../../components/Loader/FormLoader";
import {WebsiteSettingContext, WebsiteSettingProvider} from "./website-settings.context";
import WebsiteOtherSettings from "./components/other-settings";

toastr.options = {
  timeOut: 3000,
};

const Settings = ({match, user}) => {
  const [data, setData] = useState({website_config: {}});
  const [webSettings, setWebSettings] = useState({});
  const websiteContext = useContext(WebsiteSettingContext);
  let {search} = useLocation();
  const history = useHistory();
  search = new URLSearchParams(search);
  let tabId = "1";
  if (search.get("tab") && search.get("tab").toString()) {
    tabId = search.get("tab") && search.get("tab").toString()
  }
  const [activeTab, setActiveTab] = useState(tabId);
  const [themes, setThemes] = useState([]);
  const [colorSchema, setColorSchemaList] = useState([]);

  useEffect(async () => {
    setPageLoading(1);
    if (search.get("tab")) {
      setActiveTab(search.get("tab").toString())
    }
    await getWebsiteData();
    await getThemesList();
    await getSchema();
    setPageLoading(0)
  }, []);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  async function getWebsiteData() {
    const websiteData = await get(
        url.WEBSITE + "/" + localStorage.getItem("website_id")
    );
    if (websiteData && websiteData.listing_provinces) {
      try {
        websiteData.listing_provinces = JSON.parse(
            websiteData.listing_provinces
        )
      } catch (e) {
        websiteData.listing_provinces = []
      }
    }
    setData({...data, ...websiteData})
  }

  async function saveWebsiteData(websiteData) {
    websiteContext.setSaving(true);
    const response = await put(
        url.WEBSITE + "/" + localStorage.getItem("website_id"),
        websiteData
    );
    websiteContext.setSaving(false);
    if (!response.api_status) {
      toastr.success("Website updated sucessfully.", "Success");
      getWebsiteData()
    } else {
      showError(response)
    }
  }
  function updateConfig(config) {
    setData(data => {
      data.website_config = JSON.parse(JSON.stringify({...data.website_config, ...config}));
      return JSON.parse(JSON.stringify(data));
    });
  }

  async function getThemesList() {
    const response = await get(url.WEBSITE + "/getThemes");
    if (!response.api_status) {
      setData(data => {
        setWebSettings(settings => {
          try {
            return JSON.parse(
                response.filter(d => d.theme_key === data.theme_id)[0].web_settings
            ) || {};
          } catch (e) {
            return {}
          }
        });
        return data;
      });
      setThemes(response)
    } else {
      showError(response)
    }
  }
  async function getSchema() {
    const response = await get(url.WEBSITE + "/colorSchema");
    if (!response.api_status) {
      setColorSchemaList(response)
    } else {
      showError(response)
    }
  }

  const [pagesLoading, setPageLoading] = useState(0);

  function handleFile(e, key) {
    if (!e) {
      let config = data.website_config;
      let _d = {...config, [key]: null};
      setData({...data, website_config: _d});
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      let config = data.website_config;
      let _d = {...config, [key]: e.target.result};
      setData({...data, website_config: _d})
    };
    reader.readAsDataURL(e.target.files[0])
  }

  function removeFile(key) {
    let config = data.website_config;

    let _d = {...config, [key]: false};
    setData({...data, website_config: _d})
  }

  function handleValidSubmit(event, values) {
    values = JSON.parse(JSON.stringify(values));
    values = {...data, ...values};
    let imageKeys = [
      "logo",
      "company_logo",
      "home_banner_image",
      "home_banner_image_tab",
      "home_banner_image_mobile",
      "about_us_photo",
      "realtor_image",
      "salesman_image",
      "favicon",
      "dark_logo",
      "light_logo",
        "banner_floating_image",
        "website_screenshot"
    ];
    for (let imageKey of imageKeys) {
      if (data.website_config[imageKey] === false) {
        values[imageKey] = false
      } else if (
          data.website_config[imageKey] &&
          data.website_config[imageKey].startsWith("data")
      ) {
        values[imageKey] = data.website_config[imageKey]
      }
    }
    delete values.website_config.logo;
    saveWebsiteData(values)
  }

  let savePageButton = (
      <Col md="12 text-center">
        <Button color="primary" className="py-2 mt-4" type="submit">
          Save
        </Button>
      </Col>
  );
  let tabSettings = [
    {
      id: "1",
      title: "Website",
      Component: WebsiteSettings,
    },
    {
      id: "2",
      title: "Brokerage",
      Component: CompanySettings,
    },/*
    {
      id: "3",
      title: "About",
      Component: AboutSettings,
    },*/
    {
      id: "4",
      title: "Agent",
      Component: SalesmanSettings,
      industries: [1],
    },
    {
      id: "5",
      title: "Social",
      Component: SocialSettings,
    },
    {
      id: "6",
      title: "Homepage Banner",
      Component: HomepageBannerSettings,
    },
    {
      id: "7",
      title: "Theming",
      Component: ColorSchemaSettings,
    },
    {
      id: "8",
      title: "Secret Keys",
      Component: SecretKeysSettings,
    },
    {
      id: "9",
      title: "Website SEO",
      Component: WebsiteSEO,
    },
    {
      id: "10",
      title: "Policies",
      Component: PrivacyPolicySettings,
    },
    {
      id: "11",
      title: "Misc",
      Component: WebsiteOtherSettings,
    },
  ];
  tabSettings = tabSettings.filter(tab => {
    if (
        tab &&
        tab.industries &&
        tab.industries.length &&
        user &&
        user.website &&
        user.website.industry_id
    ) {
      return tab.industries.includes(user.website.industry_id)
    }
    return true
  });
  return (
      <div className="page-content">
        {pagesLoading !== 1 ? (
            <Container fluid={true}>
              <Nav tabs>
                {tabSettings.map(tabData => {
                  return (
                      <NavItem key={tabData.id}>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              active: activeTab === tabData.id,
                            })}
                            onClick={() => {
                              const params = new URLSearchParams();
                              params.tab = tabData.id;
                              console.log(params);
                              history.push("/settings?tab=" + tabData.id);
                              toggleTab(tabData.id)
                            }}
                        >
                          {tabData.title}
                        </NavLink>
                      </NavItem>
                  )
                })}
              </Nav>
              <TabContent activeTab={activeTab}>
                {pagesLoading !== 1 ?
                    tabSettings.map(({Component, id}) => {
                      return (
                          <TabPane tabId={id} key={id}>
                            <Component
                                savePageButton={savePageButton}
                                data={data}
                                handleFile={handleFile}
                                themes={themes}
                                removeFile={removeFile}
                                webSettings={webSettings}
                                isAdmin={user.role_id === 1}
                                handleSubmit={handleValidSubmit}
                                colorSchema={colorSchema}
                                updateConfig={updateConfig}
                            />
                          </TabPane>
                      )
                    }) : 'Page loading'}
              </TabContent>
            </Container>
        ) : <FormLoader/>}
      </div>

  )
};
const WebsiteSettingsComponent = (props) => {
  return <WebsiteSettingProvider>
    <Settings {...props}/>
  </WebsiteSettingProvider>
};

const mapStatetoProps = state => {
  const {user} = state.user;
  return {user}
};

export default connect(mapStatetoProps)(WebsiteSettingsComponent)
