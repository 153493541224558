import { Link } from "react-router-dom";
import Moment from "react-moment";
import React from "react";

const NotificationTile = ({ notification, closeModal }) => {

  function getLink(notification) {
    if (notification.notification_type === "SUPPORT") {
      return "/leads/" + notification.wu_id + "/profile?tab=10";
    } else if (notification.wu_id) {
      return "/leads/" + notification.wu_id + "/profile";
    }
    return "/";
  }

  return <>
    <div style={{ "background": notification.isRead ? "#eaeaea" : "" }} onClick={closeModal}>
      <Link to={getLink(notification)} className=" text-reset notification-item  ">
        <div className="media">
          <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            {
                              notification.wu_id ? <i className="bx bx-user"/> :
                                <i className="bx bx-envelope"/>
                            }
                          </span>
          </div>
          <div className="media-body" style={{wordBreak: 'break-word'}}>
            <h6 className="mt-0 mb-1">
              {notification.notification_title}
            </h6>
            <div className="font-size-12 text-muted">
              <p className="mb-1">
                {notification.notification_description}
              </p>
              <p className="mb-0">
                <i className="mdi mdi-clock-outline"/>{" "}
                <Moment fromNow titleFormat="LLL" withTitle>{notification.created}</Moment>
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>

  </>;
};
export default NotificationTile;
