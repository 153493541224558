import { Col, FormGroup, Label, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const leadSources = [
  { label: "Direct", value: "DIRECT" },
  { label: "Sign Up", value: "SIGN_UP" },
  { label: "Email", value: "EMAIL" },
  { label: "Facebook", value: "FACEBOOK" },
  { label: "Website Contact", value: "WEBSITE_CONTACT" },
  { label: "Neighbourhood Watch", value: "NEIGHBOURHOOD_WATCH" },
  { label: "Listing Alert", value: "LISTING_ALERT" },
  { label: "Dream Home", value: "DREAM_HOME" },
  { label: "Call", value: "CALL" }
];
const LeadFormAdd = (props) => {
  const [leadData, setLeadData] = useState(props.data);
  useEffect(function() {
    if (props.data.save) {
      $("#leadFormBtn").click();
    }
  }, [props.data.save]);

  function handleSubmit(e, values) {
    props.handleSubmit({ ...leadData, ...values });
  }

  return (
    <AvForm className="needs-validation" onValidSubmit={handleSubmit} modal={props.data} id="leadForm">
      <Row>
        <Col md="3" className="align-items-center d-flex">
          <label className="col-form-label mb-2 align-self-center d-flex">Lead Info</label>
        </Col>
        <Col md="9">
          <Row>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <AvField
                  name="name"
                  value={props.data.name}
                  placeholder="John Doe"
                  type="text"
                  className="form-control"
                  id="name"
                  minLength={3}
                  required={true}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <AvField
                  name="email"
                  value={props.data.email}
                  placeholder="john@example.com"
                  type="email"
                  className="form-control"
                  id="email"
                  required={true}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="phone">Cellphone</Label>
                <AvField
                  name="phone"
                  value={props.data.phone}
                  placeholder="9876627820"
                  type="text"
                  className="form-control"
                  id="phone"
                  minLength={10}
                  maxLength={13}
                  required={true}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="phone">Work Phone</Label>
                <AvField
                  name="work_phone"
                  value={props.data.work_phone}
                  placeholder="9876627820"
                  type="text"
                  className="form-control"
                  id="phone"
                  minLength={10}
                  maxLength={13}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>

      </Row>
      <Row className="mt-4">
        <Col md="3" className="align-items-center d-flex">
          <label className="col-form-label mb-2 align-self-center d-flex">Contact Preference</label>
        </Col>
        <Col md="9">
          <Row>
            <Col sm="12" md="6">
              <FormGroup className="d-flex align-items-center justify-content-between">
                <Label htmlFor="canText">Can Text</Label>
                <div
                  className="custom-control custom-switch custom-switch-md d-inline-block"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="canText"
                    checked={leadData.canText}
                    onChange={(e, v) => {
                      setLeadData({ ...leadData, canText: e.target.checked });
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="canText"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md="6"/>
            <Col sm="12" md="6">
              <FormGroup className="d-flex align-items-center justify-content-between">
                <Label htmlFor="canEmail">Can Email</Label>
                <div
                  className="custom-control custom-switch custom-switch-md d-inline-block"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="canEmail"
                    checked={leadData.canEmail}
                    onChange={(e, v) => {
                      setLeadData({ ...leadData, canEmail: e.target.checked });
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="canEmail"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md="6"/>
            <Col sm="12" md="6">
              <FormGroup className="d-flex align-items-center justify-content-between">
                <Label htmlFor="canCall">Can Call</Label>
                <div
                  className="custom-control custom-switch custom-switch-md d-inline-block"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="canCall"
                    checked={leadData.canCall}
                    onChange={(e, v) => {
                      setLeadData({ ...leadData, canCall: e.target.checked });
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="canCall"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>

        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="3" className="align-items-center d-flex">
          <label className="col-form-label mb-2 align-self-center d-flex">Lead Info</label>
        </Col>
        <Col md="9">
          <FormGroup>
            <Label htmlFor="validationCustom01">Lead Source</Label>
            <Select
              value={leadSources.filter(option => option.value === leadData.lead_source)}
              onChange={(e) => {
                setLeadData({ ...leadData, lead_source: e.value });
              }}
              options={leadSources}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="d-none">
        <button type="submit" id="leadFormBtn">save</button>
      </Row>
    </AvForm>
  );
};

LeadFormAdd.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.object
};

export default LeadFormAdd;
