import {Card, CardBody, Col, Row} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

const FacebookInsightsCards = (props) => {
  function getCard({ color, value, title, classname, icon, key }) {
    return (
      <Card>
        <CardBody>
          <div className={"d-flex align-items-center " + classname} style={color ? { color } : {}}>
            <i className={'bx bx-sm bx-' + icon}/>
            <span className=" ml-1">{title}</span>
          </div>
          <p
            className="font-size-14 mt-1 ml-2 font-size-18 font-weight-bolder number">
            {
              key === 'spent' ?
                '$' + Intl.NumberFormat().format(value.split('$')[1])
                : Intl.NumberFormat().format(value)
            }
          </p>
        </CardBody>
      </Card>
    );
  }

  return (<>
    <Row>
      <Col md="3">
        {getCard({
          value: `$${props.stats.spend}`,
          key: 'spent',
          title: 'Amount Spent',
          color: 'green',
          icon: 'dollar'
        })}
      </Col>
      <Col md="3">
        {getCard({
          value: props.stats.clicks,
          key: 'click',
          title: 'Clicks',
          classname: 'text-primary',
          icon: 'link'
        })}
      </Col>
      {
        props.stats.actions && props.stats.actions.lead ?
          <Col md="3">
            {getCard({
              value: props.stats.actions.lead,
              key: 'lead',
              title: 'Leads',
              color: 'purple',
              icon: 'user-pin'
            })}
          </Col> : ''
      }

      <Col md="3">
        {getCard({
          value: props.stats.impressions,
          key: 'impression',
          title: 'Impressions',
          classname: 'text-info',
          icon: 'check-double'
        })}
      </Col>
      <Col md="3">
        {getCard({
          value: props.stats.reach,
          title: 'Reach',
          key: 'reach',
          classname: 'text-success',
          icon: 'check'
        })}
      </Col>
      {props.stats.actions && props.stats.actions.like &&
      <Col md="3">
        {getCard({
          value: props.stats.actions.like,
          key: 'like',
          title: 'Post Likes',
          classname: 'text-success',
          icon: 'like'
        })}
      </Col>
      }
      {
        props.stats.actions && props.stats.actions.comment &&
        <Col md="3">
          {getCard({
            value: props.stats.actions.comment,
            key: 'comment',
            title: 'Post Comments',
            classname: 'text-success',
            icon: 'comment'
          })}
        </Col>
      }
    </Row>

  </>);
};
FacebookInsightsCards.propTypes = {
  stats: PropTypes.object
};
export default FacebookInsightsCards;
