import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { get, post, put } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toastr from "toastr";
import { showError } from "../../helpers/util.helper";
import { useHistory } from "react-router-dom";
import AddCategoryModal from "../../common/AddCategory";
import Select from "react-select";

const addPhoto = ({ match }) => {

  const [data, setData] = useState({});
  const [convertedContent, setConvertedContent] = useState("");
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );
  let history = useHistory();
  useEffect(async function() {
    if (match && match.params && match.params.photo_id) {
      getPhoto({ photo_id: match.params.photo_id });
    }
  }, []);

  async function handleValidSubmit(e, values) {
    onEditorStateChange();
    let photo = {
      ...data,
      ...values,
      photo_description: convertedContent || data.photo_description,
    };
    let response;
    if (photo.photo_id) {
      response = await put(url.PHOTO_LIST + "/" + data.photo_id, photo);
    } else {
      response = await post(url.PHOTO_LIST, photo);
    }
    if (!response.api_status) {
      toastr.success("Photo added successfully.", "Success");
      back();
    } else {
      showError(response);
    }
  }


  function back() {
    history.push("/photos");

  }

  function onEditorStateChange(e) {
    if (e) {
      setEditorState(e);
    }
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const value = draftToHtml(
      rawContentState
    );
    setConvertedContent(value);
  };

  function handleFile(e, key) {
    const reader = new FileReader();
    reader.onload = function(e) {
      setData({ ...data, [key]: e.target.result });
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  async function getPhoto({ photo_id }) {
    const response = await get(url.PHOTO_LIST + "/" + photo_id);
    if (!response.api_status) {
      const blocksFromHtml = htmlToDraft(response.photo_description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const state = EditorState.createWithContent(contentState);
      setEditorState(state);
      setData(response);
    } else {
      showError(response);
    }
  }


  return (
    <React.Fragment>
      <AvForm className="needs-validation" onValidSubmit={handleValidSubmit} modal={data}>

        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Add Photo" breadcrumbItem="Photo Add"/>
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <h4 className="card-title">Photo</h4>
                      <p className="card-title-desc">Add gallery photo info</p>
                    </Col>
                    <Col md="8">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Title</Label>
                            <AvField
                              name="photo_title"
                              value={data.photo_title}
                              placeholder="Lorum Ipsum.."
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <Label htmlFor="validationCustom01">Description</Label>

                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Photo</Label>
                            <AvField
                              name="photo"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={event =>
                                handleFile(event, "photo_path")
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          {
                            <img src={data.photo_path}
                                 alt="" className="img img-thumbnail"/>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12 text-center">
              <Card>
                <CardBody>
                  <Button color="secondary" className="py-2 mr-2" type="button" onClick={back}>
                    Back
                  </Button>
                  <Button color="primary" className="py-2" type="submit">
                    {
                      data.photo_id ?
                        "Update"
                        : "Save"
                    }
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </AvForm>

    </React.Fragment>
  );
};

export default addPhoto;
