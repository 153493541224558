import {Col, FormGroup, Label, Modal} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";


const ParallaxModal = (props) => {
  const [showButton, setShowButton] = useState(props.config.showButton);
  const [image, setImage] = useState(props.config.image);

  function handleValidSubmit(event, values) {
    props.onCloseParallaxModal({
      heading: values.heading,
      description: values.description,
      showButton,
      button_title: showButton && values.button_title,
      button_link: showButton && values.button_link,
      height: values.height, el: props.el,
      image
    });
  }

  function closeModal() {
    props.onCloseParallaxModal({
      heading: props.heading,
      description: props.description,
      showButton: props.config.showButton,
      button_title: props.config.button_title,
      button_link: props.config.button_link,
      height: props.height, el: props.el,
      image
    });
  }
  function deleteImage() {
    setImage(null);
  }
  function handleFile(e) {
    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={closeModal}
    >
      <AvForm onValidSubmit={handleValidSubmit}>
        <div className="modal-header">
          <h5 className="modal-parallax mt-0" id="myModalLabel">Settings</h5>
          <button
            type="button"
            onClick={closeModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Heading</Label>
              <AvField
                name="heading"
                value={props.config.heading}
                placeholder="Lorum Ipsum"
                type="text"
                className="form-control"
                id="validationCustom04"
              />
            </FormGroup>
          </Col> <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Description</Label>
              <AvField
                name="description"
                value={props.config.description}
                placeholder="Lorum Ipsum"
                type="textarea"
                rows="5"
                className="form-control"
                id="validationCustom04"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Height(in Pixels)</Label>
              <AvField
                name="height"
                value={props.config.height}
                placeholder="400px"
                type="text"
                className="form-control"
                id="validationCustom04"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01" className="d-flex align-items-center justify-content-between">
                <span>Project Photo</span>
                {
                  image && <span className="fas fa-minus-circle"
                                 onClick={() => {
                                   deleteImage();
                                 }}
                                 style={{ color: 'red', cursor: 'pointer' }}/>
                }
              </Label>
              {
                !image && <AvField
                  name="post"
                  type="file"
                  className="form-control"
                  id="validationCustom03"
                  onChange={event =>
                    handleFile(event, "portfolioPhoto")
                  }
                />
              }
            </FormGroup>
          </Col>
          <Col md="12">
            {
              <img src={image}
                   alt="" className="img img-thumbnail"/>
            }
          </Col>
          <Col md="12">
            <FormGroup className="d-flex align-items-center justify-content-between">
              <Label htmlFor="validationCustom01">Show Button</Label>
              <div
                className="custom-control custom-switch custom-switch-md d-inline-block"
                dir="ltr"
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="externalPage"
                  checked={showButton}
                  onChange={(e, v) => {
                    setShowButton(e.target.checked)
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="externalPage"
                />
              </div>
            </FormGroup>
          </Col>
          {
            showButton &&
            <>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="validationCustom01">Button Title</Label>
                  <AvField
                    name="button_title"
                    value={props.config.button_title}
                    placeholder="Submit"
                    type="text"
                    className="form-control"
                    id="validationCustom03"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="validationCustom01">Button Link</Label>
                  <AvField
                    name="button_link"
                    value={props.config.button_link}
                    placeholder="https://example.com"
                    type="url"
                    className="form-control"
                    id="validationCustom03"
                    required
                  />
                </FormGroup>
              </Col>
            </>
          }
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

ParallaxModal.propTypes = {
  el: PropTypes.object,
  config: PropTypes.object,
  openModal: PropTypes.bool,
  onCloseParallaxModal: PropTypes.func
};

export default ParallaxModal;
