import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import FacebookLogo from "../../assets/images/ae/default/facebook.png";
import {showError, showSuccess} from "../../helpers/util.helper";
import FacebookConnectAccountModal from "./SuccessModal/FacebookConnectAccount.modal";
import {post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {connect} from "react-redux";
import {userAccessTokenLogin} from '../../store/actions';
import {useHistory} from "react-router-dom";

const FacebookConnect = (props) => {
  const [successModal, setSuccessModal] = useState({ showModal: false });
  const [authresponse, setautresponse] = useState();
  const history = useHistory();
  const features = [
    'Ad account insights',
    'All Lead forms at one place',
    'View all leads'
  ];
  const howTo = [
    'Connect Facebook Account',
    'Provide all required permissions',
    'Associate your page and ad account'
  ];

  function connectFacebook(response) {
    let isPermissionDenied = false;
    FB.login((response) => {
      if (response.authResponse) {
        setautresponse(response.authResponse);
        FB.api('/me/permissions', async (data) => {
          if (data['data'] && data['data'].length) {
            data['data'].map(permission => {
              if (permission.status === 'declined') {
                isPermissionDenied = true;
              }
            });
            if (isPermissionDenied) {
              FB.api('me/permissions', 'delete');
              showError({message: 'Please provide all required permissions'});
            } else {
              const accounts = await fetchFBAccounts(response);
              openSuccessModal(accounts);
            }
          }
        });
      }
    }, { scope: 'pages_show_list, leads_retrieval, pages_manage_ads, ads_management,pages_read_engagement, pages_manage_metadata' });
  }

  function fetchFBAccounts() {
    return new Promise((resolve) => {
      FB.api('/me/accounts?fields=name,id,picture{url},access_token,category,tasks,category_list&limit=100', (data) => {
        if (data['data'] && data['data'].length) {
          let pages = data['data'];
          FB.api('/me/adaccounts?fields=name', (data) => {
            let ad_accounts = data.data;
            resolve({ ad_accounts, pages });
          });
        }
      });
    });
  }

  async function associatedCredentials(config) {
    setSuccessModal({ showModal: false });
    if (config.page_id && config.ad_account_id) {
      const response = await post(url.FACEBOOK_FEATURE_CONNECT, {
        fb_access_token: authresponse.accessToken,
        page_id: config.page_id,
        fb_user_id: authresponse.userID,
        fb_ad_account_id: config.ad_account_id
      });
      if (!response.api_status) {
        showSuccess({ message: 'Account connected successfully.' });
        props.userAccessTokenLogin();
      } else {
        showError({ message: 'Something went wrong.' });
      }
    } else {
      showError({ message: 'Please select page and ad account to continue.' });
    }

  }

  function openSuccessModal(accounts) {
    if (typeof accounts !== 'undefined') {
      if (!(accounts.pages && accounts.pages.length)) {
        showError({ message: 'No pages associated. Please create a page to continue' });
      } else if (!(accounts.ad_accounts && accounts.ad_accounts.length)) {
        showError({ message: 'No ad accounts associated. Please create a ad account to continue' });
      } else {
        setSuccessModal({ showModal: true, ...accounts });
      }
    } else {
      showError({ message: 'Something went wrong.' });

    }

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="col-lg-8 offset-2">
            <Card>
              <CardBody>
                <div className="text-center mb-5 pt-8">
                  <img src={FacebookLogo} alt="" height="50" className="my-3"/>
                  <h4 className="text-uppercase mt-3">Welcome to Facebook Marketing Portal</h4>
                  <Row className="mt-3">
                    <Col md="6">
                      <div className="text-right">
                        <h5><b>Features</b></h5>
                        {
                          features.map((feature, idx) => (
                            <p key={idx}><i className="mdi mdi-chevron-right text-primary me-1"/> {feature}</p>
                          ))
                        }
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="text-left">
                        <h5><b>How to Connect</b></h5>
                        {
                          howTo.map((feature, idx) => (
                            <p key={idx}><i className="mdi mdi-chevron-right text-primary me-1"/> {feature}</p>
                          ))
                        }
                      </div>
                    </Col>
                  </Row>
                  <button className="btn btn-primary mt-3" onClick={() => history.push('/integrations')} style={{background: '#1877F2'}}>
                    <i className="fab fa-facebook-f mr-2" style={{background: '#1877F2',}}/>
                    Continue With Facebook
                  </button>

                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        {
          successModal.showModal === true ?
            <FacebookConnectAccountModal
              pages={successModal.pages}
              ad_accounts={successModal.ad_accounts}
              openModal={successModal.showModal} onCloseFacebookModal={associatedCredentials}/>
            : ""
        }
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = state => {
  const {
    user
  } = state.user;
  return { user };
};

export default connect(mapStatetoProps, { userAccessTokenLogin })(FacebookConnect);

