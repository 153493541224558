import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import {del, get, post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {page_limit} from "../../config";
import pageColumns from "../../page.columns";
import {useHistory} from "react-router-dom";
import toastr from "toastr";
import {showError, showSuccess} from "../../helpers/util.helper";
import DeleteModal from "../../common/DeleteModal";
import {TableLoader} from "../../components/Loader";
import {signInUser} from "../../Gmb.connect";
import './testimonials.scss';
import FacebookConnectAccountModal from "../Facebook/SuccessModal/FacebookConnectAccount.modal";
import {GMBLocationModal} from "./gmb-location.modal";
//Import Breadcrumb
let GoogleAuth; // Google Auth object.

const Testimonials = ({match}) => {
  const [successModal, setSuccessModal] = useState({showModal: false});
  const [connectedPlatforms, setConnectedPlatforms] = useState({facebook: 0, gmb: 0});
  const [gmbModal, setGMBModal] = useState({showModal: false, location: []});
  const [authResponse, setAuthResponse] = useState();
  let history = useHistory();
  useEffect(() => {
    getPageData({limit, offset});
  }, []);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingTestimonialId, setdeletingTestimonialId] = useState(null);
  const actions = [
    {
      name: "Share",
      icon: "fas fa-share",
      fn: shareTestimonial
    }, {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editTestimonial
    }, {
      name: "Delete",
      icon: "fas fa-trash",
      color: "danger",
      fn: openDeleteModal
    }];
  const limit = page_limit;
  const offset = 0;
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState({
    columns: pageColumns.testimonials,
    rows: []
  });
  const extraButtons = <>
    {
      connectedPlatforms.gmb === 0 &&
      <Button
          type="button"
          color="danger"
          onClick={() => gmbLogin()}
          className="form-group mr-1 btn-sm"
      >
        <i className="mdi mdi-google mr-1"/>
        Connect GMB
      </Button>
    }
    {
      connectedPlatforms.facebook === 0 && <Button
          type="button"
          onClick={() => history.push("/integrations")}
          className="form-group mr-1 btn-sm facebookBtn"
      >
        <i className="mdi mdi-facebook mr-1"/>
        Connect Facebook
      </Button>
    }
  </>;

  function shareTestimonial({testimonial_id}) {
    history.push({pathname: 'share', search: '?id=' + testimonial_id + '&type=testimonial'})
  }

  function connectFacebookTestimonials() {
    let isPermissionDenied = false;
    FB.login((response) => {
      if (response.authResponse) {
        setAuthResponse(response.authResponse);
        FB.api('/me/permissions', async (data) => {
          if (data['data'] && data['data'].length) {
            data['data'].map(permission => {
              if (permission.status === 'declined') {
                isPermissionDenied = true;
              }
            });
            if (isPermissionDenied) {
              FB.api('me/permissions', 'delete');
              showError({message: 'Please provide all required permissions'});
            } else {
              FB.api('/me/accounts?fields=name', (data) => {
                openSuccessModal(data.data);
              });
            }
          }
        });
      }
    }, {scope: 'pages_read_user_content'});
  }

  function openSuccessModal(pages) {
    if (typeof pages !== 'undefined') {
      if (!(pages && pages.length)) {
        showError({message: 'No pages associated. Please create a page to continue'});
      } else {
        setSuccessModal({showModal: true, ...{pages}});
      }
    } else {
      showError({message: 'Something went wrong.'});
    }
  }

  function pageChange(e) {
    getPageData({limit, offset: (limit * (e - 1))});
  }

  const getPageData = async ({limit, offset}) => {
    setPageLoading(1);
    const testimonialData = await get(url.TESTIMONIAL, {
      params: {
        limit, offset
      }
    });
    setPageLoading(0);

    if(testimonialData.api_status) {
      return toastr.error('Something went wrong.');
    }
    setPageLoading(0);
    let _d = {...data, rows: testimonialData.data};
    setConnectedPlatforms({
      ...connectedPlatforms,
      facebook: testimonialData.facebook ? 1 : 0,
      gmb: testimonialData.gmb ? 1 : 0
    });
    setData(_d);
    setTotalPages(testimonialData.total_pages);
  };

  function addTestimonial() {
    history.push("/addTestimonial");
  }

  function editTestimonial({ testimonial_id }) {
    history.push("/editTestimonial" + "/" + testimonial_id);
  }


  async function deleteTestimonial() {
    setDeleteModal(false);
    const response = await del(url.TESTIMONIAL + "/" + deletingTestimonialId);
    if (!response.api_status) {
      let _d = data.rows.filter(d => d.testimonial_id !== deletingTestimonialId);
      setData({ ...data, rows: _d });
      setdeletingTestimonialId(null);
      toastr.success("Testimonial deleted successfully.", "Success");
    } else {
      showError(response);
    }
  }

  function openDeleteModal({testimonial_id}) {
    setdeletingTestimonialId(testimonial_id);
    setDeleteModal(true);
  }

  async function associatedCredentials(config) {
    setSuccessModal({showModal: false});
    if (config.page_id) {
      const response = await post(url.FACEBOOK_FEATURE_CONNECT, {
        fb_access_token: authResponse.accessToken,
        page_id: config.page_id,
        fb_user_id: authResponse.userID,
        feature: 'ratings'
      });
      if (!response.api_status) {
        showSuccess({message: 'Account connected successfully.'});
        getPageData({limit: page_limit, offset: 0})
      } else {
        showError({message: 'Something went wrong.'});
      }
    } else {
      showError({message: 'Something went wrong. Please try again after some time.'});
    }
  }

  async function gmbLogin() {
    const locations = await signInUser();
    if (locations && locations.length) {
      setGMBModal({...gmbModal, showModal: true, locations})
    }
  };

  async function saveLocation({location_id}) {
    let location_name = gmbModal.locations.find(location => location.location_id === location_id).title;
    setGMBModal({...gmbModal, showModal: false, locations: []});
    const response = await post(url.GMB_UPDATE_LOCATION, {location_id, location_name});
    if (!response.api_status) {
      getPageData({limit, offset: 0});
      return showSuccess({message: 'Testimonials fetched successfully.'});
    }
    return showError({message: 'Please select page to continue.'});
  }


  return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Dashboard" breadcrumbItem="Testimonial List"/>
            <Row>
              <Col md={12}>

                {/*<button className="btn btn-sm btn-primary">Connect GMB</button>*/}
              </Col>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {
                      pagesLoading ?
                          <TableLoader/>
                          :
                          <AETable columns={data.columns}
                                   rows={data.rows}
                                   addLink={addTestimonial}
                                   itemsPerPage={limit}
                                   actions={actions}
                                   loading={pagesLoading}
                                   totalCount={totalPages}
                                   extraButtons={extraButtons}
                                   pageFn={pageChange}/>
                    }
                  </CardBody>

                  <DeleteModal
                      onCloseClick={() => {
                        setdeletingTestimonialId(null);
                        setDeleteModal(false);
                      }}
                      onDeleteClick={deleteTestimonial}
                      show={deleteModal}>
                  </DeleteModal>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        {
          successModal.showModal === true ?
              <FacebookConnectAccountModal
                  pages={successModal.pages}
                  ad_accounts={successModal.ad_accounts}
                  openModal={successModal.showModal} onCloseFacebookModal={associatedCredentials}/>
              : ""
        }
        {
          gmbModal.showModal &&
          <GMBLocationModal {...gmbModal}
                            onSave={saveLocation}
                            onClose={() => setGMBModal({...gmbModal, showModal: false, locations: []})}/>
        }
      </React.Fragment>
  );
};

export default Testimonials;
