import React from "react";

const LeadCallButtons = ({ closeModal, lead }) => {
  return <>
    <div className="text-center mt-2">
      <a className="btn btn-success" type={"button"} href={"tel:" + lead.phone}>
        <span className="fa fa-phone"/> Call</a>
      <button className="btn btn-primary ml-2" type={"submit"}>Save</button>
      <button className="btn btn-dark ml-2" type={"button"} onClick={closeModal}>Cancel</button>
    </div>
  </>;
};

export default LeadCallButtons;
