import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AETable from "../../components/Common/Table";
import { del, get } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { page_limit } from "../../config";
import pageColumns from "../../page.columns";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
import { showError } from "../../helpers/util.helper";
import toastr from "toastr";
import NoDataAvailable from "../../components/NoDataAvailable";
import { TableLoader } from "../../components/Loader";
import SettingsImagePreview from "../Settings/util/image-preview";

const Portfolio = ({ match }) => {
  let history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingPortfolioId, setDeletingPortfolioId] = useState(null);
  useEffect(() => {
    getPortfolio({ limit, offset });
  }, []);

  const actions = [
    {
      name: "Edit",
      icon: "fas fa-pencil-alt",
      fn: editPortfolio
    }, {
      name: "Delete",
      icon: "fas fa-trash",
      color: "danger",
      fn: openDeleteModal
    }];
  const limit = page_limit;
  const offset = 0;
  const [pagesLoading, setPageLoading] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState({
    columns: pageColumns.portfolio,
    rows: []
  });

  function pageChange(e) {
    getPortfolio({ limit, offset: (limit * (e - 1)) });
  }

  const getPortfolio = async ({ limit, offset }) => {
    setPageLoading(1);
    const portfolioData = await get(url.PORTFOLIO, {
      params: {
        limit, offset
      }
    });
    setPageLoading(0);
    portfolioData.data = setPortfolioData(portfolioData.data);
    let _d = { ...data, rows: portfolioData.data };
    setData(_d);
    setTotalPages(portfolioData.total_count);
  };


  function editPortfolio({ portfolio_id }) {
    history.push("/editProject/" + portfolio_id);
  }

  function addPortfolio() {
    history.push("/addProject");
  }

  async function deletePortfolio() {
    const response = await del(url.PORTFOLIO + "/" + deletingPortfolioId);
    if (!response.api_status) {
      let _d = data.rows.filter(d => d.portfolio_id !== deletingPortfolioId);
      setData({ ...data, rows: _d });
      setDeletingPortfolioId(null);
      setDeleteModal(false);
      toastr.success("Portfolio deleted successfully.", "Success");
    } else {
      showError(response);
    }
  }

  function openDeleteModal({ portfolio_id }) {
    setDeletingPortfolioId(portfolio_id);
    setDeleteModal(true);
  }

  function setPortfolioData(portfolios) {
    if (portfolios && portfolios.length) {
      return portfolios.map(portfolio => {
        if (portfolio.portfolio_photo) {
          portfolio.portfolio_photo =
            <SettingsImagePreview image={portfolio.portfolio_photo} width="100px" height="100px"/>;
        }
        if (portfolio.categories && portfolio.categories.length) {
          portfolio.categories =
            <>
              {portfolio.categories.map(category => {
                return <p>{category.category_name}</p>;
              })}
            </>;
        }
        return portfolio;
      });
    }
    return [];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Project List"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {
                    pagesLoading ?
                      <TableLoader/>
                      :
                      data.rows.length
                        ? <AETable columns={data.columns}
                                   rows={data.rows}
                                   itemsPerPage={limit}
                                   loading={pagesLoading}
                                   addLink={addPortfolio}
                                   actions={actions}
                                   totalCount={totalPages}
                                   pageFn={pageChange}/>
                        :
                        <NoDataAvailable module="portfolio"/>
                  }

                </CardBody>
                <DeleteModal
                  onCloseClick={() => {
                    setDeletingPortfolioId(null);
                    setDeleteModal(false);
                  }}
                  onDeleteClick={deletePortfolio}
                  show={deleteModal}>
                </DeleteModal>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Portfolio;
