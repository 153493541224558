import React, {useEffect, useState} from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"
import {useHistory} from "react-router-dom"
import {useParams} from "react-router"
import {get, post, put} from "../../../helpers/api_helper"
import * as url from "../../../helpers/url_helper"
import {showError} from "../../../helpers/util.helper"
import toastr from "toastr"
import * as classnames from "classnames"
import WebsiteUsers from "./website-users"
import WebsiteSettings from "./website-settings"

const AddWebsite = (props) => {
  const history = useHistory();
  let {website_id} = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [data, setData] = useState({});
  useEffect(function () {
    if (website_id) {
      getWebsite()
    }
  }, []);

  function handleValidSubmit(event, values) {
    let d = {...data, ...values};
    saveWebsite(d)
  }


  async function getWebsite() {
    setPageLoading(true);
    const response = await get(url.WEBSITES + "/" + website_id);
    setPageLoading(false);
    if (!response.api_status) {
      setData(response)
    } else {
      showError(response)
    }
  }

  async function saveWebsite(data) {
    let response;
    if (data.website_id) {
      response = await put(url.WEBSITES + "/" + data.website_id, data)
    } else {
      response = await post(url.WEBSITES, data)
    }
    if (!response.api_status) {
      toastr.success("website updated sucessfully.", "Success");
      history.push("/websites")
    } else {
      showError(response)
    }
  }

  function back() {
    history.push("/websites")
  }

  const [activeTab, setActiveTab] = useState("1");

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }


  return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Add website" breadcrumbItem="website Add"/>
          </div>
          {
            typeof website_id !== 'undefined' ?
                !pageLoading && <Container fluid={true}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                          style={{cursor: "pointer"}}
                          className={classnames({
                            active: activeTab === "1"
                          })}
                          onClick={() => {
                            toggleTab("1")
                          }}
                      >Settings</NavLink>
                    </NavItem>
                    <NavLink
                        style={{cursor: "pointer"}}
                        className={classnames({
                          active: activeTab === "2"
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                    >Users</NavLink>
                  </Nav>
                  < TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <WebsiteSettings data={data} handleValidSubmit={handleValidSubmit} back={back}/>
                    </TabPane>
                    <TabPane tabId="2">
                      <WebsiteUsers/>
                    </TabPane>
                  </TabContent>
                </Container> :
                <WebsiteSettings data={data} handleValidSubmit={handleValidSubmit} back={back}/>
          }
        </div>
      </React.Fragment>

  )
};

export default AddWebsite
