import React from "react";
import {Col, Row} from "reactstrap";
import {connect} from "react-redux";
import {userAccessTokenLogin, userAccessTokenSuccess} from '../../store/actions';
import {useHistory} from "react-router-dom";
import {get} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {NavLink} from 'react-router-dom';

const FacebookMarketingLayout = (props) => {
  const history = useHistory();

  async function disconnectUser() {
    await get(url.FACEBOOK_DISCONNECT);
    props.userAccessTokenLogin();
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{
        minHeight: '1000px'
      }
      }>
        <div className="container-fluid">
          <div className="topnav" style={{
            marginTop: 0,
            position: 'relative'
          }
          }>
            <div className="container-fluid">
              <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                <div className="collapse navbar-collapse active" id="topnav-menu-content">
                  <ul className="navbar-nav active">
                    <li className="nav-item ">
                      <NavLink to="/fb-marketing/insights" activeClassName={'active'}
                               className="nav-link  "  role="button">
                        <i className="fa fa-chart-line me-2"/><span key="t-extra-pages"> Insights</span>
                      </NavLink>
                    </li>
                    <li className="nav-item ">
                      <NavLink to="/fb-marketing/forms" className="nav-link  "
                               role="button">
                        <i className="fa fa-list me-2"/><span key="t-extra-pages"> Lead Forms</span>
                      </NavLink>
                    </li>
                    {/*<li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle arrow-none" id="topnav-more" role="button">
                        <i className="fa fa-toggle-on me-2"/><span key="t-extra-pages"> Switch Ad Account</span>
                      </a>
                    </li>*/}
                    <li className="nav-item ">
                      <a className="nav-link  "  role="button"
                         onClick={disconnectUser}>
                        <i className="fa fa-sign-out-alt me-2"/><span key="t-extra-pages"> Disconnect</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <Row className="mt-4">
            <Col md="12">
              {props.children}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  const { user } = state.user;
  return { user };
};
export default connect(mapStateToProps, { userAccessTokenSuccess, userAccessTokenLogin })(FacebookMarketingLayout);
