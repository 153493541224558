import React, {useEffect, useState} from "react"
import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import {get} from "../../../helpers/api_helper"
import * as url from "../../../helpers/url_helper"

const WebsiteSettings = (props) => {
  const [templates, setTemplate] = useState([]);
  useEffect(async function () {
    const response = await get(url.TEMPLATES);
    if (Array.isArray(response)) {
      setTemplate(response)
    }
  }, []);
  return (
      <AvForm className="needs-validation" onValidSubmit={props.handleValidSubmit} modal={props.data}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Website info</h4>
                <p className="card-title-desc">Add information regarding your recent website.</p>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustom01">Website Name</Label>
                      <AvField
                          name="website_name"
                          value={props.data.website_name}
                          placeholder="Real Estate Exports"
                          type="text"
                          className="form-control"
                          id="validationCustom03"
                      />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="validationCustom01">Domain Name</Label>
                    <AvField
                        name="base_url"
                        value={props.data.base_url}
                        placeholder="https://example.com"
                        type="url"
                        className="form-control"
                        id="validationCustom03"
                    />
                  </FormGroup>
                </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustom01">SubDomain</Label>
                      <AvField
                          name="subdomain"
                          value={props.data.subdomain}
                          placeholder="abc"
                          type="text"
                          className="form-control"
                          id="validationCustom03"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustom01">Template</Label>
                      <AvField
                          name="template_id"
                          value={props.data.template_id}
                          placeholder="abc"
                          type="select"
                          className="form-control"
                          id="validationCustom03"
                      >
                        {
                          templates?.map((template, key) => <option key={key}
                                                                    value={template.website_id}>{template.base_url}</option>)
                        }
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12 text-center">
          <Card>
            <CardBody>
              <Button color="secondary" className="py-2 mr-2" type="button" onClick={() => {
                props.back()
              }}>
                Back
              </Button>
              <Button color="primary" className="py-2" type="submit">
                {
                  props.data.website_id ?
                    "Update"
                    : "Save"
                }
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </AvForm>
  )
}

WebsiteSettings.propTypes = {
  data: PropTypes.object,
  handleValidSubmit: PropTypes.func,
  back: PropTypes.func
}
export default WebsiteSettings
