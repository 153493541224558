import React, {useEffect, useState} from "react"
import {Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane,} from "reactstrap"
//Import Breadcrumb
import "@vtaits/react-color-picker/dist/index.css"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as classnames from "classnames"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"
import FormLoader from "../../components/Loader/FormLoader";
import {WebsiteSettingProvider} from "../Settings/website-settings.context";
import {WebsitePlanDetails} from "./components/website-plan.details";
import {WebsiteCards} from "./components/website-cards";
import {WebsiteActions} from "./components/website-actions";
import * as api from '../../helpers/api_helper';
import {WEBSITE_PAYMENTS_DATA} from "../../helpers/url_helper";
import {WebsiteTransactionsList} from "./components/website-transactions-list";

toastr.options = {
    timeOut: 3000,
};

const PaymentSettings = ({user}) => {
    const [pagesLoading, setPageLoading] = useState(0);
    let {search} = useLocation();
    const history = useHistory();
    search = new URLSearchParams(search);
    let tabId = "1";
    if (search.get("tab") && search.get("tab").toString()) {
        tabId = search.get("tab") && search.get("tab").toString()
    }
    const [activeTab, setActiveTab] = useState(tabId);
    const [paymentsData, setPaymentsData] = useState({reFetchData: getPaymentsData});
    useEffect(async () => {
        setPageLoading(1);
        if (search.get("tab")) {
            setActiveTab(search.get("tab").toString())
        }
        await getPaymentsData();

        setPageLoading(0);
    }, []);

    async function getPaymentsData() {
        const response = await api.post(WEBSITE_PAYMENTS_DATA, {});
        if (!response.api_status) {
            setPaymentsData({...paymentsData, ...response})
        }
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    let tabSettings = [

        {
            id: "1",
            title: "Plan Details",
            Component: WebsitePlanDetails,
        },
        {
            id: "2",
            title: "Payments",
            Component: WebsiteTransactionsList,
        },
        {
            id: "3",
            title: "Cards",
            Component: WebsiteCards,
        },
        {
            id: "4",
            title: "Actions",
            Component: WebsiteActions,
        },
    ];
    tabSettings = tabSettings.filter(tab => {
        if (
            tab &&
            tab.industries &&
            tab.industries.length &&
            user &&
            user.website &&
            user.website.industry_id
        ) {
            return tab.industries.includes(user.website.industry_id)
        }
        return true
    });
    return (
        <div className="page-content">
            {pagesLoading !== 1 && paymentsData.website && paymentsData.payments ? (
                <Container fluid={true}>
                    <Nav tabs>
                        {tabSettings.map(tabData => {
                            return (
                                <NavItem key={tabData.id}>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        className={classnames({
                                            active: activeTab === tabData.id,
                                        })}
                                        onClick={() => {
                                            const params = new URLSearchParams();
                                            params.tab = tabData.id;
                                            console.log(params);
                                            history.push("/payments?tab=" + tabData.id);
                                            toggleTab(tabData.id)
                                        }}
                                    >
                                        {tabData.title}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {pagesLoading !== 1 ?
                            tabSettings.map(({Component, id, title}) => {
                                return (
                                    <TabPane tabId={id} key={id}>
                                       <Card>
                                           <CardBody>
                                               <h4 className="card-title">{title}</h4>
                                               <p className="card-title-desc"/>
                                               <Component paymentsData={paymentsData}
                                               />
                                           </CardBody>
                                       </Card>
                                    </TabPane>
                                )
                            }) : 'Page loading'}
                    </TabContent>
                </Container>
            ) : <FormLoader/>}
        </div>

    )
};
const Payments = (props) => {
    return <WebsiteSettingProvider>
        <PaymentSettings {...props}/>
    </WebsiteSettingProvider>
};

const mapStatetoProps = state => {
    const {user} = state.user;
    return {user}
};

export default connect(mapStatetoProps)(Payments)
