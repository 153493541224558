import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap"
import {AvField} from "availity-reactstrap-validation"
import React from "react"
import PropTypes from "prop-types"
import FormWrapper from "../util/form-wrapper"

const socialSettings = (props) => {
    return (
        <Card>
            <CardBody>
                <h4 className="card-title">Social Media Links</h4>
                <p className="card-title-desc">Your company's presence on social media.</p>
        <FormWrapper
          fields={<Row>
              {
                  props.webSettings.facebook && <Col md="6">
                      <FormGroup>
                          <Label htmlFor="facebook-link">Facebook</Label>
                          <AvField
                              name="website_config.facebook"
                              value={props.data.website_config.facebook}
                              placeholder="https://facebook.com/example"
                              type="text"
                              className="form-control"
                              id="facebook-link"
                          />
                      </FormGroup>
                  </Col>
              }
              {
                  props.webSettings.twitter && <Col md="6">
                      <FormGroup>
                          <Label htmlFor="twitter-link">Twitter</Label>
                          <AvField
                              name="website_config.twitter"
                              value={props.data.website_config.twitter}
                              placeholder="https://twitter.com/example"
                              type="text"
                              className="form-control"
                              id="twitter-link"
                          />
                      </FormGroup>
                  </Col>
              }
              {
                  props.webSettings.instagram && <Col md="6">
                      <FormGroup>
                          <Label htmlFor="instagram-link">Instagram</Label>
                          <AvField
                              name="website_config.instagram"
                              value={props.data.website_config.instagram}
                              placeholder="https://instagram.com/example"
                              type="text"
                              className="form-control"
                              id="instagram-link"
                          />
                      </FormGroup>
                  </Col>
              }
              {
                  props.webSettings.linkedin && <Col md="6">
                      <FormGroup>
                          <Label htmlFor="linkedin-link">Linkedin</Label>
                          <AvField
                              name="website_config.linkedin"
                              value={props.data.website_config.linkedin}
                              placeholder="https://linkedin.com/example"
                              type="text"
                              className="form-control"
                              id="linkedin-link"
                          />
                      </FormGroup>
                  </Col>
              }
          </Row>}
          data={props.data}
          handleSubmit={props.handleSubmit}
        />
      </CardBody>
    </Card>
  )
}

socialSettings.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  handleFile: PropTypes.func
}

export default socialSettings
