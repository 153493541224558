import {AvField, AvForm, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import React, {useState} from "react";
import GallerySelectPhotos from "./gallery-photo-select";
import ComponentWidthInput from "./ComponentWidthInput";

const GallerySettings = (props) => {
    let [rows, setRows] = useState(props.config.rows);
    const [galleryModal, setGalleryModal] = useState({show: false});
    const [selectImages, setSelectImages] = useState(+props.config.all_images === 0 || 0);
    const [selectedImages, setSelectedImages] = useState(props.config.images || []);

    function handleValidSubmit(event, values) {
        let rowsGroup = [];
        /* if (values.caption) {
             values.caption.forEach((item, index) => {
                 rowsGroup.push({
                     caption: values.caption[index],
                     image: rows[index].image
                 });
             });
         }*/
        props.updateConfig({
            // rows: rowsGroup,
            gallery_title: values.gallery_title,
            gallery_description: values.gallery_description,
            all_images: values.all_images,
            images: +values.all_images === 1 ? [] : selectedImages,
            ...values,
        });
    }

    function addNewRow() {
        let r = {title: "", description: ""};
        setRows([...rows, r]);
    }

    function deleteRow(id) {
        let r = rows.filter((d, i) => i !== id);
        setRows(r);
    }

    function handleFile(e, index) {
        const reader = new FileReader();
        reader.onload = function (e) {
            setRows(rows.map((row, i) => {
                if (i === index) {
                    row.image = e.target.result;
                }
                return row;
            }));
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    function deleteImage(index) {
        setRows(rows.map((row, i) => {
            if (i === index) {
                row.image = undefined;
            }
            return row;
        }));
    }

    function closeModal() {
        props.onCloseGridModal({config: props.config, el: props.el})
    }

    return <>
        <AvForm onValidSubmit={handleValidSubmit}>

            <Row className="py-2">
                <Col md="12">
                    <FormGroup>
                        <Label htmlFor="validationCustom01">Heading</Label>
                        <AvField
                            name="gallery_title"
                            value={props.config.gallery_title}
                            placeholder="Lorum Ipsum"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                        />
                    </FormGroup>
                </Col>

                <Col md="12">
                    <FormGroup>
                        <Label htmlFor="validationCustom01">Sub Heading</Label>
                        <AvField
                            name="gallery_description"
                            value={props.config.gallery_description}
                            placeholder="Lorum Ipsum"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <ComponentWidthInput {...props.config}/>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <AvRadioGroup name="all_images" inline value={props.config.all_images} onChange={e => {
                            setSelectImages(+e.target.value === 0);
                        }}>
                            <AvRadio label="Show All Gallery Images" value="1"/>
                            <AvRadio label="Select Images" value="0"/>
                        </AvRadioGroup>
                    </FormGroup>
                </Col>
                {
                    selectImages > 0 && selectedImages?.map((photo, key) => (
                        <Col md={6} key={key}>
                            <img src={photo.photo_path}
                                 className={'img-thumbnail img-fluid'}
                            />
                        </Col>

                    ))
                }
                {
                    selectImages > 0 && <Col md="12">
                        <Button
                            onClick={() => {
                                setGalleryModal({...galleryModal, show: true});
                            }}
                            color="success"
                            className="mt-1">
                            Choose Images
                        </Button>
                    </Col>
                }


            </Row>
            <div className="modal-footer">
                <button
                    onClick={() => props.close()}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                >Cancel</button>
                <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                >
                    Save changes
                </button>
            </div>
        </AvForm>
        {galleryModal.show && <GallerySelectPhotos selectedImages={selectedImages} closeModal={({images}) => {
            console.log(images, 'images selected')
            if (images && images.length) {
                setSelectedImages(images)
            } else {

            }
            setGalleryModal({...galleryModal, show: false})
        }
        }/>}

    </>
};
export default GallerySettings;
