import {
  GET_WEBPAGE_DETAIL,
  GET_WEBPAGE_DETAIL_FAIL,
  GET_WEBPAGE_DETAIL_SUCCESS,
  GET_WEBPAGES,
  GET_WEBPAGES_FAIL,
  GET_WEBPAGES_LOADING,
  GET_WEBPAGES_SUCCESS
} from "./actionTypes"

export const webPageLoading = () => ({
  type: GET_WEBPAGES_LOADING
})
export const getWebPages = ({ limit, offset }) => ({
  type: GET_WEBPAGES,
  limit,
  offset
})

export const getWebPagesSuccess = webPages => ({
  type: GET_WEBPAGES_SUCCESS,
  payload: webPages
})

export const getWebPagesFail = error => ({
  type: GET_WEBPAGES_FAIL,
  payload: error
})

export const getInvoiceDetail = invoiceId => ({
  type: GET_WEBPAGE_DETAIL,
  invoiceId
})

export const getInvoiceDetailSuccess = webPages => ({
  type: GET_WEBPAGE_DETAIL_SUCCESS,
  payload: webPages
})

export const getInvoiceDetailFail = error => ({
  type: GET_WEBPAGE_DETAIL_FAIL,
  payload: error
})
