import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { formatPhone, maxLength } from "../../helpers/util.helper";
import React, { useState } from "react";

import { AvField, AvForm } from "availity-reactstrap-validation";
import { HeaderLoader } from "../../components/Loader";
import { markAsVerified } from "./leads.service";
import * as moment from "moment-timezone";
import Moment from "react-moment";
import { post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import InputMask from "react-input-mask";

const LeadDashboardHeader = ({ lead, logs, showCallModal, updateLead, setModal, getTasks, getLogs, setDeleteModal, goBack }) => {
  const { wu_id } = lead;
  const [edit, setEdit] = useState({ editNumber: false, editAddress: false });

  async function addPhone(e) {
    if (e.target.phone.value && e.target.phone.value.trim()) {
      setEdit({ ...edit, editNumber: false });
      await updateLead({ ...lead, phone: e.target.phone.value.trim() });
    }
  }

  function addPhoneBtn(value = null) {
    return <>
      <AvForm onValidSubmit={addPhone}>
        <FormGroup>
          <AvField
            name="phone"
            value={lead.phone}
            type="tel"
            mask="(999) 999-9999"
            tag={[Input, InputMask]}
            className="form-control"
            id="validationCustom03"
          />
          <button className="btn btn-sm my-1 btn-dark text-dark" type="submit">{!lead.phone ? "Add" : "Update"} Phone
          </button>
          <button className="btn btn-sm my-1 btn-dark text-dark ml-2"
                  onClick={() => setEdit({ ...lead, editNumber: false })}
                  type="button"> Cancel
          </button>
        </FormGroup>
      </AvForm>
    </>;
  }


  async function addAddress(e) {
    if (e.target.address.value && e.target.address.value.trim()) {
      setEdit({ ...edit, editAddress: false });
      await updateLead({ ...lead, address: e.target.address.value.trim() });
    }
  }


  function addAddressBtn(value = null) {
    return <>
      <AvForm onValidSubmit={addAddress}>
        <FormGroup>
          <AvField
            name="address"
            value={lead.address || ""}
            type="textarea"
            className="form-control"
            id="validationCustom03"
          />
          <button className="btn btn-sm my-1 btn-dark text-dark"
                  type="submit">{!lead.address ? "Add" : "Update"} Address
          </button>
          <button className="btn btn-sm my-1 btn-dark text-dark ml-2"
                  onClick={() => setEdit({ ...lead, editAddress: false })}
                  type="button"> Cancel
          </button>

        </FormGroup>
      </AvForm>
    </>;
  }

  async function verifyUser() {
    const response = await markAsVerified({ wu_id });
    if (response) {
      updateLead({ ...lead, isVerified: 1 });
    }
  }

  function callBg(log) {
    let logType = {
      text: "Upcoming",
      cl: "bg-primary"
    };
    if (moment(log.schedule_date).isBefore(moment())) {
      logType.text = "Past Due";
      logType.cl = "bg-danger";
    }
    return logType;
  }

  async function removeTask(task_id) {
    await post(url.LEAD + "/" + wu_id + "/tasks/remove", { tasks: [task_id] });
    getTasks();
    getLogs();
  }

  return (
    <Card>
      <CardBody>
        {
          lead && Object.keys(lead).length > 0 ?
            <Row>
              <Col md={12}>
                <p className="pointer" onClick={goBack}> <span className="fas fa-arrow-left" /> Back</p>
              </Col>
              <Col md="2" className="align-self-center">
                <p className="mb-1 font-size-22  text-uppercase font-weight-bold">{lead.name}</p>
              </Col>
              <Col md="8">
                <Row>
                  <Col md={6}>
                    <p className="text-muted p-0 m-0">{lead.email}</p>
                    <div className="text-muted p-0 m-0">
                      {!edit.editNumber ? <>
                        {lead.phone && formatPhone(lead.phone)} &nbsp;
                        <a onClick={() => setEdit({
                          ...edit,
                          editNumber: true
                        })}>{lead.phone ? "Edit" : "Add Number"}</a>
                      </> : addPhoneBtn()}</div>
                    <div className="text-muted">
                      {lead.isVerified ?
                        <p className="badge badge-success"><span className="fa fa-check-circle"/>&nbsp; Verified</p>
                        :
                        <>
                          <p className="badge badge-danger"><span className="fa fa-times-circle"/>&nbsp; Not Verified
                          </p>
                          &nbsp; <a href="javascript:void(0)" onClick={verifyUser}>Verify</a>
                        </>
                      }
                    </div>
                  </Col>
                  <Col md={6}>
                    <p className="mb-0 p-0">{lead.address}</p>
                    <p className="badge badge-primary">Source: {lead.source_name}</p>
                  </Col>
                  <Col md={12}>
                    {
                      logs.length > 0 &&
                      <div className="border px-2 rounded " style={{ borderRadius: "20px" }}>
                        <Row className="rounded">
                          <Col md={2} style={{ borderRadius: "5px 0 0 5px" }}
                               className={"p-0  p-2 text-center text-light d-table " + callBg(logs[0]).cl}>
                            <div className="  d-table-cell align-middle ">
                              <small className="mx-auto "><b>{callBg(logs[0]).text}</b>
                                <br/>
                                <Moment format="MMM DD, YYYY hh:mm A">{logs[0].task_due_date}</Moment>
                              </small>
                            </div>

                          </Col>
                          <Col md={10}>
                            <Row className="my-2">
                              <Col md={8}>
                                <h5>{logs[0].task_title}</h5>
                                <span>{maxLength(logs[0].task_description, 50)}</span>
                              </Col>
                              <Col md={4} className="text-right">
                                <a className="btn btn-sm btn-primary" href={"tel:" + lead.phone}>
                                  <span className="fa fa-phone"/>
                                </a>
                                <a className="btn btn-sm btn-primary ml-2"
                                   onClick={() => setModal({ showModal: true, task: true, data: logs[0] })}>
                                  <span className="fa fa-pencil-alt"/>
                                </a>
                                <a className="btn btn-sm btn-danger ml-2"
                                   onClick={() => {
                                     setDeleteModal({ show: true, id: logs[0].la_id, fn: removeTask });
                                   }}
                                >
                                  <span className="fa fa-trash"/>
                                </a>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    }
                  </Col>
                </Row>
              </Col>
              <Col md="2">
                <Button
                  type="button"
                  color="success"
                  className="waves-effect waves-light btn-sm"
                  onClick={() => showCallModal()}
                  block
                >
                  <span className="bx bxs-phone-call"/> Call Logs
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  className="waves-effect waves-light btn-sm"
                  onClick={() => setModal({ showModal: true, alert: true })}
                  block
                >
                  <span className="fa fa-bell"/> Add Alert
                </Button>
                <Button
                  type="button"
                  color="info"
                  onClick={() => setModal({ showModal: true, task: true })}
                  className="waves-effect waves-light btn-sm"
                  block
                >
                  <span className="fa fa-check-circle"/> Add Task
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={() => setModal({ showModal: true, email: true })}
                  className="waves-effect waves-light btn-sm"
                  block
                >
                  <span className="fa fa-envelope"/> Send Email
                </Button>
              </Col>
            </Row>
            : <HeaderLoader/>
        }
      </CardBody>

    </Card>
  );
};

export default LeadDashboardHeader;
