import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import React, {useState} from "react"
import PropTypes from "prop-types"
import FormWrapper from "../util/form-wrapper"
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {AvField} from "availity-reactstrap-validation";

const PrivacyPolicySettings = (props) => {
    const [convertedContent, setConvertedContent] = useState("");
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );

    const [termsContent, setTermsContent] = useState("");
    const [termsState, setTermsState] = useState(
        () => EditorState.createEmpty()
    );


    useState(function () {
        setEditorData(props.data.website_config.privacy_policy, setEditorState);
        setEditorData(props.data.website_config.terms_of_use, setTermsState);
    });

    useState(function () {
        setTermsContent(props.data.website_config.terms_of_use);
        setConvertedContent(props.data.website_config.privacy_policy);
    }, []);

    function setEditorData(data, fn) {
        const blocksFromHtml = htmlToDraft(data || '');
        const {contentBlocks, entityMap} = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const state = EditorState.createWithContent(contentState);
        fn(state);
    }

    function saveSettings() {
        props.updateConfig({paragraph: convertedContent});
    }

    function onEditorStateChange(e) {
        if (e) {
            setEditorState(e);
        }
        convertContentToHTML(editorState, setConvertedContent);
    }


    function onTermsStateChange(e) {
        if (e) {
            setTermsState(e);
        }
        convertContentToHTML(termsState, setTermsContent);
    }

    const convertContentToHTML = (data, fn) => {
        const rawContentState = convertToRaw(data.getCurrentContent());
        const value = draftToHtml(
            rawContentState
        );
        fn(value);
    };
    return (
        <Card>
            <CardBody>
                <FormWrapper
                    fields={<Row>

                        {
                            props.webSettings.disclaimer && <Col md="12" className="mt-3">
                                <FormGroup>
                                    <Label htmlFor="disclaimer">Disclaimer</Label>
                                    <AvField
                                        name="website_config.disclaimer"
                                        placeholder="Lorum ipsum..."
                                        type="textarea"
                                        value={props.data.website_config.disclaimer}
                                        className="form-control"
                                        id="disclaimer"
                                        rows="5"
                                    />
                                </FormGroup>
                            </Col>
                        }
                        <Col md="12">
                            <label htmlFor="">Privacy Policy</label>
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                            />
                        </Col>
                        <Col md="12" className="mt-3">
                            <label htmlFor="">Terms of Use</label>
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorState={termsState}
                                onEditorStateChange={onTermsStateChange}
                            />
                        </Col>



                    </Row>}
                    handleSubmit={(event, values) => props.handleSubmit(null, {
                        website_config: {
                            privacy_policy: convertedContent,
                            terms_of_use: termsContent,
                            disclaimer: values.website_config.disclaimer
                        }
                    })}
                    data={props.data}
                />
            </CardBody>
        </Card>
    )
}

PrivacyPolicySettings.propTypes = {
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    handleFile: PropTypes.func
}

export default PrivacyPolicySettings
