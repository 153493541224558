import React from 'react';
import NoData from '../../assets/images/ae/default/no-data.svg';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const NoDataAvailableImage = (props) => {
  return (
    <Row className=''>
      <Col className=' text-center'>
        <Card>
          <CardBody>
            <h4 className='mb-4'>{props.title || 'No insights data available.'}</h4>
            <img
              src={NoData}
              alt='no data available'
              className='col-12'
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
NoDataAvailableImage.propTypes = {
  title: PropTypes.string,
};

export default NoDataAvailableImage;
