import React from "react";
import {useHistory} from "react-router-dom";
import {NavLink} from "reactstrap";

const platforms = {
    'facebook': {
        name: 'Facebook',
        icon: 'mdi-facebook text-primary',
        color: 'primary'
    },
    gmb: {
        name: 'Google My Business',
        icon: 'mdi-google text-danger',
        color: 'danger'
    }
};
const PlatformConnectionTile = ({platform, account, connect, disconnect, posts}) => {
    const history = useHistory();
    return <>
        <div className=" d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                <span className={"mdi font-size-22 mr-2 " + platforms[platform].icon}/>
                <div>
                    <p className="m-0 p-0 font-weight-bold font-size-14">{platforms[platform].name}</p>
                    {
                        account.sub_account_name &&
                        <p className="m-0 p-0"><i> {account.sub_account_name}</i></p>

                    }
                    {
                        account.ad_account_name &&
                        <p className="m-0 p-0"><i>Ad Account: {account.ad_account_name}</i></p>
                    }
                    {
                        posts && account.connected && <p className="m-0 p-0">
                            <NavLink onClick={() => history.push(posts)} className="m-0 p-0">
                                View Posts
                            </NavLink>
                        </p>
                    }
                </div>
            </div>
            {
                account.connected ?
                    <div>
                        <span className="btn btn-danger btn-sm" onClick={() => disconnect()}>Disconnect</span>
                    </div>
                    :
                    <span className="btn-sm btn-primary btn" onClick={() => connect()}>Connect</span>
            }
        </div>
    </>
};
export default PlatformConnectionTile;
