import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useJsApiLoader } from "@react-google-maps/api";
import {capitalize, debounce, showError} from "../../../helpers/util.helper";
import Select from "react-select";
import { searchLocation } from "../leads.service";

const librariesToLoad = ["places"];

const LeadAlertModal = ({ closeModal, openModal, data = {}, saveAlert }) => {
  const [mapSet, setMapSet] = useState(false);
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  useEffect(function() {
    fetchLocationSearch({ query: "Brampton", setData: true });
    if (data.location && data.location.length) {
      try {
        let location = JSON.parse(data.location);
        let selectedIds = location.map(d => d.id);
        let sel = location.map(location => {
          return { label: prepareLocation(location), value: location };
        });
        setSelectedLocations(sel);
      } catch (e) {

      }

    }
  }, []);

  useEffect(function() {
  });
  useEffect(function() {
    if (data.lat) {
      setLatLng({ ...latLng, lat: data.lat, lng: data.lng });
    }
  }, [data]);


  function initMap() {
    if (!mapSet) {
      setMapSet(true);
      const input = document.getElementById("alertAddress");
      const options = {
        componentRestrictions: { country: "ca" },
        fields: ["formatted_address", "geometry", "name"],
        // origin: map.getCenter(),
        strictBounds: false
      };

      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.setFields(['address_component', 'geometry']);
      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      // autocomplete.bindTo("bounds", map);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const fields = autocomplete.getFields();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        setLatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
      });
    }
    return true;
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: librariesToLoad,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY
  });
  useEffect(() => {
    if (isLoaded && !mapSet) {
      initMap();
    }
  }, [isLoaded]);

  function saveAlert$(alert) {
    if (!(selectedLocations && selectedLocations.length > 0)) {
      return showError({ message: "Please choose a location." });
    }
    saveAlert({ ...data, ...alert, location: selectedLocations.map(d => d.value) });
  }

  function prepareLocation(location) {
    return [location.Community, location.Municipality, location.Area].filter(d => d).filter(d => d).map(d => capitalize(d)).join(", ");
  }
  const fetchLocationSearch = debounce(fetchLocationSearch$, 500)

  async function fetchLocationSearch$({ query }) {
    if (!(query && query.length > 1)) {
      return;
    }
    setOptionsLoading(true);
    let locations = await searchLocation(query);
    if (!(locations && locations.length)) {
      return;
    }
    setOptionsLoading(false);

    setOptions(locations.map(location => {
      return { label: prepareLocation(location), value: location };
    }));
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          closeModal();

        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              closeModal();

            }}
          >
            Lead Alert
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={(e, values) => {
              saveAlert$(values);
            }}>
              <Row id="lead-add-form">
                <Col md={12}>
                  <FormGroup className="ajax-select mt-3 mt-lg-0 select2-container">
                    <Label>Locations</Label>
                    <Select
                      value={selectedLocations}
                      isMulti={true}
                      onInputChange={(e) => {
                        fetchLocationSearch({query: e});
                      }}
                      onChange={(e) => {
                        setSelectedLocations(e);
                      }}
                      options={options}
                      isLoading={optionsLoading}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <AvField
                    type="select"
                    label="Beds"
                    name="beds"
                    value={data.beds}
                  >
                    <option value=""/>
                    <option value="1">1+</option>
                    <option value="2">2+</option>
                    <option value="3">3+</option>
                    <option value="4">4+</option>
                    <option value="5">5+</option>
                  </AvField>
                </Col>
                <Col md={6}>
                  <AvField
                    type="select"
                    label="Baths"
                    name="baths"
                    value={data.baths}
                  >
                    <option value=""/>
                    <option value="1">1+</option>
                    <option value="2">2+</option>
                    <option value="3">3+</option>
                    <option value="4">4+</option>
                    <option value="5">5+</option>

                  </AvField>
                </Col>
                <Col md={6}>

                  <AvField
                    type="number"
                    label="Min Price"
                    name="min_price"
                    value={data.min_price}
                  />
                </Col>
                <Col md={6}>
                  <AvField
                    type="number"
                    label="Max Price"
                    name="max_price"
                    value={data.max_price}
                  />
                </Col>
                <Col md={6}>
                  <AvField
                    type="number"
                    label="Min Area"
                    name="min_area"
                    value={data.min_area}
                  />
                </Col>

                <Col md={6}>
                  <AvField
                    type="number"
                    label="Max Area"
                    name="max_area"
                    value={data.max_area}
                  />
                </Col>
                <Col md={6}>
                  <AvField
                    type="number"
                    label="Min Year Built"
                    name="year_built"
                    value={data.year_built}
                  />
                </Col>
                <Col md={6}>
                  <AvField
                    type="select"
                    label="Frequency"
                    name="frequency"
                    value={data.frequency}
                  >
                    <option value="1">Daily</option>
                    <option value="7">Weekly</option>
                    <option value="14">Biweekly</option>
                    <option value="30">Monthly</option>
                  </AvField>
                </Col>
              </Row>
              <button type="submit" className="d-none" id="alertBtn"/>
            </AvForm>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                closeModal();
              }}>
              Close
            </Button>
            <Button type="button" color="primary" onClick={() => {
              $('#alertBtn').click();
            }}>
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};
export default LeadAlertModal;
