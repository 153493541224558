import {Col, FormGroup, Label, Modal, Row} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import PropTypes from "prop-types"
import ComponentWidthInput from "./ComponentWidthInput";


const PropertySettings = (props) => {


  function handleValidSubmit(event, values) {
    props.updateConfig({ ...props.config, ...values })
  }

  return (

      <AvForm onValidSubmit={handleValidSubmit}>

        <Row className="py-2">
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Heading</Label>
              <AvField
                name="title"
                value={props.config.title || 'Latest Properties'}
                placeholder=""
                type="text"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom03">Sub Heading</Label>
              <AvField
                  name="description"
                  value={props.config.description}
                  placeholder="Lorum ipsum...."
                  type="text"
                  className="form-control"
                  id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">City/Municipality</Label>
              <AvField
                name="municipality"
                value={props.config.municipality}
                placeholder="Brampton"
                type="text"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>

          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Min Price</Label>
              <AvField
                name="min_price"
                value={props.config.min_price}
                placeholder="100000"
                type="number"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Max Price</Label>
              <AvField
                name="max_price"
                value={props.config.max_price}
                placeholder="1000000"
                type="number"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="validationCustom01">Total Properties</Label>
              <AvField
                name="properties_count"
                value={props.config.properties_count}
                placeholder="6"
                type="number"
                className="form-control"
                id="validationCustom03"
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <ComponentWidthInput {...props.config}/>
          </Col>
        </Row>
        <div className="modal-footer">
          <button
              onClick={() => props.close()}
              type="button"
              className="btn btn-danger waves-effect waves-light"
          >Cancel</button>
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button>
        </div>
      </AvForm>
  )
};


export default PropertySettings
