import React, {useState} from "react";
import {PlanExtendModal} from "./plan-extend.modal";
import * as moment from 'moment-timezone';
import {PlanEndModal} from "./plan-end.modal";
import * as api from '../../../helpers/api_helper';
import {CLOSE_WEBSITE, ENABLE_WEBSITE, END_PLAN, EXTEND_AND_CHARGE, EXTEND_PLAN} from "../../../helpers/url_helper";
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {Spinner} from  '../../../components/Common/spinner'

export const WebsiteActions = ({paymentsData}) => {
    const [loading, setLoading] = useState(false);
    const [chargeModal, setChargeModal] = useState({isOpen: false});
    const [planCloseModal, setPlanCloseModal] = useState({isCharge: false, isOpen: false});
    const planEndDate = moment(paymentsData.website.plan_end_date).format('YYYY-MM-DD');

    async function extendWebsite(values) {
        setLoading(true);
        setChargeModal({...chargeModal, isCharge: false, isOpen: false});
        if (!values.isCharge) {
            const response = await api.post(EXTEND_PLAN, {plan_end_date: values.plan_end_date});
            paymentsData.reFetchData();
            setLoading(false);
            if (!response.api_status) {
                return toastr.success("Plan extended successfully.");
            } else {
                return toastr.error(response.message);
            }
        } else {
            const response = await api.post(EXTEND_AND_CHARGE, {
                amount: values.amount,
                plan_end_date: values.plan_end_date
            });
            setLoading(false);

            if (!response.api_status) {
                return toastr.success("Plan extended successfully.");
            } else {
                return toastr.error(response.message);
            }
        }
    }


    async function endPlan() {
        setPlanCloseModal({...planCloseModal, isOpen: false});
        const response = await api.post(END_PLAN);
        paymentsData.reFetchData();
        if (!response.api_status) {
            return toastr.success("Plan Ended successfully.");
        } else {
            return toastr.error(response.message);
        }
    }

    async function closeWebsite() {
        setPlanCloseModal({...planCloseModal, isOpen: false});

        const response = await api.post(CLOSE_WEBSITE);
        paymentsData.reFetchData();
        if (!response.api_status) {
            return toastr.success("Website Closed successfully.");
        } else {
            return toastr.error(response.message);
        }
    }

    async function enableWebsite() {
        setPlanCloseModal({...planCloseModal, isOpen: false});

        const response = await api.post(ENABLE_WEBSITE);
        paymentsData.reFetchData();

        if (!response.api_status) {
            return toastr.success("Website enabled successfully.");
        } else {
            return toastr.error(response.message);
        }
        paymentsData.reFetchData();
    }

    if (loading) {
        return <Spinner/>
    }
    return <>
        <div className="d-flex mb-3" style={{gap: '15px'}}>

            {
                paymentsData.website.isActive > 0 && <>
                    <button className="btn btn-sm btn-primary"

                            onClick={() => {
                                setChargeModal({...chargeModal, isCharge: false, isOpen: true})
                            }
                            }>Plan Extend
                    </button>
                    <button className="btn btn-sm btn-primary" onClick={() => {
                        setChargeModal({...chargeModal, isCharge: true, isOpen: true})
                    }
                    }>Plan Extend & Charge
                    </button>
                    <button className="btn btn-sm btn-warning" onClick={() => {
                        setPlanCloseModal({
                            isOpen: true,
                            message: "Are you sure you want to end plan for this website. AgentEdge will not charge next recurring payment for this website.",
                            fn: endPlan
                        })
                    }
                    }>Stop Recurring Payments</button>
                    <button className="btn btn-sm btn-danger" onClick={() => {
                        setPlanCloseModal({
                            isOpen: true,
                            message: "Are you sure you want to inactive this website.",
                            fn: closeWebsite
                        })
                    }}>Disable Website
                    </button>
                </>
            }
            {
                !paymentsData.website.isActive && <button className="btn btn-sm btn-success" onClick={() => {
                    setPlanCloseModal({
                        isOpen: true,
                        message: "Are you sure you want to activate this website.",
                        fn: enableWebsite
                    })
                }
                }>Enable Website</button>
            }

        </div>

        {
            chargeModal.isOpen &&
            <PlanExtendModal isCharge={chargeModal.isCharge} plan_end_date={planEndDate}
                             onSave={extendWebsite}
                             onClose={() => {
                                 setChargeModal({...chargeModal, isOpen: false, isCharge: false})
                             }
                             }/>
        }
        {
            planCloseModal.isOpen &&
            <PlanEndModal
                message={planCloseModal.message}
                onClose={() => {
                    setPlanCloseModal({isOpen: false})
                }} closePlan={planCloseModal.fn}/>
        }
    </>
}
