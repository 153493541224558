import { Card, CardBody, Col, Row } from "reactstrap";
import React from "react";
import SpanCheck from "../../../components/Common/SpanCheck";
import { maxLength } from "../../../helpers/util.helper";
import { deleteAlert } from "../leads.service";
import Moment from "react-moment";
import LeadBasicInfo from "../components/lead-basic-info";

const NeighbourhoodWatch = ({ neighbourhoodWatch, setModal, getAlerts, wu_id }) => {
  function getLocationData(location) {
    try {
      if (location.startsWith("[")) {
        let locations = JSON.parse(location);
        return <>
          {
            locations.map(location => {
              if (location.Community && location.Community !== "All") {
                return location.Community + ", " + location.Municipality + ", " + (location.Area || "");
              } else if (location.Municipality && location.Municipality !== "All") {
                return location.Municipality + ", " + (location.Area || "");
              } else {
                return location.Area;
              }
            }).join(", ")
          }
        </>;
      } else {
        return location;
      }
    } catch (e) {
      return location;
    }
  }
  async function deleteAlert$(alert_id) {
    await deleteAlert({ la_id: alert_id, wu_id });
    getAlerts();
  }

  return <Card>
    <CardBody>
      <Row>

        {
          neighbourhoodWatch && neighbourhoodWatch.length > 0 ?
            <div className="list-group w-100">
              {neighbourhoodWatch.map((item, key) => (
                <div className="list-group-item  flex-column align-items-start" key={key}>
                  <div className="d-flex flex-row ">
                    <div className="d-flex w-100">
                      <div className="flex-column w-100">
                        <div
                          className="w-100 mb-1 font-size-16 font-weight-bold camelCase align-content-between justify-content-between d-flex">
                          {
                            getLocationData(item.location)
                          }
                          <small className="d-flex flex-column">
                            <Moment format="MMM DD, YYYY hh:mm A">{item.created}</Moment>
                          </small>
                        </div>
                        <Row>
                          <Col md={8} sm={12}>
                            <p className="mb-2">
                              <b>Radius</b>: {item.search_radius && item.search_radius + "km" || "-"}
                            </p>
                            <p className="mb-1">
                              <b>Message</b>: {maxLength(item.message, 100)}
                            </p>

                            <p className="mb-2">
                              <SpanCheck
                                type={item.emailVerified ? "success" : "danger"}/> {item.emailVerified ? "Email Verified" : "Email Not Verified"}
                            </p>
                          </Col>
                          <Col md={4} sm={12}>
                            <LeadBasicInfo lead={item}/>
                          </Col>
                          <Col md={12}>
                            <div>
                              <button className="btn btn-sm btn-primary"
                                      onClick={() => setModal({ showModal: true, email: true })}><span
                                className="fa fa-envelope"/> Email
                              </button>
                              <a href={"tel:" + item.contact} className="btn btn-sm btn-success ml-2"> <span
                                className="fa fa-phone"/> Call Now</a>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              }
            </div>

            :
            <Col md={12} className="text-center mt-2">
              <p>No data available.</p>

            </Col>
        }
      </Row>
    </CardBody>

  </Card>;
};


export default NeighbourhoodWatch;
